import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import Cookies from "js-cookie";
import { AppConstant, firebaseConfig, firebaseVapidKey} from "../../../components/src/Constant";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from 'firebase/app';
import { getStorageData } from "../../../framework/src/Utilities";
interface UserData {
  userEmail: string,
  firstName: string,
  lastName: string
}
// Customizable Area End

export const configJSON = require("./config");
const landingImages = require("./assets");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userPasswords: string;
  userEmail: string;
  enablePasswordField: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  userData: UserData;
  emailValidationError: string;
  passwordValidationError: string;
  validationID:boolean;
  loginIDError:boolean;
  passwordError:boolean;
  rcmState:string
  
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class EmailAccountLoginSignupController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
   apiNewEmailLoginCallId: string = "";
  validationNewApiCallId: string = "";
  firebaseTokenApiCallId: string = "";
  labelNewTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      userEmail: "",
      userPasswords: "",
      userData: {} as UserData,
      enablePasswordField: true,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      emailValidationError: '',
      passwordValidationError: "",
      validationID:false,
      loginIDError:false,
      passwordError:false,
      rcmState:""


    };
    this.labelNewTitle = configJSON.labelNewTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callNewGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.userAlreadyLoggedIn()
    // Customizable Area End
  }
  // Customizable Area Start
  userAlreadyLoggedIn = async () => {
    const userData: any = Cookies.get("userProfile");
    const tokenKey = localStorage.getItem("authToken")
    const resetFCMToken = await getStorageData('resetFCMToken')
    this.setState({rcmState :resetFCMToken })
    if (this.state.rcmState === 'True') {
      if (JSON.parse(userData).data.attributes.user_role !== "admin") {
        this.firebaseClientToken('')
      }
      localStorage.clear()
      Cookies.remove('userProfile')
      this.setState({
        rcmState:'True'
      })
    }
    if (userData && tokenKey && this.state.rcmState !== "True") {
      this.props.navigation.navigate("LandingPage")
      return false
    } 
  
  }
  handleClickNewShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };
  setNewEmail = (text: string) => {
    this.setState({
      userEmail: text,
    });
  };

  setNewPassword = (text: string) => {
    this.setState({
      userPasswords: text,
    });
  };
  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if(getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userLoginId = message.getData(getName(MessageEnum.LoginUserName));
      const userPassword = message.getData(getName(MessageEnum.LoginPassword));
      if (userLoginId && userPassword) {
        this.setState({
          userEmail: userLoginId,
          userPasswords: userPassword,
        });
      }
    }
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (true) {
          case apiRequestCallId === this.apiNewEmailLoginCallId:
            this.apiNewEmailLoginCallIdApi(responseJson)
            break;
            case apiRequestCallId === this.validationNewApiCallId:
          this.setState({validationID:true})
          break;
          default:
          }
      }
    // Customizable Area End
  }
  apiNewEmailLoginCallIdApi = (responseJson: any) => {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      this.saveNewLoggedInUserData(responseJson);
      const showProfileImage=responseJson.user.data.attributes.profile_image
      if( showProfileImage== null){
        localStorage.setItem("profileImage",`${landingImages.defaultImage}`)
      }
      else{
        localStorage.setItem("profileImage", showProfileImage )
      }
      Cookies.set("userProfile", JSON.stringify(responseJson.user))
      this.sendNewLoginSuccessMessage();
      if (responseJson.user.data.attributes.user_role !== "admin") {
        this.requestFirebasePermission()
      }
    } else {
      if(responseJson.errors[0].failed_login === 'Incorrect password'){
        this.setState({
          passwordValidationError:AppConstant.INCORRECT_PASSWORD,
          emailValidationError: "",
          loginIDError:false,
          passwordError:true
        })
      }
     else if(responseJson.errors[0].failed_login === "Account not found"){
        this.setState({
          passwordValidationError:"",
          emailValidationError: AppConstant.LOGIN_ID ,
          loginIDError:true,
          passwordError:false
        })
      }
      else if(responseJson.errors[0].failed_login === "Account not activated"){
        this.setState({
          passwordValidationError:"",
          emailValidationError: AppConstant.NOT_ACTIVATED ,
          loginIDError:true,
          passwordError:false
        })
      }
    }
  }
  sendNewLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));
    msg.addData(getName(MessageEnum.LoginUserName), this.state.userEmail);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.userPasswords);
    this.send(msg);
    this.props.navigation.navigate("LandingPage")
  }

  requestFirebasePermission = () => {
    const firebaseApp = initializeApp(firebaseConfig);
    const messaging = getMessaging(firebaseApp);
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        return getToken(messaging, { vapidKey: firebaseVapidKey })
          .then((firebaseToken: any) => {
            if (firebaseToken) {
              this.firebaseClientToken(firebaseToken)
              onMessage(messaging, (payload: any) => {
                this.showNotification(payload)
              });
            } 
          })
      }
    });
  }

  navigateToPage = (type: string) => {
    if (type === 'notification') {
      this.props.navigation.navigate("UserNotificationUnread");
    } else {
      this.props.navigation.navigate("UserReminderUnread");
    }
  };

  showNotification = (payload: any) => {
    const { title } = payload.notification;
    let notification=  new Notification(title);
    notification.onclick = () => {
      this.navigateToPage(payload.data.type)
    };
    return notification;
  };

  firebaseClientToken = (firebaseToken: string) => {
    let token = localStorage.getItem("authToken")
    const headers = {
      "token": token,
      "Content-Type": configJSON.validationApiContentType,
    };

    let requestBody = {"device_id": firebaseToken}
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.firebaseTokenApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateDevice
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  saveNewLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));
      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );
      this.send(msg);
    }
  }
  goToForgotPasswordScreen() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
  }
  doneEmailLogIn = (event : any) => {
    event.preventDefault()
    if (
      this.state.userEmail === null ||
      this.state.userEmail.length === 0
    ) {
      this.setState({ emailValidationError: configJSON.errorEmailNotValid, passwordValidationError: "" })
      return false;
    }

    if (this.state.userPasswords === null || this.state.userPasswords.length === 0) {
      this.setState({ passwordValidationError: configJSON.errorPasswordNotValid, emailValidationError: "" })
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this. apiNewEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        "data": {
          "type": "loginid_account",
          "attributes": {
            "login_id": this.state.userEmail,
            "password": this.state.userPasswords,
          }
        }
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callNewGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationNewApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
