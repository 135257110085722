// App.js - WEB

import React, { Component } from "react";
import { View } from "react-native";
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseConfig } from '../../components/src/Constant'
import { connect } from 'react-firebase'
import './App.css';
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import ProjectReporting from "../../blocks/ProjectReporting/src/ProjectReporting";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import CfCommissionSplitCalculation from "../../blocks/CfCommissionSplitCalculation/src/CfCommissionSplitCalculation";
import ProjectTemplates from "../../blocks/ProjectTemplates/src/ProjectTemplates";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
// import Notifications from "../../blocks/notifications/src/Notifications";
import Notifications from "../../blocks/notifications/src/NotificationsTd";
import SentNotifications from "../../blocks/notifications/src/SentNotifications";
import CreateNotifications from "../../blocks/notifications/src/CreateNotification";
import DraftNotifications from "../../blocks/notifications/src/DraftNotification";
import ScheduledNotifications from "../../blocks/notifications/src/ScheduledNotifications"; 
import CreateRemindersNotifications from "../../blocks/notifications/src/CreateReminder.web";
import ScheduledRemindersNotifications from "../../blocks/notifications/src/ScheduledReminder.web";
import SentRemindersNotifications from "../../blocks/notifications/src/RemindersNotifications.web";
import RealtimeUpdates from "../../blocks/RealtimeUpdates/src/RealtimeUpdates";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import CfMetatrader5Integration from "../../blocks/CfMetatrader5Integration/src/CfMetatrader5Integration";
// import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import UploadImage from "../../blocks/ContentManagement/src/UploadImage.web";
import EditHeading from "../../blocks/ContentManagement/src/EditHeading.web";
import EditBody from "../../blocks/ContentManagement/src/EditBody.web";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import ProjectPortfolio from "../../blocks/ProjectPortfolio/src/ProjectPortfolio";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import AutomaticReminders from "../../blocks/AutomaticReminders/src/AutomaticReminders";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import EmailAccountLoginSignup from '../../blocks/email-account-login/src/EmailAccountLoginSignup.web';
import AccountAccess from '../../blocks/accountgroups/src/AccountAccess.web'
import AddAccount from '../../blocks/accountgroups/src/AddAccount.web'
import ServiceSpecificSettingsAdmin from "../../blocks/ServiceSpecificSettingsAdmin/src/ServiceSpecificSettingsAdmin.web";
import Settings from '../../blocks/Settings5/src/Settings.web'
import EditProfile from '../../blocks/Settings5/src/EditProfile.web'
// import CommissionSplit from '../../blocks/CfCommissionSplitCalculation/src/CommissionSplit.web'
import CommissionContainer from '../../blocks/CfCommissionSplitCalculation/src/CommissionContainer.web'
import CommissionEdit from '../../blocks/CfCommissionSplitCalculation/src/CommissionEdit.web'
import CommissionNote from '../../blocks/CfCommissionSplitCalculation/src/CommissionNote.web'
import ShowCommissionNotes from '../../blocks/CfCommissionSplitCalculation/src/ShowCommissionNotes.web'
import MonthlyReport from '../../blocks/CfCommissionSplitCalculation/src/MonthlyReport.web'
import FinalMonthlyReport from '../../blocks/CfCommissionSplitCalculation/src/FinalMonthlyReport.web'
import UserNotificationAll from '../../blocks/notifications/src/UserNotificationAll.web'
import UserNotificationRead from '../../blocks/notifications/src/UserNotificationRead.web'
import UserNotificationUnread from '../../blocks/notifications/src/UserNotificationUnread.web'
import UserReminderAll from '../../blocks/notifications/src/UserReminderAll.web'
import UserReminderRead from '../../blocks/notifications/src/UserReminderRead.web'
import UserReminderUnread from '../../blocks/notifications/src/UserReminderUnread.web'
import DashboardTd from '../../blocks/dashboard/src/DashboardTd.web'

const routeMap = {
ProjectReporting:{
 component:ProjectReporting,
path:"/ProjectReporting"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
CfCommissionSplitCalculation:{
 component:CfCommissionSplitCalculation,
path:"/CfCommissionSplitCalculation"},
ProjectTemplates:{
 component:ProjectTemplates,
path:"/ProjectTemplates"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
// Notifications:{
//  component:Notifications,
// path:"/Notifications"},
UserNotificationAll:{
component:UserNotificationAll,
path:"/Notifications/All"
},
UserNotificationRead:{
  component:UserNotificationRead,
  path:"/Notifications/Read"
  },
  UserNotificationUnread:{
    component:UserNotificationUnread,
    path:"/Notifications/Unread"
    },
SentNotifications:{
  component:SentNotifications,
 path:"/Notifications/SentNotifications"},
 DraftNotifications:{
  component:DraftNotifications,
 path:"/Notifications/DraftNotifications"},
 CreateNotifications:{
  component:CreateNotifications,
 path:"/Notifications/CreateNotifications"},
 ScheduledNotifications:{
  component:ScheduledNotifications,
 path:"/Notifications/ScheduledNotifications"},
 SentRemindersNotifications:{
  component:SentRemindersNotifications,
 path:"/Remainder/Sent"
 },
 CreateRemindersNotifications:{
  component:CreateRemindersNotifications,
 path:"/Remainder/Create"
 },
 ScheduledRemindersNotifications:{
  component:ScheduledRemindersNotifications,
 path:"/Remainder/Scheduled"
 },
 UserReminderAll: {
  component: UserReminderAll,
  path: "/Reminders/All"
},
UserReminderRead: {
  component: UserReminderRead,
  path: "/Reminders/Read"
},
UserReminderUnread: {
  component: UserReminderUnread,
  path: "/Reminders/Unread"
},
Dashboard: {
  component: DashboardTd,
  path: "/Dashboard"
},
RealtimeUpdates:{
 component:RealtimeUpdates,
path:"/RealtimeUpdates"},
ServiceSpecificSettingsAdmin:{
 component:ServiceSpecificSettingsAdmin,
path:"/ServiceSpecificSettingsAdmin"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
CfMetatrader5Integration:{
 component:CfMetatrader5Integration,
path:"/CfMetatrader5Integration"},
UploadImage:{
  component:UploadImage,
 path:"/ContentManagement/uploadImage"},
EditBody:{
  component:EditBody,
 path:"/ContentManagement/editBody"},
EditHeading:{
  component:EditHeading,
 path:"/ContentManagement/editHeading"},
// ContentManagement:{
//  component:ContentManagement,
// path:"/ContentManagement"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
ProjectPortfolio:{
 component:ProjectPortfolio,
path:"/ProjectPortfolio"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
AutomaticReminders:{
 component:AutomaticReminders,
path:"/AutomaticReminders"},
AdminConsole:{
 component:AdminConsole,
path:"/AdminConsole"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},

EmailAccountLoginSignup:{
  component:EmailAccountLoginSignup,
 path:"/",
 exact: true
},

AddAccount:{
  component:AddAccount,
 path:"/AddAccount"},

 EditAccount:{
  component:AddAccount,
 path:"/EditAccount"
},
CommissionEdit: {
  component: CommissionEdit,
  path: "/CommissionEdit"
},

ShowCommissionNotes: {
  component: ShowCommissionNotes,
  path: "/ShowCommissionNotes"
},
MonthlyReport: {
  component: MonthlyReport,
  path: "/MonthlyReport"
},
FinalMonthlyReport: {
  component: FinalMonthlyReport,
  path: "/FinalMonthlyReport"
},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},

AccountAccess:{
  component:AccountAccess,
 path:"/AccountAccess"
},

EditProfile:{
  component:EditProfile,
 path:"/EditProfile"
},
CommissionNote:{
  component:CommissionNote,
  path:"/CommissionNote"
},
 Settings:{
  component:Settings,
 path:"/Settings"
},

CommissionSplit: {
  component: CommissionContainer,
  path: "/CommissionSplit"
},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},

//   Home: {
// component:HomeScreen,
//     path: '/',
//     exact: true
//   },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = initializeApp(firebaseConfig);
 class App extends Component {

  render() {

    const defaultAnalytics = getAnalytics(firebaseAPI);
    logEvent(defaultAnalytics,'APP_Loaded');
    
    return (
      <View>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
