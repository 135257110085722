import React from "react";
// Customizable Area Start
import {
  Container,
  AppBar,
  Toolbar,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
const tradeImages = require("./assets");
import "./Style.css";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
const font = "'Poppins', sans-serif";

// Customizable Area End
import EmailAccountLoginSignupController, {
  configJSON,
  Props,
} from "./EmailAccountLoginSignupController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: font,
  },
});
export default class EmailAccountLoginSignup extends EmailAccountLoginSignupController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container
          maxWidth={"xl"}
          style={webStyle.pageContainer}
          className="pageContainer"
        >
          <div style={webStyle.mainWrapper}>
            <AppBar style={{ backgroundColor: "#0c0c0c" }}>
              <Toolbar>
                <img src={tradeImages.imgLogo} />
              </Toolbar>
            </AppBar>

            <div style={webStyle.loginForm} className="mainContainer">
              <div
                style={{ ...webStyle.logoVertical, textAlign: "center" }}
                className="logoVertical"
              >
                <img
                  src={tradeImages.logoVertical}
                  width="238px"
                  height="130px"
                  className="loginImage"
                />
              </div>
              <form
                data-test-id="emailSubmit"
                onSubmit={(event: any) => this.doneEmailLogIn(event)}
                style={webStyle.marginAuto}
                className="loginField"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                  }}
                >
                  <TextField
                    data-test-id="btnEmailLogIn"
                    className="loginInput"
                    InputProps={{
                      style: { color: "#fff" },
                      disableUnderline: true,
                    }}
                    style={
                      this.state.loginIDError || this.state.emailValidationError
                        ? webStyle.loginFieldError
                        : webStyle.loginField
                    }
                    type="text"
                    fullWidth
                    placeholder={configJSON.loginId}
                    value={this.state.userEmail}
                    onChange={(event: any) =>
                      this.setNewEmail(event.target.value)
                    }
                  ></TextField>
                  {this.state.emailValidationError && (
                    <span className="loginError" style={webStyle.errorHandler}>
                      {this.state.emailValidationError}
                    </span>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                  }}
                >
                  <TextField
                    data-test-id="txtInputPassword"
                    className="loginInput"
                    style={
                      this.state.passwordError ||
                      this.state.passwordValidationError
                        ? webStyle.loginFieldError
                        : webStyle.loginField
                    }
                    type={this.state.enablePasswordField ? "password" : "text"}
                    fullWidth
                    placeholder={configJSON.placeHolderPassword}
                    value={this.state.userPasswords}
                    onChange={(event: any) =>
                      this.setNewPassword(event.target.value)
                    }
                    InputProps={{
                      style: { color: "#fff" },
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color="primary"
                            aria-label="toggle password visiblity"
                            onClick={this.handleClickNewShowPassword}
                            edge="end"
                          >
                            {this.state.enablePasswordField ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                  {this.state.passwordValidationError && (
                    <span className="loginError" style={webStyle.errorHandler}>
                      {this.state.passwordValidationError}
                    </span>
                  )}
                </div>
                <div
                  style={{
                    ...webStyle.forgotPasswordText,
                    marginTop: this.state.passwordValidationError
                      ? "-42px"
                      : "-22px",
                  }}
                  className="forgotPassword"
                >
                  <small
                    data-test-id="btnForgotPassword"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.goToForgotPasswordScreen()}
                  >
                    {configJSON.forgotPassword}
                  </small>
                </div>

                <div style={webStyle.loginBtn} className="loginBtn">
                  <Button
                    variant="contained"
                    className="loginBtns"
                    style={webStyle.btn}
                    type="submit"
                  >
                    {configJSON.btnTxtLogin}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle: any = {
  marginAuto: {
    margin: "auto",
  },
  pageContainer: {
    backgroundImage: `url(${tradeImages.bgImg})`,
    backgroundPosition: `center center`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    minWidth: "100vw",
    height: "100vh",
    position: "fixed",
    top: 0,
    bottom: 0,
  },
  mainWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    width: `100%`,
    marginTop: "2%",
    padding: 0,
    maxWidth: "100%",
    justifyContent: "space-evenly",
  },
  logoVertical: {
    marginBottom: "30px",
  },
  // loginForm: {
  //   maxWidth: "450px",
  //   margin: "0 auto",

  // },
  loginFormauto: {
    marginTop: "100px",
    marginBottom: "100px",
    overflow: "auto",
    maxHeight: "100%",
  },
  loginField: {
    borderRadius: "10px",
    border: "1px solid #fff",
    marginBottom: "30px",
    fontSize: "22px",
    fontFamily: "poppins",
    width: "581px",
    // marginLeft:"-15%",
    padding: "20px",
    "::placeholder": {
      color: "#fff",
      background: "#f9f9f9",
    },
  },
  loginFieldError: {
    borderRadius: "10px",
    border: "2px solid red",
    marginBottom: "10px",
    fontFamily: "poppins",
    width: "581px",
    // marginLeft:"-15%",
    padding: "20px",
    "::placeholder": {
      color: "#fff",
      background: "#f9f9f9",
    },
  },
  forgotPasswordText: {
    textAlign: "right",
    color: "#fff",
    marginBottom: "15px",
    fontFamily: "poppins",
    fontSize: "16px",
    //  marginRight:"-108px" ,
  },
  loginBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  btn: {
    borderRadius: "35px",
    background:
      "linear-gradient(19.24deg, #07D9FF -19.29%, #04B0F8 33.7%, #0076EF 126.05%)",
    color: "#fff",
    fontSize: "22px",
    textTransform: "none" as const,
    fontFamily: "poppins",
    width: "247px",
    height: "70px",
  },
  errorHandler: {
    color: "red",
    fontSize: "16px",
    marginBottom: "20px",
    // marginLeft:"-15%",
  },
};
// Customizable Area End
