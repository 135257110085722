import React from "react";

// Customizable Area Start
import {
    TextField,
    Box,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody, 
    TableSortLabel,
    withWidth,
    Drawer
  } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import SideBar from "../../../components/src/SideBar";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import moment from 'moment';
import "../assets/css/style.css";
// Customizable Area End

import NotificationsTdController, {
  Props,
} from "./NotificationsTdController";
import NotificationsHeaderSent from "../../../components/src/NotificationsHeader";
import SecondNotiHeaderSent from "../../../components/src/SecondNotiHeader";
    // Customizable Area Start
    const theme = createTheme({
      typography: {
        fontFamily: 'Poppins !important'
      },
    });
export class SentNotifications extends NotificationsTdController {
    // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { sortOrder, sortColumn } = this.state;
    const {  width } = this.props;
    const isMobile = /s/.test(width);
    return (

      /* @ts-ignore */
      <ThemeProvider theme={theme}>
      <Box style={{...stylesSentNoti.fullscreenBody,fontFamily: 'Poppins !important'}} className="table_body">
        {/* @ts-ignore */}
          <Box style={stylesSentNoti.sidebarParentBodyBody}>
          {isMobile? 
            <div
            style={{position:"fixed", top:"10px",left:"12px",background: "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
          }}
             >
             <IconButton
            onClick={this.handleDrawerToggles}
            style={{color:'white'}}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
          open={this.state.mobileOpen}
          onClose={this.handleDrawerToggles}
          ModalProps={{
            keepMounted: true,
          }}
        >
          { <SideBar
           style={stylesSentNoti.sidebar}
              navigate={this.props.navigation.navigate}
              activeMenuItem={"Notifications & Reminders"}
              userData={this.state.userData}
            />}
          
            </Drawer>
            </div>:
     <div style={stylesSentNoti.sidebar} className="sidebarResponse"> 
     <SideBar
              navigate={this.props.navigation.navigate}
              activeMenuItem={"Notifications & Reminders"}
              userData={this.state.userData}
            />
          </div>}
          </Box>
          <Box style={stylesSentNoti.divContainerBody} className="width_100_p">
            <div style={stylesSentNoti.topNavBarBody}>
              <div style={stylesSentNoti.bellIconBody}>
                {/* <NotificationsActiveOutlinedIcon data-test-id="noti" onClick={() => this.props.navigation.navigate("Notifications")} style={stylesSentNoti.pointerBody} /> */}
              </div>
              <div style={stylesSentNoti.pageTitleBody} className="padding_0_res">
              <NotificationsHeaderSent state={this.state}
                    goToSentNotifications={this.goToSentNotifications}
                    goToReminders={this.goToReminders}
                     clickTab={this.clickTab}
                  />
              </div>
            <Box m={{ xs: "8px", md: "30px" }} sx={stylesSentNoti.scroll}>
            <Box className="BoxFlex">
             <SecondNotiHeaderSent state={this.state}
             data-test-id="goToSentNotifications"
                    goToSentNotifications={this.goToSentNotifications}
                    goToCreate={this.goToCreate}
                    goToDraft={this.goToDraft}
                    goToScheduled={this.goToScheduled}
                    clickTab={this.clickTabNoti}
                  />
              <Box className="justifyContentCenter" style={{paddingRight:"10px"}}>
              <TextField
              style={{fontFamily: 'Poppins !important'}}
              className="searchInput"
                label="Search by Title"
                data-test-id="searchInputSentNoti"
                value={this.state.searchState}
                onChange={this.searchInput}
                InputProps={{ 
                  endAdornment: (
                    <InputAdornment variant="filled" position="end">
                      <IconButton
                        onClick={() => {
                          this.setState(prevState => ({
                            ...prevState,
                            searchTerm: prevState.searchState,
                            sentJsonData: {
                              items: [],
                              page: 0,
                              hasMore: true
                            },
                            searchStatus:false
                          }), () => this.getnotificationsData())
                        }}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              </Box>
             </Box>
             <Box fontFamily={"Poppins"} className="scrollFixed" style={{ maxHeight: "70vh", marginBottom: "0px !important" }}  >
              <Table  className="PTable" >
                <TableHead>
                  <TableRow className="tableRow" style={{background:"rgb(220, 220, 220) none repeat scroll 0% 0%"}}>
                    <TableCell style={{paddingLeft:"25px"}}>
                        Title
                    </TableCell>
                    <TableCell style={{textAlign:"center"}}>
                      <TableSortLabel
                        active={sortColumn === 'created_at'}
                        direction={sortOrder}
                        data-test-id="created"
                        onClick={() => this.handleSortByDate()}
                      >
                        Date created
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={{textAlign:"center"}}>
                        Created by
                    </TableCell>
                    <TableCell style={{textAlign:"center"}}>
                        Sent date
                    </TableCell>
                    <TableCell className="lastthree" style={{textAlign:"center"}}>
                        Notification sent
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.sentJsonData.items.length>0 &&
                    this.state.sentJsonData.items
                    .map((row:any) => (
                      <TableRow key={row.id} className="tableRow">
                        <TableCell style={{overflow:"hidden" , padding:"15px 8px",paddingLeft:"25px"}}>{row.attributes.headings}</TableCell>
                        <TableCell style={{overflow:"hidden", padding:"8px 8px",width:"195px",textAlign:"center"}}>{moment(row.attributes.created_at).format('D-M-YYYY, h.mm A')}</TableCell>
                        <TableCell style={{overflow:"hidden",padding:"8px 8px",width:"180px",textAlign:"center"}}>{row.attributes.created_by.first_name}</TableCell>
                        <TableCell style={{overflow:"hidden" , padding:"8px 8px",width:"195px",textAlign:"center"}}>
                        {moment.utc(row.attributes.sent_date + ' ' + row.attributes.sent_time, 'YYYY-MM-DD hh:mm').local().format('D-M-YYYY, h.mm A')}
                          </TableCell>
                        <TableCell style={{overflow:"hidden",padding:"8px 8px",width:"180px",textAlign:"center"}} className="lastthree">{row.attributes.repitation}</TableCell>
                      </TableRow>
                    ))}
                      {(this.state.sentJsonData.items.length === 0 && !this.state.sentJsonData.hasMore) &&
                        <TableRow style={{ textAlign: "center" }}>
                          <TableCell align="center" style={{ textAlign: "center" }} colSpan={4}>No Notification found</TableCell>
                        </TableRow>
                      }
                      <TableRow ref={this.loadingRef} style={{ textAlign: "center" }}>
                        {(this.state.sentJsonData.hasMore) &&
                          <TableCell align="center" style={{ textAlign: "center" }} colSpan={4}>Loading...</TableCell>
                        } </TableRow>
                </TableBody>
              </Table>
              </Box>
            </Box>
            </div>
            </Box>
        </Box>
     </ThemeProvider>
      
    );
    // Customizable Area End
  }
}

// Customizable Area Start
let stylesSentNoti: any = {
    fullscreenBody: {
      position:"fixed",
      top:0,
      bottom:0,
      height: "100vh !important",
      minWidth: "100vw",
      fontFamily: "poppins !important",
      display: 'flex',
      boxSizing:'border-box'
    },
    sidebar: {
      width: "24vw",
      fontFamily: 'Poppins',
      marginRight: "-100px",
      zIndex: "9",
      height: "100%",
      position:"fixed",
      bottom : 0,
      top:0,
    },
    scroll: {
      height: 'calc(100vh - 200px)',
      overflow: 'auto',
    },
    divContainerBody: {
      gap: "10px",
      height: "100%",
      width: "calc(100% - 24%)",
      margin: "0 auto"
    },
    pointerBody:{
      fontFamily: 'Poppins',
      position: "fixed",
      right: "30px",
      top: "20px",
      color: "black",
      width: "32px",
      height: "32px",
      display: "flex",
      justifyContent: "right",
      alignItems: "center",
      },
    topNavBarBody: {
      width: "100%"
    },
    sidebarParentBodyBody:{
      width:'24%',
      height:'100vh',
    },
    bellIconBody:{
        textAlign: "right",
        width: "100%",
        height:"75px"
      },
    pageTitleBody:{
      borderTop:"1px solid rgba(128, 128, 128, 0.43)",
        padding:"0px 0px 0px 50px",
        boxShadow: "rgb(34, 34, 34) 0px 4px 6px -6px",
      },
}
// Customizable Area End
export default (withWidth() (SentNotifications));
