import React from "react";
// Customizable Area Start
import SideBar from "../../../components/src/SideBar";
import MenuIcon from "@material-ui/icons/Menu";
import "../../email-account-login/src/Style.css";

import {
  Table,
  TableBody,
  Tab,
  TableCell,
  AppBar,
  TableRow,
  TableHead,
  Button,
  IconButton,
  TextField,
  Drawer,
  withWidth,
  InputAdornment,
  Checkbox, Typography, Box
} from "@material-ui/core";
import "../../notifications/assets/css/style.css";
import SearchIcon from "@material-ui/icons/Search";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { bellIcon, unreadBellIcon } from "./assets";
import UnreadNotificationList from "../../../components/src/UnreadNotificationList.web";
const commisssionImages = require("./assets");
const theme = createTheme({
  typography: {
    fontFamily: "Poppins !important",
  },
});
// Customizable Area End
import CommissionSplitController, { Props } from "./CommissionSplitController";
export class CommissionContainer extends CommissionSplitController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const { width } = this.props;
    const isMobile = /s/.test(width);
    const { 
      isLoadingNoti,
      unreadNotificationRecords,
      scrollPage,
      usersAccount } = this.state;
    console.log("this.state.getUserPermissionDataCommission", this.state.getUserPermissionDataCommission)
    return (
      <ThemeProvider theme={theme}>
        <div style={webStyle.pageContainers}>
          {isMobile ? (
            <div style={webStyle.sidebarIcon} className="sidebarIcon">
              <IconButton
                style={{ color: "white" }}
                onClick={this.handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                ModalProps={{
                  keepMounted: true,
                }}
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
              >
                {
                  <SideBar
                    style={webStyle.sidebar}
                    userData={this.state.userData}
                    navigate={this.props.navigation.navigate}
                    activeMenuItem={"CommissionSplit"}
                  />
                }
              </Drawer>
            </div>
          ) : (
            <div style={webStyle.sidebar} className="sidebarResponse">
              <SideBar
                navigate={this.props.navigation.navigate}
                activeMenuItem={"CommissionSplit"}
                userData={this.state.userData}
              />
            </div>
          )}
          <div style={webStyle.pageDefault} className="accessPageDefault">
            <AppBar
              position="fixed"
              style={webStyle.pageContainerHeader}
              className="accessAddHeader"
            >
              {this.state.userData.userType !== "admin" &&
              <>
              <img
                src={unreadNotificationRecords.length > 0 ? unreadBellIcon : bellIcon}
                style={webStyle.notificationIcon}
                data-test-id="handleClickPopOver"
                onClick={this.handleClickPopOverNoti}
              />
              <UnreadNotificationList
                anchorEl={this.state.anchorElNoti}
                data-test-id="handleClosePopOver"
                handleClosePopOver={this.handleClosePopOverNoti}
                navigate={this.props.navigation.navigate}
                notificationData={unreadNotificationRecords}
                isLoading={isLoadingNoti}
                unreadNotificationList={this.onScrollUnreadNotificationList}
                handleClearAll={this.handleClearAll}
                scrollPage={scrollPage}
              />
              </>
          }
            </AppBar>
            <div className="padding_0_res" 
            style={webStyle.divider} >
              <div style={webStyle.pageHeading}>
                <Tab
                  label="Account Commission"
                  style={{
                    fontSize: "21px",
                    borderBottom: "3px solid #000",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                  onClick={this.accountCommissionPage}
                  className="AccounComPage"
                ></Tab>
                {/* {this.state.getUserPermissionDataCommission == "View Commissions" && */}
                  <Tab
                    label="Monthly Report"
                    style={webStyle.editProfileBtn}
                    onClick={this.monthlyReportPage}
                    className="AccounComPage"
                  ></Tab>
                {/* } */}
              </div>
            </div>
            <div className="commissionContainer finalReportpages" style={{ height: "80%" }}>
              <div style={webStyle.searchField} className="searchField">
                <TextField
                  style={webStyle.searchBar}
                  className="searchInputField"
                  placeholder="Search by Login ID and Account Number"
                  value={this.state.searchState}
                  data-test-id="searchButton"
                  onChange={this.searchInput}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={this.searchButton}>
                          <SearchIcon style={webStyle.search} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <div
                style={{ ...webStyle.showUserAccounts, marginLeft: "10%" }}
                className="comTable"
              >
                <Table style={{ tableLayout: "fixed" }}>

                  <TableRow
                    style={webStyle.accountDetails}
                    className="accountData"
                  >
                    {/* <TableCell
                      style={{
                        ...webStyle.accountCell,
                        fontWeight: 700,
                        display: "flex",
                        justifyContent: "evenly",
                        width: "173.4px",
                      }}
                    > */}
                    <TableCell
                      style={{
                        ...webStyle.accountCell,
                        fontWeight: 700,
                        display: "flex",
                        justifyContent: "evenly",
                        width: "173.4px",
                      }}
                    >
                      <Checkbox
                        style={{ color: "black" }}
                        checked={this.state.selectAll}
                        data-test-id="handleSelectAllToggle"
                        onChange={this.handleSelectAllToggle}
                      />
                      Login ID
                    </TableCell>
                    <TableCell
                      style={{
                        ...webStyle.accountCell,
                        fontWeight: 700,
                        width: "160px",
                      }}
                    >
                      MT5 Account No
                    </TableCell>
                    <TableCell
                      style={{
                        ...webStyle.accountCell,
                        fontWeight: 700,
                        width: "100px",
                      }}
                    >
                      MT%
                    </TableCell>
                    <TableCell
                      style={{
                        ...webStyle.accountCell,
                        fontWeight: 700,
                        width: "100px",
                      }}
                    >
                      Tink%
                    </TableCell>
                    <TableCell
                      style={{
                        ...webStyle.accountCell,
                        fontWeight: 700,
                        width: "100px",
                      }}
                    >
                      Deepest%
                    </TableCell>
                    <TableCell
                      style={{
                        ...webStyle.accountCell,
                        fontWeight: 700,
                        width: "100px",
                      }}
                    >
                      Affiliate%
                    </TableCell>
                    <TableCell
                      style={{
                        ...webStyle.accountCell,
                        fontWeight: 700,
                        width: "100px",
                      }}
                    >
                      Edit
                    </TableCell>
                    <TableCell
                      style={{
                        ...webStyle.lastCell,
                        fontWeight: 700,
                        width: "100px",
                      }}
                    >
                      Note
                    </TableCell>
                  </TableRow>

                  <TableBody style={{ display: "flex", marginBottom: "auto", flexDirection: "column" }}
                  >
                    {usersAccount.length > 0 ?
                      usersAccount.map((data: any) => {
                        return (
                          <TableRow
                            style={webStyle.accountDetails}
                            key={data.id}
                            className="accountData"
                          >
                            <TableCell
                              style={{
                                ...webStyle.accountCell,
                                fontWeight: "bold",
                                justifyContent: "evenly",
                                width: "173.4px",
                              }}
                            >
                              <Checkbox
                                data-test-id="checkingId"
                                style={{ color: "black" }}
                                checked={data.checked}
                                onChange={() => this.handleCheck(data.id)}
                              />
                              {data.attributes.login_id}
                            </TableCell>
                            <TableCell
                              style={{
                                ...webStyle.accountCell,
                                width: "160px",
                                overflow: "hidden",
                              }}
                            >
                              {data.attributes.mt_account_number}
                            </TableCell>
                            <TableCell
                              style={{
                                ...webStyle.accountCell,
                                width: "100px",
                                overflow: "hidden",
                              }}
                              data-testId="password"
                            >
                              {data.attributes.master_trader}
                            </TableCell>
                            <TableCell
                              style={{
                                ...webStyle.accountCell,
                                width: "100px",
                                overflow: "hidden",
                              }}
                            >
                              {data.attributes.tinkerbell_commission}
                            </TableCell>
                            <TableCell
                              style={{
                                ...webStyle.accountCell,
                                width: "100px",
                                overflow: "hidden",
                              }}
                            >
                              {data.attributes.deepset_fx_commission}
                            </TableCell>
                            <TableCell
                              style={{
                                ...webStyle.accountCell,
                                width: "100px",
                                overflow: "hidden",
                              }}
                            >
                              {data.attributes.affiliate_commission}
                            </TableCell>

                            <TableCell
                              style={{
                                ...webStyle.accountCell,
                                width: "100px",
                                overflow: "hidden",
                              }}
                            >
                              {this.state.getUserPermissionDataCommission == "View Commissions" &&
                                <>
                                  <img
                                    src={`${commisssionImages.editIcon}`}
                                    data-test-id="editBtn"
                                    onClick={() => {
                                      this.editUserAccount(data);
                                    }}
                                  />
                                </>
                              }
                            </TableCell>


                            <TableCell
                              style={{
                                ...webStyle.lastCell,
                                width: "100px",
                                overflow: "hidden",
                              }}
                            >
                              {this.state.getUserPermissionData == "View User Notes" &&
                                <>
                                  <img
                                    src={`${commisssionImages.noteIcon}`}
                                    data-test-id="noteBtn"
                                    onClick={() => this.noteUserAccount(data)}
                                  />
                                </>
                               } 
                            </TableCell>

                          </TableRow>
                        );
                      }) :
                      (
                        <Box style={webStyle.nodata}>
                          <Typography style={webStyle.noDataDisplay}>
                            No Data To Display
                          </Typography>
                        </Box>
                      )
                    }
                    <TableRow
                      ref={this.loadingRef}
                      style={{ textAlign: "center", border: "none", margin: "0 auto" }}
                      data-test-id="loading"
                    >
                      {this.state.isLoading && (
                        <TableCell
                          align="center"
                          style={{ textAlign: "center" }}
                          colSpan={4}
                        >
                          Loading...
                        </TableCell>
                      )}{" "}
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
              <div style={webStyle.downloads} className="comBtn">
                {this.state.getUserPermissionDataForth == "Print Commission Accounts" &&
                  <Button
                    style={webStyle.printBtn}
                    onClick={this.handleCommisionPrint}
                    data-test-id="commissionPrint"
                  >
                    Print
                  </Button>
                }
                {this.state.getUserPermissionDataFifth == "Download Commission Accounts" &&
                  <Button
                    style={webStyle.printBtn}
                    data-test-id="selectedCsv"
                    className="margin_top_new_16px"
                    onClick={this.downloadSelected}
                  >
                    Download CSV
                  </Button>
                }
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}
export default withWidth()(CommissionContainer);

// Customizable Area End

// Customizable Area Start
const webStyle: any = {
  pageContainers: {
    margin: 0,
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
    position: "fixed",
    top: 0,
    bottom: 0,
    height: "100%",
  },
  pageContainerHeader: {
    zIndex: "0",
    backgroundColor: "white",
    marginLeft: "22vw",
    width: "76vw",
    height: "75px",
    borderBottom: "1px solid rgba(128, 128, 128, 0.43)",
    boxShadow: "none",
    borderLeft: "none",
  },
  sidebar: {
    width: "24vw",
    marginRight: "-100px",
    zIndex: "9",
    height: "100%",
    position: "fixed",
    top: "0",
  },
  pageDefault: {
    height: "100%",
    width: "78vw",
    marginLeft: "21vw",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    top: "100px",
  },
  accountDetails: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "1000px",
    height: "100px",
    margin: " 10px auto",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
    borderRadius: "6px",
    textAlign: "center",
    border: "1px solid #c7c3c3",
  },
  accountCell: {
    wordWrap: "anywhere",
    overflow: "hidden",
    overflowWrap: "anywhere",
    fontSize: "15px",
    borderRight: "1px solid #DEDEDE",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    padding: "0px 10px",
    justifyContent: "center",
  },
  lastCell: {
    fontSize: "15px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    padding: "0px 10px",
    justifyContent: "center",
  },
  searchField: {
    width: "100%",
  },
  showUserAccounts: {
    overflowY: "auto",
    height: "70%",
    marginBottom: "2%",
    display: "flex",
    justifyContent: "center",
    scrollbarWidth: "thin",
    scrollbarColor: "##121111",
    overflowX: "auto",
    // marginLeft: "4%",
    marginRight: "4%",
  },
  notificationIcon: {
    position: "fixed",
    right: "30px",
    top: "20px",
    color: "black",
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
  },
  selectedTab: {
    fontSize: "21px",
    borderBottom: "3px solid #000",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  searchBar: {
    marginTop: "3%",
    width: "50%",
    borderRadius: "20px",
    marginLeft: "10%",
    padding: "10px 0 10px 5%",
    border: "none",
    marginBottom: "3%",
    borderBottom: "none",
    boxShadow:
      "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
  },
  pageHeading: {
    marginTop: "-2%",
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },
  editProfileBtn: {
    fontSize: "21px",
    fontWeight: 500,
    textTransform: "capitalize",
    color: "#6A6A6A",
  },
  note: {
    width: "21px",
    height: "21px",
  },
  search: {
    color: "white",
    backgroundColor: "#0D0D0D",
    borderRadius: "50%",
    padding: "5px",
    fontSize: 38,
    marginRight: "-10px",
  },
  downloads: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "8%",
    marginRight: "4%",
  },
  printBtn: {
    padding: "20px 35px",
    height: "42px",
    borderRadius: "20px",
    color: "black",
    fontSize: "18px",
    textTransform: "Capitalize",
    border: "1px solid black",
    marginRight: "30px",
    whiteSpace: "nowrap",
  },
  sidebarIcon: {
    position: "fixed",
    top: 10,
    left: 12,
    background:
      "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
  },
  nodata: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10%",
  },
  noDataDisplay: {
    fontSize: "18px",
    fontFamily: "Poppins",
  },
  divider: {
    padding: "0px 0px 0px 50px",
    boxShadow: "rgb(34, 34, 34) 0px 4px 6px -6px",
    width: "100%"
  },
};
// Customizable Area End
