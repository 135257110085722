import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import SpeedOutlinedIcon from "@material-ui/icons/SpeedOutlined";
import NoteOutlinedIcon from "@material-ui/icons/NoteOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import NotificationsActiveOutlinedIcon from "@material-ui/icons/NotificationsActiveOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import '../../blocks/email-account-login/src/Style.css'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Button, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import {
  Typography,
} from "@material-ui/core";
import { setStorageData } from '../../framework/src/Utilities'
const images = require("./assets");

//@ts-ignore
export default function SideBar(props) {
  const theme = createTheme({
    typography: {
      fontFamily: 'Poppins'
    },
  })
  const [sideBarList, setSideBarList] = useState([]);
  const profileImage: any = localStorage.getItem("profileImage")
  const [showModal, setShowModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width:960px)').matches);

  const handleLogout = (e: any) => {
    let token = localStorage.getItem("authToken")
    e.preventDefault();
    localStorage.clear();
    setStorageData('authToken', token);
    setStorageData('resetFCMToken', 'True');
    props.navigate("EmailAccountLoginSignup")
    closeModal();
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (!localStorage.getItem("authToken") || !Cookies.get("userProfile")) {
      props.navigate("EmailAccountLoginSignup")
    }

    const callBack = () => {
      const storedValue = localStorage.getItem('sidebarDetails');
      const list = JSON.parse(storedValue || "[]")
      setSideBarList(list)
    }
    callBack()
    window.addEventListener("storage", callBack)
    const mediaQueryList = window.matchMedia('(max-width:960px)');
    const handleMediaQueryChange = (e: any) => {
      setIsMobile(e.matches);
    };
    mediaQueryList.addEventListener('change', handleMediaQueryChange);

    return () => {
      window.removeEventListener("storage", callBack)
      mediaQueryList.removeEventListener('change', handleMediaQueryChange);
    }
  }, [])
  let hasPrintedWe = false;

  const filteredArray = sideBarList.filter((data: any) => {
    if (data.attributes.permission.section === "Accounts" && !hasPrintedWe) {
      hasPrintedWe = true;
      return true;
    }
    return false;
  });

  const resultArray = filteredArray.map((data) => {
    return data;
  });
  let hasPrintedWeSec = false;

  const filteredArraySec = sideBarList.filter((data: any) => {
    if ((data.attributes.permission.section === "Monthly Report"?data.attributes.permission.section === "Monthly Report": data.attributes.permission.section === "Account Commissions")  && !hasPrintedWeSec) {
      hasPrintedWeSec = true;
      return true;
    }
    return false;
  });

  const resultArraySec = filteredArraySec.map((data) => {
    return data;
  });
  let hasPrintedWeThird = false;

  const filteredArrayThird = sideBarList.filter((data: any) => {
    if (data.attributes.permission.section === "Content Management" && !hasPrintedWeThird) {
      hasPrintedWeThird = true;
      return true;
    }
    return false;
  });

  const resultArrayThird = filteredArrayThird.map((data) => {
    return data;
  });
  return (
    <ThemeProvider theme={theme}>
      <div style={webStyle.sideBarContainer}>
        <div>
          <div style={webStyle.headerLogo}>
            {" "}
            <img
              onClick={() => {
                props.navigate("LandingPage");
              }}
              src={`${images.headerLogo}`}
            />
          </div>
          <div style={webStyle.profileDetails}>
            <div style={webStyle.profilepicSection}>
              <img src={profileImage} style={webStyle.profilepic} />
            </div>
            
            <div style={webStyle.userInfo}>
              <Typography
                variant="h6"
                style={{
                  textDecoration: "underline",
                  textDecorationThickness: "0.5px",
                  textDecorationColor: "#7f7f7f",
                }}
              >
                {props.userData.name}
              </Typography>
              <Typography style={webStyle.userEmail}>
                {props.userData.email}
              </Typography>
            </div>
          </div>
        </div>
        <div style={webStyle.horizontalLine} />
        {props.userData.userType === "admin" ? (
          <div>
            <div
              style={
                props.activeMenuItem === "Accounts and Access"
                  ? webStyle.menuItemActive
                  : webStyle.menuItem
              }
              onClick={() => props.navigate("AccountAccess")}
            >
              <div style={webStyle.menuItemIcon}>
                <AccountCircleIcon />
              </div>
              <Typography>Accounts and Access </Typography>
            </div>
  
            <div
              style={
                props.activeMenuItem === "CommissionSplit"
                  ? webStyle.menuItemActive
                  : webStyle.menuItem
              }
              onClick={() => props.navigate("CommissionSplit")}
            >
              <div style={webStyle.menuItemIcon}>
                {" "}
                <MonetizationOnOutlinedIcon />
              </div>
              <Typography>Earnings</Typography>
            </div>
            {resultArrayThird.map((data: any) => {
              return (<div
                style={
                  props.activeMenuItem === "Content Management"
                    ? webStyle.menuItemActive
                    : webStyle.menuItem
                }
                onClick={() => props.navigate("UploadImage")}
              >
                <div style={webStyle.menuItemIcon}>
                  {" "}
                  <NoteOutlinedIcon />
                </div>
                <Typography>Content Management</Typography>
              </div>)
            })}
            {/* } */}
            <div
              style={
                props.activeMenuItem === "Dashboard"
                  ? webStyle.menuItemActive
                  : webStyle.menuItem
              }
              onClick={() => props.navigate("Dashboard")}
            >
              <div style={webStyle.menuItemIcon}>
                {" "}
                <SpeedOutlinedIcon />
              </div>
              <Typography>Dashboard</Typography>
            </div>
            <div
              style={
                props.activeMenuItem === "Notifications & Reminders"
                  ? webStyle.menuItemActive
                  : webStyle.menuItem
              }
              onClick={() => props.navigate("SentNotifications")}
            >
              <div style={webStyle.menuItemIcon}>
                {" "}
                <NotificationsActiveOutlinedIcon />
              </div>
              <Typography>Notifications & Reminders</Typography>
            </div>
       
            <div
              style={
                props.activeMenuItem === "Settings"
                  ? webStyle.menuItemActive
                  : webStyle.menuItem
              }
              onClick={() => props.navigate("Settings")}
            >
              <div style={webStyle.menuItemIcon}>
                {" "}
                <SettingsOutlinedIcon />
              </div>
              <Typography>Settings</Typography>
            </div>
            <div
              style={
                showModal
                  ? webStyle.menuItemActive
                  : webStyle.menuItem
              }
              onClick={openModal}
            >
            
              <div style={webStyle.menuItemIcon}>
                {" "}
                <ExitToAppOutlinedIcon />
              </div>
              <Typography>Logout</Typography>
            </div>
       

          </div>

        ) : (
          <div style={{ height: "100%" }}>
            {resultArray.map((data: any) => {

              return (

                <div
                  style={
                    props.activeMenuItem === "Accounts and Access"
                      ? webStyle.menuItemActive
                      : webStyle.menuItem
                  }
                  onClick={() => props.navigate("AccountAccess")}
                >
                  <div style={webStyle.menuItemIcon}>
                    <AccountCircleIcon />
                  </div>
                  <Typography>Accounts and Access </Typography>
                </div>
              )
            })}
            {resultArraySec.map((data: any) => {

              return (<div
                style={
                  props.activeMenuItem === "CommissionSplit"
                    ? webStyle.menuItemActive
                    : webStyle.menuItem
                }
                onClick={() => props.navigate("CommissionSplit")}
              >
                <div style={webStyle.menuItemIcon}>
                  {" "}
                  <MonetizationOnOutlinedIcon />
                </div>
                <Typography>Earnings</Typography>
              </div>)

            })}

            {resultArrayThird.map((data: any) => {
              return (<div
                style={
                  props.activeMenuItem === "Content Management"
                    ? webStyle.menuItemActive
                    : webStyle.menuItem
                }
                onClick={() => props.navigate("UploadImage")}
              >
                <div style={webStyle.menuItemIcon}>
                  {" "}
                  <NoteOutlinedIcon />
                </div>
                <Typography>Content Management</Typography>
              </div>)

            })}

            <div
              style={
                props.activeMenuItem === "Dashboard"
                  ? webStyle.menuItemActive
                  : webStyle.menuItem
              }
              onClick={() => props.navigate("Dashboard")}
            >
              <div style={webStyle.menuItemIcon}>
                {" "}
                <SpeedOutlinedIcon />
              </div>
              <Typography>Dashboard</Typography>
            </div>
            <div
              style={
                props.activeMenuItem === "Notifications & Reminders"
                  ? webStyle.menuItemActive
                  : webStyle.menuItem
              }
              onClick={() => props.navigate("UserNotificationAll")}
            >
              <div style={webStyle.menuItemIcon}>
                {" "}
                <NotificationsActiveOutlinedIcon />
              </div>
              <Typography style={{ paddingRight: "10px" }}>Notifications & Reminders</Typography>
            </div>
            <div
              style={
                props.activeMenuItem === "Settings"
                  ? webStyle.menuItemActive
                  : webStyle.menuItem
              }
              onClick={() => props.navigate("Settings")}
            >
              <div style={webStyle.menuItemIcon}>
                {" "}
                <SettingsOutlinedIcon />
              </div>
              <Typography>Settings</Typography>
            </div>
            <div
              className="maxHeight400px"
              style={{
                ...showModal
                  ? webStyle.menuItemActive
                  : webStyle.menuItem
              }}
              onClick={openModal}
            >
              <div style={webStyle.logOutBox}>
                <div style={webStyle.menuItemIcon}>
                  {" "}
                  <ExitToAppOutlinedIcon  />
                </div>
                <Typography>Logout</Typography>
              </div>
            </div>

          </div>
        )}

        <Dialog className="modalDelete" open={showModal} onClose={closeModal} PaperProps={isMobile ? { style: webStyle.logOutModalMobile } : { style: webStyle.logOutModal }}>
          <DialogContent style={webStyle.logoutContent}>
            <p>Are you sure you want to logout?</p>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={closeModal} className="noButton">
              NO
            </Button>
            <Button onClick={handleLogout} className="yesButton">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
    </div>
    </ThemeProvider>
  );
}

const webStyle: any = {
  logOutModal: {
    width: "835px",
    height: "346px",
    borderRadius: "20px",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
  },

  logOutModalMobile: {
    width: "auto",
    height: "auto",
    borderRadius: "20px",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
    textAlign: "center",
    margin: "0 15px"
  },
  headerLogo: {
    padding: "25px 0 0 25px",
    cursor: "pointer"
  },
  sideBarContainer: {
    width: "100%",
    height: "100%",
    color: "#fff",
    position: "relative",
    top: 0,
    bottom: 0,
    left: 0,
    fontFamily: "Poppins",
  },
  profileDetails: {
    fontFamily: "Poppins",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: "10px 0",
    padding: "10px",
  },
  profilepicSection: {
    fontFamily: "Poppins",
    width: "80px",
    height: "75px",
    borderRadius: "50%",
  },
  profilepic: {
    height: "100%",
    width: "100%",
    borderRadius: "50%",
  },
  userEmail: {
    width: "150px",
    wordWrap: "break-word",
    fontSize: "12px",
  },
  userInfo: {
    width: "65%",
    marginLeft: "10px",
  },
  horizontalLine: {
    border: "1px solid #fff",
    borderCollapse: "collpase",
    marginBottom: "10px",
    borderColor: "gray",
  },
  logOutBox: {
    bottom: "20px",
    display: "flex",
  },
  menuItem: {
    fontFamily: "Poppins",
    display: "flex",
    alignItems: "center",
    justifyItems: "flex-start",
    cursor: "pointer",
    padding: "5px 0px 5px 15px",
    marginBottom: "10px",
    color: "gray",
    fontSize: "16px",
  },
  menuItemActive: {
    fontFamily: "Poppins",
    display: "flex",
    alignItems: "center",
    justifyItems: "flex-start",
    cursor: "pointer",
    padding: "5px 0px 5px 15px",
    marginBottom: "10px",
    color: "white",
    fontSize: "16px",
  },
  menuItemIcon: {
    fontFamily: "Poppins",
    paddingRight: "10px",
  },
  logoutContent :{
    fontSize:"20px",
    fontWeight:500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop:"10%",
  }
};
