Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "notifications/notifications";
exports.userAccountEndPoint= (pageNo) => `account_block/get_all_accounts?page=${pageNo}&per_page=10&only_active=true&only_normal_user=true`;
exports.endPointNotificationGet = "bx_block_notifications/notifications?status=sent&notification_type=notification&per_page=10";
exports.getUserNotification = "bx_block_notifications/user_notifications?notification_type=notification&per_page=10";
exports.sentNotiEndPoint = "bx_block_notifications/notifications?status=sent&notification_type=notification"
exports.darftNotiEndPoint = "bx_block_notifications/notifications?status=draft&notification_type=notification"
exports.darftNotiEndPointGet = "bx_block_notifications/notifications?status=draft&notification_type=notification&per_page=10"
exports.schNotiEndPointGet = "bx_block_notifications/notifications?status=scheduled&notification_type=notification&per_page=10"
exports.schDeleteEndPoint = "bx_block_notifications/notifications";
exports.scheduledNotiEndPoint = "bx_block_notifications/notifications";
exports.searchNotiEndPointGet ="bx_block_notifications/search_notification?notification_type=notification&query";
exports.createReminderEndPoint ="bx_block_notifications/notifications";
exports.sentReminderEndPoint = (pageNo) => `bx_block_notifications/notifications?status=sent&notification_type=reminder&page=${pageNo}&per_page=10&search`;
exports.schReminderEndPoint = (pageNo) =>`bx_block_notifications/notifications?status=scheduled&notification_type=reminder&page=${pageNo}&per_page=10&search`;
exports.updateReminder = "bx_block_notifications/notifications";
exports.getUserReminder = "bx_block_notifications/user_notifications?notification_type=reminder&per_page=10"
exports.userDeleteEndPoint = "bx_block_notifications/delete_user_notification/";
exports.setReminderStatusEndPoint = "bx_block_notifications/update_user_notification";
exports.userNotifications = "bx_block_notifications/user_notifications?is_read=false&per_page=10"
exports.markAllRead = "bx_block_notifications/mark_all_read"
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.postMethod = "POST";
exports.deleteMethod = "DELETE";
exports.patchMethod = "PATCH";

exports.notifications = "Notifications";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.title = "Title";
exports.dateCreated = "Date created";
exports.createdBy = "Created By";
exports.sentDate = "Sent Date";
exports.notificationSent = "Notification Sent";

exports.dropdownOptions = ['daily', 'weekly', 'monthly'];
exports.commonMenuConfig = {
  anchorOrigin: {
    vertical: 'bottom' ,
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
}


// Customizable Area End