import React from "react";
import { Grid, Typography, TextField, Box  ,  withWidth,
  Drawer} from "@material-ui/core";
  import IconButton from "@material-ui/core/IconButton";
  import MenuIcon from '@material-ui/icons/Menu';
import { Formik } from "formik";
import ContentManagementController, { Props } from "./ContentManagementController";
import { styles } from "./ContentManagementStyles.web";
import SideBar from "../../../components/src/SideBar";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import HeaderNavigation from "../../../components/src/HeaderNavigation";
import AirplayOutlinedIcon from '@material-ui/icons/AirplayOutlined';
import { bellIcon, unreadBellIcon } from "./assets";
import UnreadNotificationList from "../../../components/src/UnreadNotificationList.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const images = require("./assets")
const theme = createTheme({
  typography: {
    fontFamily: "Poppins !important",
  },
});

export class EditHeading extends ContentManagementController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const {  width } = this.props;
    const isMobile = /s/.test(width); 
    const {
      isLoading,
      unreadNotificationRecords,
      scrollPage
    } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <div style={stylesHeading.fullscreenHead}>
          <div style={stylesHeading.sidebarParentHead}>
          {isMobile? 
            <div
            style={{position:"fixed",top:"10px", left:"12px",background: "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
          }}
             >
             <IconButton
            onClick={this.handleDrawerToggles}
            style={{color:'white'}}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
          open={this.state.mobileOpen}
          onClose={this.handleDrawerToggles}
          ModalProps={{
            keepMounted: true,
          }}
        >
          { <SideBar
          style={stylesHeading.sidebar}
              navigate={this.props.navigation.navigate}
              activeMenuItem={"Content Management"}
              userData={this.state.userData}
            />
          }
          
          </Drawer>
          </div>:
   <div style={stylesHeading.sidebar} className="sidebarResponse"> 
    <SideBar
              navigate={this.props.navigation.navigate}
              activeMenuItem={"Content Management"}
              userData={this.state.userData}
            />
          
          </div>}
          </div>
          <div style={stylesHeading.divContainerHead} className="marginAutoLeftRight">
            <div style={stylesHeading.topNavBarHead}>
              <div style={stylesHeading.bellIconHead}>
                {this.state.userData.userType !== "admin" &&
                  <>
                    <img
                      src={unreadNotificationRecords.length > 0 ? unreadBellIcon : bellIcon}
                      style={stylesHeading.pointerHead}
                      onClick={this.handleClickPopOver}
                    />
                    <UnreadNotificationList
                      anchorEl={this.state.anchorEl}
                      handleClosePopOver={this.handleClosePopOver}
                      navigate={this.props.navigation.navigate}
                      notificationData={unreadNotificationRecords}
                      isLoading={isLoading}
                      unreadNotificationList={this.onScrollUnreadNotificationList}
                      handleClearAll={this.handleClearAll}
                      scrollPage={scrollPage}
                    />
                  </>
                }
              </div>
              <div style={stylesHeading.pageTitleHead}>
                Landing Page
              </div>
            </div>
            <div className="bodyScrollBar padding_none" style={stylesHeading.bodyContentHead}>
              <div className="display_block"   style={stylesHeading.navigationContainerHead} >
                <div style={stylesHeading.adminSettingsButtonDivHead}>
                  <HeaderNavigation 
                  state={this.state}
                    goToImageUpload={this.goToImageUpload}
                    goToEditBody={this.goToEditBody}
                    data-test-id="goToEditHeading"
                    goToEditHeading={this.goToEditHeading}
                  />
                </div>
                <div style={stylesHeading.bgcolorPickerHead}>
                <div className="width_100_bg" style={stylesHeading.bgColorHead} data-test-id="updateShowColorList" onClick={() => this.updateShowColorList()}>
                <Box style={{marginTop:"-4px",fontSize:"16px"}}>Background Color</Box>
                    <div style={{...stylesHeading.colorBoxHead, background: this.state.pageData.bg_colour }}></div>
                  </div>
                  {
                    this.state.showColorList && (
                      <ul style={stylesHeading.dropdownListHead}>
                        <li style={{ background: " linear-gradient(180deg, #192C85 0%, #000000 100%)", height: "25px" }} 
                        data-test-id="updateBody" onClick={(event:React.MouseEvent)=>{this.updateColorState(event)}}>
                        </li>
                        <li style={{ background: "linear-gradient(180deg, #2D6F8F 0%, #4FB7BA 100%)", height: "25px" }} 
                        data-test-id="updateBody1"
                        onClick={(event:React.MouseEvent)=>{this.updateColorState(event)}}>
                        </li>
                        <li style={{ background: " linear-gradient(229.12deg, rgba(88, 17, 89, 0.92) 4.49%, rgba(131, 24, 133, 0.67) 53.52%)", height: "25px" }} 
                        data-test-id="updateBody2" onClick={(event:React.MouseEvent)=>{this.updateColorState(event)}}>
                        </li>
                        <li style={{ background: "linear-gradient(180deg, #113A5D 0%, rgba(17, 49, 72, 0) 206.4%)", height: "25px" }}
                       data-test-id="updateBody3" onClick={(event:React.MouseEvent)=>{this.updateColorState(event)}}>
                        </li>
                        <li style={{ background: "linear-gradient(211.19deg, #0A642B 4.35%, rgba(10, 100, 43, 0.33) 118%)", height: "25px" }}
                       data-test-id="updateBody4" onClick={(event:React.MouseEvent)=>{this.updateColorState(event)}}>
                        </li>
                      </ul>)
                  }
                </div>

              </div>
              <div style={stylesHeading.formHolderDivHead}>
                <div className="analystDivheading">
                  <Grid style={stylesHeading.GridContainer} container spacing={0}>
                    <Formik
                      enableReinitialize={true}
                      validateOnBlur={true}
                      initialValues={{}}
                      onSubmit={this.handleSubmit}
                    >
                      {({ handleSubmit }) => {
                        return (
                          <>
                            <Grid item style={stylesHeading.userFormHead} xs={12}>
                              <form onSubmit={handleSubmit}>
                                <div style={{...stylesHeading.imgWrapperHead, background:this.state.pageData.bg_colour}}>
                                  <img
                                    style={{ width: "100%", minHeight:"500px", height:"auto", objectFit: "fill" }}
                                    src={images.barsImage}
                                    className="minfixHeight"
                                  />
                                  <div style={{...stylesHeading.pageContentHead}}>
                                    <div style={stylesHeading.bars}>
                                          <TextField
                                          style={{...stylesHeading.inputStylesHead, width:"80%", padding:"8px", border:"1.5px solid white", marginRight:"auto", marginLeft:"auto", textAlign:"center"}}
                                          onChange={(event:React.ChangeEvent<HTMLInputElement>)=>this.updateHeadingState(event)}
                                          value={this.state.pageData.header}
                                          data-test-id="editorChangesHeader"
                                            fullWidth
                                            inputProps={{maxLength: 80, style: { textAlign: 'center'} }}
                                            InputProps={{
                                              style: {
                                                color: 'white',
                                                fontSize: "30px",
                                              },
                                            }}
                                          />
                                      <div style={stylesHeading.pageDescriptionHead}>
                                        <Typography variant="h6" style={{fontSize:"24px"}}>
                                          {this.state.pageData.body1}&nbsp;
                                        </Typography>
                                        <img
                                          style={stylesHeading.mt5IconHead}
                                          src={`${images.mt5Icon}`}
                                        />
                                      </div>
                                      <div style={stylesHeading.pageTextHead}>
                                        <Typography className="hedingH4Mentain" variant="h4" style={{fontSize:"48px"}}>
                                          {this.state.pageData.body2}
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                  <div style={{...stylesHeading.activeButtonHead, background: "linear-gradient(180deg, #192C85 0%, #000000 100%)", display: "inline-flex", alignItems: "center", position: 'absolute',
                                    right: 0,
                                    bottom: '15px',padding: "6px 25px",
                                    zIndex: 1, fontSize:"15px",
                                    fontFamily:"Poppins"
                                  }} data-test-id="moveLanding" onClick={()=>this.props.navigation.navigate("LandingPage")}><div>Preview&nbsp;
                                    </div>
                                    <AirplayOutlinedIcon />
                                  </div>
                                </div>



                                <div className="display_block marginBottom_50px" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                  <button className="width_100_new" type="button" onClick={this.revertPrevious} style={{...stylesHeading.buttonHead,fontSize:"15px", marginRight: "1rem" }} >
                                    Revert to previous version
                                  </button>
                                  <button className="width_100_new" style={{...stylesHeading.activeButtonHead, fontSize:"15px", background: "linear-gradient(180deg, #192C85 0%, #000000 100%)", paddingLeft: " 3.5rem", paddingRight: "3.5rem", marginRight: 0 }} type="submit">Update</button>
                                </div>
                              
                              </form>
                            </Grid>
                          </>
                        );
                      }}
                    </Formik>
                  </Grid>
                </div>
              </div>
            </div>
          </div>  
          <Snackbar open={this.state.alertState} onClose={this.handleCloseAlert} autoHideDuration={3000} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
          <MuiAlert elevation={6} variant="filled" severity="success" style={{ width: '100%' }}>
            {this.state.updatealertMsg?this.state.updatealertMsg:this.state.revertAlertMsg}
          </MuiAlert>
        </Snackbar>  
      </div>
      </ThemeProvider>
    );
  }
}

const stylesHeading: any = {
  fullscreenHead: {
    position:"fixed",
    top:0,
    bottom:0,
    height: "100vh !important",
    minWidth: "100vw",
    fontFamily: "poppins !important",
    display: 'flex',
    boxSizing:'border-box'
  },
  sidebar: {
    width: "24vw",
    fontFamily: 'Poppins',
    marginRight: "-100px",
    zIndex: "9",
    height: "100%",
    position:"fixed",
    bottom : 0,
    top:0,
  },
  divContainerHead: {
    gap: "10px",
    height: "100%",
    width: "calc(100% - 24%)",
     marginLeft: "auto"
  },
  topNavBarHead: {
    width: "100%"
  },

  formHolderDivHead: {
    width: "100%",
    margin: "0 auto"
  },
  buttonHead:{
    border:"1px solid black",
    borderRadius: "30px",
    padding: "0.4rem 1rem",
    marginRight: "1rem",
    display:"flex",
    justifyContent: "center",
    alignItems:"center",
    color:"black",
    cursor:"pointer",
    fontFamily:"Poppins"
  },
  imgWrapperHead:{
    position:'relative',
    marginBottom:'15px',
    borderRadius:"14px",
  },
  activeButtonHead:{
    color:"#fff",
    border: "none",
    borderRadius: "30px",
    padding: "0.4rem 1rem",
    marginRight: "1rem",
    display:"flex",
    justifyContent: "center",
    alignItems:"center",
    cursor:"pointer",
    fontFamily:"Poppins"
  },
  userFormHead:{
    width: "100%"
  },

  sidebarParentHead:{
    // width:'24%',
    height:"100vh"
  },
  bellIconHead:{
    textAlign: "right",
    width: "100%",
    height:"75px"
  },
  pointerHead:{
    fontFamily: 'Poppins',
    position: "fixed",
    right: "30px",
    top: "20px",
    color: "black",
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
  },
  pageTitleHead:{
    borderTop:"1px solid #8080806e",
    padding: "10px 0px 10px 50px",
    boxShadow: "0 4px 6px -6px #222",
    fontSize:"21px"
  },
  navigationContainerHead:{
    display:"flex",
    justifyContent:"space-between",
    alignItems: "center",
    width: "100%",
    padding:"2rem 0"
  },
  adminSettingsButtonDivHead:{
    position:"relative"
  },
  dropdownListHead:{
    cursor:"pointer",
    position: "absolute",
    top: "20px",
    width:"100px",
    padding:"0",
    margin: "0",
    backgroundColor: "#ffffff",
    listStyleType: "none",
    border: "1px solid #ccc",
    borderRadius: "4px",
    right:0,
    zIndex:1,
  },
  bgColorHead:{
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    cursor: "pointer",
  },
  
  colorBoxHead:{
    height:"1rem",
    width: "1rem",
    marginLeft: "5px"
  },
  bgcolorPickerHead:{
    position:'relative',
  },
  bodyContentHead:{
    padding:'0px 35px 0px 44px',
    width: '100%',
    boxSizing:'border-box',
    overflow:"auto"
  },
   pageContentHead: {
    position:"absolute",
    top:"70px",
    right: 0,
    left: 0,
    textAlign: "center",
    color: "#fff",
    boxSizing: "border-box",
    paddingTop: "40px",
    height: "50%",
    width:"100%"
  }, 
  pageDescriptionHead: {
    width:"80%",
    marginRight: "auto",
    marginLeft:"auto",
    padding: "25px 0 30px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mt5IconHead: {
    paddingLeft: "5px",
    width: "87px",
  },
  pageTextHead: {
    textAlign: "center",
  },
  inputStylesHead:{
     background: 'transparent',
     textAlign: "center",
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
      },
      '&::before': {
        borderBottom: '2px solid white',
      
    },
  },
};
export default (withWidth()(EditHeading));