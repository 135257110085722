import React from "react";
import { Box, Grid, Typography, TextField,  withWidth,
  Drawer} from "@material-ui/core";
  import IconButton from "@material-ui/core/IconButton";
  import MenuIcon from '@material-ui/icons/Menu';
import { Formik } from "formik";
import ContentManagementController, { Props } from "./ContentManagementController";
import { styles } from "./ContentManagementStyles.web";
import SideBar from "../../../components/src/SideBar";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import HeaderNavigation from "../../../components/src/HeaderNavigation";
import AirplayOutlinedIcon from '@material-ui/icons/AirplayOutlined';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { bellIcon, unreadBellIcon } from "./assets";
import UnreadNotificationList from "../../../components/src/UnreadNotificationList.web";
const images = require("./assets")
const theme = createTheme({
  typography: {
    fontFamily: "Poppins !important",
  },
});

export class EditBody extends ContentManagementController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const {  width } = this.props;
    const isMobile = /s/.test(width);
    const {
      unreadNotificationRecords,
      isLoading,
      scrollPage
    } = this.state;
    return (
      /* @ts-ignore */
      <ThemeProvider theme={theme}>
        
        <Box style={stylesBody.fullscreenBody}>
        
          <Box style={stylesBody.sidebarParentBodyBody}>
          {isMobile? 
            <div
            style={{position:"fixed",top:"10px", left:"12px",background: "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
          }}
             >
             <IconButton
            onClick={this.handleDrawerToggles}
            style={{color:'white'}}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
          open={this.state.mobileOpen}
          onClose={this.handleDrawerToggles}
          ModalProps={{
            keepMounted: true,
          }}
        >
          { <SideBar
          style={stylesBody.sidebar}
              navigate={this.props.navigation.navigate}
              activeMenuItem={"Content Management"}
              userData={this.state.userData}
            />
          }
          
          </Drawer>
          </div>:
   <div style={stylesBody.sidebar} className="sidebarResponse"> 
    <SideBar
              navigate={this.props.navigation.navigate}
              activeMenuItem={"Content Management"}
              userData={this.state.userData}
            />
          </div>}
          </Box>
          
          <Box style={stylesBody.divContainerBody} className="marginAutoLeftRight">
            <div style={stylesBody.topNavBarBody}>
              <div style={stylesBody.bellIconBody}>
                {this.state.userData.userType !== "admin" &&
                  <>
                    <img
                      src={unreadNotificationRecords.length > 0 ? unreadBellIcon : bellIcon}
                      style={stylesBody.pointerBody}
                      onClick={this.handleClickPopOver}
                    />
                    <UnreadNotificationList
                      anchorEl={this.state.anchorEl}
                      handleClosePopOver={this.handleClosePopOver}
                      navigate={this.props.navigation.navigate}
                      notificationData={unreadNotificationRecords}
                      isLoading={isLoading}
                      unreadNotificationList={this.onScrollUnreadNotificationList}
                      handleClearAll={this.handleClearAll}
                      scrollPage={scrollPage}
                    />
                  </>
                }
              </div>
              <div style={stylesBody.pageTitleBody}>
                Landing Page
              </div>
            </div>
            <div className="bodyScrollBar padding_none" style={stylesBody.bodyContentBody}>
              <div className="display_block" style={stylesBody.navigationContainerBody}>
                <div style={stylesBody.adminSettingsButtonDivBody}>
                  <HeaderNavigation 
                  state={this.state}
                    goToImageUpload={this.goToImageUpload}
                    data-test-id="goToEditBody"
                    goToEditBody={this.goToEditBody}
                    goToEditHeading={this.goToEditHeading}
                  />

                </div>
                <div style={stylesBody.bgcolorPickerBody}>
                  <div className="width_100_bg" style={stylesBody.bgColorBody} data-test-id="updateShowColorList" onClick={() => this.updateShowColorList()}>
                  <Box style={{marginTop:"-4px",fontSize:"16px"}}>Background Color</Box>
                    <div style={{...stylesBody.colorBoxBody, background: this.state.pageData.bg_colour}}></div>
                  </div>
                  {
                    this.state.showColorList && (
                      <ul style={stylesBody.dropdownListBody}>
                        <li style={{ background: " linear-gradient(180deg, #192C85 0%, #000000 100%)", height: "25px" }} data-test-id="updateBody" onClick={(event:React.MouseEvent)=>{this.updateColorState(event)}}></li>
                        <li style={{ background: "linear-gradient(180deg, #2D6F8F 0%, #4FB7BA 100%)", height: "25px" }} data-test-id="updateBody1" onClick={(event:React.MouseEvent)=>{this.updateColorState(event)}}></li>
                        <li style={{ background: " linear-gradient(229.12deg, rgba(88, 17, 89, 0.92) 4.49%, rgba(131, 24, 133, 0.67) 53.52%)", height: "25px" }} data-test-id="updateBody2" onClick={(event:React.MouseEvent)=>{this.updateColorState(event)}}></li>
                        <li style={{ background: "linear-gradient(180deg, #113A5D 0%, rgba(17, 49, 72, 0) 206.4%)", height: "25px" }} data-test-id="updateBody3" onClick={(event:React.MouseEvent)=>{this.updateColorState(event)}}></li>
                        <li style={{ background: "linear-gradient(211.19deg, #0A642B 4.35%, rgba(10, 100, 43, 0.33) 118%)", height: "25px" }} data-test-id="updateBody4" onClick={(event:React.MouseEvent)=>{this.updateColorState(event)}}></li>
                      </ul>)
                  }
                </div>

              </div>
              <div style={stylesBody.formHolderDivBody}>
                <div className="analystDivbody">
                  <Grid style={stylesBody.GridContainer} container spacing={0}>
                    <Formik
                      enableReinitialize={true}
                      validateOnBlur={true}
                      initialValues={{}}
                      data-test-id="editorChangesSubmit"
                      onSubmit={this.handleSubmit}
                    >
                      {({ handleSubmit }) => {
                        return (
                          <>
                            <Grid item style={stylesBody.userFormBody} xs={12}>
                              <form onSubmit={handleSubmit}>
                                <Box style={{...stylesBody.imgWrapperBody, background:this.state.pageData.bg_colour}}>
                                  <img
                                    style={{ minHeight:"500px", height:"auto", width: "100%", objectFit: "fill" }}
                                    src={images.barsImage}
                                  />
                                    <div style={{...stylesBody.pageContentBody}}>
                                    <div style={stylesBody.bars}>    
                                     <Typography variant="h3" style={{fontSize:"30px"}}>
                                        {this.state.pageData.header}
                                      </Typography>
                                      <div style={{...stylesBody.pageDescriptionBody, display:"inline-block"}}>
                                        <TextField
                                        className="bodyTextCssfirst"
                                          style={{...stylesBody.inputStylesBody, width:"80%",padding:"8px", border:"1.5px solid white", marginRight:"auto",  textTransform:"uppercase", marginLeft:"auto", textAlign:"center"}}
                      data-test-id="editorChanges"
                      onChange={(event:React.ChangeEvent<HTMLInputElement>)=>this.updateBody1State(event)}
                                          value={this.state.pageData.body1}
                                            // variant="outlined"
                                            fullWidth
                                            inputProps={{maxLength: 100, style: { textAlign: 'center' }}}
                                            InputProps={{
                          
                                              style: {
                                                color: 'white',
                                                textAlign: 'center',
                                                fontSize:"18px"
                                              },
                                            }}
                                          />
                                        <img
                                          style={stylesBody.mt5IconBody}
                                          src={`${images.mt5Icon}`}
                                        />
                                      </div>
                                      <div style={stylesBody.pageTextBody}>
                                      <TextField
                                      className="hedingH4Mentain bodyTextCss"
                                          style={{...stylesBody.inputStylesBody, width:"80%", padding:"8px", border:"1.5px solid white", marginRight:"auto", marginLeft:"auto", textAlign:"center"}}
                                          data-test-id="editorChangesBody" onChange={(event:React.ChangeEvent<HTMLInputElement>)=>this.updateBody2State(event)}
                                          value={this.state.pageData.body2}
                                            fullWidth
                                            inputProps={{maxLength: 40, style: { textAlign: 'center' }}}
                                            InputProps={{
                                              
                                              style: {
                                                color: 'white',
                                                textAlign: 'center',
                                                fontSize:"25px"
                                              },
                                            }}
                                          />
                                      </div>
                                    </div>
                                  </div>
                                  <div style={{...stylesBody.activeButtonBody, background: "linear-gradient(180deg, #192C85 0%, #000000 100%)", display: "inline-flex", alignItems: "center", position: 'absolute',
                                    right: 0,
                                    bottom: '15px',padding: "6px 25px",
                                    zIndex: 1, fontSize:"15px",
                                    fontFamily:"Poppins"
                                  }} data-test-id="moveLanding" onClick={()=>this.props.navigation.navigate("LandingPage")}><div>Preview&nbsp;
                                    </div>
                                    <AirplayOutlinedIcon />
                                  </div>
                                </Box>
                                <div className="display_block marginBottom_50px" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                  <button className="width_100_new" type="button" data-test-id="revertPrevious" onClick={this.revertPrevious} style={{...stylesBody.buttonBody, marginRight: "1rem", fontSize:"15px" }}>
                                    Revert to previous version
                                  </button>
                                  <button className="width_100_new" style={{...stylesBody.activeButtonBody, fontSize:"15px", background: "linear-gradient(180deg, #192C85 0%, #000000 100%)", paddingLeft: " 3.5rem", paddingRight: "3.5rem", marginRight: 0}} type="submit">Update</button>
                                </div>
                                
                              </form>
                            </Grid>
                          </>
                        );
                      }}
                    </Formik>
                  </Grid>
                </div>
              </div>
            </div>
          </Box> 
          <Snackbar open={this.state.alertState} onClose={this.handleCloseAlert} autoHideDuration={3000} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
          <MuiAlert elevation={6} variant="filled" severity="success" style={{ width: '100%' }}>
            {this.state.updatealertMsg?this.state.updatealertMsg:this.state.revertAlertMsg}
          </MuiAlert>
        </Snackbar>      
      </Box>
      </ThemeProvider>
    );
  }
}

let stylesBody: any = {
  sidebar: {
    width: "24vw",
    fontFamily: 'Poppins',
    marginRight: "-100px",
    zIndex: "9",
    height: "100%",
    position:"fixed",
    bottom : 0,
    top:0,
  },
  fullscreenBody: {
    position:"fixed",
    top:0,
    bottom:0,
    height: "100vh !important",
    minWidth: "100vw",
    fontFamily: "poppins !important",
    display: 'flex',
    boxSizing:'border-box'
  },
  divContainerBody: {
    gap: "10px",
    height: "100%",
    width: "calc(100% - 24%)",
    marginLeft: "auto",
  },
  topNavBarBody: {
    width: "100%"
  },

  formHolderDivBody: {
    width: "100%",
    margin: "0 auto"
  },

  buttonBody:{
    border:"1px solid black",
    borderRadius: "30px",
    padding: "0.4rem 1rem",
    marginRight: "1rem",
    display:"flex",
    justifyContent: "center",
    alignItems:"center",
    color:"black",
    cursor:"pointer",
    fontFamily:"Poppins"
  },
  imgWrapperBody:{
    position:'relative',
    marginBottom:'15px',
    borderRadius:"14px",
  },

  activeButtonBody:{
    color:"#fff",
    border: "none",
    borderRadius: "30px",
    padding: "0.4rem 1rem",
    marginRight: "1rem",
    display:"flex",
    justifyContent: "center",
    alignItems:"center",
    cursor:"pointer",
    fontFamily:"Poppins"
  },
  userFormBody:{
    width: "100%"
  },

  sidebarParentBodyBody:{
    // width:'24%',
    height:"100vh"
  },
  bellIconBody:{
    textAlign: "right",
    width: "100%",
    height:"75px"
  },
  pointerBody:{
    fontFamily: 'Poppins',
    position: "fixed",
    right: "30px",
    top: "20px",
    color: "black",
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
  },
  pageTitleBody:{
    borderTop:"1px solid #8080806e",
    padding: "10px 0px 10px 50px",
    boxShadow: "0 4px 6px -6px #222",
    fontSize:"21px"
  },
  navigationContainerBody:{
    display:"flex",
    justifyContent:"space-between",
    alignItems: "center",
    width: "100%",
    padding:"2rem 0"
  },
  adminSettingsButtonDivBody:{
    position:"relative"
  },
  dropdownListBody:{
    cursor:"pointer",
    position: "absolute",
    top: "20px",
    width:"100px",
    padding:"0",
    margin: "0",
    backgroundColor: "#ffffff",
    listStyleType: "none",
    border: "1px solid #ccc",
    borderRadius: "4px",
    right:0,
    zIndex:1,
  },
  bgColorBody:{
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    cursor: "pointer",
  },
  
  colorBoxBody:{
    height:"1rem",
    width: "1rem",
    marginLeft: "5px"
  },
  bgcolorPickerBody:{
    position:'relative',
  },
  bodyContentBody:{
    padding:'0px 35px 0px 44px',
    width: '100%',
    boxSizing:'border-box',
    overflow:"auto"
  },
   pageContentBody: {
    position:"absolute",
    top:"70px",
    right: 0,
    left: 0,
    textAlign: "center",
    color: "#fff",
    boxSizing: "border-box",
    paddingTop: "40px",
    height: "50%",
    width:"100%"
  }, 
  pageDescriptionBody: {
    width:"80%",
    marginRight: "auto",
    marginLeft:"auto",
    padding: "25px 0 30px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mt5IconBody: {
    marginTop:"-15px",
    paddingLeft: "5px",
    width: "87px",
  },
  pageTextBody: {
    textAlign: "center",
  },
  inputStylesBody:{
     background: 'transparent',
     textAlign: "center",
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
      },
      '&::before': {
        borderBottom: '2px solid white',
      
    },
  },
  inputBody:{
    textAlign:'center'
  }
};
export default (withWidth()(EditBody));