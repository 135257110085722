import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import Cookies from "js-cookie"
interface CustomTypeData {
  method: string,
  endPoint: string,
}
const { vector, dataUpload, Vector3, Vector4, Vector5, Vector6, Vector7, Vector8, Vector9, Vector10, Vector11, Vector12, Vector13, Vector14, Vector15, Vector16, Vector17, Vector18 } = require("./assets");
const imageHand = [{ id: 1, img: dataUpload }, { id: 2, img: Vector3 }, { id: 3, img: Vector4 }, { id: 4, img: Vector5 }, { id: 5, img: Vector6 }, { id: 6, img: Vector7 }, { id: 7, img: Vector8 }, { id: 8, img: Vector9 }, { id: 9, img: Vector10 }, { id: 10, img: Vector11 }, { id: 11, img: Vector12 }, { id: 12, img: Vector13 }, { id: 13, img: Vector11 }, { id: 14, img: Vector14 }, { id: 15, img: Vector15, }, { id: 16, img: Vector16 }, { id: 17, img: Vector17 }, { id: 18, img: Vector18 }]
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  width: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  mobileOpen: boolean;
  permissionList: any,
  accoutTypeDetail: any,
  usersAccount: any,
  permissionListUser: any,
  rowDataAccount: any,
  createAlertMsg: any,
  alertType: any,
  errorCreateAlertMsg: any,
  anchorEl: any,
  permissionId: any,
  loginID: any,
  description: any,
  accou_type: any,
  userData: {
    email: string,
    name: string,
    imageUrl: string,
    userType: string
  },
  alertState: any,
  showModalUpdate: any,
  showModalPromote: any,
  showModalRemove: any,
  hideRemoveButton: any,
  hidePromoteButton: any
  userUpdatedData: any,
  page: any,
  isLoadingNoti: boolean,
  anchorElNoti: any,
  unreadNotificationRecords: Array<any>,
  scrollPage: number,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class ServiceSpecificSettingsAdminController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPermissionApiCallId: string = "";
  getAccountTypeApiCallId: string = "";
  updateAccountTypeApiCallId: string = "";
  createPermissionApiCallId: string = "";
  updatePermissionApiCallId: string = "";
  PromoteApiCallId: string = "";
  RemoveApiCallId: string = "";
  getApiCallId: string = ""
  createPermissionRemoveApiCallId: string = "";
  markAllReadApiCallId: string = "";
  userNotificationsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [

      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),

      // Customizable Area Start

      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      mobileOpen: false,
      permissionList: [],
      accoutTypeDetail: "",
      usersAccount: [],
      permissionListUser: [],
      rowDataAccount: "",
      alertType: "",
      errorCreateAlertMsg: "",
      loginID: "",
      description: "",
      accou_type: "",
      anchorEl: null,
      userUpdatedData: '0',
      permissionId: [],
      userData: {
        email: "",
        name: "",
        imageUrl: "",
        userType: "",
      },
      page: 0,
      createAlertMsg: "",
      showModalUpdate: false,
      showModalPromote: false,
      showModalRemove: false,
      hidePromoteButton: true,
      hideRemoveButton: false,
      alertState: false,
      unreadNotificationRecords: [],
      anchorElNoti: null,
      scrollPage: 1,
      isLoadingNoti: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId != null) {
        switch (true) {
          case apiRequestCallId === this.getPermissionApiCallId:
            this.getPermissionData(responseJson);
            break;
          case apiRequestCallId === this.getAccountTypeApiCallId:
            this.getAccountTypeRole(responseJson);
            break;
          case apiRequestCallId === this.updateAccountTypeApiCallId:
          this.updateAccountTypeRole(responseJson);
          break;
          case apiRequestCallId === this.updatePermissionApiCallId:
            this.updatePermissionFunct(responseJson);
            break;
          case apiRequestCallId === this.PromoteApiCallId:
            this.updatePromoteFunct(responseJson);
            break;
          case apiRequestCallId === this.RemoveApiCallId:
            this.updateRemovFunct(responseJson);
            break;
          case apiRequestCallId === this.getApiCallId:
            this.getUserFunct(responseJson)
            this.setApiCallId()
            break;
          case apiRequestCallId === this.userNotificationsApiCallId:
            this.getUserNotificationNoti(responseJson)
            break;
          case apiRequestCallId === this.markAllReadApiCallId:
            this.clearAllNotificationListNoti(responseJson)
            break;
          default:
        }
      }
    }

    // Customizable Area Start
    // Customizable Area End
  }

  // web events


  // Customizable Area Start
  getAccountTypeRole = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      let accountType: any = responseJson.data;
      const cookieData: any = Cookies.get("userProfile");
      this.updateAccountTypeApiCallId =  this.clientApiCallAccountNoti(configJSON.getAccountType + `?account_id=${ JSON.parse(cookieData).data.id}`, configJSON.validationApiMethodType)
      this.setState({ accoutTypeDetail: accountType })
    }
  }

  updateAccountTypeRole= (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      Cookies.set("userProfile", JSON.stringify(responseJson))
      this.fetchServiceAccountData();
    }
  }

  getPermissionData = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      let allPermission: any = responseJson.data;
      allPermission = allPermission?.map((user: any) => ({
        ...user,
        checked: user.attributes.user_access
      }));
      const permissionIds = allPermission.filter((user: any) => user.checked).map((user: any) => user.id);
      this.setState({ permissionList: allPermission , permissionId:permissionIds})
    }
  }
  getUserFunct = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      const allPermission: any = responseJson.data;
      this.setState({ usersAccount: allPermission })
    }
  }
  updatePromoteFunct = (responseJson: any) => {
    if (responseJson.status) {
      this.setState({
        createAlertMsg: "Promote As admin Successfully!",
        alertType: "success",
        alertState: true,
      })
      this.handleAccountType()
    } else {
      this.setState({
        createAlertMsg: "Something Went wrong!",
        alertType: "error",
        alertState: true,
      })
    }
  }
  updateRemovFunct = (responseJson: any) => {
    if (responseJson.status) {
      this.setState({
        createAlertMsg: "Remove as admin Successfully!",
        alertType: "success",
        alertState: true,
      })
      this.handleAccountType()
    } else {
      this.setState({
        createAlertMsg: "Something Went wrong!",
        alertType: "error",
        alertState: true,
      })
    }
  }
  updatePermissionFunct = (responseJson: any) => {
    if (responseJson.status == 200) {
      this.setState({
        createAlertMsg: "Update User Permission Successfully!",
        alertType: "success",
        alertState: true,
        showModalUpdate: false,
      })
      this.handlePermissionData();
    } else {
      this.setState({
        createAlertMsg: "Please send required details!",
        alertType: "error",
        alertState: true,
        showModalUpdate: false,
      })
    }
  }

  handleCloseAlert = () => {
    this.setState({
      alertState: false,
      createAlertMsg: "",
      errorCreateAlertMsg: "",
    })
  }

  handelInputPost = (id: string) => {
    this.setState(prevState => {
      const updatedPermissionList = prevState.permissionList.map((user: any) => {
        if (user.id === id) {
          return {
            ...user,
            checked: !user.checked,
          };
        }
        return user;
      });
      const selectedIds = updatedPermissionList
        .filter((user: any) => user.checked)
        .map((user: any) => user.id);
      return {
        permissionList: updatedPermissionList,
        permissionId: selectedIds,
      };
    });
  };
  handlePermissionData = () => {
    let token = localStorage.getItem("authToken");
    const headers = {
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPermissionApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPermissionList + `?account_id=${this.state.rowDataAccount.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };


  handleAccountType = () => {
    let token = localStorage.getItem("authToken");
    const headers = {
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAccountTypeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAccountType + `?account_id=${this.state.rowDataAccount.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  updatePromoteAsAdnin = () => {
    let token = localStorage.getItem("authToken");
    let requestBody;
    requestBody = {
      account_id: this.state.rowDataAccount.id,
      user_role: "admin",
    }
    const headers = {
      "Content-Type": "application/json",
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PromoteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateUserRole
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return this.PromoteApiCallId;
  }
  updateRemoveAsAdnin = () => {
    let token = localStorage.getItem("authToken");
    let requestBody;
    requestBody = {
      account_id: this.state.rowDataAccount.id,
      user_role: "normal_user",
    }
    const headers = {
      "Content-Type": "application/json",
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.RemoveApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateUserRole
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return this.RemoveApiCallId;
  }
  updateUserPermission = () => {
    let token = localStorage.getItem("authToken");
    let requestBody;
    requestBody = {
      account_id: this.state.rowDataAccount.id,
      permission_ids: this.state.permissionId,
    }
    const headers = {
      "Content-Type": "application/json",
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updatePermissionApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addPermission
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return this.updatePermissionApiCallId;
  }
  usersAccountFetch = async () => {
    this.getApiCallId = await this.callApi({
      method: 'GET',
      endPoint: `account_block/get_all_accounts?&page=${this.state.page + 1}&per_page=10&&sort_by=desc`,
    })
  }
  callApi = async (data: CustomTypeData) => {
    const { method, endPoint } = data;
    const header = { "token": localStorage.getItem("authToken") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id,
      requestMessage);
    return requestMessage.messageId;
  };
  fetchServiceAccountData = () => {
    const cookieData: any = Cookies.get("userProfile");
    const parsedCookieData = JSON.parse(cookieData).data.attributes
    this.setState({
      userData: {
        email: parsedCookieData.email,
        name: parsedCookieData.first_name + " " + parsedCookieData.last_name,
        imageUrl: parsedCookieData.profile_image,
        userType: parsedCookieData.user_role,
      }
    })
  }
  setApiCallId = () => {
    const { rowDataAccount } = this.state
    if (this.state.userUpdatedData != 0) {
      this.setState({
        loginID: rowDataAccount.attributes.login_id,
        description: rowDataAccount.attributes.account_description,
        accou_type: rowDataAccount.attributes.account_type,
      })
    }
  }
  handleTypeAndPermission = () => {
    this.handleAccountType();
    this.handlePermissionData()
  }
  async componentDidMount() {
    this.usersAccountFetch();
    this.fetchServiceAccountData();
    const accountDtaPermission = localStorage.getItem('accountId');
    if (accountDtaPermission) {
      this.setState({
        rowDataAccount: JSON.parse(accountDtaPermission),
        userUpdatedData: JSON.parse(accountDtaPermission).id
      },
        () => this.handleTypeAndPermission())

    }

    this.userNotificationsApiCallId = this.clientApiCallAccountNoti(configJSON.userNotifications + `&page=${this.state.scrollPage}`, configJSON.validationApiMethodType)
  }
  closeModalUpdate = () => {
    this.setState({ showModalUpdate: false })
  }
  closeModalPromote = () => {
    this.setState({ showModalPromote: false })
  }
  closeModalRemove = () => {
    this.setState({ showModalRemove: false })
  }
  showModalRemove = () => {
    this.setState({ showModalRemove: true })
  }
  handleDrawerToggles = () => {
    this.setState((prevState: any) => ({
      mobileOpen: !prevState.mobileOpen,
    }));
  };
  handlePromoteItem = () => {
    this.updatePromoteAsAdnin()
    this.setState({
      showModalPromote: false
    });

  }
  handleRemoveItem = () => {
    this.updateRemoveAsAdnin()
    this.setState({
      showModalRemove: false
    });

  }
  handleImage = (id: any) => {
    const foundItem: any = imageHand.find(item => item.id == id);
    return foundItem?.img;
  }

  handleClickPopOver = (event: any) => {
    this.setState({
      anchorElNoti: event.currentTarget
    })
  };

  handleClosePopOver = () => {
    this.setState({ anchorElNoti: null })
  }

  onScrollUnreadNotificationList = () => {
    this.setState({ isLoadingNoti: true }, () => this.userNotificationsApiCallId = this.clientApiCallAccountNoti(configJSON.userNotifications + `&page=${this.state.scrollPage}`, configJSON.validationApiMethodType))
  }

  handleClearAllNoti = () => { 
    this.markAllReadApiCallId = this.clientApiCallAccountNoti(configJSON.markAllRead, configJSON.patchMethod) }

  
  clearAllNotificationListNoti = (responseJsons: any) => {
    if (responseJsons && !responseJsons.errors) {
      this.setState({ unreadNotificationRecords: [], isLoadingNoti: false, scrollPage: 1 })
    }
  }
  getUserNotificationNoti = (responseJsons: any) => {
    if (responseJsons && !responseJsons.errors) {
      const unreadDatas: any = responseJsons.data;
      const itemsNoti = [...this.state.unreadNotificationRecords, ...unreadDatas]
      this.setState({ unreadNotificationRecords: itemsNoti, scrollPage: responseJsons.meta.next_page, isLoadingNoti: false })
    } else {
      this.setState({ unreadNotificationRecords: [], isLoadingNoti: false, scrollPage: 1 })
    }
  }
  clientApiCallAccountNoti = (endPoint: string, method: string) => {
    let authTokens = localStorage.getItem("authToken");
    const headers = {
      token: authTokens,
      "Content-Type": "application/json",
    };
    const clientReqMessages = new Message(getName(MessageEnum.RestAPIRequestMessage));
    clientReqMessages.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    clientReqMessages.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    clientReqMessages.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    runEngine.sendMessage(clientReqMessages.id, clientReqMessages);
    return clientReqMessages.messageId
  }



  // Customizable Area End
}
