import React from 'react'
import { Box, Popover } from "@material-ui/core";
import moment from 'moment';

const UnreadNotificationList = (props: any) => {

    const { anchorEl, handleClosePopOver, navigate, notificationData, isLoading, unreadNotificationList, scrollPage, handleClearAll } = props

    const handleClose = () => {
        handleClosePopOver()
    }

    const redirectToNotification = (notificationType: string) => {
        const redirectTo = notificationType == 'reminder' ? 'UserReminderUnread' : 'UserNotificationUnread'
        navigate(redirectTo)
    }

    const handleScrollAccount = (event: any) => {
        const dropdown = event.target;
        const { scrollHeight, scrollTop, clientHeight } = dropdown
        if ((scrollHeight - scrollTop <= clientHeight + 20) && !isLoading && scrollPage) {
            unreadNotificationList()
        }
    }

    const clearAllFunction = () => {
        notificationData.length > 0 && handleClearAll()
    }
    
    return (
        <>
            {anchorEl && <div style={{
                position: 'absolute',
                width: '0',
                height: '0',
                borderLeft: '14px solid transparent',
                borderRight: '14px solid transparent',
                borderBottom: '14px solid #fff',
                right: "32px"
            }}></div>}

            <Popover
                className="bellNotification"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                PaperProps={{
                    style: {
                        overflowX: "auto",
                        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09) !important",
                        marginTop: '52px',
                        ...(notificationData.length > 0 && { bottom: '30px' }),
                    }
                }}
                onScroll={handleScrollAccount}
            >
                <Box className="notificationContent">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #E2E8F0"
                        }}
                    >
                        <p className="notiHeading">Your Notifications</p>
                        <p className="clearHeading" onClick={clearAllFunction}>Clear All</p>
                    </div>

                    {notificationData.length > 0 && notificationData.map((ele: any) => (
                        <Box
                            key={ele.id}
                            onClick={() =>
                                redirectToNotification(ele.attributes.notification.attributes.notification_type)
                            }
                            style={{
                                padding: "15px 5px",
                                borderBottom: "1px solid #E2E8F0",
                                cursor: "pointer"
                            }}
                        >
                            <span className="notificationTitle">
                                <b style={{ textTransform: 'capitalize' }}>{ele.attributes.notification.attributes.notification_type}</b>  {ele.attributes.notification.attributes.headings}
                            </span>
                            <div className="bottomBlock">
                                <span style={{ textTransform: 'capitalize' }}>Sent by  {ele.attributes.notification.attributes.created_by.first_name + " " + ele.attributes.notification.attributes.created_by.last_name}</span>
                                <span> {moment.utc(ele.attributes.created_at, 'YYYY-MM-DD hh:mm').local().format('D-M-YYYY, h.mm A')}</span>
                            </div>
                        </Box>
                    ))}
                    {(isLoading) && <Box className="notificationTitle" style={{ padding: '15px' }}>
                        Loading...
                    </Box>
                    }
                    {notificationData.length == 0 &&
                        <Box
                            className="notificationTitle"
                            style={{ marginTop: "15px" }}
                        >
                            No new notifications.
                        </Box>
                    }
                </Box>
            </Popover>
        </>
    )
}

export default UnreadNotificationList;