import React from "react";
// Customizable Area Start
import SideBar from "../../../components/src/SideBar";
import MenuIcon from "@material-ui/icons/Menu";
import Snackbar from "@material-ui/core/Snackbar";
import { bellIcon, unreadBellIcon } from "./assets";
import UnreadNotificationList from "../../../components/src/UnreadNotificationList.web";
import MuiAlert from "@material-ui/lab/Alert";
import {
  AppBar,
  TextField,
  Typography,
  Button,
  Tab,
  IconButton,
  Drawer,
  withWidth,
} from "@material-ui/core";
import "../../email-account-login/src/Style.css";
import { createTheme, ThemeProvider,styled } from "@material-ui/core/styles";
const StyledTextField = styled(TextField)({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
  },
});
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins !important'
  },
});
// Customizable Area End
import CommissionSplitController, { Props } from "./CommissionSplitController";
export class CommissionEdit extends CommissionSplitController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const { width } = this.props;
    const isMobile = /s/.test(width);
    const { userSuccessAlert, showAlert, alertType,isLoadingNoti, unreadNotificationRecords,
      scrollPage, } = this.state;
    return (
      <ThemeProvider theme={theme}>
      <div style={webStyle.addAccountScreen}>
        {isMobile ? (
          <div style={webStyle.sidebarParentHead} className="sidebarIcon">
            <IconButton
              onClick={this.handleDrawerToggle}
              style={{ color: "white" }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
            >
              {
                <SideBar
                  style={webStyle.sidebar}
                  navigate={this.props.navigation.navigate}
                  activeMenuItem={"CommissionSplit"}
                  userData={this.state.userData}
                />
              }
            </Drawer>
          </div>
        ) : (
          <div className="sidebarResponse" style={webStyle.sidebar}>
            <SideBar
              activeMenuItem={"CommissionSplit"}
              navigate={this.props.navigation.navigate}
              userData={this.state.userData}
            />
          </div>
        )}
        <div
          style={webStyle.pageDefaultScreen}
          className="accessAddPageDefault"
        >
          <AppBar
            position="fixed"
            style={webStyle.pageHeading}
            className="accessAddHeader"
          >
            {this.state.userData.userType !== "admin" &&
              <>
                <img
                  style={webStyle.notifications}
                  src={unreadNotificationRecords.length > 0 ? unreadBellIcon : bellIcon}
                  onClick={this.handleClickPopOverNoti}
                  className="notiIconAdd"
                />
                <UnreadNotificationList
                  anchorEl={this.state.anchorElNoti}
                  handleClosePopOver={this.handleClosePopOverNoti}
                  navigate={this.props.navigation.navigate}
                  notificationData={unreadNotificationRecords}
                  isLoading={isLoadingNoti}
                  unreadNotificationList={this.onScrollUnreadNotificationList}
                  handleClearAll={this.handleClearAll}
                  scrollPage={scrollPage}
                />
              </>
            }
          </AppBar>
          <div style={webStyle.divider} className="padding_0_res">
            <div style={webStyle.pageHeadingss}>
              <Tab
                label="Account Commission"
                style={webStyle.selectedTab}
                className="AccounComPage"
                onClick={this.accountCommissionPage}
              ></Tab>

              <Tab
                label="Monthly Report"
                style={webStyle.MonthlyBtn}
                className="AccounComPage"
                onClick={this.monthlyReportPage}
              ></Tab>
            </div>
          </div>
          
<div style={{overflowY:"auto", height:"70%", overflowX :"hidden"}} className="editAccountCom">
          <div style={webStyle.userAccountForm} className="userDetailsAccount">
            <form
              ref={this.myFormRef}
              data-test-id="formSubmit"
              className="userDetailsForm"
            >
              <div style={webStyle.userInputDetails}>
                <div style={webStyle.formDetails} className="formDetails">
                  <Typography style={webStyle.inputTitle}>
                    Account Name*:
                  </Typography>
                  <div className="inputName">
                    <TextField
                      placeholder={this.state.userFirstName}
                      inputProps={{
                        maxLength: 30,
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      name="firstName"
                      className="nameDetails"
                      disabled
                      style={{
                        ...webStyle.inputNameDetails,
                        marginRight: "30px",
                        backgroundColor: "EFEFEF",
                      }}
                    ></TextField>
                    <TextField
                      placeholder={this.state.userLastName}
                      inputProps={{
                        maxLength: 30,
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      disabled
                      name="lastName"
                      className="nameDetails"
                      style={{
                        ...webStyle.inputNameDetails,
                        backgroundColor: "EFEFEF",
                      }}
                    ></TextField>
                  </div>
                </div>
                <div style={webStyle.formDetails} className="formDetails">
                  <Typography style={webStyle.inputTitle}>
                    Login ID*:
                  </Typography>
                  <TextField
                    disabled
                    placeholder={this.state.loginID}
                    inputProps={{
                      maxLength: 15,
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    name="loginID"
                    className="inputDetails"
                    style={{
                      ...webStyle.inputDetails,
                      backgroundColor: "EFEFEF",
                    }}
                  ></TextField>
                </div>

                <div style={webStyle.formDetails} className="formDetails">
                  <Typography style={webStyle.inputTitle}>
                    MT5 Account Number:
                  </Typography>
                  <TextField
                    placeholder={this.state.MT5}
                    name="MT5"
                    disabled
                    InputProps={{
                      disableUnderline: true,
                    }}
                    style={{
                      ...webStyle.inputDetails,
                      backgroundColor: "EFEFEF",
                    }}
                    className="inputDetails"
                  ></TextField>
                </div>

                <div style={webStyle.formDetails} className="formDetails">
                  <Typography style={webStyle.inputTitle}>
                    Tinkerbell Commission%:
                  </Typography>
                  <StyledTextField
                    placeholder="Tinkerbell Commission"
                    name="tinkerbell"
                    type="number"
                    data-test-id="tinkerbell"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    onChange={this.handleTinkerbell}
                    value={this.state.tinkerbell}
                    className="inputDetails"
                    style={webStyle.inputDetails}
                  ></StyledTextField>
                </div>
                <div style={webStyle.formDetails} className="formDetails">
                  <Typography style={webStyle.inputTitle}>
                    Deepest FX Commission%:{" "}
                  </Typography>
                  <StyledTextField
                    placeholder="Deepest FX Commission"
                    name="deepest"
                    type="number"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    data-test-id="deepestCom"
                    onChange={this.handleDeepest}
                    value={this.state.deepest}
                    className="inputDetails"
                    style={webStyle.inputDetails}
                  ></StyledTextField>
                </div>
                <div style={webStyle.formDetails} className="formDetails">
                  <Typography style={webStyle.inputTitle}>
                    Affiliated Commission%:{" "}
                  </Typography>
                  <StyledTextField
                    placeholder="Affiliated Commission"
                    name="affiliated"
                    type="number"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    onChange={this.handleAffiliated}
                    data-test-id="affiliatedCom"
                    value={this.state.affiliated}
                    style={webStyle.inputDetails}
                    className="inputDetails"
                  ></StyledTextField>
                </div>
                <div style={webStyle.formDetails} className="formDetails">
                  <Typography style={webStyle.inputTitle}>
                    Master Trader%:{" "}
                  </Typography>
                  <StyledTextField
                    placeholder="Master Trader"
                    name="master"
                    type="number"
                    data-test-id="masterCom"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    onChange={this.handleMaster}
                    value={this.state.master}
                    style={webStyle.inputDetails}
                    className="inputDetails"
                  ></StyledTextField>
                </div>
              </div>
              <div style={webStyle.formAction} className="formAction">
                <Button
                  style={webStyle.resetFormBtn}
                  onClick={(e: any) => {
                    this.resetUserDetails(e);
                  }}
                  className="button_width"
                  data-test-id="resetUserDetails"
                >
                  Reset Form
                </Button>
                {this.state.getUserPermissionDataEditCommission  == "Edit Commissions" &&
                <Button
                  style={webStyle.btnAddAcount}
                  onClick={this.updateUserAccount}
                  data-test-id="editUser"
                  className="button_width"
                >
                  Update
                </Button>
  }
              </div>
            </form>
          </div>
          </div>
        </div>

        <Snackbar
          open={showAlert}
          autoHideDuration={3000}
          data-test-id="handleAlertClose"
          onClose={this.handleAlertClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            onClose={this.handleAlertClose}
            severity={alertType}
            elevation={6}
            variant="filled"
            style={{ width: "100%" }}
          >
            {alertType === "success" ? `${userSuccessAlert}` : `${this.state.usersUpdateError}`}
          </MuiAlert>
        </Snackbar>
      </div>
      </ThemeProvider>
    );
  }
}
export default withWidth()(CommissionEdit);

// Customizable Area End

// Customizable Area Start
const webStyle: any = {
  sidebarParentHead:{
    position: "fixed",
    top: "10px",
    left: "12px",
    background: "rgba(0, 0, 0, 0) linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%) repeat scroll 0% 0%"
  },
  pageHeading: {
    backgroundColor: "white",
    fontFamily: "Poppins",
    width: "76%",
    height: "75px",
    borderBottom: "1px solid rgba(128, 128, 128, 0.43)",
    boxShadow: "none",
    borderLeft: "none",
    zIndex:"2"
  },
  addAccountScreen: {
    fontFamily: "Poppins",
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    margin: 0,
    overflow: "hidden",
  },
  sidebar: {
    width: "24vw",
    fontFamily: 'Poppins',
    marginRight: "-100px",
    zIndex: "9",
    height: "100%",
    position:"fixed",
    bottom : 0,
    top:0,
  },
  pageDefaultScreen: {
    height: "100%",
    width: "79vw",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    top: "100px",
    marginLeft: "21vw",
  },
  btnAddAcount: {
    fontFamily: "Poppins",
    padding: "20px 40px",
    height: "42px",
    background:
      "linear-gradient(0deg, rgba(16, 41, 114, 1.0), rgba(2, 5, 10, 1.0))",
    borderRadius: "20px",
    color: "white",
    fontSize: "15px",
    textTransform: "Capitalize",
    marginRight: "3%",
    lineHeight:"1"
  },
  pageHeadingss: {
    marginTop: "-2%",
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },
  MonthlyBtn: {
    fontSize: "21px",
    fontWeight: 500,
    textTransform: "capitalize",
    color: "#6A6A6A",
  },
  userAccountForm: {
    margin: "5px 13% 5px 13%",
    width: "72%",
    // overflowY:'auto'
  },
  formDetails: {
    display: "flex",
    justifyContent: "space-between",
  },
  inputDetails: {
    borderRadius: "5px",
    padding: "10px 10px 10px 10px",
    width: "542px",
    marginBottom: "20px",
    border: "1px solid gray",
  },
  
  userInputDetails: {
    fontFamily: "Poppins",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  selectedTab: {
    fontSize: "21px",
    borderBottom: "3px solid #000",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  resetFormBtn: {
    fontFamily: "Poppins",
    padding: "20px 30px",
    height: "42px",
    borderRadius: "20px",
    color: "black",
    fontSize: "15px",
    textTransform: "Capitalize",
    border: "1px solid black",
    marginRight: "30px",
    lineHeight:"1"
  },
  inputNameDetails: {
    fontFamily: "Poppins",
    width: "244px",
    marginBottom: "20px",
    border: "1px solid gray",
    borderRadius: "5px",
    padding: "10px",
  },
  inputTitle: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 400,
    marginTop: "12px",
  },
  formAction: {
    fontFamily: "Poppins",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  notifications: {
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
    fontFamily: "Poppins",
    position: "fixed",
    right: "30px",
    top: "20px",
    color: "black",
    width: "32px",
    height: "32px",
  },
  barIcon: {
    position: "fixed",
    top: 10,
    left: 12,
    background:
      "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
  },
  divider: {
    padding: "0px 0px 0px 50px",
    boxShadow: "rgb(34, 34, 34) 0px 4px 6px -6px",
    marginBottom:"3%"
  },
};
// Customizable Area End
