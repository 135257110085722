export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const vector = require("../assets/vector.png");
export const dataUpload=require("../assets/dataUpload.png");
export const Vector3 = require("../assets/Vector (3).png");
export const Vector4 = require("../assets/Vector (4).png");
export const Vector5 = require("../assets/Vector (5).png");
export const Vector6 = require("../assets/Vector (6).png");
export const Vector7 = require("../assets/Vector (7).png");
export const Vector8 = require("../assets/Vector (8).png");
export const Vector9 = require("../assets/Vector (9).png");
export const Vector10 = require("../assets/Vector (10).png");
export const Vector11 = require("../assets/Vector (11).png");
export const Vector12 = require("../assets/Vector (12).png");
export const Vector13 = require("../assets/Vector (13).png");
export const Vector14 = require("../assets/Vector (14).png");
export const Vector15 = require("../assets/Vector (15).png");
export const Vector16 = require("../assets/Vector (16).png");
export const Vector17 = require("../assets/Vector (17).png");
export const Vector18 = require("../assets/Vector (18).png");
export const bellIcon = require("../assets/Notification_bell.svg");
export const unreadBellIcon = require("../assets/Notification_unread.svg");
export const defaultUser = require("../assets/defaultImage.jpg");
