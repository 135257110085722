import React from 'react';
import {
    Table,
    TableCell,
    TableRow,
    TableBody,
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination'
import "../src/assets/Css/Style.css"
import { formatMinutesToHoursAndMinutes } from './utils/utils'
import { defaultUser } from './assets'

const UserTableList = (props: any) => {
    const { userData, handleChangeUser, selectedUser, page, handlePaginationChange, usagePagination } = props;

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        handlePaginationChange(value)
    }

    const handleSelect = (row: any) => {
        handleChangeUser(row)
    }

    const handleRowColor = (row: any) => {
        if (selectedUser) {
            return row.id == selectedUser.id ? "#DADADA" : "initial";
        }
    }

    return (
        <div className='tableContainer responsiveMargin' style={{ margin: userData.length === 0 ? '60px auto' : 'initial' }}>
            <Table>
                <TableBody>
                    {userData.length > 0 && userData.map((row: any, index: number) => (
                        <>
                            {row.first_name && <TableRow key={index} className='tableRowList' onClick={() => handleSelect(row)} style={{ cursor: 'pointer', backgroundColor: handleRowColor(row) }}>
                                <TableCell><img src={row.profile_image || defaultUser} width={42} height={42} /></TableCell>
                                <TableCell>{row.first_name + ' ' + row.last_name}</TableCell>
                                <TableCell>
                                    {formatMinutesToHoursAndMinutes(row.duration?.duration)}
                                </TableCell>
                            </TableRow>}
                        </>
                    ))}                   
                    {usagePagination.total_pages === 0 && 
                        <TableRow style={{ textAlign: "center"}}>
                            <TableCell align="center" className='tableRowList' style={{padding: '50px 0px'}}>No data found</TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>

            {usagePagination.total_pages > 0 &&
                (<Pagination
                    className="paginationContainer"
                    count={usagePagination.total_pages}
                    page={page}
                    onChange={handleChange}
                />)
            }
        </div>
    )
}

export default UserTableList