import React from "react";

// Customizable Area Start
import {
  Container,
  AppBar,
  Toolbar,
  Box,
  Button,
  TextField,
  InputAdornment,
  Typography,
  IconButton,
} from "@material-ui/core";
const tradeImages = require("./assets");
import '../../email-account-login/src/Style.css'

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
const font = "'Poppins', sans-serif";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: font,
  },
});

// Customizable Area End
import ForgotResetPasswordController, {
  configJSON,
  Props,
} from "./ForgotResetPasswordController";

export default class NewPassword extends ForgotResetPasswordController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      // Required for all blocks
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"xl"} style={webStyle.pagesContainer} className="pageContainer">
          <Box sx={webStyle.mainsWrapper}>
            <AppBar style={{ backgroundColor: "#0c0c0c" }}>
              <Toolbar>
                <img src={tradeImages.imgLogo} />
              </Toolbar>
            </AppBar>
            <Box style={webStyle.loginForm} className="mainNewContainer">
              <Box textAlign="center" className="logoVertical">
                <img src={tradeImages.logoVertical} width="238px" height="133px"/>
              </Box>
              <Typography style={webStyle.passwordTitle} className="passwordText">Password Reset</Typography>
              <Typography
                style={webStyle.passwordContent}
                className="newPassText"
              >
                Enter your new password and repeat it.
              </Typography>
              <form className="forgotField">
                <TextField
                 className="loginInput"
                  data-test-id="newPasswordField"
                  inputProps={{
                    maxLength: 16,
                  }}
                  style={
                    this.state.passwordErrorDisplay
    
    ? {...webStyle.passwordFieldError, marginBottom:"30px"}
                      : webStyle.passwordField
                  }
                  type={this.state.enablePasswordField ? "password" : "text"}
                  fullWidth
                  placeholder={configJSON.placeHolderNewPassword}
                  value={this.state.newUserPassword}
                  onChange={(event : any) =>
                    this.setNewPasswordField(event.target.value)
                  }
                  InputProps={{
                    style: { color: "#fff" },
                    disableUnderline:true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          color="primary"
                          aria-label="toggle password visiblity"
                          onClick={this.handleClickNewShowPassword}
                          edge="end"
                        >
                          {this.state.enablePasswordField ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  

                />
                <TextField
                  data-test-id="ConfirmPasswordField"
                  className="loginInput"
                  inputProps={{
                    maxLength: 16,
                  }}
                  style={
                    this.state.passwordErrorDisplay
                      ? webStyle.passwordFieldError
                      : webStyle.passwordField
                  }
                  type={
                    this.state.enableConfirmPasswordField ? "password" : "text"
                  }
                  fullWidth
                  placeholder={configJSON.placeHolderConfirmPassword}
                  value={this.state.confirmUserPassword}
                  onChange={(event : any) =>
                    this.setNewConfirmPasswordField(event.target.value)
                  }
                  error={this.state.passwordErrorDisplay ? true : false}
                  InputProps={{
                    style: { color: "#fff" },
                    disableUnderline:true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          color="primary"
                          aria-label="toggle password visiblity"
                          onClick={this.handleClickNewShowConfirmPassword}
                          edge="end"
                        >
                          {this.state.enableConfirmPasswordField ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
          <span style={webStyle.error} className="showMessage">
                    {this.state.passwordErrorDisplay}
                </span>
                <span style={webStyle.success} className="showMessage">
                  {this.state.passwordSuccessDisplay}
                </span>
               
                <Box sx={webStyle.loginBtn} className="resetbtn">
                  <Button
                    data-test-id="formSubmit"
                    variant="contained"
                    style={webStyle.btn}
                    className="newBtn"
                    onClick={() => this.updateNewUserPassword()}
                  >
                    {configJSON.btnUpdatePassword}

                  </Button>
                </Box>
                </form>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>

      // Customizable Area end
    );
  }
}
// Customizable Area Start
const webStyle : any = {
  pagesContainer: {
    backgroundImage: `url(${tradeImages.bgImg})`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    minWidth:"100vw",
    height:"100vh",
    position:"fixed",
    top:0,
    bottom:0

  },
  mainsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    margin: 0,
    padding: 0,
    maxWidth: "100%",
  },
  passwordContent: {
    color: "#fff",
    textAlign: "center",
    fontSize:"25px",
    fontWeight:400
  },
  loginForm: {
    width: "581px",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
     justifyContent: "space-evenly",
    marginTop:"70px"
  },
  passwordTitle : {
     fontSize:"44px",
     fontWeight:400,
     color:"#FFFFFF"
  },
  passwordField: {
    borderRadius: "10px",
    border: "1px solid #fff",
    fontSize:"22px",
    fontFamily: "poppins",
    marginBottom:"30px",
    width:"581px",
    padding:"20px",
    "::placeholder": {
      color: "#fff",
      background: "#f9f9f9",
    },
  },
  passwordFieldError: {
    borderRadius: "10px",
    border: "2px solid red",
    width:"581px",
    fontFamily: "poppins",
    padding:"20px",
    "::placeholder": {
      color: "#fff",
      background: "#f9f9f9",
    },
  },
  error: {
    color: "red",
    fontSize:"16px",
    textAlign:"left",
    marginTop:"-50px",
    width:"100%",
    textTransform:"capitalize" 
  },

  success: {
    color: "green",
    fontSize:"16px",
    textAlign:"left",
    width:"100%",
    marginTop:"-50px",
  },
  loginBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  btn: {
    minHeight: "71px",
    minWidth: "247px",
    borderRadius: "35px",
    background:
      "linear-gradient(19.24deg, #07D9FF -19.29%, #04B0F8 33.7%, #0076EF 126.05%)",
    color: "#fff",
    fontSize: "22px",
    textTransform: "none" as const,
    fontFamily: "poppins",
    
  },
};
// Customizable Area end
