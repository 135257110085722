import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';

const CircleChart = (props: any) => {
  const { commissionsRecords, commissionArray, COLORS, commissionNameType } = props
  const outerRadius = 108;  // Calculate the required width and height based on the outer radius  
  const chartWidth = outerRadius * 2;
  const chartHeight = outerRadius * 2;

  return (
    <div className='pieChartContainer'>
      <span className='commisionTitle'>Total Commissions $</span>
      <PieChart width={chartWidth+10} height={chartHeight+10}>
        <text
          className='boldLabel'
          x={chartWidth / 2}
          y={chartHeight / 2 - 20} // Adjust the vertical position
          textAnchor='middle'
          dominantBaseline='middle'
        >
          ${commissionsRecords.total_commisssions}
        </text>
        <text
          x={chartWidth / 2}
          y={chartHeight / 2 + 20}
          textAnchor='middle'
          dominantBaseline='middle'
        >
          Total Commissions
        </text>
        <Pie
          data={commissionArray}
          cx={chartWidth / 2} // Center the pie chart horizontally
          cy={chartHeight / 2} // Center the pie chart vertically
          outerRadius={outerRadius}
          innerRadius={100}
          fill='#8884d8'
          dataKey='value'
          paddingAngle={-2}
          cornerRadius={5}
          blendStroke
        >
          {commissionArray.map((entry: any, index: number) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
      <ul>
        {commissionArray.map((element: any, index: number) => (
          <li key={`title-${index}`} style={{ color: COLORS[index] , margin: '7px 0px' }}>
            <span className='listColor'>{commissionNameType[index]}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CircleChart;

