export const AppConstant={
    PASSWORD_SUCCESS:"Password has been updated",
    PASSWORD_ERROR_DISPLAY_TRYAGAIN : "Try Again...",
    PASSWORD_ERROR_DISPLAY_NO_MATCH : "Passwords do not match",
    PASSWORD_ERROR_DISPLAY_VALID_PASSWORD : "Please enter Valid password",
    REGEXP_REG : /^\w+([.-]?\w+)*@\w+([.-]?\w+)([.-]?\w+)$/,
    INCORRECT_PASSWORD : "Incorrect password",
    LOGIN_ID : "Account not found",
    NOT_ACTIVATED : "Account not activated",
    PASSWORD_VALIDATION: "please enter a valid password (8-16 characters,1 Uppercase, Special, Numbers)",
    NUMBER_TYPE: /[^0-9.]/g,
    DECIMAL_TYPE : /^(\d+(\.\d{0,2})?)?$/
}

export const firebaseConfig = {
    apiKey: "AIzaSyBnN7_BET_Yhuqn8roZPgEyHdtz_Q7L2mk",
    authDomain: "td-trade-92290.firebaseapp.com",
    projectId: "td-trade-92290",
    storageBucket: "td-trade-92290.appspot.com",
    messagingSenderId: "543669411425",
    appId: "1:543669411425:web:50a79bf035371061901420"
}

export const firebaseVapidKey = `BJiU95-t0G_IyZSjlMto_T5DV262VPDP8sMkgZHEh7zbkPLBHhID60FyhO5dZat8LKvuWALRo35TEgRaqi64EIU`