import React from "react";

// Customizable Area Start
import {
  TextField,
  Box,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  withWidth,
  Drawer,
  Popover,
  MenuItem,
  MenuList,
  Grid,
  Button
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import SideBar from "../../../components/src/SideBar";
import Divider from "@material-ui/core/Divider";
import MoreVert from "@material-ui/icons/MoreVert";
import Snackbar from '@material-ui/core/Snackbar';
import UnreadNotificationList from "../../../components/src/UnreadNotificationList.web";
import moment from 'moment';
import MenuIcon from '@material-ui/icons/Menu';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "../assets/css/style.css";
const images = require("../../../components/src/assets");
// Customizable Area End

import UserNotificationTdController, {
  Props,
} from "./UserNotificationTdController";
import NotificationsHeaderSent from "../../../components/src/NotificationsHeader";
import UserSecondNotiHeader from "../../../components/src/UserSecondNotiHeader.web";
import ModalPopUp from "../../../components/src/ModalPopUp.web";
import AlertSechNoti from "../../../components/src/AlertNotification.web";
// Customizable Area Start
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins'
  },
});
export class UserReminderRead extends UserNotificationTdController {
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  handleTargetRow = () => {
    let response: any = {};
    if (this.state.readReminderList.items) {
      response = this.state.readReminderList.items.find((item: any) => item.id === this.state.getUpdatedId);
    }
    return response;
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { showModal, searchState, readReminderList, updateStatus , unreadNotificationRecords, isLoading, scrollPage, showUpdateRowData} = this.state
    const { width } = this.props
    const isMobile = /s/.test(width);
    const targetRecord = this.handleTargetRow()
    return (
      /* @ts-ignore */
      <ThemeProvider theme={theme}>
        <Box className="table_body" style={stylesReadReminder.fullscreenBody}>
          {/* @ts-ignore */}
          <Box
            style={stylesReadReminder.sidebarParentBodyBody}>
            {isMobile ?
              <div
                style={{
                  top: "10px",
                  left: "12px",
                  position: "fixed", background: "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
                }}
              >
                <IconButton
                  style={{ color: 'white' }}
                  onClick={this.handleDrawerToggles}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  ModalProps={{
                    keepMounted: true,
                  }}
                  open={this.state.mobileOpen}
                  onClose={this.handleDrawerToggles}
                >
                  {<SideBar
                    navigate={this.props.navigation.navigate}
                    style={stylesReadReminder.sidebar}
                    userData={this.state.userData}
                    activeMenuItem={"Notifications & Reminders"}
                  />}
                </Drawer>
              </div> :
              <div style={stylesReadReminder.sidebar}
                className="sidebarResponse"  >
                <SideBar
                  activeMenuItem={"Notifications & Reminders"}
                  userData={this.state.userData}
                  navigate={this.props.navigation.navigate}
                />
              </div>}
          </Box>
          <Box style={stylesReadReminder.divContainerBody} className="width_100_p"  >
            <div style={stylesReadReminder.topNavBarBody}>
              <>
                <div style={stylesReadReminder.bellIconBody}>
                  {this.state.userData.userType !== "admin" &&
                    <>
                      <img
                        data-test-id="notificationReadTab"
                        src={this.handleIcon()}
                        style={stylesReadReminder.pointerBody}
                        onClick={this.handleClickPopOver}
                      />
                      <UnreadNotificationList
                        anchorEl={this.state.anchorElNoti}
                        handleClosePopOver={this.handleClosePopOverNoti}
                        navigate={this.props.navigation.navigate}
                        notificationData={unreadNotificationRecords}
                        isLoading={isLoading}
                        unreadNotificationList={this.onScrollUnreadNotificationList}
                        handleClearAll={this.handleClearAll}
                        scrollPage={scrollPage}
                      />
                    </>
                  }
                </div>
              </>
              <div style={stylesReadReminder.pageTitleBody} className="padding_0_res">
                <NotificationsHeaderSent
                  clickTab={this.clickTab}
                  state={this.state}
                  goToSentNotifications={this.goToAll}
                  goToReminders={this.goToAllReminder}
                />
              </div>
            </div>
            <Box className="verticalScroll">
              <Box className="scrollMaxHeight" style={{ margin: "24px", overflow: 'auto', minHeight: '80%' }}>
                <Box className="BoxFlex" style={{ marginTop: "0px" }}>
                  <UserSecondNotiHeader
                    goToAll={this.goToAllReminder}
                    goToRead={this.goToReadReminder}
                    clickTab={this.clickTabNoti}
                    goToUnread={this.goToUnreadReminder}
                    state={this.state}
                  />
                  <Box style={{ marginRight: "4px" }}>
                    <TextField
                      className="searchInput"
                      value={searchState}
                      onChange={this.reminderSearchInput}
                      label="Search by Title"
                      data-test-id="searchBtnRead"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment variant="filled" position="end" >
                            <IconButton
                              onClick={() => this.handleSearch('readReminder')}
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Box>
                </Box>
                <Box className="dynamicHeight">
                  <Table className="PTable">
                  {!showUpdateRowData && <TableHead>
                      <TableRow className="tableRow rowCell" style={stylesReadReminder.rowHead}>
                        <TableCell>
                          Label
                        </TableCell>
                        <TableCell>
                          Sent By
                        </TableCell>
                        <TableCell>
                          Date
                        </TableCell>
                        <TableCell>
                          More
                        </TableCell>
                      </TableRow>
                    </TableHead>}
                    <TableBody>
                      {showUpdateRowData && targetRecord ?
                        <>
                          <Grid item xs={12}>
                            <div style={{ borderRadius: "10px", border: "1px solid #C7C3C3" }}>
                              <div className="paddingBottomDflex" style={{ justifyContent: "space-between" }}>
                                <Box className="customTextFieldBox" style={{ padding: "12px", width: "fit-content", maxWidth: "500px", wordBreak: "break-all", minWidth: "200px" }}>
                                  <span> {targetRecord.attributes.notification.attributes.headings}</span>
                                </Box>
                                <div className="flexContainer">
                                  <span>{targetRecord.attributes.notification.attributes.created_by.first_name + " " + targetRecord.attributes.notification.attributes.created_by.last_name}</span>
                                  <span>{moment.utc(targetRecord.attributes.created_at, 'YYYY-MM-DD hh:mm').local().format('D-M-YYYY, h.mm A')}</span>
                                  <div>
                                    <IconButton>
                                      <MoreVert style={{ color: "#000000" }} />
                                    </IconButton>
                                  </div>
                                </div>
                              </div>
                              <Grid item xs={12}>
                                <form>
                                  <div style={stylesReadReminder.card}>
                                    <div className="borderBottomCss">
                                      <Box style={stylesReadReminder.titleText}>
                                        <span> {targetRecord.attributes.notification.attributes.headings}</span>
                                      </Box>
                                      <span style={{ marginLeft: "10px" }}>Date {moment.utc(targetRecord.attributes.created_at, 'YYYY-MM-DD hh:mm').local().format('D-M-YYYY, h.mm A')}</span>
                                    </div>
                                    <p style={{ paddingRight: "32px", wordBreak: "break-all" }}>
                                      <span style={{ fontSize: "17px" }} dangerouslySetInnerHTML={{ __html: targetRecord.attributes.notification.attributes.contents }}>
                                      </span>
                                    </p>
                                    <div style={stylesReadReminder.footer}>
                                      <div style={{ display: "flex" }}>
                                        <img src={`${images.iconLogo}`} style={{ height: "50px", width: "170px", marginRight: "10px" }} />
                                        <p> {moment.utc(targetRecord.attributes.notification.attributes.sent_date, 'YYYY-MM-DD').local().format('D-M-YYYY')}</p>
                                      </div>
                                      <Button
                                        data-test-id="cancelBtn"
                                        style={stylesReadReminder.cancelBtn}
                                        onClick={() => {
                                          this.setState({
                                            showUpdateRowData: false
                                          })
                                        }}>Back</Button>
                                    </div>
                                  </div>
                                </form>
                              </Grid>
                            </div>
                          </Grid>
                        </>
                        :
                      readReminderList.items.length > 0 &&
                        readReminderList.items.map((readData: any) => (
                          <TableRow key={readData.id} className="tableRow rowCell"
                          onClick={() => this.onRowClickData(readData.id)}
                          >
                            <TableCell>
                              {readData.attributes.notification.attributes.headings}
                            </TableCell>
                            <TableCell>
                              {readData.attributes.notification.attributes.created_by.first_name + " " + readData.attributes.notification.attributes.created_by.last_name}
                            </TableCell>
                            <TableCell>
                              {moment.utc(readData.attributes.created_at, 'YYYY-MM-DD hh:mm').local().format('D-M-YYYY, h.mm A')}
                            </TableCell>
                            <TableCell>
                              <div
                                onClick={(e: any) => {
                                  if (e) {
                                    e.stopPropagation()
                                  }
                                }}
                              >
                                <IconButton
                                  onClick={(e: any) => {
                                    this.handleIconRowClick(e, readData, 'readReminder');
                                  }}
                                  data-test-id="moreBtns"
                                >
                                  <MoreVert style={{ alignItems: "center", color: "black" }} />
                                </IconButton>
                                <Popover
                                  onClose={this.handleClosePopOver}
                                  open={Boolean(this.state.anchorEl)}
                                  transformOrigin={{
                                    horizontal: "center",
                                    vertical: "top",
                                  }}
                                  data-test-id="showPopOverBtns"
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  PaperProps={{
                                    style: {
                                      boxShadow: "#CECECE 0px 2px 2px",
                                    }
                                  }}
                                  anchorEl={this.state.anchorEl}
                                >
                                  <MenuList>
                                    <MenuItem
                                      onClick={() => {
                                        this.setState(
                                          {
                                            updateStatus: !updateStatus
                                          },
                                          () => {
                                            this.setUpdateStatus();
                                          }
                                        );
                                      }}
                                      data-test-id="editUserPage">
                                      Mark as Unread
                                    </MenuItem>
                                    <Divider
                                      style={{
                                        padding: "0 10%",
                                        width: "80%"
                                      }}
                                    />
                                    <MenuItem
                                      onClick={() =>
                                        this.setState({
                                          showModal: true,
                                          anchorEl: null,
                                        })
                                      }
                                      data-test-id="deleteBtn"
                                    >
                                      Delete
                                    </MenuItem>
                                  </MenuList>
                                </Popover>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      {(readReminderList.items.length === 0 && !readReminderList.hasMore) &&
                        <TableRow style={{ textAlign: "center" }}>
                          <TableCell align="center" style={{ textAlign: "center" }} colSpan={4}>No data found</TableCell>
                        </TableRow>
                      }
                      <TableRow style={{ textAlign: "center" }} ref={this.readLoadingRef} >
                        {(readReminderList.hasMore && !showUpdateRowData) &&
                          <TableCell style={{ textAlign: "center" }} align="center" colSpan={4}>Loading...</TableCell>
                        } </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {showModal && <ModalPopUp isMobile={isMobile} closeModal={this.closeModal} showModal={showModal} handleDelete={this.handleDeleteNotification} />}
        <Snackbar onClose={this.handleCreateCloseAlert} open={this.state.alertState} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <AlertSechNoti sx={{ width: '100%' }} severity={this.state.alertType} >
            {this.state.createAlertMsg}
          </AlertSechNoti>
        </Snackbar>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

let stylesReadReminder: any = {
  card: {
    padding: "24px",
    background: " #F9F9F9",
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.50)",
    borderRadius: "18px",
    margin: "0 24px 24px"
  },
  sidebar: {
    zIndex: "9",
    height: "100%",
    position: "fixed",
    bottom: 0,
    top: 0,
    width: "24vw",
    fontFamily: 'Poppins',
    marginRight: "-100px",
  },
  cancelBtn: {
    backgroundColor: "rgb(199, 195, 195)",
    width: "120px",
    borderRadius: "30px",
    fontSize: "17px",
  },
  titleText: {
    maxWidth: "500px",
    fontSize: "18px",
    fontWeight: "500",
    color: "#0F1119",
    marginBottom: "18px",
    wordBreak: "break-all",
  },
  footer: {
    justifyContent: "space-between",
    flexWrap: "wrap",
    display: "flex",
  },
  fullscreenBody: {
    position: "fixed",
    top: 0,
    bottom: 0,
    height: "100vh !important",
    minWidth: "100vw",
    fontFamily: "poppins !important",
    display: 'flex',
    boxSizing: 'border-box'
  },
  pointerBody: {
    color: "black",
    width: "32px",
    height: "32px",
    marginTop: "20px",
    marginRight: "20px",
    cursor: "pointer"
  },
  divContainerBody: {
    width: "calc(100% - 24%)",
    marginLeft: "auto",
    gap: "10px",
    height: "100%",
  },
  sidebarParentBodyBody: {
    height: '100vh'
  },
  topNavBarBody: {
    width: "100%"
  },
  pageTitleBody: {
    padding: "0px 0px 0px 50px",
    boxShadow: "rgb(34, 34, 34) 0px 4px 6px -6px",
    borderTop: "1px solid rgba(128, 128, 128, 0.43)",
  },
  bellIconBody: {
    width: "100%",
    textAlign: "right",
    height: "75px",
    boxShadow: "none"
  },
  rowHead: {
    outline: "unset",
    background: "#DCDCDC",
  }
}
// Customizable Area End
export default withWidth()(UserReminderRead);
