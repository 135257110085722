import React from "react";
// Customizable Area Start
import { Typography , Drawer, withWidth} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import SideBar from "../../../components/src/SideBar";
import MenuIcon from '@material-ui/icons/Menu';
import '../../email-account-login/src/Style.css'
const landingImages = require("./assets");
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins !important'
  },
});
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";
 export class LandingPage extends LandingPageController {
  constructor(props: Props) {
  super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const {  width } = this.props;
    const isMobile = /s/.test(width);
    return (
      <ThemeProvider theme={theme}>
      <div style={webStyle.pageContainer}>
        {isMobile? 
            <div
            style={{position:"fixed",top:"10px", left:"12px",background:"linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
          }}
             >
             <IconButton
             data-test-id="toggle"
            onClick={this.handleDrawerToggles}
            style={{color:'white'}}
          >
            <MenuIcon />
          </IconButton>
        <Drawer
          open={this.state.mobileOpen}
          onClose={this.handleDrawerToggles}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {<SideBar
          style={webStyle.sidebar}
          navigate={this.props.navigation.navigate}
            activeMenuItem={""}
            userData={this.state.userData}
          />}
          
        </Drawer>
     </div>:
     <div style={webStyle.sidebar} className="sidebarResponse"> 
            <SideBar
            navigate={this.props.navigation.navigate}
            activeMenuItem={""}
            userData={this.state.userData}
          />
          </div>}
          
        <div  style={{...webStyle.pageDefault, marginLeft:isMobile? '' :'24vw'}}
    >
          <div style={webStyle.pageImage} className="pageImage">
            <img
              src={this.state.pageData.image}
              width={"100%"}
              height={"100%"}
                data-test-id="image"
    style={{objectFit:"fill"}}
            />
          </div>
          <div
            style={{...webStyle.pageContent, background: this.state.pageData.bg_colour}}
            className="pageContent"
          >
            <div style={webStyle.bars}>
              <Typography style={{fontSize:"45px",lineHeight:"1"}} className="pageHeader">{this.state.pageData.header}</Typography>
              <div style={webStyle.pageDescription}>
                <Typography style={{fontSize:"24px", textTransform:"uppercase"}} className="pageBody1">
                  {this.state.pageData.body1}&nbsp;
                </Typography>
                <img
                  style={webStyle.mt5Icon}
                  src={`${landingImages.mt5Icon}`}
                />
              </div>
              <div style={webStyle.pageText}>
                <Typography style={{fontSize:"45px"}} className="pageBody2">
                  {this.state.pageData.body2}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      </ThemeProvider>
    );
  }
}
export default (withWidth() (LandingPage))
// Customizable Area End

// Customizable Area Start
const webStyle : any = {
  pageContainer: {
    margin: 0,
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "Poppins, sans-serif",
    hieght : "100vh",
  },
  sidebar: {
    width: "24vw",
    fontFamily: 'Poppins',
    marginRight: "-100px",
    zIndex: "9",
    height: "100%",
    position:"fixed",
    bottom : 0,
    top:0,
  },
  pageDefault: {
    height: "100vh",
    width: "79vw",
    display: "flex",
    flexDirection: "column",
    flexGrow:1,
    fontFamily: 'Poppins',
  },
  pageImage: {
    width: "100%",
    fontFamily: 'Poppins',
    height:"100%",
  },
  pageContent: {
    fontFamily: 'Poppins',
    textAlign: "center",
    color: "#fff",
    boxSizing: "border-box",
    height:'100%',
    padding:'4%'
  },
  bars: {
    fontFamily: 'Poppins',
    backgroundImage: `url(${landingImages.barsImage})`,
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  pageDescription: {
    fontFamily: 'Poppins',
    padding: "25px 0 30px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  mt5Icon: {
    fontFamily: 'Poppins',
    paddingLeft: "5px",
    width: "84px",
    height: "84px"
  },
  pageText: {
    fontFamily: 'Poppins',
    textAlign: "center",
    margin: "0 auto",
    maxWidth: "700px",
  }, 
};
// Customizable Area End
