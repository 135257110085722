import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import { imgPasswordVisible, imgPasswordInVisible } from "./assets";
import { SyntheticEvent } from "react";
import { AppConstant } from "../../../components/src/Constant";
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  emailValidationError: string;
  emailSuccessDisplay: string;
  passwordErrorDisplay: any;
  passwordSuccessDisplay: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: boolean;
  enableConfirmPasswordField: boolean;
  btnConfirmPasswordShowHide: boolean;
  newUserPassword: string;
  confirmUserPassword: string;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
interface CustomTypeDataForgotPassword {
  contentType: string;
  method: string;
  body: {
    data: CustomTypeBodyDataForgotPassword;
  };
  endPoint: string;
}
interface CustomTypeBodyDataForgotPassword {
  email: string;
}
interface CustomTypeDataUpdatePassword {
  contentType: string;
  method: string;
  body: {
    data: CustomTypeBodyDataUpdatePassword;
  };
  endPoint: string;
}
interface CustomTypeBodyDataUpdatePassword {
  new_password: string;
  confirm_password: string;
}
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  forgotPasswordApiCallId: string = "";
  updatePasswordApiCallId: string = "";
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  isChangePassword: boolean = true;

  //Properties from configs
  imgPasswordInVisible: any = imgPasswordInVisible;
  imgPasswordVisible: any = imgPasswordVisible;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired),
    };
    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      emailValidationError: "",
      emailSuccessDisplay: "",
      passwordErrorDisplay: "",
      passwordSuccessDisplay: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      emailSchema: emailSchema,
      token: "",
      enablePasswordField: true,
      enableConfirmPasswordField: true,
      btnConfirmPasswordShowHide: true,
      newUserPassword: "",
      confirmUserPassword: "",
    };

    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.validationRuleRequest();
  }
 
  validationRuleRequest = () => {
    const headers = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationAPICallId = requestMessages.messageId;
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileValidationSettingsAPiEndPoint
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessages.id, requestMessages);
  };

  async receive(from: string, message: Message) {
    const isForgotPasswordApiCall =
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.forgotPasswordApiCallId !== null &&
      this.forgotPasswordApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  
    const isUpdatePasswordApiCall =
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.updatePasswordApiCallId !== null &&
      this.updatePasswordApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  
    if (isForgotPasswordApiCall) {
      this.handleForgotPasswordApiCall(message);
    } else if (isUpdatePasswordApiCall) {
      this.handleUpdatePasswordApiCall(message);
    }
  }
  
  handleForgotPasswordApiCall(message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  
    if (
      responseJson &&
      !responseJson.errors &&
      apiRequestCallId === this.forgotPasswordApiCallId
    ) {
      if (responseJson.message === "Mail sent sucessfully") {
        this.setState({
          emailValidationError: "",
          emailSuccessDisplay: "Please check your Mail",
        });
      }
    } else {
        this.setState({
        emailSuccessDisplay: "",
        emailValidationError: "This is not a registered email",
      });
    }
  }
  
  handleUpdatePasswordApiCall(message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  
    if (
      responseJson &&
      !responseJson.errors &&
      apiRequestCallId === this.updatePasswordApiCallId
    ) {
      if (responseJson.data) {
        this.setState({
          passwordErrorDisplay: "",
          passwordSuccessDisplay: AppConstant.PASSWORD_SUCCESS,
          newUserPassword: "",
          confirmUserPassword: "",
        });
        setTimeout(() =>{
        this.props.navigation.navigate("EmailAccountLoginSignup")
        }, 1000)
      }
    } 
    else{
        this.setState({
        passwordErrorDisplay: AppConstant.PASSWORD_VALIDATION,
        passwordSuccessDisplay: "",
        newUserPassword: "",
        confirmUserPassword: "",
      });
    }
  }
  
  

  
// Customizable Area End
  // Customizable Area Start
  //code for web
  setNewEmailValue = (text: string) => {
    this.setState({
      emailValue: text,
    });
  };
  handleClickNewShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };
  handleClickNewShowConfirmPassword = () => {
    this.setState({
      enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
    });
  };
  setNewPasswordField = (text: string) => {
    this.setState({
      newUserPassword: text,
    });
  };
  setNewConfirmPasswordField = (text: string) => {
    this.setState({
      confirmUserPassword: text,
    });
  };
  apiCallNewUpdatePassword = async (data: CustomTypeDataUpdatePassword) => {
    const { contentType, method, endPoint, body } = data;
    const urlParams = new URLSearchParams(window.location.search);
    const header = {
      "Content-Type": contentType,
      "token"  : urlParams.get("token")|| localStorage.getItem("authToken"),
    };

    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessages.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessages.id, requestMessages);
    return requestMessages.messageId;
  };
  updateNewUserPassword = async () => {
    if (
      this.state.newUserPassword === "" ||
      this.state.confirmUserPassword === ""
    ) {
      this.setState({
        passwordSuccessDisplay: "",
        passwordErrorDisplay: AppConstant.PASSWORD_ERROR_DISPLAY_VALID_PASSWORD,
      });
      return false;
    }
    if (this.state.newUserPassword !== this.state.confirmUserPassword) {
      this.setState({
        passwordSuccessDisplay: "",
        passwordErrorDisplay: AppConstant.PASSWORD_ERROR_DISPLAY_NO_MATCH,
      });
      return false;
    }
    
    //call api
    if(this.state.newUserPassword === this.state.confirmUserPassword){
    const passwordData = {
      data: {
        new_password: this.state.newUserPassword,
        confirm_password: this.state.confirmUserPassword,
      },
    };
    this.updatePasswordApiCallId = await this.apiCallNewUpdatePassword({
      contentType: "application/json",
      method: "PUT",
      body: passwordData,
      endPoint: "reset_password",
    });
  }
    return true;
  };
  doNewPasswordReset = async (event: SyntheticEvent) => {
    event.preventDefault();
    const reg: RegExp = AppConstant.REGEXP_REG;
    if (
      this.state.emailValue === null ||
      this.state.emailValue.length === 0 ||
      !reg.test(this.state.emailValue)
    ) {
      this.setState({
        emailValidationError: configJSON.pleaseEnterAValidEmail,
        emailSuccessDisplay:""
      });
        return false;
    }
    const rawData = {
      data: {
        email: `${this.state.emailValue}`,
      },
    };
    // Make Request here
    this.forgotPasswordApiCallId = await this.apiCallNewForgotPassword({
      contentType: "application/json",
      method: "POST",
      body: rawData,
      endPoint: "/send_forgot_password_instructions",
    });
  };

  apiCallNewForgotPassword = async (data: CustomTypeDataForgotPassword) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
    };

    const requestsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestsMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestsMessage.id, requestsMessage);
    return requestsMessage.messageId;
  };
  // Customizable Area End
}
