import React from "react";
// Customizable Area Start
import SideBar from "../../../components/src/SideBar";
import MenuIcon from "@material-ui/icons/Menu";
import "../../email-account-login/src/Style.css";
import moment from "moment";
import {
  Table,
  Box,
  Typography,
  TableBody,
  Tab,
  TableCell,
  AppBar,
  TableRow,
  Button,
  IconButton,
  TableSortLabel,
  Drawer,
  withWidth,
  TableHead,
} from "@material-ui/core";
import "../../notifications/assets/css/style.css";
const commisssionImages = require("./assets");
import { bellIcon, unreadBellIcon } from "./assets";
import UnreadNotificationList from "../../../components/src/UnreadNotificationList.web";
const images = require("../../../components/src/assets");
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
  typography: {
    fontFamily: "Poppins !important",
  },
});

// Customizable Area End
import CommissionSplitController, { Props } from "./CommissionSplitController";
export class ShowCommissionNotes extends CommissionSplitController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const { width } = this.props;
    const isMobileCommission = /s/.test(width);
    const {
      sortOrder,
      sortColumn,
      unreadNotificationRecords,
      scrollPage,
      isLoadingNoti,
    } = this.state;
    const targetData = this.state.showAlUserNotes.find(
      (item: any) => item.id === this.state.getUpdatedId
    );
    const inputDateTime = moment(
      targetData?.attributes?.created_at,
      "DD-MM-YYYY hh:mm A"
    );
    const formattedDateTime = inputDateTime.format("YYYY-MM-DDTHH:mm:ss[Z]");
    const istDateTime = moment(formattedDateTime).utcOffset("+05:30");
    const formattedIST = istDateTime.format("DD-MM-YYYY hh:mm A");
    const inputUpdateDateTime = moment(
      targetData?.attributes?.updated_at,
      "DD-MM-YYYY hh:mm A"
    );
    const formattedUpdateDateTime = inputUpdateDateTime.format(
      "YYYY-MM-DDTHH:mm:ss[Z]"
    );
    const istUpdateDateTime = moment(formattedUpdateDateTime).utcOffset(
      "+05:30"
    );
    const formattedUpdateIST = istUpdateDateTime.format("DD-MM-YYYY hh:mm A");
    return (
      <ThemeProvider theme={theme}>
        <div style={webStyle.pageContainer}>
          {isMobileCommission ? (
            <div className="sidebarIcon" style={webStyle.sidebarIcon}>
              <IconButton
                onClick={this.handleDrawerToggle}
                style={{ color: "white" }}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                ModalProps={{
                  keepMounted: true,
                }}
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
              >
                {
                  <SideBar
                    activeMenuItem={"CommissionSplit"}
                    style={webStyle.sidebar}
                    userData={this.state.userData}
                    navigate={this.props.navigation.navigate}
                  />
                }
              </Drawer>
            </div>
          ) : (
            <div style={webStyle.sidebar} className="sidebarResponse">
              <SideBar
                userData={this.state.userData}
                navigate={this.props.navigation.navigate}
                activeMenuItem={"CommissionSplit"}
              />
            </div>
          )}
          <div style={webStyle.pageDefault} className="accessPageDefault">
            <AppBar
              style={webStyle.pageHeaderShow}
              className="accessAddHeader"
              position="fixed"
            >
              {this.state.userData.userType !== "admin" &&
                <>
                  <img
                    src={unreadNotificationRecords.length > 0 ? unreadBellIcon : bellIcon}
                    onClick={this.handleClickPopOverNoti}
                    style={webStyle.notificationIcon}
                  />
                  <UnreadNotificationList
                    handleClosePopOver={this.handleClosePopOverNoti}
                    unreadNotificationList={this.onScrollUnreadNotificationList}
                    notificationData={unreadNotificationRecords}
                    navigate={this.props.navigation.navigate}
                    anchorEl={this.state.anchorElNoti}
                    handleClearAll={this.handleClearAll}
                    scrollPage={scrollPage}
                    isLoading={isLoadingNoti}
                  />
                </>
              }
            </AppBar>
            <div className="padding_0_res"
              style={webStyle.divider} >
              <div
                style={webStyle.pageHeading}>
                <Tab
                  style={webStyle.selectedTab}
                  className="AccounComPage"
                  label="Account Commission"
                  onClick={this.accountCommissionPage}
                ></Tab>
                <Tab
                  label="Monthly Report"
                  style={webStyle.editProfileBtn}
                  onClick={this.monthlyReportPage}
                  className="AccounComPage"
                ></Tab>
              </div>
            </div>
            <div
              style={{
                height: "80%",
                overflow: "auto",
                scrollbarWidth: "none",
              }}
              className="showComHeight"
            >
              <div
                style={{
                  overflow: "auto",
                  maxHeight: "100%",
                  marginRight: "4%",
                  marginLeft: "4%",
                }}
              >
                <div
                  style={{ ...webStyle.userDatadetails, marginRight: "4%" }}
                  className="viewUser"
                >
                  <div style={webStyle.userDatadetails}>
                    <h3 style={{ paddingRight: "40px", fontWeight: 600 }}>
                      <span style={{ fontWeight: 100 }}>Login ID :</span>{" "}
                      {this.state.loginID}{" "}
                    </h3>
                    <h3
                      style={{
                        fontWeight: 600,
                        wordBreak: "break-word",
                        paddingRight: "10px",
                      }}
                    >
                      <span style={{ fontWeight: 100 }}>Account number : </span>
                      {this.state.MT5}
                    </h3>
                  </div>
                  {this.state.getUserPermissionDataSec ==
                    "Add New User Notes" && (
                      <Button
                        type="button"
                        className="addBtn"
                        style={webStyle.addAccountBtn}
                        test-id="goToAccount"
                        onClick={this.goToNote}
                      >
                        Add New Note
                      </Button>
                    )}
                </div>
                {this.state.showAlUserNotes.length <= 0 ? (
                  <div style={webStyle.noDataContent}>
                    <Typography style={webStyle.noNotes}>
                      No notes to display
                    </Typography>
                    <Typography style={webStyle.noNotes}>
                      Add New Note to get started
                    </Typography>
                    {this.state.getUserPermissionDataSec ==
                      "Add New User Notes" && (
                        <Button
                          type="button"
                          className="addBtn"
                          style={webStyle.addAccountBtn}
                          test-id="goToAccount"
                          onClick={this.goToNote}
                        >
                          Add New Note
                        </Button>
                      )}
                  </div>
                ) : (
                  <Box
                    style={{
                      marginBottom: "30px !important",
                      marginRight: "4%",
                    }}
                    className="userDataNoMargin"
                  >
                    <Table
                      className="RemTable NoteTable"
                      style={{ tableLayout: "auto" }}
                    >
                      <TableHead>
                        <TableRow
                          className="tableRow"
                          style={{
                            backgroundColor: "#DCDCDC",
                            width: "1000px",
                          }}
                        >
                          <TableCell
                            style={{ paddingRight: "2px", width: "25%" }}
                            align="center"
                          >
                            <TableSortLabel
                              // style={{width:"25%"}}
                              data-test-id="heading"
                              active={sortColumn === "headings"}
                              direction={sortOrder}
                              onClick={() => this.handleSort("headings")}
                            >
                              Note Title
                            </TableSortLabel>
                          </TableCell>
                          <TableCell
                            style={{ paddingRight: "2px", width: "25%" }}
                            align="center"
                          >
                            <TableSortLabel
                              data-test-id="created_at"
                              active={sortColumn === "created_at"}
                              direction={sortOrder}
                              onClick={() => this.handleSort("created_at")}
                            >
                              Date created
                            </TableSortLabel>
                          </TableCell>
                          <TableCell
                            style={{ paddingRight: "2px", width: "25%" }}
                            align="center"
                          >
                            <TableSortLabel
                              data-test-id="created_by"
                              active={sortColumn === "created_by"}
                              direction={sortOrder}
                              onClick={() => this.handleSort("created_by")}
                            >
                              Created by
                            </TableSortLabel>
                          </TableCell>
                          <TableCell
                            style={{ paddingRight: "2px", width: "25%" }}
                            align="center"
                          >
                            <TableSortLabel
                              data-test-id="sort"
                              active={sortColumn === "sent_date"}
                              direction={sortOrder}
                              onClick={() => this.handleSort("sent_date")}
                            >
                              Last updated
                            </TableSortLabel>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {this.state.showUpdateNoteScreen ? (
                          <>
                            <TableRow
                              key={targetData?.id}
                              className="tableRow"
                              style={{ alignItems: "center", width: "1000px" }}
                            >
                              <TableCell
                                align="center"
                                style={webStyle.showTitle}
                              >
                                {targetData?.attributes.title}
                              </TableCell>
                              <TableCell
                                style={{ whiteSpace: "nowrap" }}
                                align="center"
                              >
                                {formattedIST}
                              </TableCell>
                              <TableCell align="center">
                                {targetData?.attributes.created_by.first_name}
                                &nbsp;
                                {targetData?.attributes.created_by.last_name}
                              </TableCell>
                              <TableCell
                                style={{ whiteSpace: "nowrap" }}
                                align="center"
                              >
                                {formattedUpdateIST}
                              </TableCell>
                            </TableRow>
                            <Box style={webStyle.updateNoteEditor}>
                              <Box
                                style={webStyle.textUpdate}
                                dangerouslySetInnerHTML={{
                                  __html: targetData?.attributes.description,
                                }}
                              ></Box>
                              <div style={webStyle.noteUpdateField}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <img
                                    style={{ width: "140px", height: "30px" }}
                                    src={`${images.iconLogo}`}
                                  />
                                  <p>{this.state.onlyDate}</p>
                                </div>
                                <div style={webStyle.downloads}>
                                  {this.state.getUserPermissionDataThird ==
                                    "Edit User Notes" && (
                                      <Button
                                        style={webStyle.saveNoteBtn}
                                        data-test-id="updateNotes"
                                        className="button_width"
                                        onClick={() =>
                                          this.userUpdateNoteScreen(targetData)
                                        }
                                      >
                                        Update Note
                                      </Button>
                                    )}
                                  <Button
                                    style={webStyle.cancelBtn}
                                    onClick={this.goBackUserNotes}
                                    className="button_width"
                                    data-test-id="goBackUserNotes"
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </div>
                            </Box>
                          </>
                        ) : (
                          <>
                            {this.state.showAlUserNotes
                              ?.sort((aa: any, bb: any) => {
                                const valueAA = aa.attributes[sortColumn];
                                const valueBB = bb.attributes[sortColumn];
                                return this.customSort(
                                  sortOrder,
                                  valueAA,
                                  valueBB
                                );
                              })
                              .map((row: any) => {
                                const inputDateTime = moment(
                                  row.attributes.created_at,
                                  "DD-MM-YYYY hh:mm A"
                                );
                                const formattedDateTime = inputDateTime.format(
                                  "YYYY-MM-DDTHH:mm:ss[Z]"
                                );
                                const istDateTime =
                                  moment(formattedDateTime).utcOffset("+05:30");
                                const formattedIST =
                                  istDateTime.format("DD-MM-YYYY hh:mm A");
                                const inputUpdateDateTime = moment(
                                  row.attributes.updated_at,
                                  "DD-MM-YYYY hh:mm A"
                                );
                                const formattedUpdateDateTime =
                                  inputUpdateDateTime.format(
                                    "YYYY-MM-DDTHH:mm:ss[Z]"
                                  );
                                const istUpdateDateTime = moment(
                                  formattedUpdateDateTime
                                ).utcOffset("+05:30");
                                const formattedUpdateIST =
                                  istUpdateDateTime.format(
                                    "DD-MM-YYYY hh:mm A"
                                  );
                                return (
                                  <TableRow
                                    key={row.id}
                                    className="tableRow"
                                    style={{
                                      ...webStyle.userNotesList,
                                      cursor: "pointer",
                                    }}
                                    data-test-id="notesUpdating"
                                    onClick={() => this.updateUserNote(row.id)}
                                  >
                                    <TableCell>
                                      {row.attributes.title}
                                    </TableCell>
                                    <TableCell style={{ whiteSpace: "nowrap" }}>
                                      {formattedIST}
                                    </TableCell>
                                    <TableCell>
                                      {row.attributes.created_by.first_name}
                                      &nbsp;
                                      {row.attributes.created_by.last_name}
                                    </TableCell>
                                    <TableCell style={{ whiteSpace: "nowrap" }}>
                                      {formattedUpdateIST}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            <TableRow
                              ref={this.loadingCommissionRef}
                              id="comRef"
                              style={{
                                textAlign: "center",
                                border: "none",
                                margin: "0 auto",
                              }}
                              data-test-id="loading"
                            >
                              {this.state.isLoading && (
                                <TableCell
                                  align="center"
                                  style={{ textAlign: "center" }}
                                  colSpan={4}
                                >
                                  Loading...
                                </TableCell>
                              )}{" "}
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </Box>
                )}
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}
export default withWidth()(ShowCommissionNotes);

// Customizable Area End

// Customizable Area Start
const webStyle: any = {
  pageContainer: {
    margin: 0,
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "Poppins, sans-serif",
    position: "fixed",
    top: 0,
    bottom: 0,
    height: "100%",
    width: "100%",
  },
  pageHeaderShow: {
    fontFamily: "Poppins",
    backgroundColor: "white",
    marginLeft: "22vw",
    width: "76vw",
    height: "75px",
    borderBottom: "1px solid rgba(128, 128, 128, 0.43)",
    boxShadow: "none",
    borderLeft: "none",
  },
  addAccountPage: {
    fontFamily: "Poppins",
    padding: 0,
    display: "flex",
    hieght: "1500px",
    justifyContent: "space-between",
  },
  sidebar: {
    fontFamily: "Poppins",
    position: "fixed",
    top: "0",
    width: "24vw",
    marginRight: "-100px",
    zIndex: "9",
    height: "100%",
  },
  pageDefault: {
    fontFamily: "Poppins",
    height: "100%",
    width: "76vw",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    top: "100px",
    marginLeft: "24vw",
  },
  accountNavbar: {
    fontFamily: "Poppins",
    display: "flex",
    justifyContent: "space-between",
    margin: "10px 60px",
    width: "95%",
  },
  cancelBtn: {
    fontFamily: "Poppins",
    width: "150px",
    borderRadius: "30px",
    fontSize: "17px",
    textTransform: "Capitalize",
    backgroundColor: "C7C3C3",
  },
  notificationIcon: {
    fontFamily: "Poppins",
    position: "fixed",
    right: "30px",
    top: "20px",
    color: "black",
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
  },
  textUpdate: {
    padding: "30px",
    fontSize: "17px",
  },
  noteUpdateField: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "3%",
  },
  userDatadetails: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "18px",
  },
  addAccountBtn: {
    fontFamily: "Poppins",
    padding: "20px 0px",
    height: "42px",
    background:
      "linear-gradient(0deg, rgba(16, 41, 114, 1.0), rgba(2, 5, 10, 1.0))",
    borderRadius: "20px",
    color: "white",
    fontSize: "15px",
    textTransform: "Capitalize",
    width: "181px",
    marginBottom: "2%",
    lineHeight: 1,
    marginTop: "20px",
  },
  selectedTab: {
    fontSize: "21px",
    borderBottom: "3px solid #000",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  pageHeading: {
    marginTop: "-2%",
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },
  editProfileBtn: {
    fontSize: "21px",
    fontWeight: 500,
    textTransform: "capitalize",
    color: "#6A6A6A",
  },
  note: {
    width: "21px",
    height: "21px",
  },
  search: {
    color: "white",
    backgroundColor: "#0D0D0D",
    borderRadius: "50%",
    pading: "20px",
    fontSize: 38,
  },
  saveNoteBtn: {
    fontFamily: "Poppins",
    lineHeight: 1,
    background:
      "linear-gradient(0deg, rgba(16, 41, 114, 1.0), rgba(2, 5, 10, 1.0))",
    borderRadius: "30px",
    color: "white",
    fontSize: "17px",
    textTransform: "Capitalize",
    marginRight: "3%",
    width: "150px",
  },
  downloads: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "4%",
  },
  userNotesList: {
    textTransform: "capitalize",
    fontSize: "17px",
    fontWeight: 500,
    width: "1000px",
  },
  noNotes: {
    fontSize: "20px",
    fontFamily: "Poppins",
    marginBottom: "20px",
  },
  printBtn: {
    padding: "20px 35px",
    height: "42px",
    borderRadius: "20px",
    color: "black",
    fontSize: "18px",
    textTransform: "Capitalize",
    border: "1px solid black",
    marginRight: "30px",
  },
  updateNoteEditor: {
    height: "auto",
    border: "1px solid #C7C3C3",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "1000px",
    minHeight: "45vh",
    marginBottom: "3%",
  },
  divider: {
    padding: "0px 0px 0px 50px",
    boxShadow: "rgb(34, 34, 34) 0px 4px 6px -6px",
  },
  showTitle: {
    overflowWrap: "anywhere",
  },
  sidebarIcon: {
    position: "fixed",
    top: 10,
    left: 12,
    background:
      "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
  },
  noDataContent: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "10%",
    marginBottom: "10%",
  },
};
// Customizable Area End
