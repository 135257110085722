Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CfCommissionSplitCalculation";
exports.labelBodyText = "CfCommissionSplitCalculation Body";
exports.userNotifications = "bx_block_notifications/user_notifications?is_read=false&per_page=10"
exports.markAllRead = "bx_block_notifications/mark_all_read"
exports.patchMethod = "PATCH";
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End