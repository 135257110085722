import React, { useState } from 'react';
import { PieChart, Pie, Cell } from "recharts";

const activeUsersPercentage = (512 / 800) * 100;
const inactiveUsersPercentage = 100 - activeUsersPercentage;
const data = [
    { id: "1", name: "L1", value: activeUsersPercentage },
    { id: "2", name: "L2", value: inactiveUsersPercentage }
];

const UserStats = (props: any) => {
    const { title, colors, userMinutes, userCount } = props
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const displayUserCount = userCount > 999 ? `${userCount.toString().substring(0, 3)}+` : userCount;

    return (
        <>
            <div style={card.cardHolder}>
                <div>
                    <PieChart width={70} height={70}
                        style={{ cursor: 'pointer' }}
                        onMouseEnter={() => setTooltipVisible(true)}
                        onMouseLeave={() => setTooltipVisible(false)}
                    >
                        <text
                            style={card.centerText}
                            x={35}
                            y={25}
                            textAnchor="middle"
                            dominantBaseline="middle"
                        >
                            {displayUserCount}
                        </text>
                        <text
                            style={card.centerText}
                            x={35}
                            y={45}
                            textAnchor="middle"
                            dominantBaseline="middle"
                        >
                            User
                        </text>
                        <Pie
                            data={data}
                            dataKey="value"
                            innerRadius="94%"
                            outerRadius="100%"
                            fill="#82ca9d"
                            startAngle={90}
                            endAngle={-270}
                            cornerRadius={5}
                            paddingAngle={-2}
                            blendStroke
                        >
                            {data.map((entry, index) => (
                                <Cell key={index} fill={colors[index]} />
                            ))}
                        </Pie>
                    </PieChart>
                </div>
                <div style={card.innerCotainer}>
                    <p style={card.text}>{title}</p>
                    <p style={card.value}>{userMinutes}</p>
                </div>
            </div>

            {tooltipVisible && (
                <div className="showCount" style={{ minWidth: '115px', maxWidth: '180px' }}>
                    <span style={{ fontSize: "16px", fontWeight: 600, wordBreak: 'break-word'}}>{userCount}</span>
                    <span style={{ fontSize: "14px", fontWeight: 300}}>User</span>
                </div>
            )}
        </>
    )
}

let card: any = {
    cardHolder: {
        display: "flex",
        alignItems: "center",
        borderRadius: '20px',
        width: '380px',
        background: '#FFF',
        padding: '20px',
        gap: "15px"
    },
    text: {
        color: '#171725',
        fontSize: '16px',
        letterSpacing: '0.1px',
        margin: '0px',
    },
    value: {
        color: '#696974',
        fontSize: '14px',
        fontWeight: '400',
        letterSpacing: '0.1px',
        margin: '0px',
    },
    centerText: {
        fontSize: '14px',
        fontWeight: '300',
        color: '#171725',
    }
}
export default UserStats