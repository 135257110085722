import React from "react";
// Customizable Area Start
import SideBar from "../../../components/src/SideBar";
import {
  AppBar,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  Tab,
  Drawer, withWidth
} from "@material-ui/core";
import { bellIcon, unreadBellIcon } from "./assets";
import UnreadNotificationList from "../../../components/src/UnreadNotificationList.web";
import SettingsController, { Props } from "./SettingsController";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Divider from "@material-ui/core/Divider";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import MenuIcon from '@material-ui/icons/Menu';
import '../../email-account-login/src/Style.css';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins !important'
  },
});
// Customizable Area End
export class Settings extends SettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const {  width } = this.props;
    const isMobile = /s/.test(width);
    const {
      showPassword,
      usersUpdateError,
      alertType,
      changePassword,
      confirmPassword,
      showConfirmPassword,
      showAlert,
      userSuccessAlert
      , unreadNotificationRecords, isLoading, scrollPage
    } = this.state;
    const showButton = changePassword && confirmPassword;

    return (
      <ThemeProvider theme={theme}>
      <div style={webStyle.pageContainer} className="pageContainer">
        {isMobile? 
            <div
            className="sidebarIcon"
            style={webStyle.sidebarIcon}
            >
             <IconButton
            onClick={this.handleDrawersToggle}
            style={{color:'white'}}
          >
            <MenuIcon />
          </IconButton>
        <Drawer
          open={this.state.mobileOpen}
          ModalProps={{
            keepMounted: true,
          }}
          onClose={this.handleDrawersToggle}
        >
          {<SideBar
          activeMenuItem={"Settings"}
          navigate={this.props.navigation.navigate}
            userData={this.state.userData}
          style={webStyle.sidebar}
          />}
          
        </Drawer>
     </div>:
     <div className="sidebarResponse" style={webStyle.sidebar}> 
            <SideBar
             activeMenuItem={"Settings"}
            userData={this.state.userData}
            navigate={this.props.navigation.navigate}
          />
          </div>}
        <div style={webStyle.pageDefault} className="accessAddPageDefault">
          <AppBar position="fixed" style={webStyle.pageHeader} className="accessHeaderSection">
            {this.state.userData.userType !== "admin" &&
                <>
                  <img
                    style={webStyle.notificationIcon}
                    onClick={this.handleClickPopUp}
                    src={unreadNotificationRecords.length > 0 ? unreadBellIcon : bellIcon}
                  />
                  <UnreadNotificationList
                    handleClosePopOver={this.handleClosePopUp}
                    navigate={this.props.navigation.navigate}
                    anchorEl={this.state.anchorEl}
                    unreadNotificationList={this.onScrollUnreadNotificationLists}
                    isLoading={isLoading}
                    notificationData={unreadNotificationRecords}
                    handleClearAll={this.handleClearAllNoti}
                    scrollPage={scrollPage}
                  />
                </>
            }
          </AppBar>
          <div>
            <div style={webStyle.pageHeading} className="pageHeading">
                <Tab label="Change Password" className="changePasswordPage" data-test-id="changePassword" style={{fontSize:"21px",borderBottom: "3px solid #000", fontWeight:"bold"}}
                onClick={this.changePasswordPage}></Tab>

              <Tab
                label="Edit Profile"
                style={webStyle.editProfileBtn}
                onClick={this.editProfilePage}
                className="editProfileBtn"
              ></Tab>
            </div>
          </div>
          <Divider style={webStyle.divider}/>
          <div className="userChangePass" style={webStyle.changePassover}>
          <div style={webStyle.changePasswordScreen} className="changePasswordScreen">
            <div style={webStyle.formDetails} className="formDetailss">
              <Typography style={webStyle.inputTitle} className="inputTitle">Login ID:</Typography>
              <TextField
                placeholder={`${this.state.userData.loginID}`}
                style={webStyle.inputDetails}
                className="inputDetailss"
                disabled
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>{" "}
            <Divider style={webStyle.dividerContainer} />
            <div style={webStyle.formDetails} className="formDetailss">
              <Typography style={webStyle.inputTitle} className="inputTitle">
                Change Password:
              </Typography>
              <TextField
                type={showPassword ? "text" : "password"}
                placeholder="Change Password"
                inputProps={{
                  maxLength: 16,
                }}
                name="changePassword"
                data-test-id="handleInput"
                onChange={this.handelInput}
                value={this.state.changePassword}
                className="inputDetailss"
                style={
                  this.state.changePasswordField
                    ? webStyle.inputError
                    : webStyle.inputDetails
                }
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={this.togglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>{" "}
            <div style={webStyle.formDetails} className="formDetailss">
              <Typography style={webStyle.inputTitle} className="inputTitle">
                Re-Enter Password:
              </Typography>
              <TextField
                type={showConfirmPassword ? "text" : "password"}
                data-test-id="handleInput1"
                placeholder="Re-Enter Password"
                inputProps={{
                  maxLength: 16,
                }}
                name="confirmPassword"
                onChange={this.handelInput}
                value={this.state.confirmPassword}
                className="inputDetailss"
                style={
                  this.state.confirmPasswordField
                    ? webStyle.inputError
                    : webStyle.inputDetails
                }
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={this.toggleConfirmPasswordVisibility}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <Button
            className="passwordBtn"
              style={{...webStyle.passwordBtn , opacity : showButton ? '1' : '0.6',margin:"auto"}}
              data-test-id="changeBtn"
              onClick={this.changePasswordBtn}
            >
              Change Password
            </Button>
          </div>
          </div>
        </div>

        <Snackbar
          onClose={this.handleAlertClose}
          open={showAlert}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
          data-test-id="close"
            onClose={this.handleAlertClose}
            variant="filled"
            severity={alertType}
            elevation={6}
            style={{ width: "100%" }}
          >
            {alertType==="success"
              ? `${userSuccessAlert}`
              : `${usersUpdateError}`}
          </MuiAlert>
        </Snackbar>
      </div>
      </ThemeProvider>
    );
  }
}
export default (withWidth()(Settings))
// Customizable Area End

// Customizable Area Start
const webStyle: any = {
  pageContainer: {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: 'Poppins',
    position: "fixed",
    margin: 0,
    padding: 0,
    top: 0,
    bottom: 0,
    height: "100%",
  },
  changePassover:{
    overflow:"auto",
    marginBottom:"150px"
  },
  pageHeader: {
    fontFamily: 'Poppins',
    height: "75px",
    borderBottom: "1px solid rgba(128, 128, 128, 0.43)",
    boxShadow: "none",
    borderLeft: "none",
    backgroundColor: "white",
    marginLeft: "22vw",
    width: "76vw",
  },
  sidebar: {
    fontFamily: 'Poppins',
    marginRight: "-100px",
    zIndex: "9",
    height: "100%",
    position: "fixed",
    top: "0",
    width: "24vw",
  },
  pageDefault: {
    fontFamily: 'Poppins',
    height: "100%",
    width: "78vw",
    top: "100px",
    marginLeft: "24vw",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    position: "fixed",
  },
  notificationIcon: {
    fontFamily: 'Poppins',
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
    position: "fixed",
    right: "30px",
    top: "20px",
    color: "black",
    width: "32px",
    height: "32px",
  },
  pageHeading: {
    marginTop: "-2%",
    marginLeft: "6%",
    display: "flex",
    justifyContent: "space-around",
    width: "50%",
  },
  changePasswordScreen: {
    fontFamily: 'Poppins',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // height: "80vh",
    marginTop:"10%",
    width: "100%",
    alignItems: "center",
    // borderTop: "1px solid rgba(128, 128, 128, 0.43)",
  },
  formDetails: {
    fontFamily: 'Poppins',
    display: "flex",
    justifyContent: "space-between",
    width: "70%",
    marginBottom: "50px",
  },
  inputDetails: {
    fontFamily: 'Poppins',
    width: "60%",
    padding: "15px",
    border: "1px solid gray",
    borderRadius: "5px",
    height:"30px",
  },
  inputError: {
    fontFamily: 'Poppins',
    width: "60%",
    padding: "15px",
    border: "2px solid red",
    borderRadius: "5px",
    height:"30px",
  },
  inputTitle: {
    fontFamily: 'Poppins',
    fontSize: "20px",
    fontWeight: 400,
    marginTop: "10px",
  },
  passwordBtn: {
    fontFamily: 'Poppins',
    color: "white",
    fontSize: "15px",
    fontWeight: 400,
    background:
      "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
    padding: "5px 20px",
    borderRadius: "20px",
    textTransform: "capitalize",
    marginLeft: "-5vw",
  },
  changePasswordBtn: {
    fontFamily: 'Poppins',
    fontSize: "21px",
    fontWeight: 500,
    textTransform: "capitalize",
    // borderBottom: "3px solid #0F1119",
    // color: "#0F1119",

    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  editProfileBtn: {
    fontFamily: 'Poppins',
    fontSize: "21px",
    fontWeight: 500,
    textTransform: "capitalize",
    color: "#6A6A6A",
  },
  dividerContainer: {
    fontFamily: 'Poppins',
    height: "2px",
    width: "70%",
    marginBottom: "30px",
    marginTop: "-20px",
  },
  divider:{
     height:"2px",

  },
 sidebarIcon:{position:"fixed",top:10, left:12, background: "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",}
};

// Customizable Area End
