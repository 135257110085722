import React from "react";

// Customizable Area Start
import {
  Box,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Grid,
  TextField,
  withWidth,
  Drawer
} from "@material-ui/core";
import SideBar from "../../../components/src/SideBar";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { isEmpty } from "../../../framework/src/Utilities";
import { Formik } from "formik"
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from 'draft-js';
import * as Yup from "yup";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { timeValidation } from "./CreateNotification.web";
import "../assets/css/style.css";
const images = require("../../../components/src/assets");
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins !important'
  },
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const configJSON = require("./config");

const MenuProps = {
  ...configJSON.commonMenuConfig,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      backgroundColor: "#F5F5F5",
      borderRadius: "20px",
      padding: "10px",
      marginTop: '30px',
    },
  },
};

const notificationDraftSchema = Yup.object().shape({
  headings: Yup.string()
    .required('Title is Required'),
  contents: Yup.string()
    .required('Content is Required'),
  sent_date: Yup.date().required(),
  sent_time: timeValidation
});
// Customizable Area End

import NotificationsTdController, {
  Props,
} from "./NotificationsTdController";
import NotificationsHeaderDraft from "../../../components/src/NotificationsHeader";
import SecondNotiHeaderDraft from "../../../components/src/SecondNotiHeader";
// Customizable Area Start

export class DraftNotifications extends NotificationsTdController {
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { sortOrder, sortColumn, personName, draftJsonData, draftData, userJsonData } = this.state;
    const { width } = this.props;
    const isMobile = /s/.test(width);
    return (

      /* @ts-ignore */
      <ThemeProvider theme={theme}>
        <Box style={stylesDraftNoti.fullscreenDraft} className="table_body">
          {/* @ts-ignore */}
          <Box style={stylesDraftNoti.sidebarParentDraft} className="width_0">
            {isMobile ?
              <div
                style={{
                  position: "fixed", top:"10px",left:"12px", background: "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
                }}
              >
                <IconButton
                  onClick={this.handleDrawerToggles}
                  style={{ color: 'white' }}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  open={this.state.mobileOpen}
                  onClose={this.handleDrawerToggles}
                  ModalProps={{
                    keepMounted: true,
                  }}
                >
                  {<SideBar
                    style={stylesDraftNoti.sidebar}
                    navigate={this.props.navigation.navigate}
                    activeMenuItem={"Notifications & Reminders"}
                    userData={this.state.userData}
                  />}

                </Drawer>
              </div> :
              <div style={stylesDraftNoti.sidebar} className="sidebarResponse">
                <SideBar
                  navigate={this.props.navigation.navigate}
                  activeMenuItem={"Notifications & Reminders"}
                  userData={this.state.userData}
                />
              </div>}
          </Box>
          <Box style={stylesDraftNoti.divContainerDraft} className="width_100_p">
            <div style={stylesDraftNoti.topNavBarDraft}>
              <div style={stylesDraftNoti.bellIconDraft}>
                {/* <NotificationsActiveOutlinedIcon data-test-id="draftNoti" onClick={() => this.props.navigation.navigate("Notifications")} style={stylesDraftNoti.pointerDraft} /> */}
              </div>
              <div style={stylesDraftNoti.pageTitleDraft} className="padding_0_res">
                <NotificationsHeaderDraft state={this.state}
                  goToSentNotifications={this.goToSentNotifications}
                  goToReminders={this.goToReminders}
                  clickTab={this.clickTab}
                />
              </div>
              <Box sx={stylesDraftNoti.scroll}  m={{ xs: "8px", md: "30px" }}>
                <Box>
                  <div style={{ display: "flex" }} className="display_block_960">
                    <SecondNotiHeaderDraft state={this.state}
                      goToSentNotifications={this.goToSentNotifications}
                      goToCreate={this.goToCreate}
                      data-test-id="goToDraft"
                      goToDraft={this.goToDraft}
                      goToScheduled={this.goToScheduled}
                      clickTab={this.clickTabNoti}
                    />
                    <Box style={{ marginTop: "17px", marginLeft: "auto", marginRight: "10px" }}>
                      <button style={{ ...stylesDraftNoti.activeButtonDraft, fontSize: "17px", background: "#000", paddingLeft: " 1rem", paddingRight: "1rem", border: "1px solid #0c143e", marginRight: 0 }} onClick={this.newCompose} type="button">Compose New</button>
                    </Box>
                    <Box style={{paddingRight:"10px"}}>
                      <TextField
                       data-test-id="searchInputDraft"
                        className="searchInput"
                        label="Search by Title"
                        value={this.state.searchState}
                        onChange={this.searchInputDraft}
                        InputProps={{

                          endAdornment: (
                            <InputAdornment variant="filled" position="end">
                              <IconButton
                                onClick={() => {
                                  this.setState(prevState => ({
                                    ...prevState,
                                    searchTerm: prevState.searchState,
                                    draftJsonData: {
                                      items: [],
                                      page: 0,
                                      hasMore: true
                                    },
                                    searchStatus:false
                                  }),() =>this.getDraftNotificationData())
                                }}
                              >
                                <SearchIcon 
                                />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Box>
                  </div>
                  {
                    draftData.id ? <>
                      <Grid container spacing={0}>
                        <Formik
                          validateOnBlur={true}
                          data-test-id="draftSubmit"
                          initialValues={{
                            headings: draftData.headings,
                            sent_date: draftData.sent_date,
                            sent_time: draftData.sent_time,
                            id: draftData.id,
                            send_to_all: draftData.send_to_all,
                            contents: draftData.contents,
                          }}
                          enableReinitialize={true}
                          onSubmit={(values, { resetForm }) => {
                            this.handleSubmitFormikValidation(values,resetForm)
                          }}
                          validationSchema={notificationDraftSchema}
                        >
                          {({ values,
                            errors,
                            setFieldTouched,
                            setFieldValue,
                            touched, handleSubmit }) => {
                            return (
                              <>
                                <Grid item xs={12}>
                                  <Box className="BoxFlex bxFlexSelct WrapperBox"  style={{paddingTop:"20px",paddingBottom:"20px",alignItems:'center'}}>
                                    <FormControl className="selectCheckbox" style={{width: 170 }}>
                                      <InputLabel id="demo-multiple-checkbox-label"><AccountCircleOutlinedIcon />
                                        <span className="textLabel">Account</span>
                                      </InputLabel>
                                      <Select id="demo-multiple-checkbox"
                                        value={personName}
                                        labelId="demo-multiple-checkbox-label"
                                        data-test-id="personName"
                                        onChange={(event) => this.handleChangeAccount(event, setFieldValue)}
                                        MenuProps={MenuProps}
                                        multiple
                                        renderValue={(selected: any) => <span title={selected.map((x: any) => x.first_name).join(', ')} className="selectAccountText" >
                                          {selected.map((x: any) => x.first_name).join(', ')}
                                        </span>}
                                        onScroll={this.handleScrollAccount}
                                        IconComponent={ArrowDropDownIcon}
                                      >
                                        {userJsonData.items.length > 0 && userJsonData.items.map((element: any) => (
                                          <MenuItem value={element} key={element} >
                                            <ListItemText
                                              primary={element.attributes.first_name}
                                            />
                                            <Checkbox checked={personName.indexOf(element) > -1} />
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                    <FormControl className="leftP36" style={{ paddingTop:"5px"}}>
                                      <RadioGroup
                                        name="position"
                                        defaultValue="top"
                                        row
                                        aria-labelledby="demo-form-control-label-placement"
                                      >
                                        <FormControlLabel
                                          style={{ fontSize: "17px", flexDirection: "row-reverse" }}
                                          checked={values.send_to_all === true}
                                          label="Send to All"
                                          value={values.send_to_all}
                                          data-test-id="draftFormLabel"
                                          control={<Radio onClick={(event: any) => {
                                            if (event.target.value) {
                                              this.setState({ personNameErrors: "", sentObj: {}, personName: [] })
                                            }
                                            setFieldValue("send_to_all", !values.send_to_all)
                                          }} />}
                                        />
                                      </RadioGroup>
                                    </FormControl>

                                  </Box>
                                  <FieldError
                                    error={this.state.personNameErrors}
                                    touched={true}
                                  />
                                  <form onSubmit={handleSubmit} style={{padding: '2px'}} >
                                    <Box className="createCardNoti" sx={{ marginRight: { xs: "8px" } }}>
                                      <div className="postionRel borderBottomCss">
                                        <Box className="customTextFieldBox">
                                          <TextField
                                            type="text"
                                            name={"headings"}
                                            data-test-id="draftHeading"
                                            onChange={(event: any) => {
                                              setFieldValue("headings", event.target.value)
                                            }}
                                            value={values.headings} variant="outlined" placeholder="Label" />
                                          <FieldError
                                            error={errors.headings}
                                            touched={touched.headings}
                                          />
                                        </Box>
                                        <div className="timePostionDiv" style={{ display: "flex", flexWrap: "wrap", justifyContent: "end", gap: "10px", padding: "10px" }}>
                                            
                                          <input data-test-id="draftDate" onChange={(event: any) => {
                                            setFieldValue("sent_date", event.target.value)
                                          }} value={values.sent_date} type="date" min={new Date().toISOString().split('T')[0]}
                                            className="inputDateCust"
                                          />
                                          <FormControl variant="standard" className="timeSelectclass" style={{ width: "190px", marginLeft: "20px" }} >
                                            <input
                                              id="outlined-adornment-weight" data-test-id="draftTime"
                                              onChange={(event: any) => {
                                                setFieldValue("sent_time", event.target.value)
                                              }}
                                              value={values.sent_time}
                                              type="time"
                                              className="inputDateCu inputDateCust"
                                            />
                                            <FieldError
                                              error={errors.sent_time}
                                              touched={touched.sent_time}
                                            />
                                          </FormControl>
                                        </div>
                                      </div>
                                      <Editor
                                        editorClassName="editorClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorState={this.state.editorScheduledState}
                                        toolbarClassName="toolbarClassName"
                                        data-test-id="draftEditorChanges"
                                        onEditorStateChange={(event: EditorState) => this.onEditorStateScheduledChange(event, setFieldValue)}
                                        toolbar={{
                                          inline: { inDropdown: true },
                                          list: { inDropdown: true },
                                          textAlign: { inDropdown: true },
                                          link: { inDropdown: true },
                                          history: { inDropdown: true },
                                        }}
                                      />
                                      <div style={{ display: "flex" }}>
                                        <img alt="logo" style={{ width: "170px", height: "50px" }} src={`${images.iconLogo}`} />
                                        <p>{this.state.onlyDate}</p>
                                      </div>
                                      <FieldError
                                        error={errors.contents}
                                        touched={touched.contents}
                                      />                                    
                                    </Box>
                                    <div style={{ marginTop: "10px", display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "10px", flexWrap: "wrap" }}>
                                      <button data-test-id="draftBtn" style={{ ...stylesDraftNoti.buttonCreateDraft}} onClick={() => this.setState({ draftBoolean: true })} type="submit">Save Draft</button>
                                      <button onClick={this.backBtn} type="button" style={{ ...stylesDraftNoti.buttonCreateDraft}} >
                                        Back</button>
                                      <button style={{ ...stylesDraftNoti.activeButtonDraft, background: "linear-gradient(180deg, #192C85 0%, #000000 100%)", padding: "0.8rem 1.5rem", border: "1px solid #0c143e", marginRight: 0 }} type="submit">Post Notification</button>
                                    </div>
                                  </form>
                                </Grid>
                              </>
                            );
                          }}
                        </Formik>
                      </Grid>
                    </>
                      : <Box className="scrollFixed"
                        fontFamily={"Poppins"}
                        style={{
                          marginBottom: "0px !important",
                          maxHeight: "70vh"
                        }} sx={{ marginRight: { xs: "8px" } }} >
                        <Table className="draftTable PTable">
                          <TableHead>
                            <TableRow className="tableRow" style={{ background: "rgb(220, 220, 220) none repeat scroll 0% 0%" }}>
                              <TableCell style={{ paddingLeft: "25px" }}>
                                Draft
                              </TableCell>
                              <TableCell style={{ textAlign: "center", width: "238px" }}>
                                  Date for send reminders
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                Time
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                Frequency
                              </TableCell>
                              <TableCell className="lastOne" style={{ textAlign: "center" }}>
                                Edit
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.draftJsonData.items.length > 0 &&
                              this.state.draftJsonData.items
                                .map((row: any) => (
                                  <TableRow key={row.id} className="tableRow">
                                    <TableCell style={{ overflow: "hidden", padding: "15px 8px", paddingLeft: "25px" }}>{row.attributes.headings}</TableCell>
                                    <TableCell style={{ overflow: "hidden", padding: "8px 8px", width: "250px", textAlign: "center" }}>{new Date(row.attributes.sent_date).toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit' })}</TableCell>
                                    <TableCell style={{ overflow: "hidden", padding: "8px 8px", width: "180px", textAlign: "center" }}>{moment.utc(row.attributes.sent_time, 'hh:mm').local().format('h.mm A')}</TableCell>
                                    <TableCell style={{ overflow: "hidden", padding: "8px 8px", width: "180px", textAlign: "center" }}>{row.attributes.status}</TableCell>
                                    <TableCell className="lastOne"
                                      data-test-id="draftEditNoti"
                                      style={{ textAlign: "right" }}
                                      onClick={() => this.draftEdit(row)}
                                    ><EditIcon /></TableCell>
                                  </TableRow>
                                ))}
                            {(this.state.draftJsonData.items.length === 0 && !this.state.draftJsonData.hasMore) &&
                              <TableRow style={{ textAlign: "center" }}>
                                <TableCell align="center" style={{ textAlign: "center" }} colSpan={4}>No Notification found</TableCell>
                              </TableRow>
                            }
                            <TableRow ref={this.loadingRef} style={{ textAlign: "center" }}>
                              {(this.state.draftJsonData.hasMore) &&
                                <TableCell align="center" style={{ textAlign: "center" }} colSpan={4}>Loading...</TableCell>
                              } </TableRow>
                          </TableBody>
                        </Table>
                      </Box>
                  }

                </Box>
              </Box>
            </div>
          </Box>
          <Snackbar open={this.state.alertState} onClose={this.handleCreateCloseAlert} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <MuiAlert elevation={6} variant="filled" severity={this.state.alertType} style={{ width: '100%' }}>
              {this.state.createAlertMsg}
            </MuiAlert>
          </Snackbar>
        </Box>
      </ThemeProvider>

    );
    // Customizable Area End
  }
}

// Customizable Area Start
export function FieldError({ error, touched }: any) {
  return error && touched ? (
    <div style={{ color: "#e65e52" }}>{error}</div>
  ) : null;
}
let stylesDraftNoti: any = {
  fullscreenDraft: {
    position: "fixed",
    top: 0,
    bottom: 0,
    height: "100vh !important",
    minWidth: "100vw",
    fontFamily: "poppins !important",
    display: 'flex',
    boxSizing: 'border-box'
  },
  sidebar: {
    width: "24vw",
    fontFamily: 'Poppins',
    marginRight: "-100px",
    zIndex: "9",
    height: "100%",
    position: "fixed",
    bottom: 0,
    top: 0,
  },
  divContainerDraft: {
    gap: "10px",
    height: "100%",
    width: "calc(100% - 24%)",
    margin: "0 auto"
  },
  activeButtonDraft: {
    fontSize: "17px",
    color: "#fff",
    border: "none",
    borderRadius: "30px",
    padding: "0.4rem 1rem",
    marginRight: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  buttonCreateDraft: {
    fontSize: "17px",
    border: "1px solid black",
    borderRadius: "30px",
    padding: "0.8rem 1.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "black",
    cursor: "pointer"
  },
  pointerDraft: {
    fontFamily: 'Poppins',
    position: "fixed",
    right: "30px",
    top: "20px",
    color: "black",
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
  },
  scroll: {
    height: 'calc(100vh - 180px)',
    overflow: 'auto',
  },
  topNavBarDraft: {
    width: "100%"
  },
  sidebarParentDraft: {
    width: '24%',
    height: '100vh',
  },
  bellIconDraft: {
    textAlign: "right",
    width: "100%",
    height: "75px"
  },
  pageTitleDraft: {
    borderTop: "1px solid rgba(128, 128, 128, 0.43)",
    padding: "0px 0px 0px 50px",
    boxShadow: "rgb(34, 34, 34) 0px 4px 6px -6px",
  },
}
// Customizable Area End
export default (withWidth()(DraftNotifications));
