import React from "react";
// Customizable Area Start
import SideBar from "../../../components/src/SideBar";
import MenuIcon from "@material-ui/icons/Menu";
import "../../email-account-login/src/Style.css";
import { bellIcon, unreadBellIcon } from "./assets";
import UnreadNotificationList from "../../../components/src/UnreadNotificationList.web";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Table,
  Typography,
  TableBody,
  Tab,
  MenuItem,
  Select,
  TableCell,
  FormControl,
  InputLabel,
  AppBar,
  TableRow,
  Button,
  Box,
  IconButton,
  Drawer,
  withWidth,
  Checkbox,
} from "@material-ui/core";
import "../../notifications/assets/css/style.css";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins !important'
  },
});
// Customizable Area End
import CommissionSplitController, { Props } from "./CommissionSplitController";
export class MonthlyReport extends CommissionSplitController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const { width } = this.props;
    const isMobileMonthly = /s/.test(width);
    const currentYear = new Date().getFullYear();
    const yearOptions = [];
    for (let year = currentYear - 23; year <= currentYear + 27; year++) {
      yearOptions.push(year);
    }
    const monthOptions = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const { usersAccount, selectedIds,
      isLoadingNoti,
      unreadNotificationRecords,
      scrollPage, } = this.state;
    const isButtonDisabled = this.isButtonDisabled();
    const { selectedMonth, showAlert, userSuccessAlert,
      alertType, usersUpdateError } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <div style={webStyle.pageContainer}>
          {isMobileMonthly ? (
            <div style={webStyle.sidebarIcon} className="sidebarIcon">
              <IconButton
                style={{ color: "white" }}
                onClick={this.handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                ModalProps={{
                  keepMounted: true,
                }}
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
              >
                {
                  <SideBar
                    style={webStyle.sidebarShow}
                    userData={this.state.userData}
                    navigate={this.props.navigation.navigate}
                    activeMenuItem={"CommissionSplit"}
                  />
                }
              </Drawer>
            </div>
          ) : (
            <div style={webStyle.sidebarShow} className="sidebarResponse">
              <SideBar
                navigate={this.props.navigation.navigate}
                activeMenuItem={"CommissionSplit"}
                userData={this.state.userData}
              />
            </div>
          )}
          <div style={webStyle.pageMonthlyDefault} className="accessPageDefault">
            <AppBar
              position="fixed"
              style={webStyle.pageHeaderMonthly}
              className="accessAddHeader"
            >
              {this.state.userData.userType !== "admin" &&
                <>
                  <img
                    onClick={this.handleClickPopOverNoti}
                    style={webStyle.notificationMonthlyIcon}
                    src={unreadNotificationRecords.length > 0 ? unreadBellIcon : bellIcon}
                  />
                  <UnreadNotificationList
                    handleClosePopOver={this.handleClosePopOverNoti}
                    anchorEl={this.state.anchorElNoti}
                    notificationData={unreadNotificationRecords}
                    navigate={this.props.navigation.navigate}
                    handleClearAll={this.handleClearAll}
                    isLoading={isLoadingNoti}
                    scrollPage={scrollPage}
                    unreadNotificationList={this.onScrollUnreadNotificationList}  
                  />
                </>
              }
            </AppBar>
            <div className="padding_0_res"
              style={webStyle.divider} >
              <div
                style={webStyle.pageHeading}>
                <Tab
                  className="AccounComPage"
                  label="Account Commission"
                  style={webStyle.editProfileBtn}
                  onClick={this.accountCommissionPage}
                ></Tab>
                <Tab
                  label="Monthly Report"
                  style={webStyle.selectedTab}
                  className="AccounComPage"
                  onClick={this.monthlyReportPage}
                ></Tab>
              </div>
            </div>
            <div className="commissionMonthlyContainer finalReportpages" style={{ height: "80%" }}>

              <Box style={webStyle.selectedMonths} className="selectedMonths">
                <FormControl>
                  <InputLabel htmlFor="month-select" style={webStyle.selectLabel}>
                    Select the month and year
                  </InputLabel>
                  <Button style={webStyle.mainButton}>
                    <Button onClick={this.selectionMonth} style={{ padding: 0 }} data-test-id="handleMonth"><Select
                      labelId="month-select"
                      data-test-id="selectionMonth"
                      id="month-select"
                      inputProps={{
                        disableUnderline: true,
                      }}
                      style={webStyle.monthYearStyle}
                      value={selectedMonth}
                      onChange={this.handleMonthChange}
                    >
                      {monthOptions.map((month: any) => (
                        <MenuItem
                          key={month} value={month}
                          style={{ textAlign: "center", paddingLeft: "10px" }}
                        >
                          {month}
                        </MenuItem>
                      ))}
                    </Select></Button>
                    <Button onClick={this.selectionYear} data-test-id="selectionYear">
                      <Select
                        value={this.state.selectedYear}
                        IconComponent={() => <div />}
                        onChange={this.handleYearChange}
                        style={webStyle.yearStyle}
                        data-test-id="selectedYear"
                      >
                        {yearOptions.map((year) => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    </Button>
                  </Button>
                </FormControl>
              </Box>
              <div style={{ ...webStyle.showUserMonthlyAccounts, marginLeft: "8%" }} className="comTable">
                <Table style={{ tableLayout: "fixed" }}>
                  <TableBody>
                    <TableRow
                      style={webStyle.accountReportDetails}
                      className="accountData"
                    >
                      {usersAccount.length > 0 ? (
                        <TableCell
                          style={{
                            ...webStyle.accountReportCell,
                            fontWeight: 700,
                            width: "30px",
                          }}
                        >
                          <Checkbox
                            style={{ color: "black" }}
                            checked={this.state.selectAll}
                            data-test-id="selectAll"
                            onChange={this.handleSelectAll}
                          />
                        </TableCell>
                      ) : (
                        ""
                      )}
                      <TableCell
                        style={{
                          ...webStyle.accountReportCell,
                          fontWeight: 700,
                          display: "flex",
                          justifyContent: "evenly",
                          width: "160px",
                        }}
                      >
                        Login ID
                      </TableCell>
                      <TableCell
                        style={{
                          ...webStyle.accountReportCell,
                          fontWeight: 700,
                          width: "160px",
                        }}
                      >
                        MT5 Account No
                      </TableCell>
                      <TableCell
                        style={{
                          ...webStyle.accountReportCell,
                          fontWeight: 700,
                          width: "100px",
                        }}
                      >
                        MT%
                      </TableCell>
                      <TableCell
                        style={{
                          ...webStyle.accountReportCell,
                          fontWeight: 700,
                          width: "100px",
                        }}
                      >
                        Tink%
                      </TableCell>
                      <TableCell
                        style={{
                          ...webStyle.accountReportCell,
                          fontWeight: 700,
                          width: "100px",
                        }}
                      >
                        Deepest%
                      </TableCell>
                      <TableCell
                        style={{
                          ...webStyle.lastCell,
                          fontWeight: 700,
                          width: "100px",
                        }}
                      >
                        Affiliate%
                      </TableCell>
                    </TableRow>
                    {usersAccount.length > 0 ? (
                      usersAccount.map((data: any) => {
                        return (
                          <TableRow
                            style={webStyle.accountReportDetails}
                            key={data.id}
                            className="accountData"
                          >
                            <TableCell
                              style={{
                                ...webStyle.accountReportCell,
                                fontWeight: 700,
                                width: "30px",
                              }}
                            >
                              <Checkbox
                                style={{ color: "black" }}
                                data-test-id="selectedCheckbox"
                                name={`checkbox${data.id}`}
                                checked={selectedIds.includes(data.id)}
                                onChange={() => { this.handleCheckboxChange(data.id) }}
                              />
                            </TableCell>
                            <TableCell
                              style={{
                                ...webStyle.accountReportCell,
                                fontWeight: "bold",
                                justifyContent: "evenly",
                                width: "160px",
                              }}
                            >
                              {data.attributes.login_id}
                            </TableCell>
                            <TableCell
                              style={{
                                ...webStyle.accountReportCell,
                                width: "160px",
                              }}
                            >
                              {data.attributes.mt_account_number}
                            </TableCell>
                            <TableCell
                              style={{
                                ...webStyle.accountReportCell,
                                width: "100px",
                              }}
                              data-testId="password"
                            >
                              {data.attributes.master_trader}
                            </TableCell>
                            <TableCell
                              style={{
                                ...webStyle.accountReportCell,
                                width: "100px",
                              }}
                            >
                              {data.attributes.tinkerbell_commission}
                            </TableCell>
                            <TableCell
                              style={{
                                ...webStyle.accountReportCell,
                                width: "100px",
                              }}
                            >
                              {data.attributes.deepset_fx_commission}
                            </TableCell>
                            <TableCell
                              style={{ ...webStyle.lastCell, width: "100px" }}
                            >
                              {data.attributes.affiliate_commission}
                            </TableCell>
                          </TableRow>
                        );
                      })

                    ) : (
                      <Box style={webStyle.nodata}>
                        <Typography style={webStyle.noDataDisplay}>
                          No Data To Display
                        </Typography>
                      </Box>
                    )}
                    <TableRow ref={this.loadingRef} style={{ textAlign: "center" }} data-test-id="loading">
                      {(this.state.isLoading) &&
                        <TableCell align="center" style={{ textAlign: "center" }}>Loading...</TableCell>
                      } </TableRow>
                  </TableBody>
                </Table>
              </div>
              <div style={webStyle.downloads}>
                {this.state.getUserPermissionDataGenerate == "Generate Commissions Reports" &&
                  <Button
                    style={
                      isButtonDisabled
                        ? webStyle.createReportBtn
                        : webStyle.addAccountBtn
                    }
                    className="button_width"
                    disabled={this.isButtonDisabled()}
                    onClick={this.showMonthlyReport}
                  >
                    Create Report
                  </Button>
                }
              </div>
            </div>
            <Snackbar
              autoHideDuration={3000}
              onClose={this.handleAlertClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={showAlert}
            >
              <MuiAlert
                onClose={this.handleAlertClose}
                severity={alertType}
                elevation={6}
                variant="filled"
                style={{ width: "100%" }}
              >
                {alertType === "error"
                  ? `${usersUpdateError}`
                  : `${userSuccessAlert}`}
              </MuiAlert>
            </Snackbar>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}
export default withWidth()(MonthlyReport);

// Customizable Area End

// Customizable Area Start
const webStyle: any = {
  pageContainer: {
    top: 0,
    bottom: 0,
    height: "100%",
    margin: 0,
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "Poppins, sans-serif",
    position: "fixed",
  },
  pageHeaderMonthly: {
    backgroundColor: "white",
    marginLeft: "22vw",
    width: "76vw",
    height: "75px",
    borderBottom: "1px solid rgba(128, 128, 128, 0.43)",
    boxShadow: "none",
    borderLeft: "none",
  },
  sidebarShow: {
    position: "fixed",
    top: "0",
    width: "24vw",
    marginRight: "-100px",
    zIndex: "9",
    height: "100%",
  },
  pageMonthlyDefault: {
    height: "100%",
    width: "78vw",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    top: "100px",
    marginLeft: "21vw",
  },
  createReportBtn: {
    fontFamily: "Poppins",
    padding: "10px 20px",
    borderRadius: "30px",
    fontSize: "15px",
    textTransform: "Capitalize",
    backgroundColor: "C7C3C3",
    color: "white",
  },

  addAccountBtn: {
    fontFamily: "Poppins",
    padding: "10px 20px",
    background:
      "linear-gradient(0deg, rgba(16, 41, 114, 1.0), rgba(2, 5, 10, 1.0))",
    borderRadius: "30px",
    color: "white",
    fontSize: "15px",
    textTransform: "Capitalize",
  },
  selectedTab: {
    fontSize: "21px",
    borderBottom: "3px solid #000",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  accountReportDetails: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "1000px",
    height: "100px",
    margin: " 10px auto",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
    borderRadius: "6px",
    textAlign: "center",
    border: "1px solid #c7c3c3",
  },

  accountReportCell: {
    wordWrap: "anywhere",
    overflow: "hidden",
    overflowWrap: "anywhere",
    fontSize: "15px",
    borderRight: "1px solid #DEDEDE",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    padding: "0px 10px",
    justifyContent: "center",
  },
  lastCell: {
    fontFamily: "Poppins",
    fontSize: "15px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    // padding: "20px 10px",
    justifyContent: "center",
  },
  showUserMonthlyAccounts: {
    overflowY: "auto",
    height: "70%",
    marginBottom: "2%",
    display: "flex",
    justifyContent: "center",
    scrollbarWidth: "thin",
    scrollbarColor: "##121111",
    overflowX: "auto",
    marginRight: "4%",
    maxHeight: "90%",
  },
  notificationMonthlyIcon: {
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
    fontFamily: "Poppins",
    position: "fixed",
    right: "30px",
    top: "20px",
    color: "black",
    width: "32px",
    height: "32px",
  },
  nodata: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10%",
  },
  noDataDisplay: {
    fontSize: "18px",
    fontFamily: "Poppins",
  },
  pageHeading: {
    width: "100%",
    marginTop: "-2%",
    display: "flex",
    justifyContent: "space-around",
  },
  editProfileBtn: {
    fontSize: "21px",
    fontWeight: 500,
    textTransform: "capitalize",
    color: "#6A6A6A",
  },
  downloads: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "4%",
  },
  selectLabel: {
    marginTop: "-5%",
    paddingLeft: "3vw",
    fontSize: "12px",
    width: "274px",
    textAlign: "center",
    opacity: "0.7"
  },
  monthYearStyle: {
    borderRadius: "50px",
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#10121E",
    color: "white",
    textTransform: "capitalize",
    width: "152px",
    padding: "5px 0",
  },
  yearStyle: {
    backgroundColor: "none",
    border: "none",
    color: "gray",
    fontSize: "16px",
    borderBottom: 'none'
  },
  mainButton: {
    width: "274px",
    padding: 0,
    borderRadius: "20px",
    backgroundColor: "white",
    border: "none",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
    height: "42px",
    paddingRight: "30px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  popoverStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "none",
    border: "none",
  },
  selectedMonths: {
    marginTop: "2%",
    marginLeft: "10%",
    marginBottom: "2%",
  },
  sidebarIcon: {
    position: "fixed",
    top: 10,
    left: 12,
    background:
      "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
  },
  divider: {
    padding: "0px 0px 0px 50px",
    boxShadow: "rgb(34, 34, 34) 0px 4px 6px -6px",
    width: "100%"
  },
}
// Customizable Area End
