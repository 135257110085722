import React from "react";
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import "../src/assets/Css/custom.css";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
  typography: {
    fontFamily: "Poppins !important",
  },
});
const HeaderNavigation = (props:any) =>  {
        const {state,
            goToImageUpload,
            goToEditBody,
            goToEditHeading,
        } = props
      
        return (
            <ThemeProvider theme={theme}>
            <div className="buttonHolder header_style">
                <button className={state.uploadImage ? "activeButton" + " " + "width_100" : "button" + " " + "width_100"}
                    style={{ fontSize: "15px", background: state.uploadImage ? "linear-gradient(180deg, #192C85 0%, #000000 100%)" : "#fff" }}
                    onClick={goToImageUpload}
                >
                    <div style={{fontFamily:"Poppins"}}>Upload Image</div>
                    <ImageOutlinedIcon className="buttonIcon" />
                </button>
                <button className={state.editHeading ? "activeButton" + " " + "width_100" : "button" + " " + "width_100"}
                    style={{ fontSize: "15px", background: state.editHeading ? "linear-gradient(180deg, #192C85 0%, #000000 100%)" : "#fff" }}
                    onClick={goToEditHeading}
                >
                    <div style={{fontFamily:"Poppins"}}>Edit Heading</div> <BorderColorOutlinedIcon className="buttonIcon" style={{ fontSize: 'medium' }} />
                </button>
                <button className={state.editBody ? "activeButton" + " " + "width_100" : "button" + " " + "width_100"}
                    style={{ fontSize: "15px", background: state.editBody ? "linear-gradient(180deg, #192C85 0%, #000000 100%)" : "#fff" }}
                    onClick={goToEditBody}
                >
                    <div style={{fontFamily:"Poppins"}}>Edit Body </div> <BorderColorOutlinedIcon className="buttonIcon" style={{ fontSize: 'medium' }} />
                </button>
                </div>
                </ThemeProvider>
            )
}
export default HeaderNavigation;

