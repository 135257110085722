import React from 'react';
import { Box, Tab } from "@material-ui/core";
import TabList from "@material-ui/lab/TabList";
import TabContext from "@material-ui/lab/TabContext";

const DashboardTabs = (props: any) => {
    const { tabList, state , handleSwitch} = props

    const handleTabClick = (e: any, value: string) => {
        handleSwitch(value)
    }
    return (
            <TabContext value={state}>
                <Box sx={{ borderBottom: "1px solid rgba(0, 137, 255, 0.24)" }} className='dashboardTab'>
                    <TabList onChange={handleTabClick} >
                        {tabList.map((tabLable: string , index: number) => {
                            return(<Tab key={index} label={tabLable} value={String(index)} className={state === String(index) ? 'activeDashboardTab' : ''}
                              />)
                        })}
                    </TabList>
                </Box>
            </TabContext>
    )
}

export default DashboardTabs