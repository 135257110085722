import React from "react";
// Customizable Area Start
import SideBar from "../../../components/src/SideBar";
import { Tabs, Tab, AppBar, Typography,Link, TextField, Divider, IconButton, InputAdornment, Button,Drawer,Table,TableBody,TableRow, TableCell, withWidth } from "@material-ui/core";
import SettingsController, { Props } from "./SettingsController";
import BorderColorIcon from '@material-ui/icons/BorderColor';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MenuIcon from '@material-ui/icons/Menu';
import '../../email-account-login/src/Style.css';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { bellIcon, unreadBellIcon } from "./assets";
import UnreadNotificationList from "../../../components/src/UnreadNotificationList.web";

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins !important'
  },
});
// Customizable Area End
export class EditProfile extends SettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const { userSuccessAlert,userData,showAlert,userNumber,isButtonAble, userAddress,userSecondaryEmail,usersUpdateError, alertType, editModePhone, editModeAddress, editModeEmail, unreadNotificationRecords, isLoading, scrollPage} = this.state
    const {  width } = this.props;
    const isMobiles = /s/.test(width); 
    return (
      <ThemeProvider theme={theme}>
      <div style={webStyle.pageContainer}>
        {isMobiles? 
            <div
            style={webStyle.sidebarIcon} className="sidebarIcon"
            >
             <IconButton
             data-test-id="sidebarIcon"
            style={{color:'white'}}
            onClick={this.handleDrawersToggle}
          >
            <MenuIcon />
          </IconButton>
        <Drawer
          ModalProps={{
            keepMounted: true,
          }}
          open={this.state.mobileOpen}
          onClose={this.handleDrawersToggle}
        >
          {<SideBar
          navigate={this.props.navigation.navigate} 
          activeMenuItem={"Settings"}
          userData={userData}
          style={webStyle.sidebar}
          />}
        </Drawer>
     </div>:
     <div style={webStyle.sidebar} className="sidebarResponse"> 
            <SideBar
             activeMenu 
             Item={"Settings"}
             activeMenuItem={"Settings"}
             navigate={this.props.navigation.navigate}
            userData={userData}
          />
          </div>}
        <div style={webStyle.pageDefault} className="accessAddPageDefault">
          <AppBar  style={webStyle.pageHeader} position="fixed" className="accessHeaderSection">
            {this.state.userData.userType !== "admin" &&
              <>
                <img
                  onClick={this.handleClickPopUp}
                  src={unreadNotificationRecords.length > 0 ? unreadBellIcon : bellIcon}
                  style={webStyle.notificationIcon}
                />
                <UnreadNotificationList
                  handleClosePopOver={this.handleClosePopUp}
                  anchorEl={this.state.anchorEl}
                  notificationData={unreadNotificationRecords}
                  isLoading={isLoading}
                  navigate={this.props.navigation.navigate}
                  handleClearAll={this.handleClearAllNoti}
                  scrollPage={scrollPage}
                  unreadNotificationList={this.onScrollUnreadNotificationLists}
                />
              </>
            }
          </AppBar>
          <div>
            <div className="pageHeading" style={webStyle.pageHeading} >
            <Tabs value={1} 
            aria-label="tabs">

              <Tab label="Change Password" className="changePasswordPage" style={webStyle.editProfileBtn} onClick={this.changePasswordPage}></Tab>
              </Tabs>
              <Tab label="Edit Profile" className="editProfileBtn" style={{fontSize:"21px",borderBottom: "3px solid #000", fontWeight:"bold"}} data-test-id="editProfile" onClick={this.editProfilePage}></Tab>
           </div>
          </div>
          <Divider style={webStyle.divider}/>
          <div className="overflow_auto">
          <div style={webStyle.editProfileScreen} className="editProfileScreen">
            <Typography style={webStyle.profileHead} className="profilePicture">Profile picture</Typography>
            <Typography style={webStyle.uploadHead}>Upload a picture to personalize your experience</Typography>
            <div style={webStyle.updatedProfile} className="updatedProfile">
             <div>
              <img src={this.setImage()}  style={webStyle.userImage} className="userImage"/>
              <input
          accept="image/*"
          id="image-upload"
          multiple={false}
          type="file"
          data-test-id="updateProfileImage"
          style={{ display: 'none' }}
          onChange={this.updateProfileImage}
        />
        </div>
<div>
<label htmlFor="image-upload">
          <Button style={webStyle.updateBtn} endIcon={<BorderColorIcon/>} component="span" className="updateBtn">
          Update
          </Button>
          </label>
          </div>
          <div>
          <Button style={webStyle.removeBtn} endIcon={<DeleteOutlineIcon/>} data-test-id="removeProfileImage" onClick={this.removeProfileImage} className="removeBtn">
          Remove
          </Button>
          </div>
            </div>
            <Table style={{width:"60%"}} className="width_100_900px">
        <TableBody className="userTable">
          <TableRow>
            <TableCell>
              <Typography style={webStyle.accountDetailsHeading}>Login ID</Typography>
            </TableCell>
            <TableCell>
              <Typography style={{ textAlign: "left",marginLeft:'15%' }}>{userData.loginID}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography style={webStyle.accountDetailsHeading}>User Primary Email</Typography>
            </TableCell>
            <TableCell>
              <Typography style={{ textAlign: "left",marginLeft:'15%' }}>{userData.email}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography>User Phone Number</Typography>
            </TableCell>
            <TableCell>
              <TextField
              className="marginRightRes"
                placeholder="Enter Your Phone Number"
                style={{ textAlign: "left",marginLeft:'15%' ,minWidth:"auto" }}
                data-test-id="handleInput4"
                name="phoneNumber"
                onChange={this.handelInput}
                disabled={!editModePhone}
                value={userNumber}
                inputProps={{
                  maxLength: 12,
                }}
                inputRef={this.editPhone}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <BorderColorIcon onClick={this.handleEditPhoneClick} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography>Address</Typography>
            </TableCell>
            <TableCell>
              <TextField
className="marginRightRes"
                placeholder="Enter Your Address"
                name="address"
                data-test-id="handleInput3"
                style={{ textAlign: "left",marginLeft:'15%',minWidth:"auto" }}
                onChange={this.handelInput}
                inputRef={this.editAddress}
                disabled={!editModeAddress}
                value={userAddress}
                inputProps={{
                  maxLength: 30,
                }}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <BorderColorIcon onClick={this.handleEditAddressClick} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography>User Secondary Email</Typography>
            </TableCell>
            <TableCell>
              <TextField
              className="marginRightRes"
                placeholder="Enter Your Secondary Email"
                data-test-id="handleInput2"
                name="secondaryEmail"
                style={{ textAlign: "left",marginLeft:'15%',minWidth:"auto" }}
                inputRef={this.editEmail}
                disabled={!editModeEmail}
                onChange={this.handelInput}
                value={userSecondaryEmail}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <BorderColorIcon onClick={this.handleEditEmailClick} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography>MT5 Activation URL</Typography>
            </TableCell>
            <TableCell>
            {userData.userActivationUrl == null? 
              <Typography style={{ textAlign: "left",marginLeft:'15%' }}>No Activation Url Found</Typography>
            :
            <Link href={userData.userActivationUrl} className="marginRightRes" style={{ textAlign: "left",marginLeft:'15%', fontWeight:'bold'}}>MT5 Activaltion URL</Link>
            }
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
            <Button style={{...webStyle.changesBtn, opacity : isButtonAble ? '1': ''}} data-test-id="handleUserProfile" onClick={this.handleUserProfile} disabled={!isButtonAble}>Apply changes</Button>
            <Snackbar open={showAlert} autoHideDuration={3000} onClose={this.handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <MuiAlert onClose={this.handleAlertClose} severity={alertType} elevation={6} variant="filled" style={{ width: '100%' }}>
              {alertType === 'success' ? `${userSuccessAlert}` : `${usersUpdateError}`}
            </MuiAlert>
          </Snackbar>
          </div>
          </div>
        </div>
        
      </div>
      </ThemeProvider>
    );
  }
}
export default (withWidth()(EditProfile))
// Customizable Area End

// Customizable Area Start
const webStyle: any = {
  pageContainer: {
    margin: 0,
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
    fontFamily: 'Poppins',
    position: "fixed",
    top: 0,
    bottom: 0,
    height: "100%",
    overflowX:"hidden"
  },
  pageHeader: {
    fontFamily: 'Poppins',
    backgroundColor: "white",
    marginLeft: "22vw",
    width: "76vw",
    height: "75px",
    borderBottom: "1px solid rgba(128, 128, 128, 0.43)",
    boxShadow: "none",
    borderLeft: "none",
  },
  sidebar: {
    fontFamily: 'Poppins',
    position: "fixed",
    top: "0",
    width: "24vw",
    marginRight: "-100px",
    zIndex: "9",
    height: "100%",
  },
  pageDefault: {
    fontFamily: 'Poppins',
    maxHeight: "100%",
    width: "78vw",
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    top: "100px",
    marginLeft: "24vw",
    bottom: 0,
  },
  notificationIcon: {
    fontFamily: 'Poppins',
    position: "fixed",
    right: "30px",
    top: "20px",
    color: "black",
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
  },
  pageHeading: {
    fontFamily: 'Poppins',
    display: "flex",
    justifyContent: "space-around",
    width:"50%",
    marginLeft:"6%",
    marginTop:"-2%",
  },
  editProfileScreen :{
    fontFamily: 'Poppins',
    display:'flex',
    flexDirection:"column",
    justifyContent:"space-evenly",
    height:"auto",
    width:"100%",
    paddingLeft:"10vw",
    // borderTop:"1px solid rgba(128, 128, 128, 0.43)",
    marginBottom:"20%",
    paddingTop:'3%'
  },

  changePasswordBtn:{
    fontFamily: 'Poppins',
    fontSize:"21px",
    fontWeight:500,
    textTransform:"capitalize",
     borderBottom :"3px solid #0F1119",
     color:"#0F1119",
     '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  editProfileBtn :{
    fontFamily: 'Poppins',
    fontSize:"21px",
    fontWeight:500,
    textTransform:"capitalize",
     color:"#6A6A6A",
  },
  updatedProfile :{
    fontFamily: 'Poppins',
   display:'flex',
   alignItems:"center",
   gap:"20px",
   width:"35%",
   marginLeft:"-20px"
  },
  changesBtn: {
    fontFamily: 'Poppins',
    color: "white",
    fontSize: "18px",
    fontWeight: 400,
    background:
      "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
    borderRadius: "20px",
    textTransform: "capitalize",
    width:"191px",
    height:"42px",
    marginTop:"30px"
  },
  userImage :{
    fontFamily: 'Poppins',
    width:"80px",
    height:"80px",
    borderRadius:"50%"
  },
  profileHead :{
    fontFamily: 'Poppins',
    color:"#2A2A2A",
    fontSize:"20px",
    fontWeight:500,
    textAlign:"left",
    marginBottom:"10px"
  },
  uploadHead:{
    fontFamily: 'Poppins',
    color:"#000000",
    fontSize:"15px",
    fontWeight:400,
    marginBottom:"10px"
  },
  updateBtn :{
    fontFamily: 'Poppins',
    width:"141px",
     height:"40px",
    borderRadius:"20px",
    textAlign:"center",
    border:"1px solid #0F1119",
    color:"#0F1119",
    fontWeight:400,
    fontSize:"15px",
    textTransform:"capitalize"
  },
  removeBtn:{
    fontFamily: 'Poppins',
    width:"141px",
     height:"40px",
    borderRadius:"20px",
    textAlign:"center",
    color:"white",
    backgroundColor:'#000000',
    fontWeight:400,
    fontSize:"15px",
    textTransform:"capitalize"
  },
  userDetails:{
    fontFamily: 'Poppins',
    height:"250px",
    width:"60%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-evenly",   
  },
  accountDetailsHeading :{
    fontFamily: 'Poppins',
    fontSize:"15px",
  },
  usersDetails :{
    fontFamily: 'Poppins',
    display:'flex',
     justifyContent:"space-between",
     paddingTop:"20px",
     paddingBottom:"10px",
    textAlign:"left",
  },
  usersDetailsEmail :{
    fontFamily: 'Poppins',
    display:'flex',
    justifyContent:"space-between",
    paddingTop:"20px",
    marginBottom:"40px",
 },
 usersDefaultDetails :{
  fontFamily: 'Poppins',
  display:'flex',
     flexGrow:1,
     justifyContent:"space-between",
     paddingTop:"20px",
     paddingBottom:"10px",
    textAlign:"left"
 },
 divider:{
  height:"2px",
},
 sidebarIcon:{position:"fixed",
 top:10, left:12,
  background: "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
}

};
// Customizable Area End
