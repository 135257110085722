import React from "react";

// Customizable Area Start
import {
  TextField,
  Box,
  Grid,
  IconButton,
  Drawer,
  AppBar,
  withWidth
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import SideBar from "../../../components/src/SideBar";
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsTdController from "./NotificationsTdController";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Formik } from "formik"
import { Editor } from "react-draft-wysiwyg";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { convertToRaw, EditorState } from 'draft-js';
import * as Yup from "yup";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ThirdReminderHeader from "../../../components/src/ThirdReminderHeader";
import NotificationsHeader from "../../../components/src/NotificationsHeader";
const images = require("../../../components/src/assets");
import RadioUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import ArrowDropDownIcon from '@material-ui/icons/ExpandMore';
// @ts-ignore
import draftToHtml from 'draftjs-to-html';
export const configJSON = require("./config");
// Customizable Area End
// Customizable Area Start
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuPropsValue = {
  ...configJSON.commonMenuConfig,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      backgroundColor: "#F5F5F5",
      borderRadius: "20px",
      padding: "10px",
      marginTop: '30px',
    },
  },
};

export const timeValidation =  Yup.string().when("sent_date", {
  is: (send_date) => {
    return send_date ? new Date() < new Date(send_date) : true;
  },
  then: Yup.string().required("Required Field"),
  otherwise: Yup
    .string()
    .test(
      "is-future-time",
      "Past Time Cannot be selected",
      function (value) {
        const newDate = new Date(
          new Date().toISOString().split("T")[0] + " " + value
        );
        const currentDate = new Date()
        currentDate.setSeconds(0)
        return newDate.toString() >= currentDate.toString();
      }
    )
})

const MenuPropsFrequencyValue = {
  PaperProps: {
    style: {
      borderRadius: "20px",
      marginTop: '10px',
    },
  },
};

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins'
  },
  overrides: {
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
});
const reminderSchema = Yup.object().shape({
  headings: Yup.string()
    .required('Title is Required'),
  contents: Yup.string()
    .required('Content is Required'),
  frequency: Yup.string()
    .required('Frequency is Required'),
  sent_date: Yup.date().required(),
  sent_time: timeValidation
});

export function FieldError({ error, touched }: any) {
  return error && touched ? (
    <div style={{ color: "#e65e52" }}>{error}</div>
  ) : null;
}
export class CreateReminder extends NotificationsTdController {
  // Customizable Area End
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { personReminderName, userJsonData, showComposeTitle } = this.state;
    const { width } = this.props;
    const isMobile = /s/.test(width);
    const dropdownOptions = configJSON.dropdownOptions;

    return (
      <ThemeProvider theme={theme}>
        <Box style={stylesCreateRe.fullscreenCreateRe}>
          <div style={stylesCreateRe.pageContainer}>
            {isMobile ?
              <div
              style={stylesCreateRe.sidebarIcon}
              >
                <IconButton
                  style={{ color: 'white' }}
                  onClick={this.handleDrawerToggles}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  ModalProps={{
                    keepMounted: true,
                  }}
                  open={this.state.mobileOpen}
                  onClose={this.handleDrawerToggles}

                >
                  {<SideBar
                    style={stylesCreateRe.sidebar}
                    userData={this.state.userData}
                    navigate={this.props.navigation.navigate}
                    activeMenuItem={"Notifications & Reminders"}
                  />}
                </Drawer>
              </div>
              :
              <div style={stylesCreateRe.sidebar} className="sidebarResponse">
                <Box style={stylesCreateRe.sidebarParentCreateRe}>
                  <SideBar
                    navigate={this.props.navigation.navigate}
                    activeMenuItem={"Notifications & Reminders"}
                    userData={this.state.userData}
                  />
                </Box>
              </div>}
          </div>

          <Box className="mainLftContainer">
            <div style={stylesCreateRe.topNavBarCreateRe} >
              <AppBar position="fixed" style={stylesCreateRe.pageHeader} className="accessHeader">
                {/* <NotificationsActiveOutlinedIcon data-test-id="notiRem" onClick={() => this.props.navigation.navigate("Notifications")} style={stylesCreateRe.notificationIcon} /> */}
              </AppBar>
              <div style={stylesCreateRe.topNavBarCreateRe}>
                <div style={stylesCreateRe.pageTitleCreateRe}>
                  <NotificationsHeader state={this.state}
                    goToSentNotifications={this.goToSentNotifications}
                    goToReminders={this.goToReminders}
                    clickTab={this.clickTab}
                  />
                </div>

                <Box className="bodyScrollBar">
                  <Box m={{ xs: 1, sm: 2, md: 3 }}>
                    <Box sx={{ display: 'Flex', justifyContent: 'space-between', flexWrap: 'wrap', alignItems: "baseline" }}>
                      <ThirdReminderHeader state={this.state}
                        goToReminders={this.goToReminders}
                        data-test-id="goToRemindersCreate"
                        goToRemindersCreate={this.goToRemindersCreate}
                        goToRemindersScheduled={this.goToRemindersScheduled}
                        clickTabNoti={this.clickTabRiminder}
                      />
                    </Box>
                    <Formik
                      initialValues={{
                        headings: "",
                        id: "",
                        contents: "",
                        frequency: "",
                        sent_date: new Date().toISOString().split('T')[0],
                        sent_time: this.state.formattedTime,
                        send_to_all: true
                      }}
                      data-test-id="remFormSubmit"
                      enableReinitialize={true}
                      onSubmit={(values, { resetForm }) => {
                        if (!values.send_to_all && !personReminderName.length) {
                        this.setState({ personReminderNameErrors: "Accounts should be selected" })
                        }
                        else {
                          this.setState({ personReminderNameErrors: "" })
                          this.handleReminderSubmit(values);
                          resetForm();
                        }
                      }}
                      validationSchema={reminderSchema}
                    >
                      {({ values,
                        errors,
                        resetForm,
                        setFieldValue,
                        touched, handleSubmit }) => {
                        return (
                          <>
                            {showComposeTitle && <Box style={stylesCreateRe.composeNewBtn}>Compose New Reminders</Box>}
                            <Box className='BoxFlex bxFlexSelct WrapperBox' style={{ padding:'20px 10px'}}>
                              <FormControl className="selectCheckbox" style={{ margin: "5px 24px", minWidth: '150px' }}>
                                <InputLabel id="demo-multiple-checkbox-label"><AccountCircleOutlinedIcon /> <span className="textLabel">Account</span></InputLabel>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  multiple
                                  className="hide-scrollbar"
                                  value={personReminderName}
                                  data-test-id="remEditorChanges"
                                  onChange={(event) => this.handleChangeReminderAccount(event, setFieldValue)}
                                  renderValue={(selected: any) => <span className="selectAccountText" title={selected.map((x: any) => x.first_name).join(', ')}>{selected.map((x: any) => x.first_name).join(', ')}</span>}
                                  MenuProps={MenuPropsValue}
                                  IconComponent={ArrowDropDownIcon}
                                  onScroll={this.handleScrollAccount}
                                >
                                  {userJsonData.items.length > 0 && userJsonData.items.map((ele: any) => (
                                    <MenuItem key={ele.attributes.email} value={ele}>
                                      <ListItemText>
                                        <p style={stylesCreateRe.accountName}>{ele.attributes.first_name + " " + ele.attributes.last_name}</p>
                                        <p style={stylesCreateRe.accountEmail}>{ele.attributes.email}</p>
                                      </ListItemText>
                                      <Checkbox icon={<RadioUncheckedIcon fontSize="small" />} checkedIcon={<RadioCheckedIcon fontSize="small" />} checked={personReminderName.indexOf(ele) > -1} />
                                    </MenuItem>
                                  ))}
                                  <MenuItem>
                                    {(this.state.userJsonData.isLoading) && <ListItemText>
                                      Loading...
                                    </ListItemText>
                                    }
                                  </MenuItem>
                                </Select>
                              </FormControl>
                              <FormControl style={stylesCreateRe.paddingLeft24} className="leftP36">
                                <RadioGroup
                                  aria-labelledby="demo-form-control-label-placement"
                                  name="position"
                                  row
                                  defaultValue="top"
                                >
                                  <FormControlLabel style={{ fontSize: "17px" ,flexDirection:"row-reverse"}} data-test-id="remFormLabel"
                                    value={true} checked={values.send_to_all === true} label="Send to All"  control={<Radio onClick={(event: any) => {
                                      if (event.target.value) {
                                        this.setState({ personReminderNameErrors: "", personReminderName: [], sentReminderObj: {} })
                                      }
                                      setFieldValue("send_to_all", !values.send_to_all)
                                    }} />} />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                            <FieldError
                              error={this.state.personReminderNameErrors}
                              touched={true}
                            />
                            <Grid container spacing={0}>
                              <Grid item xs={12}>
                                <form onSubmit={handleSubmit}>
                                  <div className="createCardNoti">
                                    <div className="postionRel borderBottomCss">
                                      <Box className="customTextFieldBox">
                                        <TextField variant="outlined" placeholder="Reminders Title"
                                          type="text"
                                          data-test-id="remHeading"
                                          name={"headings"}
                                          onChange={(event: any) => {
                                            setFieldValue("headings", event.target.value)
                                          }}
                                          value={values.headings} />
                                        <FieldError
                                          error={errors.headings}
                                          touched={touched.headings}
                                        />
                                      </Box>
                                      <div>
                                        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "end", gap: "10px", padding: "10px" }} className="timePostionDiv">
                                          <input className="inputDateCust" data-test-id="remDate" onChange={(event: any) => {
                                            setFieldValue("sent_date", event.target.value)
                                          }} value={values.sent_date} type="date" min={new Date().toISOString().split('T')[0]}
                                          />
                                          <input className="inputDateCust" data-test-id="remTime" onChange={(event: any) => {
                                            setFieldValue("sent_time", event.target.value)
                                          }} value={values.sent_time} type="time"
                                          />
                                        </div>
                                        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "end", gap: "10px", padding: "10px" }} className="timePostionDiv">
                                          <FieldError
                                            error={errors.sent_date}
                                            touched={touched.sent_date}
                                          />
                                          <FieldError
                                            error={errors.sent_time}
                                            touched={touched.sent_time}
                                          />
                                        </div>
                                      </div>

                                    </div>
                                    <Editor
                                      editorState={this.state.editorReminderState}
                                      data-test-id="remEditor"
                                      toolbarClassName="toolbarClassName"
                                      wrapperClassName="wrapperClassName"
                                      editorClassName="editorClassName"
                                      onEditorStateChange={(event: EditorState) => this.onEditorStateCreateReminderChange(event,setFieldValue )}
                                      toolbar={{
                                        textAlign: { inDropdown: true },
                                        inline: { inDropdown: true },
                                        list: { inDropdown: true },
                                        link: { inDropdown: true },
                                        history: { inDropdown: true },
                                      }}
                                    />
                                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                      <img style={{ width: "140px", height: "30px" }} src={`${images.iconLogo}`} />
                                      <p>{this.state.onlyDate}</p>
                                    </div>
                                    <FieldError
                                      error={errors.contents}
                                      touched={touched.contents}
                                    />
                                  </div>
                                  <div style={stylesCreateRe.btnCover}>
                                    <FieldError
                                      error={errors.frequency}
                                      touched={touched.frequency}
                                    />
                                    <FormControl className="SelectOptionfreque">
                                      <Select
                                        value={values.frequency}
                                        data-test-id="remSelect"
                                        onChange={(event: any) => {
                                          setFieldValue("frequency", event.target.value)
                                        }}
                                        renderValue={
                                          () =>
                                            <p style={{ padding: "24px", textTransform: "capitalize" }}>{values.frequency || "Frequency"}</p>
                                        }
                                        displayEmpty
                                        IconComponent={ArrowDropDownIcon}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        disableUnderline
                                        MenuProps={MenuPropsFrequencyValue}
                                        style={{ border: "1px solid black", height: "40px", borderRadius: "20px", padding: "4px", width: "154px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center" }}
                                      >
                                        {dropdownOptions.map((option: any) => (
                                          <MenuItem className="optionTextCss" style={{ width: '168px' }} key={option} value={option}>
                                            {
                                              <RadioGroup
                                                value={values.frequency}
                                                row
                                              >
                                                <FormControlLabel style={{ textTransform: "capitalize" }} labelPlacement="start" name="rrrrr" value={option} control={<Radio />} label={option} />
                                              </RadioGroup>
                                            }
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>

                                    <button type="button" data-test-id="remButton" onClick={() => {
                                      this.setState({
                                        editorReminderState: EditorState.createEmpty(),
                                        personReminderName: [],
                                        sentReminderObj: {}
                                      })
                                      resetForm();
                                    }} style={{ ...stylesCreateRe.buttonCreateRe, }} >
                                      Reset All
                                    </button>
                                    <button typeof="submit" style={{ ...stylesCreateRe.activeButtonRe, background: "linear-gradient(180deg, #192C85 0%, #000000 100%)", paddingLeft: " 1.5rem", paddingRight: "1.5rem", border: "1px solid #0c143e", }} type="submit">Post Reminders</button>
                                  </div>
                                </form>
                              </Grid>
                            </Grid>
                          </>
                        );
                      }}
                    </Formik>

                  </Box>
                </Box>
              </div>
            </div>
          </Box>
          <Snackbar open={this.state.alertState} onClose={this.handleCreateCloseAlert} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <MuiAlert elevation={6} variant="filled" data-test-id="alertComponent" severity={this.state.alertType}>
              {this.state.createAlertMsg}
            </MuiAlert>
          </Snackbar>
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}


// Customizable Area Start

let stylesCreateRe: any = {
  fullscreenCreateRe: {
    position: "fixed",
    top: 0,
    bottom: 0,
    width:"100vw",
    height: "100vh !important",
    fontFamily: "Poppins, sans-serif",
    display: 'flex',
    boxSizing: 'border-box'
  },
  buttonCreateRe: {
    fontSize: "15px",
    border: "1px solid black",
    borderRadius: "30px",
    padding: "0.6rem 1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "black",
    cursor: "pointer",
    width: "154px",
    backgroundColor: "white"

  },
  divContainerCreateRe: {
    gap: "10px",
    height: "100%",
    width: "74vw",
    marginLeft: "25vw",
  },
  pointerCreateRe: {
    cursor: "pointer",
    margin: "1rem"
  },
  activeButtonRe: {
    fontSize: "15px",
    color: "#fff",
    border: "none",
    borderRadius: "30px",
    padding: "0.6rem 1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  topNavBarCreateRe: {
    width: "100%",
    marginTop: '74px',
  },
  sidebarParentCreateRe: {
    height: '100vh',
  },
  bellIconCreateRe: {
    textAlign: "right",
    width: "100%"
  },
  pageTitleCreateRe: {
    borderTop: "1px solid rgba(128, 128, 128, 0.43)",
    boxShadow: "rgb(34, 34, 34) 0px 4px 6px -6px",
  },

  sidebar: {
    fontFamily: 'poppins',
    position: "fixed",
    top: "0",
    width: "24vw",
    marginRight: "-100px",
    zIndex: "9",
    height: "100%",
  },

  btnCover: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '18px',
    marginTop: '15px',
    flexWrap: 'wrap',
    marginBottom: "60px",
    alignItems: "center"
  },

  pageHeader: {
    fontFamily: 'Poppins',
    backgroundColor: "white",
    marginLeft: "22vw",
    width: "76vw",
    height: "75px",
    borderBottom: "1px solid rgba(128, 128, 128, 0.43)",
    boxShadow: "none",
    borderLeft: "none",
  },

  notificationIcon: {
    fontFamily: 'Poppins',
    position: "fixed",
    right: "30px",
    top: "20px",
    color: "black",
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
  },
  formControl: {
    borderRadius: '50px',
    padding: '8px',
    backgroundColor: '#f0f0f0',
    width: '150px',
    fontSize: "17px",
    border: "1px solid black"
  },
  inputLabel: {
    background: 'transparent',
    paddingLeft: '10px',
    paddingRight: '5px',
    fontSize: "17px",
  },
  composeNewBtn:{
    fontSize: "17px", 
    margin: "20px",
    paddingTop: "12px"
  },
  accountName: {
    fontSize: "16px", 
    lineHeight: "0.8", 
    maxWidth: "200px", 
    wordWrap: "break-word", 
    whiteSpace: "normal"
  },
  accountEmail: {
    fontSize: "11px",
     lineHeight: "1", 
     maxWidth: "200px", 
     wordWrap: "break-word", 
     paddingRight: "10px", 
     whiteSpace: "normal"
  },
  paddingLeft24: {
    paddingLeft: "24px"
  },
  sidebarIcon: { position: "fixed", zIndex: 1200, top: 10, left: 12, background: "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)", }
}
// Customizable Area End
export default (withWidth()(CreateReminder));
