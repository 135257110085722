export const sideBarBg = require("./assets/sideBarBg.png")
export const headerLogo = require("./assets/Deepset_Header.png")
export const profilePic = require("./assets/profile.png")
export const bellRing = require("./assets/BellRinging.png")
export const currencyDollar = require("./assets/CurrencyCircleDollar.png")
export const gauge = require("./assets/Gauge.png")
export const notePencil = require("./assets/NotePencil.png")
export const screenChart = require("./assets/ProjectorScreenChart.png")
export const userCircle = require("./assets/UserCircle.svg")
export const logOut = require("./assets/Group 298.png")
export const gear = require("./assets/Gear.png")
export const userCircleGear = require("./assets/UserCircleGear.png")
export const iconLogo = require("./assets/iconLogo.png")
export const defaultUser = require("./assets/defaultUser.jpg")



