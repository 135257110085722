import React from "react";

// Customizable Area Start
import {
  Grid,
  TextField,
  Box,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TableSortLabel,
  withWidth,
  Drawer,
  Popover,
  MenuItem,
  MenuList,
  Button
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import MoreVert from "@material-ui/icons/MoreVert";
import SideBar from "../../../components/src/SideBar";
import MenuIcon from '@material-ui/icons/Menu';
import "../assets/css/style.css";
import Divider from "@material-ui/core/Divider";
import UnreadNotificationList from "../../../components/src/UnreadNotificationList.web";
import moment from 'moment';
import UserNotificationDelete from "../../../components/src/UserNotificationDelete.web";
const images = require("../../../components/src/assets");
// Customizable Area End

import UserNotificationTdController, {
  Props,
} from "./UserNotificationTdController";
import Snackbar from '@material-ui/core/Snackbar';
import NotificationsHeaderSent from "../../../components/src/NotificationsHeader";
import UserSecondNotiHeader from "../../../components/src/UserSecondNotiHeader.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AlertSechNoti from "../../../components/src/AlertNotification.web";
// Customizable Area Start
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins'
  },
});

export class UserNotificationAll extends UserNotificationTdController {
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { allNotificationList, updateStatus, searchState, showModal, showUpdateRowData, unreadNotificationRecords, isLoading, scrollPage } = this.state;
    const { width } = this.props;
    const isMobile = /s/.test(width);
    const targetData = allNotificationList.items.find((item: any) => item.id === this.state.getUpdatedId);
    return (


      <ThemeProvider theme={theme}>
        <>
          <Box style={stylesSentNoti.fullscreenBody} className="table_body">

            <Box style={stylesSentNoti.sidebarParentBodyBody}>
              {isMobile ?
                <div
                  style={{
                    top: "10px",
                    left: "12px", position: "fixed", background: "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
                  }}
                >
                  <IconButton
                    style={{ color: 'white' }}
                    onClick={this.handleDrawerToggles}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Drawer
                    onClose={this.handleDrawerToggles}
                    open={this.state.mobileOpen}
                    ModalProps={{
                      keepMounted: true,
                    }}
                  >
                    {<SideBar
                      style={stylesSentNoti.sidebar}
                      activeMenuItem={"Notifications & Reminders"}
                      navigate={this.props.navigation.navigate}
                      userData={this.state.userData}
                    />}

                  </Drawer>
                </div> :
                <div className="sidebarResponse" style={stylesSentNoti.sidebar}>
                  <SideBar
                    activeMenuItem={"Notifications & Reminders"}
                    navigate={this.props.navigation.navigate}
                    userData={this.state.userData}
                  />
                </div>}
            </Box>
            <Box className="width_100_p" style={stylesSentNoti.divContainerBody} >
              <div style={stylesSentNoti.topNavBarBody}>
                <>
                  <div style={stylesSentNoti.bellIconBody}>
                    {this.state.userData.userType !== "admin" &&
                      <>
                        <img
                          data-test-id="reminderTab"
                          onClick={this.handleClickPopOver}
                          src={this.handleIcon()}
                          style={stylesSentNoti.pointerBody}
                        />
                        <UnreadNotificationList
                          handleClearAll={this.handleClearAll}
                          anchorEl={this.state.anchorElNoti}
                          scrollPage={scrollPage}
                          data-test-id="handleClearAll"
                          navigate={this.props.navigation.navigate}
                          isLoading={isLoading}
                          unreadNotificationList={this.onScrollUnreadNotificationList}
                          handleClosePopOver={this.handleClosePopOverNoti}
                          notificationData={unreadNotificationRecords}                     
                        />
                      </>
                    }
                  </div>
                </>
                <div className="padding_0_res" style={stylesSentNoti.pageTitleBody} >
                  <NotificationsHeaderSent
                    clickTab={this.clickTab}
                    goToSentAll={this.goToAll}
                    state={this.state}
                    goToReminders={this.goToAllReminder}
                  />
                </div>
              </div>
              <Box className="verticalScroll">
                <Box className="scrollMaxHeight" style={{ margin: "24px", overflow: 'auto', minHeight: '80%' }}>
                  <Box className="BoxFlex">
                    <UserSecondNotiHeader
                      state={this.state}
                      clickTab={this.clickTabNoti}
                      goToAll={this.goToAll}
                      goToUnread={this.goToUnread}
                      goToRead={this.goToRead}
                    />
                    <Box style={{marginRight:"4px"}}>
                      <TextField value={searchState} data-test-id="searchButtonUser" onChange={this.searchInput} label="Search by Title"
                        className="searchInput"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              variant="filled"
                              position="end">
                              <IconButton
                               onClick={() => this.handleSearchNo('allNotification')}
                              >
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Box>
                  </Box>
                  <Box className="dynamicHeight">
                    <Table className="PTable" >
                      {!showUpdateRowData &&
                        <TableHead>
                          <TableRow className="tableRow rowCell" style={stylesSentNoti.titleBar}
                          >
                            <TableCell
                            >
                              Label
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>Sent By
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              <TableSortLabel>Date</TableSortLabel>
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>More
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      }
                      <TableBody>
                        {showUpdateRowData
                          && targetData ?
                          <>
                            <Grid item xs={12}>
                              <div style={{
                                border: "1px solid #C7C3C3"
                                , borderRadius: "10px"
                              }}>
                                <div
                                  style={{ justifyContent: "space-between" }}
                                  className="paddingBottomDflex" >
                                  <Box className="customTextFieldBox"
                                    style={{ padding: "12px", width: "fit-content", maxWidth: "500px", wordBreak: "break-all", minWidth: "200px" }}>
                                    <span style={stylesSentNoti.wordWrap}>
                                      {targetData.attributes.notification.attributes.headings}
                                    </span>
                                  </Box>
                                  <div className="flexContainer">
                                    <span>
                                      {targetData.attributes.notification.attributes.created_by.first_name + " " + targetData.attributes.notification.attributes.created_by.last_name}
                                    </span>
                                    <span>
                                      {moment.utc(targetData.attributes.notification.attributes.sent_date + ' ' + targetData.attributes.notification.attributes.sent_time, 'YYYY-MM-DD hh:mm').local().format('D-M-YYYY, h.mm A')}
                                    </span>
                                    <div>
                                      <IconButton >
                                        <MoreVert
                                          style={{ color: "#000000" }}
                                        />
                                      </IconButton>
                                    </div>
                                  </div>
                                </div>
                                <Grid item xs={12}>
                                  <form>
                                    <div
                                      style={stylesSentNoti.card}>
                                      <div
                                        className="borderBottomCss">
                                        <Box
                                          style={stylesSentNoti.titleText}>
                                          <span style={stylesSentNoti.wordWrap}>
                                            {targetData.attributes.notification.attributes.headings}
                                          </span>
                                        </Box>
                                        <div
                                          style={{ display: "flex" }}>
                                          <span
                                            style={{ marginLeft: "10px" }}
                                          >Date  {moment.utc(targetData.attributes.notification.attributes.sent_date + ' ' + targetData.attributes.notification.attributes.sent_time, 'YYYY-MM-DD hh:mm').local().format('D-M-YYYY, h.mm A')}
                                          </span>
                                        </div>
                                      </div>
                                      <p
                                        style={{ paddingRight: "32px" }}
                                      >
                                        <span
                                          style={{ ...stylesSentNoti.wordWrap, fontSize: "17px" }}
                                          dangerouslySetInnerHTML={{ __html: targetData.attributes.notification.attributes.contents }}>
                                        </span>
                                      </p>
                                      <div style={{ justifyContent: "space-between", display: "flex" }}>
                                        <div style={{ display: "flex" }}>
                                          <img src={`${images.iconLogo}`} style={{
                                            width: "170px",
                                            height: "50px",
                                            marginRight: "10px"
                                          }} />
                                          <p>
                                            {moment.utc(targetData.attributes.notification.attributes.sent_date, 'YYYY-MM-DD').local().format('D-M-YYYY')}
                                          </p>
                                        </div>
                                        <Button
                                          data-test-id="cancelButton"
                                          style={stylesSentNoti.cancelBtn}
                                          onClick={() => {
                                            this.setState({
                                              showUpdateRowData: false
                                            })
                                          }}>Back</Button>
                                      </div>
                                    </div>
                                  </form>
                                </Grid>
                              </div>
                            </Grid>
                          </>
                          : allNotificationList.items.length > 0
                          &&
                          allNotificationList.items.map((row: any) => (
                            <>
                              <TableRow
                                key={row.id}
                                style={{ backgroundColor: this.handleRowColor(row) }}
                                className="tableRow rowCell" >
                                <TableCell data-test-id="rowBtn" onClick={() => this.onRowClickData(row.id)} >{row.attributes.notification.attributes.headings}</TableCell>
                                <TableCell data-test-id="rowBtn1" onClick={() => this.onRowClickData(row.id)}>{row.attributes.notification.attributes.created_by.first_name + " " + row.attributes.notification.attributes.created_by.last_name}</TableCell>
                                <TableCell data-test-id="rowBtn2" onClick={() => this.onRowClickData(row.id)}>{moment.utc(row.attributes.notification.attributes.sent_date + ' ' + row.attributes.notification.attributes.sent_time, 'YYYY-MM-DD hh:mm').local().format('D-M-YYYY, h.mm A')}</TableCell>
                                <TableCell>
                                  <div>
                                    <IconButton
                                      data-test-id="moreBtn"
                                      onClick={(e: any) => { this.handleIconRowClick(e, row, "allNotification") }}
                                    >
                                      <MoreVert style={{ color: "#000000" }} />
                                    </IconButton>
                                    <Popover
                                      anchorEl={this.state.anchorEl}
                                      data-test-id="handlePopOver"
                                      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                      onClose={this.handleClosePopOver}
                                      open={Boolean(this.state.anchorEl)}
                                      PaperProps={{ style: { boxShadow: "#CECECE 0px 2px 2px", } }}
                                      transformOrigin={{ horizontal: "center", vertical: "top", }}
                                    >
                                      <MenuList>
                                        <MenuItem data-test-id="menu" onClick={() => {
                                          this.setState({
                                            updateStatus: !updateStatus,
                                          },
                                            () => {
                                              this.setUpdateStatus();
                                            }
                                          );
                                        }}>
                                          {updateStatus ? 'Mark as Unread' : 'Mark as Read'}
                                        </MenuItem>
                                        <Divider style={{ padding: "0 10%", width: "80%" }} />
                                        <MenuItem onClick={() => this.setState({ anchorEl: null, showModal: true })}
                                          data-test-id="deleteUser">
                                          Delete
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </div>
                                </TableCell>
                              </TableRow>
                            </>))}
                        {(this.state.allNotificationList.items.length === 0
                          &&
                          !this.state.allNotificationList.hasMore)
                          &&
                          <TableRow style={{ textAlign: "center" }}>
                            <TableCell
                              style={{ textAlign: "center" }}
                              align="center" colSpan={4}
                            >No data found</TableCell>
                          </TableRow>
                        }
                        <TableRow
                          style={{ textAlign: "center" }}
                          ref={this.loadingRefNoti} >
                          {(this.state.allNotificationList.hasMore && !showUpdateRowData) &&
                            <TableCell
                              style={{ textAlign: "center" }}
                              align="center" colSpan={4}>Loading...</TableCell>
                          } </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {showModal && <UserNotificationDelete
            closeModal={this.closeModal}
            showModal={showModal}
            handleDelete={this.handleDeleteNotification}
            isMobile={isMobile} />}
          <Snackbar
            onClose={this.handleCreateCloseAlert}
            open={this.state.alertState}
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}>
            <AlertSechNoti
              sx={{ width: '100%' }}
              severity={this.state.alertType}
            >
              {this.state.createAlertMsg}
            </AlertSechNoti>
          </Snackbar>

        </>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

let stylesSentNoti: any = {
  wordWrap: {
    wordWrap: "anywhere"
  },
  pointerBody: {
    fontFamily: 'Poppins',
    position: "fixed",
    right: "30px",
    top: "20px",
    color: "black",
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
  },
  cancelBtn: {
    width: "120px",
    borderRadius: "30px",
    fontSize: "17px",
    backgroundColor: "rgb(199, 195, 195)",
  },
  sidebar: {
    zIndex: "9",
    height: "100%",
    position: "fixed",
    bottom: 0,
    top: 0,
    width: "24vw",
    fontFamily: 'Poppins',
    marginRight: "-100px",
    paddingRight: "10px"
  },
  fullscreenBody: {
    fontFamily: "poppins !important",
    display: 'flex',
    position: "fixed",
    top: 0,
    bottom: 0,
    boxSizing: 'border-box',
    height: "100vh !important",
    minWidth: "100vw",
  },
  sidebarParentBodyBody: {
    height: '100vh'
  },
  divContainerBody: {
    width: "calc(100% - 24%)",
    marginLeft: "auto",
    gap: "10px",
    height: "100%",
  },

  pageTitleBody: {
    borderTop: "1px solid rgba(128, 128, 128, 0.43)",
    boxShadow: "rgb(34, 34, 34) 0px 4px 6px -6px",
    padding: "0px 0px 0px 50px"
  },
  topNavBarBody: {
    width: "100%"
  },

  titleBar: {
    background: "#DCDCDC",
  },
  bellIconBody: {
    width: "100%",
    textAlign: "right",
    height: "75px"
  },

  titleText: {
    fontSize: "18px",
    fontWeight: "500",
    color: "#0F1119",
    width: "300px"
  },
  card: {
    borderRadius: "18px",
    background: " #F9F9F9",
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.50)",
    padding: "24px",
    margin: "0 24px 24px"
  },

}
// Customizable Area End
export default withWidth()(UserNotificationAll);
