import React from "react";
// Customizable Area Start
import SideBar from "../../../components/src/SideBar";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import Snackbar from "@material-ui/core/Snackbar";
import Divider from "@material-ui/core/Divider";
import MuiAlert from "@material-ui/lab/Alert";
import MoreVert from "@material-ui/icons/MoreVert";
import MenuIcon from "@material-ui/icons/Menu";
import "../../email-account-login/src/Style.css";

import {
  Table,
  TableBody,
  Dialog,
  DialogContent,
  DialogActions,
  TableCell,
  TableHead,
  AppBar,
  MenuList,
  TableRow,
  Typography,
  Button,
  IconButton,
  Popover,
  MenuItem,
  Drawer,
  withWidth,
} from "@material-ui/core";
import { bellIcon, unreadBellIcon } from "./assets";
import UnreadNotificationList from "../../../components/src/UnreadNotificationList.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
  typography: {
    fontFamily: "Poppins !important",
  },
});
// Customizable Area End
import AccountAccessController, { Props } from "./AccountAccessController";
export class AccountAccess extends AccountAccessController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const { width } = this.props;
    const isMobile = /s/.test(width);
    const { unreadNotificationRecords, isLoadingNoti, scrollPage } = this.state;
    const {
      usersAccount,
      showModal,
      usersUpdateError,
      userSuccessAlert,
      showAlert,
      alertType,
    } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <div style={webStyle.pageContainer}>
          {isMobile ? (
            <div className="sidebarIcon" style={webStyle.sidebarIcon} >
              <IconButton
               onClick={ this.handleDrawerToggle }
                style={{ color: "white" }}
               
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                ModalProps = {{
                  keepMounted:true,
                }}
                open = {this.state.mobileOpen}
                onClose={ this.handleDrawerToggle }
              >
                {
                  <SideBar
                    style = {webStyle.sidebar}
                    navigate={this.props.navigation.navigate}
                    userData={ this.state.userData }
                    activeMenuItem = {"Accounts and Access"}
                  />
                }
              </Drawer>
            </div>
          ) : (
            <div style={ webStyle.sidebar } className="sidebarResponse">
              <SideBar
                navigate = {this.props.navigation.navigate}
                activeMenuItem = {"Accounts and Access"}
                userData={ this.state.userData }
              />
            </div>
          )}
          <div style={webStyle.pageDefault} className = "accessPageDefault">
            <AppBar
              position="fixed"
              style={webStyle.pageHeader}
              className="accessHeaderSection"
            >
              {this.state.userData.userType !== "admin" && (
                <>
                  <img
                    src={
                      unreadNotificationRecords.length > 0
                        ? unreadBellIcon
                        : bellIcon
                    }
                    style={webStyle.notificationIcon}
                    onClick={this.handleClickPopOver}
                    data-test-id="handleClickPopOver"
                  />
                  <UnreadNotificationList
                    anchorEl={this.state.anchorElNoti}
                    data-test-id="handleClosePopOver"
                    handleClosePopOver={this.handleClosePopOver}
                    navigate={this.props.navigation.navigate}
                    notificationData={unreadNotificationRecords}
                    isLoading={isLoadingNoti}
                    unreadNotificationList={this.onScrollUnreadNotificationList}
                    handleClearAll={this.handleClearAllNoti}
                    scrollPage={scrollPage}
                  />
                </>
              )}
            </AppBar>
            <div style={webStyle.accountNavbar} className="accountNavbar">
              <Typography style={webStyle.accountText} className="accessText">
                Account and Access
              </Typography>
              {this.state.getUserPermissionData == "Add New Users" && (
                <Button
                  type="button"
                  className="addBtn"
                  style={webStyle.addAccountBtn}
                  startIcon={<PersonAddOutlinedIcon />}
                  data-test-id="goToAccount"
                  onClick={this.goToAddAccount}
                >
                  Add Account
                </Button>
              )}
            </div>

            <div
              style={{ ...webStyle.showUserAccounts, marginLeft: "8%" }}
              className="userData"
            >
              <Table style={{ tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow
                    style={webStyle.accountDetails}
                    className="margin_auto_define"
                  >
                    <TableCell
                      style={{
                        ...webStyle.accountCell,
                        fontWeight: 700,
                        width: "150px",
                      }}
                    >
                      Login ID
                    </TableCell>
                    <TableCell
                      style={{
                        ...webStyle.accountCell,
                        fontWeight: 700,
                        width: "160px",
                      }}
                    >
                      MT5 Account No
                    </TableCell>
                    <TableCell
                      style={{
                        ...webStyle.accountCell,
                        fontWeight: 700,
                        width: "150px",
                      }}
                    >
                      Password
                    </TableCell>
                    <TableCell
                      style={{ ...webStyle.accountDesc, fontWeight: 700 }}
                    >
                      Account Description
                    </TableCell>

                    {this.state.userData.userType == "admin" && (
                    <TableCell
                      style={{
                        fontSize: "15px",
                        width: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: 700,
                      }}
                    >
                      Access
                    </TableCell>)}
                    <TableCell
                      style={{ ...webStyle.accountMore, fontWeight: 700 }}
                    >
                      More
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersAccount.map((data: any) => {
                    return (
                      <TableRow
                        style={webStyle.accountDetails}
                        key={data.id}
                        className="margin_auto_define"
                      >
                        <TableCell
                          style={{
                            ...webStyle.accountCell,
                            fontWeight: "bold",
                            width: "150px",
                            overflow: "hidden",
                          }}
                          data-test-id="userLoginId"
                        >
                          {data.attributes.login_id}
                        </TableCell>
                        <TableCell
                          style={{
                            ...webStyle.accountCell,
                            width: "160px",
                            overflow: "hidden",
                          }}
                        >
                          {data.attributes.mt_account_number}
                        </TableCell>
                        <TableCell
                          style={{
                            ...webStyle.accountCell,
                            width: "150px",
                            overflow: "hidden",
                          }}
                          data-testId="password"
                        >
                          {data.attributes.user_password}
                        </TableCell>
                        <TableCell
                          style={{
                            ...webStyle.accountDesc,
                            overflow: "hidden",
                          }}
                        >
                          {data.attributes.account_description}
                        </TableCell>
                        {this.state.userData.userType == "admin" && (
                        <TableCell
                          style={{
                            width: "200px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            overflow: "hidden",
                          }}
                        >
                          {data.attributes.activated ? (
                            <Button
                              style={webStyle.accessGivenBtn}
                              data-test-id="userRemoveAccess"
                              onClick={() => {
                                this.userRemoveAccess(data.id);
                              }}
                              key={data.id}
                            >
                            Remove Access
                            </Button>
                          ) : (
                            <Button
                              style={webStyle.giveAccessBtn}
                              data-test-id="userGiveAccess"
                              key={data.id}
                              onClick={() => {
                                this.userGiveAccess(data.id);
                              }}
                            >
                              Give Access
                            </Button>
                          )}
                        </TableCell>
                        )}
                        <TableCell style={webStyle.accountMore}>
                          <div>
                            <IconButton
                              className="iconBtnMore"
                              data-test-id="editDeletePage"
                              onClick={(e: any) => {
                                this.handleIconClick(e, data);
                              }}
                            >
                              <MoreVert style={{ color: "black" }} />
                            </IconButton>
                            <Popover
                              open={Boolean(this.state.anchorEl)}
                              anchorEl={this.state.anchorEl}
                              data-test-id="close"
                              onClose={() => this.setState({ anchorEl: null })}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                              PaperProps={{
                                style: {
                                  boxShadow: "#CECECE 0px 2px 1px",
                                },
                              }}
                            >
                              <MenuList>
                                {this.state.getUserPermissionDataSec ==
                                  "Edit Users" && (
                                  <MenuItem
                                    data-test-id="editUserPage"
                                    onClick={() => {
                                      this.editUserAccount(data);
                                    }}
                                    className="editIconBtn"
                                  >
                                    Edit
                                  </MenuItem>
                                )}
                                <Divider
                                  style={{ width: "80%", padding: "0 10%" }}
                                />
                                {this.state.getUserPermissionDataForth ==
                                  "Edit User Permissions" && (
                                  <MenuItem
                                    data-test-id="default"
                                    onClick={() => {
                                      this.defaultPermission(data);
                                    }}
                                    className="defaultPermissionBtn"
                                  >
                                    Permissions
                                  </MenuItem>
                                )}
                                <Divider
                                  style={{ width: "80%", padding: "0 10%" }}
                                />
                                {this.state.getUserPermissionDataThird ==
                                  "Delete Users" && (
                                  <MenuItem
                                    onClick={() =>
                                      this.setState({
                                        showModal: true,
                                        anchorEl: null,
                                      })
                                    }
                                    className="deleteIconUser"
                                    data-test-id="deleteUser"
                                  >
                                    Delete
                                  </MenuItem>
                                )}
                              </MenuList>
                            </Popover>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow
                    ref={this.loadingRef}
                    style={{ textAlign: "center" }}
                    data-test-id="loading"
                  >
                    {this.state.isLoading && (
                      <TableCell
                        align="center"
                        style={{ textAlign: "center" }}
                        colSpan={4}
                      >
                        Loading...
                      </TableCell>
                    )}{" "}
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>

          <Dialog
            open={showModal}
            onClose={this.closeModal}
            PaperProps={{ style: webStyle.deleteModal }}
          >
            <DialogContent style={webStyle.deleteContent}>
              <div>
                <p style={{ fontSize: "17px", textAlign: "center" }}>
                  Are you sure you want to delete this user?
                </p>
              </div>
              <div className="deleteModal" style={webStyle.deleteDisplayFlex}>
                <Button
                  className="width_management"
                  onClick={this.closeModal}
                  style={webStyle.nodelete}
                >
                  NO
                </Button>
                <Button
                  className="width_management"
                  data-test-id="yesDeleteUser"
                  onClick={this.handleDeleteUser}
                  style={webStyle.yesdelete}
                >
                  Yes
                </Button>
              </div>
            </DialogContent>
          </Dialog>

          <Snackbar
            open={showAlert}
            autoHideDuration={3000}
            onClose={this.handleAlertClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <MuiAlert
              onClose={this.handleAlertClose}
              severity={alertType}
              elevation={6}
              variant="filled"
              style={{ width: "100%" }}
            >
              {alertType === "success"
                ? `${userSuccessAlert}`
                : `${usersUpdateError}`}
            </MuiAlert>
          </Snackbar>
        </div>
      </ThemeProvider>
    );
  }
}
export default withWidth()(AccountAccess);

// Customizable Area End

// Customizable Area Start
const webStyle: any = {
  pageContainer: {
    margin: 0,
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "Poppins, sans-serif",
    position: "fixed",
    top: 0,
    bottom: 0,
    height: "100%",
  },
  pageHeader: {
    fontFamily: "Poppins",
    backgroundColor: "white",
    marginLeft: "22vw",
    width: "76vw",
    height: "75px",
    borderBottom: "1px solid rgba(128, 128, 128, 0.43)",
    boxShadow: "none",
    borderLeft: "none",
    zIndex: "5",
  },
  addAccountPage: {
    fontFamily: "Poppins",
    padding: 0,
    display: "flex",
    hieght: "1500px",
    justifyContent: "space-between",
  },
  sidebar: {
    fontFamily: "Poppins",
    position: "fixed",
    top: "0",
    width: "24vw",
    marginRight: "-100px",
    zIndex: "9",
    height: "100%",
  },
  pageDefault: {
    fontFamily: "Poppins",
    height: "100%",
    width: "calc(100% - 24%)",
    marginLeft: "auto",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    top: "100px",
  },
  accountNavbar: {
    fontFamily: "Poppins",
    display: "flex",
    justifyContent: "space-between",
    width: "95%",
  },
  AccountAccessContainer: {
    fontFamily: "Poppins",
    width: "98%",
    height: "2px",
    backgroundColor: "LightGray",
    zIndex: 1,
  },
  accountText: {
    fontFamily: "Poppins",
    fontSize: "25px",
    fontWeight: 500,
    marginLeft: "8%",
  },
  addAccountBtn: {
    fontFamily: "Poppins",
    padding: "20px 20px",
    height: "42px",
    background:
      "linear-gradient(0deg, rgba(16, 41, 114, 1.0), rgba(2, 5, 10, 1.0))",
    borderRadius: "20px",
    color: "white",
    fontSize: "15px",
    textTransform: "Capitalize",
    marginRight: "3%",
  },
  giveAccessBtn: {
    fontFamily: "Poppins",
    padding: "10px 10px",
    width: "150px",
    borderRadius: "30px",
    fontSize: "16px",
    textTransform: "Capitalize",
    background:
      "linear-gradient(0deg, rgba(16, 41, 114, 1.0), rgba(2, 5, 10, 1.0))",
    color: "white",
  },
  accessGivenBtn: {
    fontFamily: "Poppins",
    padding: "10px 10px",
    width: "160px",
    borderRadius: "30px",
    fontSize: "16px",
    textTransform: "Capitalize",
    background: "Lightgray",
    color: "black",
  },
  accountDetails: {
    marginLeft: "auto",
    marginRight: "Auto",
    marginTop: "10px",
    marginBottom: "10px",
    fontFamily: "Poppins",
    display: "flex",
    justifyContent: "space-evenly",
    width: "1090px",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
    borderRadius: "6px",
    textAlign: "center",
  },

  accountCell: {
    fontFamily: "Poppins",
    overflow: "hidden",
    overflowWrap: "anywhere",
    fontSize: "15px",
    borderRight: "1px solid #DEDEDE",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    padding: "0px 10px",
    justifyContent: "center",
  },
  accountMore: {
    fontFamily: "Poppins",
    fontSize: "15px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    padding: "0px 10px",
    justifyContent: "center",
    width: "80px",
  },
  accountDesc: {
    overflow: "hidden",
    fontFamily: "Poppins",
    fontSize: "15px",
    textAlign: "center",
    width: "250px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "16px 10px",
    wordWrap: "anywhere",
  },
  showUserAccounts: {
    fontFamily: "Poppins",
    overflowY: "auto",
    height: "70%",
    marginBottom: "13%",
    display: "flex",
    justifyContent: "center",
    scrollbarWidth: "thin",
    scrollbarColor: "##121111",
    overflowX: "auto",
    marginLeft: "20px",
    marginRight: "4%",
  },
  notificationIcon: {
    fontFamily: "Poppins",
    position: "fixed",
    right: "30px",
    top: "20px",
    color: "black",
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
  },
  deleteModal: {
    display: "flex",
    fontFamily: "Poppins",
    width: "635px",
    height: "346px",
    borderRadius: "20px",
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
  },

  nodelete: {
    width: "80px",
    fontFamily: "Poppins",
    padding: "20px 35px",
    height: "42px",
    borderRadius: "20px",
    color: "black",
    fontSize: "15px",
    textTransform: "Capitalize",
    border: "1px solid black",
    display: "flex",
    justifyContent: "center",
    marginBottom: "20%",
    marginRight: "10px",
  },
  yesdelete: {
    width: "80px",
    fontFamily: "Poppins",
    padding: "20px 35px",
    height: "42px",
    background:
      "linear-gradient(0deg, rgba(16, 41, 114, 1.0), rgba(2, 5, 10, 1.0))",
    borderRadius: "20px",
    color: "white",
    fontSize: "16px",
    textTransform: "Capitalize",
    display: "flex",
    justifyContent: "center",
    marginBottom: "20%",
    // marginRight: "23%",
    marginLeft: "10px",
  },
  deleteDisplayFlex: {
    display: "flex",
  },
  deleteContent: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  sidebarIcon: {
    position: "fixed",
    top: 10,
    left: 12,
    background:
      "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
  },
};
// Customizable Area End
