import React, { useState, useEffect } from 'react'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
} from "recharts";
import CustomTooltip from './CustomTooltip.web';

const barWidth = 12;
const gapWidth = 16;
const barHeight = 300;

const BarChartComponent = (props: any) => {
    const { yTitle, xTitle, userData, selectedUser, resetSelectedUser } = props
    const chartWidth = 20 * (barWidth + gapWidth + 20);
    let blankObjectsNeeded = 20 - userData.length;
    const blankObj = {
        "id": 0,
        "first_name": "",
        "last_name": "",
        "profile_image": "",
        "duration": {
            "duration": 0,
            "duration_by_hour": ""
        }
    }
    for (let i = 0; i < blankObjectsNeeded; i++) {
        userData.push(blankObj);
    }
    const [hoveredBar, setHoveredBar] = useState<any>(null);
    const [userBar, setUserBar] = useState<any>(null);

    const handleBarMouseEnter = (data: any, index: number) => {
        setUserBar(null)
        setHoveredBar((prevState: any) => {
            return { ...prevState, data, index };
        });
        resetSelectedUser()
    };

    const handleBarMouseLeave = () => {
        setHoveredBar(null);
    };

    useEffect(() => {
        if (selectedUser) {
            const index = userData.findIndex((user: any) => user.id === selectedUser.id);
            setUserBar((prevState: any) => {
                return { ...prevState, payload: selectedUser, x: ((index) * 44) + 76, y: 272};
            });
        }else{
            setUserBar(null)
        }
    }, [selectedUser])

    return (
        <>
            <div style={chartStyles.YAxisLable}>
                <span>{yTitle}</span>
            </div>
            {userData.length > 0 &&
                <>
                    <BarChart
                        style={{ zIndex: 999 }}
                        barGap={16}
                        width={chartWidth}
                        height={barHeight}
                        data={userData}
                        margin={{
                            top: 32,
                            right: 16,
                            left: 0,
                            bottom: 0
                        }}
                    >
                        <defs>
                            <linearGradient id="customGradient" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="0%" stopColor="#03B4FF" />
                                <stop offset="100%" stopColor="#0062FF" />
                            </linearGradient>
                        </defs>
                        <XAxis
                            dataKey="userName"
                            axisLine={{ stroke: '#E2E8F0' }}
                            tick={false} />
                        <YAxis tick={false} axisLine={{ stroke: '#E2E8F0' }} />
                        <Bar
                            style={{ cursor: 'pointer' }}
                            dataKey="duration.duration"
                            fill="url(#customGradient)"
                            barSize={barWidth}
                            onMouseEnter={handleBarMouseEnter}
                            onMouseLeave={handleBarMouseLeave} />
                    </BarChart>
                    {hoveredBar && <CustomTooltip data={hoveredBar.data} />}
                    {userBar && <CustomTooltip data={userBar} />}
                </>}
            <div style={chartStyles.XAxisLable}>
                <span>{xTitle.total_count} User</span>
            </div>
        </>
    )
}
let chartStyles: any = {
    YAxisLable: {
        paddingTop: '42px',
        paddingLeft: '56px',
        paddingBottom: '14px',
        color: '#141A35',
        fontSize: '14px',
        fontWeight: '400',
        letterSpacing: ' 0.1px'
    },
    XAxisLable: {
        paddingBottom: '24px',
        textAlign: 'center',
        color: '#696974',
        fontSize: '14px',
        fontWeight: '400',
    }
}

export default BarChartComponent;