import React from "react";
// Customizable Area Start
import SideBar from "../../../components/src/SideBar";
import MenuIcon from '@material-ui/icons/Menu';
import '../../email-account-login/src/Style.css';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Tab, Grid,
  AppBar,
  Button,
  IconButton,
   TextField,
   Drawer, withWidth,Box
} from "@material-ui/core";
import { Formik } from "formik"
//@ts-ignore
import { Editor } from "react-draft-wysiwyg";
import { isEmpty } from "../../../framework/src/Utilities";
import { bellIcon, unreadBellIcon } from "./assets";
import UnreadNotificationList from "../../../components/src/UnreadNotificationList.web";
import * as Yup from "yup";
const images = require("../../../components/src/assets");
// Customizable Area End
import CommissionSplitController, { Props } from "./CommissionSplitController"

  const theme = createTheme({
    typography: {
      fontFamily: 'Poppins !important'
    },
  });
 
 function FieldError({ error, touched } : {error: string, touched: boolean}) {
    return error && touched &&(
      <div style={{ color: "#e65e52" }}>{error}</div>
    ) 
  }
export class CommissionNote extends CommissionSplitController {
    formik: any;
    constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const {  width } = this.props;
    const isMobileNote = /s/.test(width);
    const {
      userSuccessAlert,
      showAlert,
      alertType,selecedNoteId, 
      unreadNotificationRecords,
      isLoadingNoti, 
      scrollPage} = this.state;
    return (
      <ThemeProvider theme={theme}>
      <div style={webStyle.pageContainer}>
        {isMobileNote? 
            <div
            style={webStyle.sidebarIcon}
            className="sidebarIcon"
             >
             <IconButton
            onClick={this.handleDrawerToggle}
          data-test-id="toggleFunct"
          style={{color:'white'}}
          >
            <MenuIcon />
          </IconButton>
        <Drawer
          open={this.state.mobileOpen}
          onClose={this.handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {<SideBar
          navigate={this.props.navigation.navigate}
            activeMenuItem={"CommissionSplit"}
            style={webStyle.sidebar}
          userData={this.state.userData}
          />}
        </Drawer>
     </div>:
     <div className="sidebarResponse" style={webStyle.sidebar}> 
            <SideBar
            navigate={this.props.navigation.navigate}
            activeMenuItem={"CommissionSplit"}
            userData={this.state.userData}
          />
          </div>}
        <div style={webStyle.pageNoteDefault} className="accessPageDefault">
          <AppBar position="fixed" style={webStyle.pageNoteHeader} className="accessAddHeader">
            {this.state.userData.userType !== "admin" &&
              <>
                <img
                  src={unreadNotificationRecords.length > 0 ? unreadBellIcon : bellIcon}
                  style={webStyle.notificationIcon}
                  onClick={this.handleClickPopOverNoti}
                />
                <UnreadNotificationList
                  anchorEl={this.state.anchorElNoti}
                  handleClosePopOver={this.handleClosePopOverNoti}
                  navigate={this.props.navigation.navigate}
                  notificationData={unreadNotificationRecords}
                  isLoading={isLoadingNoti}
                  unreadNotificationList={this.onScrollUnreadNotificationList}
                  handleClearAll={this.handleClearAll}
                  scrollPage={scrollPage}
                />
              </>
            }
          </AppBar>
          <div style={webStyle.divider} className="padding_0_res">
            <div style={webStyle.pageHeading}>
              <Tab
                style={webStyle.selectedTab}
                label="Account Commission"
                className="AccounComPage"
                data-test-id="AccounComPage"
                onClick={this.accountCommissionPage}
              ></Tab>
              <Tab
                label="Monthly Report"
                style={webStyle.editProfileBtn}
                className="AccounComPage"
                onClick={this.monthlyReportPage}
              ></Tab>
            </div>
          </div>
          
<div style={{overflow:"auto", scrollbarWidth: "none"}}>
          <div style={webStyle.noteUpdateField} className="viewUser">
    <div style={{paddingLeft:"10%"}}>
      <h3 style={{fontSize:"20px", fontWeight:500}}>
      {this.state.goToUpdateNote ? "Update Note Editor": "New Note Editor"}
      </h3>
  </div>
    <div style={webStyle.download}>
    <h3 style={{paddingRight:"40px", fontWeight:600}}><span style={{fontWeight:100}}>Login ID :</span> {this.state.loginID} </h3>
              <h3 style={{fontWeight:600}}><span style={{fontWeight:100}}>Account number : </span>{this.state.MT5}</h3>
    </div>
  </div>
          <div style={webStyle.showUserAccounts} className="noteTabledata">
          <Grid container spacing={0} style={webStyle.noteEditor}>
                    <Formik
                      enableReinitialize={true}
                      data-test-id="formicData"
                      validateOnBlur={true}
                      initialValues={{
                        headings:this.state.sentObj.headings,
                        id:"",
                      }}
                      innerRef={(p:any)=> this.formik = p}
                      onSubmit={(values,{resetForm})=>{
                        if(isEmpty('')){
                          this.setState({
                            contentsError:"content is required"
                          })
                        }else{
                          resetForm();
                          this.setState({
                            contentsError:""
                          })
                        }
                      }}
                    >
                      
                      {({values,
                    errors,
                    resetForm,
                    setFieldTouched,
                    setFieldValue,
                    touched, handleSubmit }) => {
                        return (
                          <>
                            <Grid container spacing={0}>
                              <Grid item xs={12} style={{maxWidth:"100%"}} className="noteEditorScreen">
                                <form onSubmit={handleSubmit}>
                                  <div className="createCardNoti">
                                    <div className="postionRel borderBottomCss">
                                      <Box className="customTextFieldBox">
                                      <TextField 
                                          type="text"
                                          name={"headings"}
                                          onChange={(event:any)=>{
                                            setFieldValue("headings", event.target.value)
                                            this.setState({
                                              sentObj:{
                                                ...this.state.sentObj,
                                                headings: event.target.value,
                                              },
                                            })
                                          }}
                                          style={{textTransform:"capitalize"}}
                                          data-test-id="blurTest"
                                          onBlur={() => setFieldTouched("headings")}
                                          value={values.headings} variant="outlined"
                                           placeholder="Note Title" />
                                    {FieldError({error: errors.headings || this.state.notificationError, touched : touched.headings || this.state.notificationError})}
                                        
                                      </Box>
                                      <div className="timePostionDiv">
                                        {this.state.createNotiData.id?<>
                                          <input className="inputDateCust" type="date" min={new Date().toISOString().split('T')[0]}/>
                                        </>
                                        :<>
                                       <p className="datePreTag DatePreText"> Date: {this.state.currentDate}</p>
                                        </>
                                        }
                                        
                                      </div>

                                    </div>
                                    <Editor
                                      toolbarClassName="toolbarClassName"
                                      wrapperClassName="wrapperClassName"
                                      editorClassName="editorClassName"
                                      editorState={this.state.editorState}
                                      data-test-id="editorChanges"
                                      onEditorStateChange={this.onEditorStateChange}
                                      toolbar={{
                                        link: { inDropdown: true },
                                        history: { inDropdown: true },
                                        inline: { inDropdown: true },
                                        list: { inDropdown: true },
                                        textAlign: { inDropdown: true },
                                      }}
                                    />
                                    <div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop:"10%" }}>
                                      <img style={{ width: "140px", height: "30px" }} src={`${images.iconLogo}`} />
                                      <p>{this.state.onlyDate}</p>
                                    </div>
                                    {FieldError({error: this.state.contentsError, touched : this.state.contentsError})}
                                    
              </div>
              <div style={{...webStyle.download , marginTop:"3%", marginBottom:"14%"}} className="saveEditorBtn">
          {this.state.goToUpdateNote ? <Button style={webStyle.saveNoteBtn} className="button_width" data-test-id ='updateNote' onClick={() =>this.createUserNoteBtn(selecedNoteId)}>Save Note</Button> : <Button style={webStyle.saveNoteBtn} data-test-id="createNotes" className="button_width" onClick={()=> this.createUserNoteBtn(this.state.isEditingUser)}>Save Note</Button>}
          <Button style={webStyle.cancelBtn} className="button_width" onClick={this.goBackUserNotes}>Cancel</Button>
              </div>
                                  
                                </form>
                              </Grid>
                            </Grid>
                          </>
                        );
                      }}
                    </Formik>
                    

              </Grid>
              
          </div>

             
          </div>
        </div>
        <Snackbar
          open={showAlert}
          autoHideDuration={3000}
          onClose={this.handleAlertClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            onClose={this.handleAlertClose}
            severity={alertType}
            elevation={6}
            variant="filled"
            style={{ width: "100%" }}
          >
            {alertType === "success"
              && `${userSuccessAlert}`
              }
          </MuiAlert>
        </Snackbar>
      </div>
      </ThemeProvider>
    );
  }
}
export default (withWidth() (CommissionNote))

// Customizable Area End

// Customizable Area Start
const webStyle: any = {
  pageContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    height: "100%",
    margin: 0,
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
    width:"100%"
  },
  pageNoteHeader: {
    backgroundColor: "white",
    marginLeft: "22vw",
    width: "76vw",
    height: "75px",
    borderBottom: "1px solid rgba(128, 128, 128, 0.43)",
    boxShadow: "none",
    borderLeft: "none",
  },
  sidebar: {
    top: "0",
    width: "24vw",
    marginRight: "-100px",
    zIndex: "9",
    height: "100%",
    position: "fixed",
  },
  noteUpdateField:{
    display:"flex",
    justifyContent:"space-between",
    zIndex:"101",
    paddingBottom:"3%"
},
  pageNoteDefault: {
    height: "100%",
    width: "76vw",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    top: "100px",
    marginLeft: "24vw",
  },
  userDatadetails:{
    display:"flex",
    justifyContent:"space-between",
    fontSize:"18px"
     },
  cancelBtn: {
    padding: "10px 10px",
    width: "150px",
    borderRadius: "30px",
    fontSize: "16px",
    textTransform: "Capitalize",
    backgroundColor:"C7C3C3"
  },
  showUserAccounts: {
    // overflowY: "hidden",
    marginBottom: "3%",
    display: "flex",
    justifyContent: "center",
    scrollbarWidth: "thin",
    scrollbarColor: "##121111",
    overflowX:"hidden",
    marginTop:"-5%",
    // height:"100%"
  },
  notificationIcon: {
    position: "fixed",
    right: "30px",
    top: "20px",
    color: "black",
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
  },
  pageHeading: {
    marginTop: "-2%",
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },
  monthlyBtn: {
    fontSize: "21px",
    fontWeight: 500,
    textTransform: "capitalize",
    color: "#6A6A6A",
  },
  download: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: "4%",
  },
  noteEditor:{
    marginLeft:"8%",
    marginTop:"5%",
    marginRight:"8%"
  },
  sidebarIcon:{
    position:"fixed",
  top:10, 
  left:12, 
  background: "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
},
saveNoteBtn: {
    padding: "10px 10px",
    // height: "42px",
    background:
      "linear-gradient(0deg, rgba(16, 41, 114, 1.0), rgba(2, 5, 10, 1.0))",
    borderRadius: "30px",
    color: "white",
    fontSize: "15px",
    textTransform: "Capitalize",
    marginRight: "3%",
    width:"150px"
  },
  divider: {
    padding: "0px 0px 0px 50px",
    boxShadow: "rgb(34, 34, 34) 0px 4px 6px -6px",
  },
  editProfileBtn: {
    fontSize: "21px",
    fontWeight: 500,
    textTransform: "capitalize",
    color: "#6A6A6A",
  },
  selectedTab: {
    fontSize: "21px",
    borderBottom: "3px solid #000",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
};
// Customizable Area End
