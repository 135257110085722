import React from "react";

//Customizable Area Start
import {
  Container,
  AppBar,
  Toolbar,
  Box,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";

const tradeImages = require("./assets")
import '../../email-account-login/src/Style.css'


import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import ForgotResetPasswordController, { configJSON, Props } from "./ForgotResetPasswordController";
const font = "'Poppins', sans-serif";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  }, typography: {
    fontFamily: font
  }
});

//Customizable Area End
export default class ForgotPassword extends ForgotResetPasswordController {
  apiEmailLoginCallId: string = "";
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={"xl"} style={webStyle.pageContainers} className="pageContainer">
          <Box sx={webStyle.mainWrappers}>
            <AppBar style={{ backgroundColor: "#0c0c0c" }}>
              <Toolbar><img src={tradeImages.imgLogo} /></Toolbar>
            </AppBar>
            <div style={webStyle.loginFormauto}>
            <Box style={webStyle.resetForm} className="mainContainer">
              <Box textAlign="center" style={webStyle.logoVertical} className="logoVertical">
                <img src={tradeImages.logoVertical} width="240px" />
              </Box>
              <Box sx={webStyle.centerText} >
                <Typography style={{fontSize:"2.75rem"}} className="forgotHeading">{configJSON.pageHeadingText}</Typography>
                <Box sx={webStyle.smallText}>
                  <Typography style={{fontSize:"25px"}} className="resetContent">Enter your email address to</Typography>
                  <Typography style={{fontSize:"25px"}} className="resetContent">Retrieve your password</Typography>
                </Box>
              </Box>
              <form className="forgotField">
              <div style={{display:"flex", flexDirection:"column" , justifyContent:"space-around"}}>
                <TextField
                 className="loginInput"
                InputProps={{
                    style: { color: '#fff' },
                    disableUnderline:true,
                  }}
                  style={this.state.emailValidationError ? webStyle.resetFieldError : webStyle.resetField}
                  type="text" fullWidth
                  placeholder="Email Address"
                  data-test-id="txtInputEmail"
                  value={this.state.emailValue}
                  onChange={(event :any) => this.setNewEmailValue(event.target.value)}
                >
                  
                </TextField>
              
                {this.state.emailValidationError ? <Typography style={webStyle.error}>{this.state.emailValidationError}</Typography> : ""}
                {this.state.emailSuccessDisplay ? <Typography style={webStyle.success}>{this.state.emailSuccessDisplay}</Typography> : ""}
                </div>
                <Box sx={webStyle.resetBtn} className="newPassBtn">
                  <Button variant="contained" data-test-id="formSubmit" className="btn" style={webStyle.btn} onClick={(event) => { this.doNewPasswordReset(event) }}>
                    {configJSON.btnResetPassword}
                  </Button>
                </Box>
              </form>
            </Box>
            </div>
          </Box>
        </Container>
      </ThemeProvider>
    );
  }
}

// Customizable Area Start
const webStyle : any= {
  loginFormauto:{
   overflow:"100%"
      },
  pageContainers: {
    backgroundImage: `url(${tradeImages.bgImg})`,
    backgroundPosition: `center center`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    minWidth:"100vw",
    height:"100vh",
        position:"fixed",
        top:0,
        bottom:0,
  },
  mainWrappers: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: '100%',
    width: `100%`,
    marginTop: '2%',
    padding: 0,
    maxWidth: "100%",
  },
  logoVertical: {
    marginBottom: '30px',
    width:"238px",
    height:"130px"
  },
  error: {
    color: "red",
    marginBottom: "10px",
    fontSize:"16px",
    textAlign:"left"
  },
  success: {
    color: "green",
    marginBottom: "10px",
    fontSize:"16px",
    marginTop:"-20px",
    textAlign:"left"
  },
  centerText: {
    textAlign: "center",
    color: "#fff",
    fontWeight: "500",
  },
  smallText: {
    fontWeight: "400",
    margin: "20px 0",
    fontSize:"25px"
  },
  resetForm: {
    width: "100%",
    display:"flex",
    justifyContent:"center",
    flexDirection:"column",
    alignItems:"center"
  },
  resetField: {
    width : "581px",
    border: "1px solid #fff",
    borderRadius: "10px",
    marginBottom: "30px",
    fontSize:"22px",
    fontFamily: "poppins",
    padding:"20px 20px 20px 20px",
    '::placeholder': {
      color: "#fff",
      background: "#f9f9f9",
    }
  },
  resetFieldError: {
    width : "581px",
    borderRadius: "10px",
    border: "2px solid red",
    marginBottom: "10px",
    fontFamily: "poppins",
    padding:"20px 20px 20px 20px",
    '::placeholder': {
      color: "#fff",
      background: "#f9f9f9",
    },
  },
  resetBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop:"20px"
  },
  btn: {
    height: "70px",
    width: "247px",
    borderRadius: "35px",
    background: "linear-gradient(19.24deg, #07D9FF -19.29%, #04B0F8 33.7%, #0076EF 126.05%)",
    color: "#fff",
    fontSize: "22px",
    textTransform: "none" as const
  }
}
//Customizable Area End

