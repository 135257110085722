import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Drawer,
  withWidth,
  AppBar, Table, TableBody, TableRow, TableCell
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import SideBar from "../../../components/src/SideBar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import RemovePermissionModal from "../../../components/src/RemovePermissionModal.web";
import AlertSechNoti from "../../../components/src/AlertNotification.web";
import Snackbar from '@material-ui/core/Snackbar';
import RadioUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import '../../email-account-login/src/Style.css';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import UpdateUserPermission from "../../../components/src/UpdateUserPermission.web";
import PromoteToAdmin from "../../../components/src/PromoteToAdmin.web";
import "./Style.css";
import { bellIcon, unreadBellIcon } from "./assets";
import UnreadNotificationList from "../../../components/src/UnreadNotificationList.web";
const { vector,defaultUser} = require("./assets");
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins'
  },
});
// Customizable Area End

import ServiceSpecificSettingsAdminController, {
  Props,
  configJSON,
} from "./ServiceSpecificSettingAdminController.web";
export class ServiceSpecificSettingsAdmin extends ServiceSpecificSettingsAdminController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    const { showModalUpdate, showModalPromote, showModalRemove, rowDataAccount, accoutTypeDetail, unreadNotificationRecords, isLoadingNoti, scrollPage} = this.state;
    const { width } = this.props;
    const isMobile = /s/.test(width);

    return (

      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box >
          <div className="pageContainerFix">
            <Box style={webStyle.sidebarParentBodyBody}>
              {isMobile ?
                <div className="zIndexMinus"
                  style={{
                    top: 0, position: "fixed", background: "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
                  }}
                >
                  <IconButton
                    style={{ color: 'white' }}
                    data-test-id="handleDrawerToggles"
                    onClick={this.handleDrawerToggles}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Drawer
                    onClose={this.handleDrawerToggles}
                    open={this.state.mobileOpen}
                    ModalProps={{
                      keepMounted: true,
                    }}
                  >
                    {<SideBar
                      style={webStyle.sidebar}
                      activeMenuItem={"Accounts and Access"}
                      navigate={this.props.navigation.navigate}
                      userData={this.state.userData}
                    />}

                  </Drawer>
                </div> :
                <div className="sidebarResponse sidebarFix">
                  <SideBar
                    activeMenuItem={"Accounts and Access"}
                    navigate={this.props.navigation.navigate}
                    userData={this.state.userData}
                  />
                </div>}
            </Box>
            <div
              className="accessAddPageDefault pageDefaultFix">
              <AppBar
                position="fixed"
                className="accessHeader pageHeaderFix"> 
                {this.state.userData.userType !== "admin" &&
              <>       
                <img
                  src={unreadNotificationRecords.length > 0 ? unreadBellIcon : bellIcon}
                  className="notificationIconFix"
                  onClick={this.handleClickPopOver}
                  data-test-id="handleClickPopOver"
                />
                <UnreadNotificationList
                  anchorEl={this.state.anchorElNoti}
                  data-test-id="handleClosePopOver"
                  handleClosePopOver={this.handleClosePopOver}
                  navigate={this.props.navigation.navigate}
                  notificationData={unreadNotificationRecords}
                  isLoading={isLoadingNoti}
                  unreadNotificationList={this.onScrollUnreadNotificationList}
                  handleClearAll={this.handleClearAllNoti}
                  scrollPage={scrollPage}
                />
                </>}
              </AppBar>
              <div style={{ marginBottom: "10%" }} className="overflow_auto" >
                <div className="editProfileScreenFix">
                  <Typography className="profileHead">Permissions and Access</Typography>
                  <div style={webStyle.updatedProfile} >
                    <div>
                      <img style={webStyle.userImage} src={rowDataAccount.attributes?.profile_image?rowDataAccount.attributes.profile_image:defaultUser} className="userImage" />
                      <input
                        style={{display: 'none'}}
                        accept="image/*"
                        id="image-upload"
                        multiple={false}
                        type="file"
                      />
                    </div>
                    <div>
                      {accoutTypeDetail.attributes?.account_type === "Admin" &&
                        <Button className="removeBtnFix" data-test-id="removeBtnFix" onClick={() => this.setState({
                          anchorEl: null,
                          showModalRemove: true
                        })}>Remove as Admin</Button>}
                      {accoutTypeDetail.attributes?.account_type === "User" &&
                        <Button className="removeBtnFix" data-test-id="promoteBtn" onClick={() => this.setState({
                          anchorEl: null,
                          showModalPromote: true,
                        })}>Promote to Admin</Button>}
                    </div>
                  </div>
                  <Table >
                    <TableBody >
                      <TableRow>
                        <TableCell ><Typography style={webStyle.accountDetailsHeading}>Login ID</Typography></TableCell>
                        <TableCell><Typography style={{textAlign:"left"}}>{this.state.loginID}</Typography></TableCell>
                      </TableRow>
                      <TableRow><TableCell><Typography style={webStyle.accountDetailsHeading}>Account Description</Typography></TableCell>
                        <TableCell><Typography style={{ textAlign: "left",overflowWrap: "anywhere" }}>{this.state.description}</Typography></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell><Typography style={webStyle.accountDetailsHeading}>Account Type</Typography></TableCell>
                        <TableCell><Typography style={{ textAlign: "left", color: "#000000", fontWeight: "bold" }}>
                          {accoutTypeDetail.attributes?.account_type}
                        </Typography></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <div
                    style={webStyle.accountBg}>
                    <div>
                      <Typography className="colorWhite">Permission and Access
                      </Typography>
                    </div>
                    <div>
                      <Typography className="rightColor">Status
                      </Typography>
                    </div>
                  </div>
                  {this.state.permissionList?.map((row: any) => (
                    <div key={row.id} style={{ ...row.attributes.user_access ? webStyle.accountRowDesign : webStyle.accountRowDesignShadow }}>
                      <div style={webStyle.marginLeftDisplay}>
                        <img src={this.handleImage(row.id)} style={webStyle.uploadImage} />
                        <Typography className={row.attributes.user_access ? "colorWhites" : "colorWhitesGray"}>{row.attributes.name}</Typography>
                      </div>
                      <div>
                        <Typography className={row.attributes.user_access ? "rightColors" : "colorWhitesGray"}>
                          <Checkbox
                            onChange={() => this.handelInputPost(row.id)}
                            data-test-id="checkbox_id"
                            style={webStyle.marginLeft}
                            checked={row.checked}
                            icon={<RadioUncheckedIcon fontSize="small" />}
                            checkedIcon={<RadioCheckedIcon fontSize="small" />} />
                          <img src={vector} style={webStyle.uploadImage} />
                        </Typography>
                      </div>
                    </div>
                  ))}
                </div>
                <Button className="giveAccessBtn" style={{ marginRight: "10px", paddingLeft: "10px", paddingRight: "10px" }} data-test-id="modal"
                  onClick={() => this.setState({
                    anchorEl: null,
                    showModalUpdate: true
                  })}>
                  Update User Permissions
                </Button>
              </div>
            </div>
          </div>
        </Box>
        {showModalUpdate &&
          <UpdateUserPermission
            closeModal={this.closeModalUpdate}
            showModal={showModalUpdate}
            isMobile={isMobile}
            handleSubmit={this.updateUserPermission}
            data-test-id="closeModalUpdate"
          />}
        {showModalPromote &&
          <PromoteToAdmin
            closeModal={this.closeModalPromote}
            showModal={showModalPromote}
            isMobile={isMobile}
            handleSubmit={this.handlePromoteItem}
            data-test-id="showModalPromote"
          />}
        {showModalRemove &&
          <RemovePermissionModal
            closeModal={this.closeModalRemove}
            showModal={this.showModalRemove}
            isMobile={isMobile}
            handleSubmit={this.handleRemoveItem}
            data-test-id="showModalRemove"
          />}

        <Snackbar onClose={this.handleCloseAlert} data-test-id="handleCloseAlert" open={this.state.alertState} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <AlertSechNoti sx={{ width: '100%' }} severity={this.state.alertType}>
            {this.state.createAlertMsg}
          </AlertSechNoti>
        </Snackbar>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
export default withWidth()(ServiceSpecificSettingsAdmin);
// Customizable Area Start
const webStyle:any = {
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  sidebarParentBodyBody: {
    height: '100vh'
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  marginLeft: {
    marginRight: "20px"
  },
  marginLeftDisplay: {
    display: "flex",
    alignItem: "center"
  },
  uploadImage: {
    fontFamily: 'Poppins',
    height: "26px",
    width: "24px",
    backgroundColor: "none",
    margin: "auto"
  },
  accountBg: {
    alignItems: "center",
    background: "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
    display: "flex",
    justifyContent: "space-between",
    height: "70px",
    marginTop: "20px",
    padding: "5px",
    paddingLeft: "20px",
    paddingRight: "20px",
    borderRadius: "5px",
    boxShadow: "#CECECE 0px 2px 1px",
  },
  sidebar: {
    fontFamily: 'Poppins',
    position: "fixed",
    top: "0",
    width: "24vw",
    marginRight: "-100px",
    zIndex: "9",
    height: "100%",
  },
  accountRowDesignShadow: {
    boxShadow: "#CECECE 0px 2px 20px",
    alignItems: "center",
    background: "#F8F8F8",
    display: "flex",
    justifyContent: "space-between",
    height: "70px",
    marginTop: "20px",
    padding: "5px",
    paddingLeft: "20px",
    paddingRight: "20px",
    borderRadius: "5px",
  },
  accountRowDesign: {
    boxShadow: "#CECECE 0px 2px 1px",
    alignItems: "center",
    background: "#E9FFE8",
    display: "flex",
    justifyContent: "space-between",
    height: "70px",
    marginTop: "20px",
    padding: "5px",
    paddingLeft: "20px",
    paddingRight: "20px",
    borderRadius: "5px",
  },

  pageHeading: {
    fontFamily: 'Poppins',
    display: "flex",
    justifyContent: "space-around",
    width: "50%",
    marginLeft: "6%",
    marginTop: "-2%",
  },

  changePasswordBtn: {
    fontFamily: 'Poppins',
    fontSize: "21px",
    fontWeight: 500,
    textTransform: "capitalize",
    borderBottom: "3px solid #0F1119",
    color: "#0F1119",
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  changesBtn: {
    fontFamily: 'Poppins',
    color: "white",
    fontSize: "18px",
    fontWeight: 400,
    background:
      "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
    borderRadius: "20px",
    textTransform: "capitalize",
    width: "191px",
    height: "42px",
    marginTop: "30px"
  },

  editProfileBtn: {
    fontFamily: 'Poppins',
    fontSize: "21px",
    fontWeight: 500,
    textTransform: "capitalize",
    color: "#6A6A6A",
  },

  updatedProfile: {
    fontFamily: 'Poppins',
    display: 'flex',
    alignItems: "center",
    gap: "20px",
    width: "35%",
  },
  userImage: {
    fontFamily: 'Poppins',
    width: "80px",
    height: "80px",
    borderRadius: "50%"
  },

  uploadHead: {
    fontFamily: 'Poppins',
    color: "#000000",
    fontSize: "15px",
    fontWeight: 400,
    marginBottom: "10px"
  },
  updateBtn: {
    fontFamily: 'Poppins',
    width: "141px",
    height: "40px",
    borderRadius: "20px",
    textAlign: "center",
    border: "1px solid #0F1119",
    color: "#0F1119",
    fontWeight: 400,
    fontSize: "15px",
    textTransform: "capitalize"
  },


  accountDetailsHeading: {
    fontSize: "15px",
    fontFamily: 'Poppins',

  },
  usersDetailsEmail: {
    fontFamily: 'Poppins',
    display: 'flex',
    justifyContent: "space-between",
    paddingTop: "20px",
    marginBottom: "40px",
  },
  userDetails: {
    fontFamily: 'Poppins',
    height: "250px",
    width: "60%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },

  usersDefaultDetails: {
    fontFamily: 'Poppins',
    display: 'flex',
    flexGrow: 1,
    justifyContent: "space-between",
    paddingTop: "20px",
    paddingBottom: "10px",
    textAlign: "left"
  },
  usersDetails: {
    fontFamily: 'Poppins',
    display: 'flex',
    justifyContent: "space-between",
    paddingTop: "20px",
    paddingBottom: "10px",
    textAlign: "left",
  },
  sidebarIcon: {
    position: "fixed",
    top: 10, left: 12,
    background: "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  divider: {
    height: "2px",
  },


};
// Customizable Area End
