import React from 'react'
import { Dialog, DialogContent, DialogActions, Button } from "@material-ui/core";

const UserNotificationDelete = (props: any) => {
    const { showModal, closeModal, handleDelete, isMobile } = props;

    return (
        <Dialog
            className="modalDelete"
            open={showModal}
            onClose={closeModal}
            PaperProps={isMobile ? { style: modalStyle.mobileCSS } : { style: modalStyle.dialogContainer }}
        >
            <DialogContent style={modalStyle.deleteContent}>
                <p>Are you sure you want to delete this notification?</p>
            </DialogContent>
            <DialogActions>
                <button style={{width:"140px",alignItems:"center"}} className="noDeleteReminderButton" onClick={handleDelete}>
                    Yes
                </button>
                <button style={{width:"140px",border:"none",alignItems:"center"}} className="yesDeleteReminderButton" onClick={closeModal}>
                    No
                </button>
            </DialogActions>
        </Dialog>
    )
}

export default UserNotificationDelete

const modalStyle: any = {
    deleteContent: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "10%",
    },
    dialogContainer: {
        width: "835px",
        height: "346px",
        borderRadius: "20px",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
    },
    mobileCSS: {
        width: "auto",
        height: "auto",
        borderRadius: "20px",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
        textAlign: "center",
        margin: "0 15px",
    }
}


