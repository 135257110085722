import React from "react";
import { Box, Grid ,  withWidth,
  Drawer} from "@material-ui/core";
  import IconButton from "@material-ui/core/IconButton";
  import MenuIcon from '@material-ui/icons/Menu';
import { Formik } from "formik";
import ContentManagementController, { Props } from "./ContentManagementController";
import { styles } from "./ContentManagementStyles.web";
import SideBar from "../../../components/src/SideBar";
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import HeaderNavigation from "../../../components/src/HeaderNavigation";

import { bellIcon, unreadBellIcon } from "./assets";
import UnreadNotificationList from "../../../components/src/UnreadNotificationList.web";
import AirplayOutlinedIcon from '@material-ui/icons/AirplayOutlined';
import "../assets/css/Style.css";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
  typography: {
    fontFamily: "Poppins !important",
  },
});

export class UploadingImage extends ContentManagementController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const {  width } = this.props;
    const isMobile = /s/.test(width);
    const {
      scrollPage,
      unreadNotificationRecords,
      isLoading,
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
      <Box style={stylesUploadImg.fullscreenUpImg}>
        
          <Box style={stylesUploadImg.sidebarParentUpImg}>
          {isMobile? 
            <div
            style={{position:"fixed", zIndex:4, top:"10px", left:"12px", background: "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
          }}
             >
             <IconButton
            onClick={this.handleDrawerToggles}
            style={{color:'white'}}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
          open={this.state.mobileOpen}
          onClose={this.handleDrawerToggles}
          ModalProps={{
            keepMounted: true,
          }}
        >
          { <SideBar
          style={stylesUploadImg.sidebar}
              navigate={this.props.navigation.navigate}
              activeMenuItem={"Content Management"}
              userData={this.state.userData}
            />
          }
          
          </Drawer>
          </div>:
   <div style={stylesUploadImg.sidebar} className="sidebarResponse"> 
    <SideBar
              navigate={this.props.navigation.navigate}
              activeMenuItem={"Content Management"}
              userData={this.state.userData}
            />
          </div>}
          </Box>
          <Box style={stylesUploadImg.divContainerUpImg} className="marginAutoLeftRight">
            <div style={stylesUploadImg.topNavBarUpImg}>
              <div style={stylesUploadImg.bellIconUpImg}>
                {this.state.userData.userType !== "admin" &&
                  <>
                    <img
                      src={unreadNotificationRecords.length > 0 ? unreadBellIcon : bellIcon}
                      style={stylesUploadImg.pointerUpImg}
                      onClick={this.handleClickPopOver}
                    />
                    <UnreadNotificationList
                      anchorEl={this.state.anchorEl}
                      handleClosePopOver={this.handleClosePopOver}
                      navigate={this.props.navigation.navigate}
                      notificationData={unreadNotificationRecords}
                      isLoading={isLoading}
                      unreadNotificationList={this.onScrollUnreadNotificationList}
                      handleClearAll={this.handleClearAll}
                      scrollPage={scrollPage}
                    />
                  </>
                }
              </div>
              <div style={stylesUploadImg.pageTitleUpImg}>
                Landing Page
              </div>
            </div>
            <div className="bodyScrollBar padding_none" style={stylesUploadImg.bodyContentUpImg}>
              <div className="display_block" style={stylesUploadImg.navigationContainerUpImg}>
                <div style={stylesUploadImg.adminSettingsButtonDivUpImg}>
                  <HeaderNavigation 
                   state={this.state}
                   data-test-id="goToImageUpload"
                    goToImageUpload={this.goToImageUpload}
                    goToEditBody={this.goToEditBody}
                    goToEditHeading={this.goToEditHeading}
                  />

                </div>
                <div style={stylesUploadImg.bgcolorPickerUpImg}>
                  <div className="width_100_bg" style={stylesUploadImg.bgColorUpImg} data-test-id="updateShowColorList" onClick={() => this.updateShowColorList()}>
                    <Box style={{marginTop:"-4px",fontSize:"16px"}}>Background Color</Box>
                    <div style={{...stylesUploadImg.colorBoxUpImg, background: this.state.pageData.bg_colour}} ></div>
                  </div>
                  {
                    this.state.showColorList && (
                      <ul style={stylesUploadImg.dropdownListUpImg}>
                        <li style={{ background: " linear-gradient(180deg, #192C85 0%, #000000 100%)", height: "25px" }} data-test-id="updateBody" onClick={(event:React.MouseEvent)=>this.updateColorState(event)}></li>
                        <li style={{ background: "linear-gradient(180deg, #2D6F8F 0%, #4FB7BA 100%)", height: "25px" }} data-test-id="updateBody1" onClick={(event:React.MouseEvent)=>this.updateColorState(event)}></li>
                        <li style={{ background: " linear-gradient(229.12deg, rgba(88, 17, 89, 0.92) 4.49%, rgba(131, 24, 133, 0.67) 53.52%)", height: "25px" }} data-test-id="updateBody2" onClick={(event:React.MouseEvent)=>this.updateColorState(event)}></li>
                        <li style={{ background: "linear-gradient(180deg, #113A5D 0%, rgba(17, 49, 72, 0) 206.4%)", height: "25px" }} data-test-id="updateBody3" onClick={(event:React.MouseEvent)=>this.updateColorState(event)}></li>
                        <li style={{ background: "linear-gradient(211.19deg, #0A642B 4.35%, rgba(10, 100, 43, 0.33) 118%)", height: "25px" }} data-test-id="updateBody4" onClick={(event:React.MouseEvent)=>this.updateColorState(event)}></li>
                      </ul>)
                  }
                </div>
              </div>
              <div style={stylesUploadImg.formHolderDivUpImg}>
                <div className="analystDiv">
                  <Grid style={stylesUploadImg.GridContainer} container spacing={0}>
                    <Formik
                      enableReinitialize={true}
                      validateOnBlur={true}
                      initialValues={{}}
                      onSubmit={this.handleSubmit}
                    >
                      {({ handleSubmit }) => {
                        return (
                          <>
                            <Grid item style={stylesUploadImg.userFormUpImg} xs={12}>
                              <form onSubmit={handleSubmit}>
                                <Box style={stylesUploadImg.imgWrapperUpImg}>
                                  <img
                                    style={{ width: "100%", height: "484px", objectFit: "fill", borderRadius: "14px" }}
                                    src={this.state.imagePreview?this.state.imagePreview:this.state.pageData.image}
                                  />
                                  <div  style={{position:"relative", display:"flex"}}>
                                  {this.state.getUserPermissionData=="Manage Content" &&
                                    <div className="width_d_block"  style={{display: "flex",position: "absolute",right: 0,bottom: "18px"}}>
                                    <div className="width_100_c">
                                      <input
                                        accept="image/*"
                                        style={stylesUploadImg.inputUpload}
                                        id="contained-button-file"
                                        multiple
                                        type="file"
                                        data-test-id="fileupload"
                                        onChange={this.fileUploadHandler}
                                      />
                                      <label htmlFor="contained-button-file">
                                        <Button
                                        className="width_100_c"
                                        style={{...stylesUploadImg.activeButtonUpImg, background: "linear-gradient(180deg, #192C85 0%, #000000 100%)", display: "inline-flex", alignItems: "center",
                                        zIndex: 1,
                                        marginRight:"0.7rem",
                                        border:" 1px #fff solid",
                                        padding: "6px 25px",
                                        textTransform:"capitalize",
                                        fontSize:"15px"
                                      }}
                                        variant="contained" endIcon={<CloudUploadIcon />} component="span">
                                          Upload
                                        </Button>
                                      </label>
                                      &nbsp;
                                    </div>
                                    <div className="pre_100"  style={{...stylesUploadImg.activeButtonUpImg, background: "linear-gradient(180deg, #192C85 0%, #000000 100%)", display: "inline-flex", alignItems: "center", 
                                    zIndex: 1,
                                    border:" 1px #fff solid",
                                        padding: "6px 25px",fontSize:"15px",
                                        fontFamily:"Poppins"
                                    }} data-test-id="moveLanding" onClick={()=>this.props.navigation.navigate("LandingPage")}><div>Preview&nbsp;
                                    </div>
                                    <AirplayOutlinedIcon />
                                  </div>
                                    </div>
                      }
                                  </div>
                                </Box>
                                {this.state.getUserPermissionData=="Manage Content" &&
                                <div className="display_block marginBottom_50px" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                  <button className="width_100_new" type="button" onClick={this.revertPrevious} style={{...stylesUploadImg.buttonUpImg,fontSize:"15px", marginRight: "1rem"}}>
                                    Revert to previous version
                                  </button>
                                  <button className="width_100_new" style={{...stylesUploadImg.activeButtonUpImg, ...stylesUploadImg.updateButtonImg, fontSize:"15px"}} type="submit">
                                    Update
                                    </button>
                                </div>
                      }
                              </form>
                            </Grid>
                          </>
                        );
                      }}
                    </Formik>
                  </Grid>
                </div>
              </div>
            </div>
          </Box>  
          <Snackbar autoHideDuration={3000} anchorOrigin={{vertical: 'top', horizontal: 'right'}} onClose={this.handleCloseAlert} open={this.state.alertState}>
          <MuiAlert variant="filled" severity="success" style={{ width: '100%' }} elevation={6}>
            {this.state.updatealertMsg ? this.state.updatealertMsg : this.state.revertAlertMsg}
          </MuiAlert>
        </Snackbar>  
      </Box>
      </ThemeProvider>
    );
  }
}
const stylesUploadImg: any = {
  fullscreenUpImg: {
    position:"fixed",
    top:0,
    bottom:0,
    height: "100vh !important",
    minWidth: "100vw",
    fontFamily: "poppins !important",
    display: 'flex',
    boxSizing:'border-box'
  },
  sidebar: {
    width: "24vw",
    fontFamily: 'Poppins',
    marginRight: "-100px",
    zIndex: "9",
    height: "100%",
    position:"fixed",
    bottom : 0,
    top:0,
  },
  divContainerUpImg: {
    gap: "10px",
    height: "100%",
    width: "calc(100% - 24%)",
    marginLeft: "auto",
    // overflow:"auto"
  },
  topNavBarUpImg: {
    width: "100%"
  },

  formHolderDivUpImg: {
    width: "100%",
    margin: "0 auto",
    borderRadius:"30px"
  },
  buttonUpImg:{
    border:"1px solid black",
    borderRadius: "30px",
    padding: "0.4rem 1rem",
    marginRight: "1rem",
    display:"flex",
    justifyContent: "center",
    alignItems:"center",
    color:"black",
    cursor:"pointer",
    fontFamily:"Poppins"

  },
  imgWrapperUpImg:{
    position:'relative',
    marginBottom:'15px',
  },
  inputUpload:{
    display:"none",
  },
  updateButtonImg:{
    background: "linear-gradient(180deg, #192C85 0%, #000000 100%)", paddingLeft: " 3.5rem", paddingRight: "3.5rem", border: "1px solid #0c143e", marginRight: 0
  },
  activeButtonUpImg:{
    color:"#fff",
    border: "none",
    borderRadius: "30px",
    padding: "0.4rem 1rem",
    marginRight: "1rem",
    display:"flex",
    justifyContent: "center",
    alignItems:"center",
    cursor:"pointer",
    fontFamily:"Poppins"
  },
  userFormUpImg:{
    width: "100%"
  },

  sidebarParentUpImg:{
    // width:'24%',
    height:"100vh"
  },
  bellIconUpImg:{
    textAlign: "right",
    width: "100%",
    height:"75px"
  },
  pointerUpImg:{
    fontFamily: 'Poppins',
    position: "fixed",
    right: "30px",
    top: "20px",
    color: "black",
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
  },
  pageTitleUpImg:{
    borderTop:"1px solid #8080806e",
    padding: "10px 0px 10px 50px",
    boxShadow: "0 4px 6px -6px #222",
    fontSize:"21px"
  },
  navigationContainerUpImg:{
    display:"flex",
    justifyContent:"space-between",
    alignItems: "center",
    width: "100%",
    padding:"2rem 0"
  },
  adminSettingsButtonDivUpImg:{
    position:"relative"
  },
  dropdownListUpImg:{
    cursor:"pointer",
    position: "absolute",
    top: "20px",
    width:"100px",
    padding:"0",
    margin: "0",
    backgroundColor: "#ffffff",
    listStyleType: "none",
    border: "1px solid #ccc",
    borderRadius: "4px",
    right:0,
    zIndex:1,
  },
  bgColorUpImg:{
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    cursor: "pointer",
  },
  colorBoxUpImg:{
    height:"1rem",
    width: "1rem",
    marginLeft: "5px"
  },
  bgcolorPickerUpImg:{
    position:'relative',
  },
  bodyContentUpImg:{
    padding:'0px 35px 0px 44px',
    width: '100%',
    boxSizing:'border-box',
    overflow:"auto"
  },
  
};
export default (withWidth()  (UploadingImage));