import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import Cookies from "js-cookie";
// import io from 'socket.io-client';
// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  width: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userData: {
    email: string;
    name: string;
    imageUrl: string;
    userType: string;
  };
  mobileOpen: boolean;
  selectedTab: string;
  selectedNavTab: string;
  searchState: string;
  selectedUser: any;
  commissionTitle: string;
  usagesType: string;
  commissionType: string;
  usageRecords: Array<any>;
  commissionsRecords: any;
  commissionArray: Array<any>;
  page:number;
  per_page:number;
  usagePagination: any;
  platformUsageData: any;
  mergedCommissionsData: Array<any>;
  unreadNotificationRecords: Array<any>;
  anchorEl: any;
  scrollPage:number;
  isLoading: boolean,
}
interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserUsagesApiCallId: string = "";
  getUserPermissionApiCallId:string="";
  getCommissionsApiCallId: string = "";
  platformUserUsageApiCallId: string = "";
  dayCommissionsApiCallId: string = "";
  weeklyCommissionsApiCallId: string = "";
  monthlyCommissionsApiCallId: string = "";
  userNotificationsApiCallId: string = "";
  markAllReadApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      userData: {
        email: "",
        name: "",
        imageUrl: "",
        userType: ""
      },
      mobileOpen: false,
      selectedTab: "0",
      selectedNavTab: "0",
      searchState: "",
      selectedUser: null,
      commissionTitle: "1 Day Commissions",
      usagesType: "today",
      commissionType: "day",
      usageRecords: [],
      commissionsRecords: {},
      commissionArray: [],
      page:1,
      per_page:20,
      usagePagination:{},
      platformUsageData: {},
      mergedCommissionsData: [],
      unreadNotificationRecords: [],
      anchorEl: null,
      scrollPage:1,
      isLoading: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    //Data from Cookie
     this.handleUserPermissionList();
    this.fetchUserData();
    this.getUserUsagesData()
    this.getCommissionsApiCallId = this.clientApiCall(configJSON.getAvailableCommissions + `?type=${this.state.commissionType}`, configJSON.getDataMethod)
    this.platformUserUsageApiCallId = this.clientApiCall(configJSON.platformUserUsage, configJSON.getDataMethod)
    this.dayCommissionsApiCallId = this.clientApiCall(configJSON.dayCommissions, configJSON.getDataMethod)
    this.userNotificationsApiCallId = this.clientApiCall(configJSON.userNotifications + `&page=${this.state.scrollPage}`, configJSON.getDataMethod)
  }

  clientApiCall = (endPoint: string, method: string, httpBody?: any) => {
    let authToken = localStorage.getItem("authToken");
    
    const header = {
      token: authToken,
      "Content-Type": "application/json",
    };

    const clientReqMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    clientReqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint
    );

    clientReqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
    );

    clientReqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), method
    );

    runEngine.sendMessage(clientReqMessage.id, clientReqMessage);
    return clientReqMessage.messageId
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.receiveApi(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  receiveApi = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getUserUsagesApiCallId) {
        this.dashboardUserUsagesData(responseJson)
    }  
    if (apiRequestCallId === this.getCommissionsApiCallId) {
        this.dashboardCommissionsData(responseJson)
    }
    if (apiRequestCallId === this.platformUserUsageApiCallId) {
      this.platformUserUsageData(responseJson)
    }
    if (apiRequestCallId === this.dayCommissionsApiCallId) {
      this.collectiveCommissionsData(responseJson)
    }
    if (apiRequestCallId === this.weeklyCommissionsApiCallId) {
      this.collectiveCommissionsData(responseJson)
    }
    if (apiRequestCallId === this.monthlyCommissionsApiCallId) {
      this.collectiveCommissionsData(responseJson)
    }
    if(apiRequestCallId === this.getUserPermissionApiCallId){
      this.getUserPermission(responseJson)
     }
    if (apiRequestCallId === this.userNotificationsApiCallId) {
      this.getUserNotification(responseJson)
    }
    if (apiRequestCallId === this.markAllReadApiCallId) {
      this.clearAllNotificationList(responseJson)
    }
  }
  getUserPermission = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      let sidebar: any = responseJson.data;  
      localStorage.setItem('sidebarDetails', JSON.stringify(sidebar));
      window.dispatchEvent(new Event('storage'))
    }
  }
  dashboardUserUsagesData = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      const Data: any = responseJson.data;
      this.setState({
        usageRecords: Data,
        usagePagination: responseJson.pagination
      })
    } else {
      this.setState({
        usageRecords: [],
        usagePagination:{}
      })
    }
  }

  dashboardCommissionsData = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      const commissionArray = Object.keys(responseJson)
        .filter(key => key !== "total_commisssions")
        .map(key => ({ value: responseJson[key], name: [key] }));

      this.setState({
        commissionsRecords: responseJson,
        commissionArray
      })
    } else {
      this.setState({
        commissionsRecords: {},
        commissionArray: []
      })
    }
  }

  platformUserUsageData = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({
        platformUsageData: responseJson
      })
    } else {
      this.setState({
        platformUsageData: {}
      })
    }
  }

  collectiveCommissionsData = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      const commissionData: any = responseJson.data;
      this.setState({
        mergedCommissionsData: commissionData
      })
    } else {
      this.setState({
        mergedCommissionsData: []
      })
    }
  }

  getUserNotification = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      const unreadData: any = responseJson.data;
      const items = [...this.state.unreadNotificationRecords, ...unreadData]
      this.setState({
        unreadNotificationRecords: items,
        scrollPage: responseJson.meta.next_page,
        isLoading: false
      })
    } else {
      this.setState({
        unreadNotificationRecords: [],
        isLoading: false,
        scrollPage: 1
      })
    }
  }

  clearAllNotificationList = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({
        unreadNotificationRecords: [],
        isLoading: false,
        scrollPage: 1
      })
    }
  }

  fetchUserData = () => {
    const cookieData: any = Cookies.get("userProfile");
    const parsedCookieData = JSON.parse(cookieData).data.attributes;
    this.setState({
      userData: {
        email: parsedCookieData.email,
        name: parsedCookieData.first_name + " " + parsedCookieData.last_name,
        imageUrl: parsedCookieData.profile_image,
        userType: parsedCookieData.user_role
      }
    });
  };

  handleDrawerToggles = () => {
    this.setState((prevState: any) => ({
      mobileOpen: !prevState.mobileOpen
    }));
  };

  handleSwitch = (value: string) => {
    const usagesType = configJSON.tabToUsageTypeMap[value];
    if (usagesType !== undefined) {
      this.setState({
        selectedTab: value,
        usagesType,
        page:1,
        usageRecords: [],
        usagePagination: {},
        searchState:"",
        selectedUser: null
      }, () => this.getUserUsagesData());
    }
  };

  handleSwitchNav = (value: string) => {
    const commissionTitle = configJSON.navTabToCommissionTitleMap[value];
    const commissionType = configJSON.tabToCommissionTypeMap[value];

    if (commissionTitle !== undefined) {
      this.setState({ selectedNavTab: value, mergedCommissionsData: [], selectedUser: null, commissionTitle, commissionType }, () => {
        this.getCommissionsApiCallId = this.clientApiCall(
          configJSON.getAvailableCommissions + `?type=${this.state.commissionType}`,
          configJSON.getDataMethod
        )
        if (this.state.selectedNavTab === '0') {
          this.dayCommissionsApiCallId = this.clientApiCall(configJSON.dayCommissions, configJSON.getDataMethod)
        }
        if (this.state.selectedNavTab === '1') {
          this.weeklyCommissionsApiCallId = this.clientApiCall(configJSON.weeklyCommissions, configJSON.getDataMethod)
        }
        if (this.state.selectedNavTab === '2') {
          this.monthlyCommissionsApiCallId = this.clientApiCall(configJSON.monthlyCommissions, configJSON.getDataMethod)
        }
      });
    }
  };

  handleSearchInput = (e: any) => {
    this.setState({
      searchState: e.target.value
    });
  };

  handlePaginationChange = (page: number) => {
    this.setState({
      page,
      usageRecords: [],
      usagePagination: {}
    }, () => this.getUserUsagesData());
  }

  handleChangeUser = (userDetails: any) => {
    this.setState({
      selectedUser: userDetails
    });
  };

  resetSelectedUser = () => {
    this.setState({
      selectedUser: null
    });
  };

  handleSearchUser = () => {
    this.setState({
      usageRecords: [],
      usagePagination: {},
      selectedUser: null
    }, () => this.getUserUsagesData());
  };

  getUserUsagesData = () => {
    this.getUserUsagesApiCallId = this.clientApiCall(configJSON.getUserUsages + `?type=${this.state.usagesType}&page=${this.state.page}&per_page=${this.state.per_page}&search=${this.state.searchState}`, configJSON.getDataMethod)
  }

  handleClickPopOver = (event: any) => {
    this.setState({
      anchorEl: event?.currentTarget
    })
  };

  handleClosePopOver = () => {
    this.setState({ anchorEl: null })
  }

  handleUserPermissionList = () => {
    this.getUserPermissionApiCallId =  this.clientApiCall(configJSON.getPerData,configJSON.getDataMethod)
  };

  onScrollUnreadNotificationList = () => {
    this.setState({
      isLoading: true
    }, () => this.userNotificationsApiCallId = this.clientApiCall(configJSON.userNotifications + `&page=${this.state.scrollPage}`, configJSON.getDataMethod))
  }

  handleClearAll = () => {
    this.markAllReadApiCallId = this.clientApiCall(configJSON.markAllRead, configJSON.patchMethod)
  }
  // Customizable Area End
}
