import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React, { RefObject } from "react"
import { AppConstant } from "../../../components/src/Constant";

// Customizable Area Start
import Cookies from "js-cookie"
interface CustomTypeData {
  method: string,
  endPoint: string,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  width?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userData: {
    email: string,
    name: string,
    imageUrl: string,
    userType: string
  }
  getUserPermissionData: any,
  getUserPermissionDataSec: any,
  getUserPermissionDataThird: any,
  getUserPermissionDataForth: any,
  navigateStateManage: any,
  usersAccount: any,
  createUserAccount: any,
  newUserAccount: any,
  userFirstName: any,
  userLastName: any,
  loginID: any,
  email: any,
  MT5: any,
  serverName: any,
  password: any,
  description: any,
  tinkerbell: any,
  deepest: any,
  affiliated: any,
  master: any,
  userImage: any,
  userDetailsFile: any,
  giveAccess: any,
  giveAccountAcces: any,
  selectedAccountId: any,
  isEditingUser: any,
  selectedUserAccount: any,
  updateUserAccount: any,
  userAccessId: any,
  userAccountAccess: boolean
  showAlert: boolean,
  alertType: any,
  usersUpdateError: any,
  userSuccessAlert: string,
  userAccountAccessRemove: boolean,
  userUpdatedData: any,
  userDetailss: any,
  userGivenAccess: any,
  userUpdatedAccess: boolean,
  userActivatedData: any,
  accessStatus: any,
  showPassword: boolean,
  loginError: boolean,
  passwordError: boolean,
  lastNameError: boolean,
  firstNameError: boolean,
  emailError: boolean,
  affiliatedError: boolean,
   deepestError: boolean,
    tinkerError : boolean,
  masterError:boolean,
  loginIDError: boolean,
  anchorEl: any,
  showModal: any,
  setDeleteUserAccount: any,
  showOptionID: any,
  mobileOpen: boolean,
  validFile: boolean,
  isLoading: boolean,
  page: any,
  userAccountData: any,
  unreadNotificationRecords: Array<any>,
  anchorElNoti: any,
  scrollPage: number,
  isLoadingNoti: boolean,
  // Customizable Area End
}

interface SS {
  id?: any;
}

export default class AccountAccessController extends BlockComponent<
  Props,
  S,
  SS
> {
  public myFormRef: RefObject<HTMLFormElement>;
  public loadingRef: any;
  public observer: IntersectionObserver | null;
  userNotificationsApiCallId: string = "";
  markAllReadApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.myFormRef = React.createRef();
    this.loadingRef = React.createRef();
    this.observer = null;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      getUserPermissionData: {},
      getUserPermissionDataSec: {},
      getUserPermissionDataThird: {},
      getUserPermissionDataForth: {},
      navigateStateManage: {},
      userData: {
        email: "",
        name: "",
        imageUrl: "",
        userType: "",
      },
      usersAccount: [],
      createUserAccount: false,
      newUserAccount: {},
      userFirstName: '',
      userLastName: "",
      loginID: "",
      email: "",
      MT5: null,
      serverName: "",
      password: "",
      description: "",
      tinkerbell: "",
      deepest: "",
      affiliated: "",
      master: "",
      userImage: null,
      userDetailsFile: false,
      giveAccess: false,
      giveAccountAcces: {},
      selectedAccountId: null,
      isEditingUser: null,
      selectedUserAccount: true,
      updateUserAccount: {},
      userAccessId: "",
      userAccountAccess: false,
      showAlert: false,
      alertType: '',
      usersUpdateError: [],
      userSuccessAlert: '',
      userAccountAccessRemove: false,
      userUpdatedData: '0',
      userDetailss: {},
      userGivenAccess: false,
      userUpdatedAccess: true,
      userActivatedData: false,
      accessStatus: {},
      showPassword: false,
      loginError: false,
      passwordError: false,
      lastNameError: false,
      firstNameError: false,
      emailError: false,
      affiliatedError: false,
   deepestError: false,
    tinkerError : false,
      masterError: false,
      loginIDError: false,
      anchorEl: null,
      showModal: false,
      setDeleteUserAccount: {},
      showOptionID: "",
      mobileOpen: false,
      validFile: true,
      isLoading: true,
      page: 0,
      userAccountData: "",
      unreadNotificationRecords: [],
      anchorElNoti: null,
      scrollPage:1,
      isLoadingNoti: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId != null) {
        switch (true) {
          case apiRequestCallId === this.getApiCallId:
            this.getUserFunct(responseJson)
            this.setApiCallId()
            break;
          case apiRequestCallId === this.getApiAccountCallId:
            this.setApiAccountCallId(responseJson)
            break;
          case apiRequestCallId === this.getApiEditCallId:
            this.setApiEditCallId(responseJson)
            break;
          case apiRequestCallId === this.getApiAccessCallId:
            this.setState({
              giveAccountAcces: responseJson.data,
            });
            break;
          case apiRequestCallId === this.getApiDeleteCallId:
            this.setApiDeleteCallId(responseJson)
            break;
          case apiRequestCallId === this.getUserPermissionApiCallId:
            this.getUserPermission(responseJson)
            break;
          case apiRequestCallId === this.userNotificationsApiCallId:
            this.getUserNotificationNoti(responseJson)
            break;
          case apiRequestCallId === this.markAllReadApiCallId:
            this.clearAllNotificationListNoti(responseJson)
            break;
          default:
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getUserPermission = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      let sidebar: any = responseJson.data;
      const foundItems: any = sidebar.find((item: any) =>
        item.attributes.permission.name == "Add New Users");
      this.setState({ getUserPermissionData: foundItems?.attributes.permission.name })
      const foundItemsSec: any = sidebar.find((item: any) =>
        item.attributes.permission.name == "Edit Users");
      this.setState({ getUserPermissionDataSec: foundItemsSec?.attributes.permission.name })
      const foundItemsThird: any = sidebar.find((item: any) =>
        item.attributes.permission.name == "Delete Users");
      this.setState({ getUserPermissionDataThird: foundItemsThird?.attributes.permission.name })
      const foundItemsForth: any = sidebar.find((item: any) =>
        item.attributes.permission.name == "Edit User Permissions");
      this.setState({ getUserPermissionDataForth: foundItemsForth?.attributes.permission.name })
      this.setState({
        navigateStateManage: this.state.getUserPermissionData !== "Add New Users" &&
          this.state.getUserPermissionDataSec !== "Edit Users" &&
          this.state.getUserPermissionDataThird !== "Delete Users" &&
          this.state.getUserPermissionDataForth !== "Edit User Permissions" && 
          this.state.userData.userType !== "admin"
      })
      localStorage.setItem('sidebarDetails', JSON.stringify(sidebar));
      window.dispatchEvent(new Event('storage'))
    }
    if (this.state.navigateStateManage) {
      this.props.navigation.navigate("LandingPage")
    }
  }
  async componentDidMount() {
    this.observer = new IntersectionObserver(this.handleObserver, {
      root: null,
      rootMargin: '20px',
      threshold: [0, 0.25, 0.5, 0.75, 1]
    });
    if (this.loadingRef.current) {
      this.observer.observe(this.loadingRef.current);
    }
    // Data from API
    this.fetchusersAccount()
    this.handleUserPermissionList()
    // Redirect User Who is not logged in
    this.redirectInvalidAccount()
    //Data from Cookie
    this.fetchUserAccountData();
    let accountId = localStorage.getItem('accountId')
    if (accountId) {
      this.setState({
        userAccountData: JSON.parse(accountId),
        userUpdatedData: JSON.parse(accountId).id
      })
    } else {
      this.setState({
        selectedUserAccount: false
      })
    }
    const storedAccessStatus = localStorage.getItem('activated');
    if (storedAccessStatus) {

      this.setState({ usersAccount: JSON.parse(storedAccessStatus) });
    }

    this.userNotificationsApiCallId = this.clientApiCallAccountNoti(configJSON.userNotifications + `&page=${this.state.scrollPage}`, configJSON.getApiMethod)
  }
  async componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
  handleObserver = (entries: any) => {
    const [entry] = entries;
    if (entry.isIntersecting && this.state.isLoading) {
      this.setState({ isLoading: false })
      this.fetchusersAccount()
    }
  };
  getApiCallId: string = ""
  fetchusersAccount = async () => {
    this.getApiCallId = await this.apiCall({
      method: 'GET',
      endPoint: `account_block/get_all_accounts?&page=${this.state.page + 1}&per_page=10&&sort_by=desc`,
    })
  }
  handleUserPermissionList = async () => {
    this.getUserPermissionApiCallId = await this.apiCall({
      method: 'GET',
      endPoint: `bx_block_roles_permissions/user_permissions`,
    })
  };
  apiCall = async (data: CustomTypeData) => {
    const { method, endPoint } = data;
    const header = {
      "token": localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  accountApiCall = async (data: CustomTypeData) => {
    const { method, endPoint } = data;
    const header = {
      "token": localStorage.getItem("authToken")
    };
    let formdata = new FormData();
    if (this.state.userDetailsFile) {
      formdata.append("data[file]", this.state.userImage);
      formdata.append("data[type]", "loginid_account");
    }
    else {
      formdata.append("data[type]", "loginid_account");
      formdata.append("data[attributes][login_id]", this.state.loginID);
      formdata.append("data[attributes][mt_account_number]", this.state.MT5);
      formdata.append("data[attributes][mt_server_name]", this.state.serverName);
      formdata.append("data[attributes][account_description]", this.state.description);
      formdata.append("data[attributes][tinkerbell_commission]", this.state.tinkerbell);
      formdata.append("data[attributes][deepset_fx_commission]", this.state.deepest);
      formdata.append("data[attributes][affiliate_commission]", this.state.affiliated);
      formdata.append("data[attributes][master_trader]", this.state.master);
      formdata.append("data[attributes][email]", this.state.email);
      formdata.append("data[attributes][first_name]", this.state.userFirstName);
      formdata.append("data[attributes][last_name]", this.state.userLastName);
      formdata.append("data[attributes][password]", this.state.password);
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  redirectInvalidAccount = () => {
    if (!localStorage.getItem("authToken")) {
      this.props.navigation.navigate("EmailAccountLoginSignup")
    }
  }
  fetchUserAccountData = () => {
    const cookieData: any = Cookies.get("userProfile");
    const parsedCookieData = JSON.parse(cookieData).data.attributes
    this.setState({
      userData: {
        email: parsedCookieData.email,
        name: parsedCookieData.first_name + " " + parsedCookieData.last_name,
        imageUrl: parsedCookieData.profile_image,
        userType: parsedCookieData.user_role,
      }
    })
  }
  goToAddAccount = () => {
    this.setState({ userUpdatedData: '0' })
    localStorage.removeItem('accountId')
    this.setState({
      selectedUserAccount: false
    }, this.props.navigation.navigate("AddAccount"))
    
  }
  getApiAccountCallId: any = ""
  createUsersAccount = async (e: any) => {
    e.preventDefault()
    if (this.state.selectedUserAccount) {
      if (this.state.password == "") {
        this.setState({
          usersUpdateError: "Please Enter Password",
          showAlert: true,
          alertType: 'error',
          passwordError: true
        });
        return
      }
      this.updateUserAccount()
    }
    else {
      this.getApiAccountCallId = await this.accountApiCall({
        method: 'POST',
        endPoint: 'account_block/accounts'
      })
    }
  }
  handelInput = (e: any) => {
    const { name, value } = e.target;
    if (name === 'firstName') {
      this.setState({
        userFirstName: value,
      });
    }
    if (name === 'lastName') {
      this.setState({
        userLastName: e.target.value,
      });
    }
    if (name === 'loginID') {
      this.setState({
        loginID: e.target.value,
      });
    }
    if (name === 'email') {
      this.setState({
        email: e.target.value,
      });
    }
    if (name === 'MT5') {
      this.setState({
        MT5: e.target.value,
      });
    }
    if (name === 'server') {
      this.setState({
        serverName: e.target.value,
      });
    }
    if (name === 'password') {
      this.setState({
        password: e.target.value,
      });
    }
    if (name === 'description') {
      this.setState({
        description: e.target.value,
      });
    }
  };
  handelInputTinkerbell = (e: any) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(AppConstant.NUMBER_TYPE, '');
    const parsedValue = parseFloat(inputValue);
      if ((inputValue === '' || AppConstant.DECIMAL_TYPE.test(inputValue)) &&  (inputValue === '' || (parsedValue >= 0 && parsedValue <= 100))) {
        this.setState({
          tinkerbell: e.target.value,
        });
    }
  };
  handelInputDeepest = (e: any) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(AppConstant.NUMBER_TYPE, '');
    const parsedValue = parseFloat(inputValue);
      if ((inputValue === '' || AppConstant.DECIMAL_TYPE.test(inputValue)) &&  (inputValue === '' || (parsedValue >= 0 && parsedValue <= 100))) {
        this.setState({
          deepest: e.target.value,
        });
    }
  };
  handelInputAffiliated = (e:any) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(AppConstant.NUMBER_TYPE, '');
    const parsedValue = parseFloat(inputValue);
      if ((inputValue === '' || AppConstant.DECIMAL_TYPE.test(inputValue)) && (inputValue === '' || (parsedValue >= 0 && parsedValue <= 100))) {
        this.setState({
          affiliated: e.target.value,
        });
    }
  }
  handelInputMaster = (e: any) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(AppConstant.NUMBER_TYPE, '');
    const parsedValue = parseFloat(inputValue);
      if ((inputValue === '' || AppConstant.DECIMAL_TYPE.test(inputValue)) &&  (inputValue === '' || (parsedValue >= 0 && parsedValue <= 100))) {
        this.setState({
          master: e.target.value,
        });
    }
  }

  handelChangeInput = (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];
    this.setState({
      userImage: file,
      userDetailsFile: true
    });
    if (file) {
      if (file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.setState({ validFile: true })
      }
      else {
        this.setState({
          usersUpdateError: "Only CSV and XLS files are allowed.",
          showAlert: true,
          alertType: 'error',
          validFile: false
        })
      }
    }
  }
  resetUserDetails = (event: any) => {
    event.preventDefault();
    this.setState({
      userFirstName: '',
      userLastName: "",
      loginID: "",
      email: "",
      MT5: '',
      serverName: '',
      password: "",
      description: "",
      tinkerbell: "",
      deepest: "",
      affiliated: "",
      master: "",
      userImage: null
    });
    const fileInput: any = document.getElementById('file-upload');
    if (fileInput) {
      fileInput.value = '';
    }
  }
  userGiveAccess = (id: any) => {
    let httpBody: any = {
      "id": id,
      "access": "true"
    };

    this.userAccess(id, httpBody)
  }
  userRemoveAccess = (id: any) => {
    this.setState({ userGivenAccess: true })
    this.setState({ userUpdatedData: !this.state.userUpdatedData })

    let removeAccess: any = {
      "id": id,
      "access": "false"
    };
    this.userAccess(id, removeAccess)
  }
  getApiAccessCallId: string = ""
  userAccess = (id: any, httpBody: any) => {

    this.setState((prevState) => {
      const updatedButtons = prevState.usersAccount.map((account: any) => {
        if (account.id === id) {
          return {
            ...account,
            attributes: {
              ...account.attributes,
              activated: !account.attributes.activated
            }
          };
        }
        return account;
      });

      // Save updated button state to local storage
      localStorage.setItem('activated', JSON.stringify(updatedButtons));

      return {
        usersAccount: updatedButtons,
      };
    });

    this.setState((prevState) => {
      const { accessStatus } = prevState;
      const currentStatus = accessStatus[id];

      return {
        accessStatus: {
          ...accessStatus,
          [id]: !currentStatus,
        },
      };
    });

    this.setState({ userAccessId: id })
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };
    this.setState({ giveAccess: !this.state.giveAccess })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getApiAccessCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/update_access'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.getApiAccessCallId;
  };
  editUserAccount = (accountId: any) => {
    this.setState({
      selectedUserAccount: true
    }, this.props.navigation.navigate("EditAccount"))
    this.setState({ anchorEl: null })
  };
  defaultPermission = (accountId: any) => {
    this.props.navigation.navigate('ServiceSpecificSettingsAdmin')
  };
  getApiEditCallId: string = ""
  updateUserAccount = () => {
    const { usersAccount, isEditingUser } = this.state
    let formDatas = new FormData();
    formDatas.append("data[type]", "loginid_account");
    formDatas.append("data[attributes][login_id]", this.state.loginID);
    formDatas.append("data[attributes][mt_account_number]", this.state.MT5);
    formDatas.append("data[attributes][mt_server_name]", this.state.serverName);
    formDatas.append("data[attributes][account_description]", this.state.description);
    formDatas.append("data[attributes][tinkerbell_commission]", this.state.tinkerbell);
    formDatas.append("data[attributes][deepset_fx_commission]", this.state.deepest);
    formDatas.append("data[attributes][affiliate_commission]", this.state.affiliated);
    formDatas.append("data[attributes][master_trader]", this.state.master);
    formDatas.append("data[attributes][email]", this.state.email);
    formDatas.append("data[attributes][first_name]", this.state.userFirstName);
    formDatas.append("data[attributes][last_name]", this.state.userLastName);
    formDatas.append("data[attributes][password]", this.state.password);
    formDatas.append("data[id]", this.state.userUpdatedData);

    if (this.state.isEditingUser) {
      // Editing an existing User account 
      const updatedAccount = usersAccount.map((account: any) => {
        if (account.id === isEditingUser) {
          return { ...account, formDatas };
        }
        return account;
      });

      this.setState({
        usersAccount: updatedAccount,
        isEditingUser: null,
      });
    }
    const header = {
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getApiEditCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${this.state.userUpdatedData}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formDatas
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.getApiEditCallId;
  }
  handleAlertClose = () => {
    this.setState({ showAlert: false });
  };
  setApiCallId = () => {
    const { userAccountData } = this.state
    if (this.state.userUpdatedData != 0) {
      this.setState({
        userFirstName: userAccountData.attributes.first_name,
        userLastName: userAccountData.attributes.last_name,
        isEditingUser: userAccountData.id,
        loginID: userAccountData.attributes.login_id,
        email: userAccountData.attributes.email,
        MT5: userAccountData.attributes.mt_account_number,
        serverName: userAccountData.attributes.mt_server_name,
        description: userAccountData.attributes.account_description,
        tinkerbell: userAccountData.attributes.tinkerbell_commission,
        deepest: userAccountData.attributes.deepset_fx_commission,
        affiliated: userAccountData.attributes.affiliate_commission,
        master: userAccountData.attributes.master_trader,
        password: userAccountData.attributes.user_password
      })
    }
  }
  setApiAccountCallId = (responseJson: any) => {
    if (this.handleSuccessResponse(responseJson)) {
      return;
    }
    if (this.handleErrorResponse(responseJson)) {
      return;
    }
    if (this.handleEmailError()) {
      return;
    }
     if (this.handlePasswordError()) {
      return;
    }
     if (this.handleLoginIdError()) {
      return;
    }
      this.handleOtherErrors(responseJson);
  };
  handleSuccessResponse = (responseJson: any): boolean => {
    if ((!responseJson.errors && responseJson.data) || (!responseJson.errors && responseJson.success === true)) {
      this.setState({
        newUserAccount: responseJson.data,
        userSuccessAlert: "Account Created Successfully",
        showAlert: true,
        alertType: 'success',
      });
      setTimeout(() => {
        this.props.navigation.navigate("AccountAccess");
      }, 500);

      return true;
    }
    return false;
  };
  handleErrorResponse = (responseJson: any): boolean => {
    if (responseJson && !responseJson.errors && responseJson.success === false){
      this.setState({
        usersUpdateError: "Import correct csv with valid Details",
        showAlert: true,
        alertType: 'error',
      });
      return true;
    }
    return false;
  };
  handleEmailError = () => {
    if (this.state.email === "") {
      this.setState({
        usersUpdateError: "email can't be blank",
        showAlert: true,
        alertType: 'error',
        emailError: true,
      });
      return true;
    }
    return false;
  };
  handlePasswordError = () => {
    if (this.state.password === "") {
      this.setState({
        usersUpdateError: "Please Enter Password",
        showAlert: true,
        alertType: 'error',
        passwordError: true,
      });

      return true;
    }
    return false;
  };
  handleLoginIdError = () => {
    if (this.state.loginID === "") {
      this.setState({
        usersUpdateError: "Login ID can't be Blank",
        showAlert: true,
        alertType: 'error',
        loginIDError: true,
        emailError: false
      });

      return true;
    }
    return false;
  };
  handleOtherErrors = (responseJson: any) => {
    let responseError: any;
    const showErrorMsg = responseJson.errors;

    if (Array.isArray(showErrorMsg) && typeof showErrorMsg[0] === 'string') {
      responseError = showErrorMsg;
    } else if (Array.isArray(showErrorMsg) && typeof showErrorMsg[0] === 'object') {
      for (const error of showErrorMsg) {
        const key = Object.keys(error)[0];
        const value = error[key];
        responseError = `${key}: ${value}`;
      }
    }

    this.showAccountErrors(responseError);
  };

  setApiEditCallId = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({
        newUserAccount: responseJson.data,
        userSuccessAlert: "Account Updated Successfully",
        showAlert: true,
        alertType: 'success',
      });
      setTimeout(() => {
        this.props.navigation.navigate("AccountAccess")
      }, 500)
    }
    else {
      let responseError: any;
      let showEditErrorMsg = responseJson.errors
      if (Array.isArray(showEditErrorMsg) && typeof showEditErrorMsg[0] === 'string') {
        responseError = showEditErrorMsg
      } else if (Array.isArray(showEditErrorMsg) && typeof showEditErrorMsg[0] === 'object') {
        for (const error of showEditErrorMsg) {
          const key = Object.keys(error)[0];
          const value = error[key];
          responseError = `${key}: ${value}`;
        }
      }
      this.showAccountErrors(responseError)
      const { usersUpdateError } = this.state;
      this.setState({ usersUpdateError: responseError })
    }
  }
  togglePasswordVisibility = () => {
    this.setState({ showPassword: !this.state.showPassword })
  };
  errorStyle = (style: any) => {
    if (this.state.loginError || this.state.loginIDError) {
      return style.loginFieldError
    }
    else {
      return style.inputDetails
    }
  }
  emailErrorStyle = (style: any) => {
    if (this.state.loginError || this.state.emailError) {
      return style.loginFieldError
    }
    else {
      return style.inputDetails
    }
  }
  showAccountErrors = (responseErrorMsg: any) => {
    const handleErrors = (errorMessage: any, errorFields: any) => {
      this.setState({
        showAlert: true,
        alertType: 'error',
        usersUpdateError: errorMessage,
        firstNameError: errorFields.includes('first_name'),
        lastNameError: errorFields.includes('last_name'),
        passwordError: errorFields.includes('password'),
        loginIDError: errorFields.includes('login_id'),
        emailError: errorFields.includes('email'),
        tinkerError : errorFields.includes('tinker_com'),
        deepestError : errorFields.includes('deepest_com'),
        affiliatedError : errorFields.includes('affiliated_com'),
        masterError : errorFields.includes('master_trader')
      });
    };
    const errorMapping: any = {
        "login_id: Login Id length should be minimum 1 and maximum 15 characters": ["Login Id length should be minimum 1 and maximum 15 characters", ['login_id']],
      "email: can't be blank": ["Email can't be blank", ['email']],
      "login_id: can't be blank": ["can't be blank", ['login_id']],
      "first_name: can't be blank": ["First Name can't be blank", ['first_name']],
      "last_name: can't be blank": ["Last Name can't be blank", ['last_name']],
      "email: has already been taken": ["Email has already been taken", ['email']],
      "login_id: has already been taken": ["Login ID has already been taken", ['login_id']],
      "Password is invalid": ["Password is Invalid", ['password']],
      "Invalid email format": ["Invalid email format", ['email']],
      "Invalid email": ["Invalid email format", ['email']],
      "tinkerbell_commission: Min 0% | maximum 100%" : ["tinkerbell_commission: Min 0% | maximum 100%", ['tinker_com']],
      "deepset_fx_commission: Min 0% | maximum 100%" : ["deepset_fx_commission: Min 0% | maximum 100%", ['deepest_com']], 
      "affiliate_commission: Min 0% | maximum 100%":["affiliate_commission: Min 0% | maximum 100%", ['affiliated_com']],
      "master_trader: Min 0% | maximum 100%" : ["master_trader: Min 0% | maximum 100%", ['master_trader']],
      "Account with same email or loginID already exist": ["Account with same email or loginID already exist", ['email', 'login_id']]
    };
    const responseError = `${responseErrorMsg}`;
    if (errorMapping[responseError]) {
      const [errorMessage, errorFields] = errorMapping[responseError];
      handleErrors(errorMessage, errorFields);
    }
  }
  handleIconClick = (event: any, accountId: any) => {
    const accountData = JSON.stringify(accountId)
    localStorage.setItem('accountId', accountData)

    this.setState({ anchorEl: event.currentTarget, showOptionID: accountId.id })
  }
  closeModal = () => {
    this.setState({ showModal: false })
  }
  getApiDeleteCallId: string = "";
  getUserPermissionApiCallId: string = "";
  handleDeleteUser = () => {
    this.closeModal();
    const header = {
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getApiDeleteCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${this.state.showOptionID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.getApiDeleteCallId;
  }
  setApiDeleteCallId = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({
        userSuccessAlert: "Account has been Deleted",
        showAlert: true,
        alertType: 'success',
        page: 0,
        usersAccount: []
      });
      this.fetchusersAccount();
    }
  }
  handleDrawerToggle = () => {
    this.setState((prevState: any) => ({
      mobileOpen: !prevState.mobileOpen,
    }));
  };
  getUserFunct = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      const reData: any = responseJson.data.map((user: any) => ({ ...user, checked: false }));
      const items = [...this.state.usersAccount, ...reData];
      this.setState(prevState => ({ ...prevState, usersAccount: items, isLoading: !!responseJson.meta.next_page, page: prevState.page + 1 }))
    }
  }
  handleClickPopOver = (event: any) => {
    this.setState({
      anchorElNoti: event.currentTarget
    })
  };
  handleClosePopOver = () => {
    this.setState({ anchorElNoti: null })
  }
  onScrollUnreadNotificationList = () => {
    this.setState({
      isLoadingNoti: true
    }, () => this.userNotificationsApiCallId = this.clientApiCallAccountNoti(configJSON.userNotifications + `&page=${this.state.scrollPage}`, configJSON.getApiMethod))}

  handleClearAllNoti = () => {
    this.markAllReadApiCallId = this.clientApiCallAccountNoti(
      configJSON.markAllRead, 
      configJSON.patchMethod)
  }
  clearAllNotificationListNoti = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({unreadNotificationRecords: [],isLoadingNoti: false,scrollPage: 1})
    }
  }
  getUserNotificationNoti = (responseJsonData: any) => {
    if (responseJsonData && !responseJsonData.errors) {
      const unreadDatas: any = responseJsonData.data;
      const itemsNoti = [...this.state.unreadNotificationRecords, ...unreadDatas]
      this.setState({unreadNotificationRecords: itemsNoti,scrollPage: responseJsonData.meta.next_page,isLoadingNoti: false})
    } else {
      this.setState({unreadNotificationRecords: [],isLoadingNoti: false,scrollPage: 1})
    }
  }
  clientApiCallAccountNoti = (endPoint: string, method: string) => {
    let authToken = localStorage.getItem("authToken");
    const headers = {
      token: authToken,
      "Content-Type": "application/json",
    };
    const clientReqMessages = new Message(getName(MessageEnum.RestAPIRequestMessage));
    clientReqMessages.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    clientReqMessages.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    clientReqMessages.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    runEngine.sendMessage(
      clientReqMessages.id, 
      clientReqMessages
      );
    return clientReqMessages.messageId
  }
 handleFieldStyle=(webStyle:any, errorState: any)=> {
  if(errorState){
    return webStyle.loginFieldError
  }
  else{
return webStyle.inputDetails
  }
                        
                        
 }
  // Customizable Area End
}
