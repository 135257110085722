import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography, TextField, Tab, Tabs } from "@material-ui/core";
 import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import "../src/assets/Css/Style.css"


const UserSecondNotiHeader = (props:any) =>  {
        const { classes,state,
            goToAll,
            goToRead,
            clickTabNoti,
            goToUnread,
        } = props
        return (

            <TabContext value={state.tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Box onChange={clickTabNoti} aria-label="lab API tabs example"
              
              className="tabsecond"
              style={{justifyContent:"flex-start"}}
              >
                <Tab className={state.allNoti?"activeHeadSpan":""} label="All" value="1" style={{fontSize:"19px",borderBottom: state.allNoti ?"":""}} onClick={goToAll} />
                <Tab className={state.readNoti?"activeHeadSpan":""} label="Read" value="2" style={{fontSize:"19px",borderBottom: state.readNoti ? "":""}} onClick={goToRead} />
                <Tab className={state.unreadNoti?"activeHeadSpan":""} label="Unread" value="3"  style={{fontSize:"19px", borderBottom: state.unreadNoti ? "":""}}  onClick={goToUnread} />
              </Box>
            </Box>
            {/* <TabPanel value="1">Item One</TabPanel>
            <TabPanel value="2">Item Two</TabPanel>
            <TabPanel value="3">Item Three</TabPanel>
            <TabPanel value="4">Item Three</TabPanel> */}
          </TabContext>
            )
}
export default UserSecondNotiHeader;

