Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
const moment = require('moment');
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.getUserUsages = "bx_block_dashboard/dashboards/get_user_usages"
exports.getAvailableCommissions = "bx_block_dashboard/dashboards/available_commissions"
exports.platformUserUsage = "bx_block_dashboard/dashboards/user_usage"
exports.getPerData="bx_block_roles_permissions/user_permissions"
exports.dayCommissions = "bx_block_dashboard/dashboards/day_commissions"
exports.weeklyCommissions = "bx_block_dashboard/dashboards/weekly_commissions"
exports.monthlyCommissions = "bx_block_dashboard/dashboards/monthly_commissions"
exports.userNotifications = "bx_block_notifications/user_notifications?is_read=false&per_page=10"
exports.markAllRead = "bx_block_notifications/mark_all_read"
exports.patchMethod = "PATCH";
exports.getDataMethod = "GET";
exports.TAB_LIST = ["Today", "Yesterday", "1 Week", "1 Month"];
exports.TOP_NAV_LIST = ["1 Day", "1 Week", "1 Month"];
exports.tabToUsageTypeMap = {
  "0": "today",
  "1": "yesterday",
  "2": "week",
  "3": "month",
}
exports.tabToCommissionTypeMap = {
  "0": "day",
  "1": "week",
  "2": "month"
}
exports.navTabToCommissionTitleMap = {
  "0": "1 Day Commissions",
  "1": "1 Week Commissions",
  "2": `1 Month (${moment().format('MMMM')})`,
}
exports.commissionNameType = [
  'Deepset FX Commissions $',
  'Affiliate Commissions $',
  'Master Trader Commissions $',
  'Tinkerbell Commissions $'
];
exports.COLORS = ['#0062FF', '#3DD598', '#FF974A', '#03B4FF'];
// Customizable Area End