import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography, TextField, Tab } from "@material-ui/core";
 import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import TabContext from "@material-ui/lab/TabContext";
import Tabs from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import "../src/assets/Css/Style.css"


const HeaderNotifications = (props:any) =>  {
        const { classes,state,
            goToSentNotifications,
            goToReminders,
            clickTab,
        } = props
        return (

            <TabContext value={state.tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Box onChange={clickTab} aria-label="lab API tabs example"
              style={{justifyContent:"space-around", display:"flex"}}
              >
                <Tab label="Notifications" value="5" style={{fontSize:"21px",borderBottom: state.notiBorder ? "4px solid #000":"", fontWeight:state.notiBorder?"bold":"normal"}} onClick={goToSentNotifications} />
                <Tab label="Reminders" value="6" style={{fontSize:"21px",borderBottom: state.ReminderNotiBorder ? "4px solid #000":"", fontWeight:state.ReminderNotiBorder?"bold":"normal"}} onClick={goToReminders} />
              </Box>
            </Box>
            {/* <TabPanel value="1">Item One</TabPanel>
            <TabPanel value="2">Item Two</TabPanel>
            <TabPanel value="3">Item Three</TabPanel> */}
          </TabContext>
            )
}
export default HeaderNotifications;

