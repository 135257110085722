import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ReactDOM from 'react-dom';
import Cookies from "js-cookie"
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

interface CustomGetTypeData {
  contentType: string,
  method: string,
  endPoint: string,
}
interface CustomPostTypeData {
  contentType: string,
  method: string,
  body: {
    data: CustomTypeBodyData
  },
  endPoint: string,
}
interface CustomTypeBodyData {
  attributes: {
    first_name: string,
    last_name: string,
    phone_number: string,
    email: string,
    gender: string,
    country: string,
    industry: string,
    message: string
  },
  type: string,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  width: any;


  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  imageUploadcheck: boolean;
  alertState: any;
  userData: any,
  pageData: any;
  getUserPermissionData: any,
  uploadImage: boolean;
  editHeading: boolean;
  editBody: boolean;
  showColorList: boolean;
  imagePreview: any;
  revertAlertMsg: any;
  updatealertMsg: any;
  mobileOpen: any;
  unreadNotificationRecords: Array<any>;
  anchorEl: any;
  scrollPage:number;
  isLoading: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getApiCallId: string = "";
  getPermissionApiCallId: string = "";
  userNotificationsApiCallId: string = "";
  markAllReadApiCallId: string = "";
  getUpdateContent: any;
  getRevertPrevious: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      updatealertMsg: "",
      getUserPermissionData: {},
      revertAlertMsg: "",
      imagePreview: "",
      imageUploadcheck: false,
      alertState: false,
      userData: {
        email: "",
        name: "",
        imageUrl: "",
        userType: "",
      },
      pageData: {
        header: "",
        body1: "",
        body2: "",
        image: null,
        bg_colour: "",
        id: ""
      },
      uploadImage: false,
      editHeading: false,
      editBody: false,
      showColorList: false,
      mobileOpen: false,
      unreadNotificationRecords: [],
      anchorEl: null,
      scrollPage:1,
      isLoading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId != null) {
        switch (true) {
          case apiRequestCallId === this.getUpdateContent:
            this.setUpdateContent(responseJson)
            break;
          case apiRequestCallId === this.getRevertPrevious:
            this.setRevertPrevious(responseJson)
            break;
          case apiRequestCallId === this.getPermissionApiCallId:
            this.getUserPermission(responseJson)
            break;

          case responseJson && !responseJson.errors && apiRequestCallId === this.getApiCallId:
            this.getContentMgmt(responseJson)
            break;

          case apiRequestCallId === this.userNotificationsApiCallId:
            this.getUserNotificationAll(responseJson)
            break;
          case apiRequestCallId === this.markAllReadApiCallId:
            this.clearAllNotificationListAll(responseJson)
            break;
          default:
        }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };
  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };
  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
    },
  };
  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };
  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    const Url = window.location.pathname;
    if (Url.includes("/ContentManagement/uploadImage")) {
      this.setState({
        uploadImage: true,
        editHeading: false,
        editBody: false,
      });
    } else if (Url.includes("/ContentManagement/editHeading")) {
      this.setState({
        uploadImage: false,
        editHeading: true,
        editBody: false,
      });
    } else if (Url.includes("/ContentManagement/editBody")) {
      this.setState({
        uploadImage: false,
        editHeading: false,
        editBody: true,
      });
    }
    document.addEventListener('click', this.handleClickOutside, true);

    // Data from API
    this.fetchPageData()
    this.handlePermissionList()
    // Redirect User Who is not logged in
    this.redirectInvalidUser()
    //Data from Cookie
    this.fetchUserData()
    this.userNotificationsApiCallId = this.clientApiCallCmAll(configJSON.userNotifications + `&page=${this.state.scrollPage}`, configJSON.validationApiMethodType)
  }
  async componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }
  setUpdateContent = (responseJson: any) => {
    if (responseJson) {
      const upData = responseJson.data.attributes;
      this.setState({
        pageData: {
          id: upData.id,
          header: upData.header,
          body1: upData.body1,
          body2: upData.body2,
          image: upData.image,
          bg_colour: upData.bg_colour
        },
        updatealertMsg: "Data Updated Successfully!",
        alertState: true
      })
    }

  }

  setRevertPrevious = (responseJson: any) => {
    if (responseJson) {
      const upDataRevert = responseJson.data.attributes;
      this.setState({
        pageData: {
          id: upDataRevert.id,
          header: upDataRevert.previous_header,
          body1: upDataRevert.previous_body1,
          body2: upDataRevert.previous_body2,
          image: upDataRevert.previous_image,
          bg_colour: upDataRevert.previous_bg_colour
        },
        revertAlertMsg: "Data Revert Successfully!",
        alertState: true
      })

    }
  }
  getUserPermission = (responseJson: any) => {
    console.log("this.state.getUserPermissionData", this.state.getUserPermissionData)
    if (responseJson && !responseJson.errors) {
      let sidebar: any = responseJson.data;
      const foundItem: any = sidebar.find((item: any) => item.attributes.permission.name == "Manage Content");
      this.setState({ getUserPermissionData: foundItem?.attributes.permission.name })
      localStorage.setItem('sidebarDetails', JSON.stringify(sidebar));
      window.dispatchEvent(new Event('storage'))
    }
    if (this.state.getUserPermissionData !== "Manage Content") {
      this.props.navigation.navigate("LandingPage")
    }
  }
  getContentMgmt = (responseJson: any) => {
    const data = responseJson.data.attributes;
    this.setState({
      pageData: {
        header: data.header,
        body1: data.body1,
        body2: data.body2,
        image: data.image,
        bg_colour: data.bg_colour,
        id: data.id
      }
    })
  }
  fetchUserData = () => {
    const cookieData: any = Cookies.get("userProfile");
    const parsedCookieData = JSON.parse(cookieData).data.attributes
    this.setState({
      userData: {
        email: parsedCookieData.email,
        name: parsedCookieData.first_name + " " + parsedCookieData.last_name,
        imageUrl: parsedCookieData.profile_image,
        userType: parsedCookieData.user_role,
      }
    })
  }

  getUpdateData = (key: any) => {
    let token = "";
    token = localStorage.getItem("authToken") || ""
    const headers = {
      "token": token,
    };
    let formData = new FormData();
    if (this.state.imageUploadcheck === true) {
      this.setState({ imagePreview: '', imageUploadcheck: false })
      formData.append("image", this.state.pageData.image);
    }
    formData.append("header", this.state.pageData.header);
    formData.append("body1", this.state.pageData.body1);
    formData.append("body2", this.state.pageData.body2);
    formData.append("bg_colour", this.state.pageData.bg_colour);
    formData.append("id", key);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUpdateContent = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contentUpdateEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getRevertData = (key: any) => {
    let token = "";
    token = localStorage.getItem("authToken") || ""
    const headers = {
      "token": token,
    };
    let formDataRe = new FormData();
    if (this.state.imageUploadcheck === true) {
      this.setState({ imagePreview: '', imageUploadcheck: false })
      formDataRe.append("image", this.state.pageData.image);
    }
    formDataRe.append("header", this.state.pageData.header);
    formDataRe.append("body1", this.state.pageData.body1);
    formDataRe.append("body2", this.state.pageData.body2);
    formDataRe.append("bg_colour", this.state.pageData.bg_colour);
    formDataRe.append("id", key);


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRevertPrevious = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.revertEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formDataRe
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.examplePutAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  redirectInvalidUser = () => {
    if (!localStorage.getItem("authToken") || !Cookies.get("userProfile")) {
      this.props.navigation.navigate("EmailAccountLoginBlock")
    }
  }
  goToImageUpload = () => {
    this.props.navigation.navigate("UploadImage");
  }
  goToEditBody = () => {
    this.props.navigation.navigate("EditBody");
  };
  goToEditHeading = () => {
    this.props.navigation.navigate("EditHeading");
  };
  handleSubmit = () => {
    this.getUpdateData(this.state.pageData.id);
  }
  revertPrevious = () => {
    this.getRevertData(this.state.pageData.id);
  }
  getapiCall = async (data: CustomGetTypeData) => {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      "token": localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  fetchPageData = async () => {
    this.getApiCallId = await this.getapiCall({
      contentType: "application/json",
      method: 'GET',
      endPoint: 'bx_block_landingpage/landing_pages'
    })
  }
  handlePermissionList = async () => {
    this.getPermissionApiCallId = await this.getapiCall({
      contentType: "application/json",
      method: 'GET',
      endPoint: `bx_block_roles_permissions/user_permissions`,
    })
  };
  updateShowColorList = () => {
    this.setState({
      showColorList: !this.state.showColorList
    })
  }
  // Form Data Updation
  updateHeadingState = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      pageData: {
        ...this.state.pageData,
        header: event.target.value,
      }
    })
  }
  updateBody1State = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      pageData: {
        ...this.state.pageData,
        body1: event.target.value,
      }
    })
  }
  updateBody2State = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      pageData: {
        ...this.state.pageData,
        body2: event.target.value,
      }
    })
  }
  fileUploadHandler = (e: any) => {
    const objectUrl: any = URL.createObjectURL(e.target.files[0]);
    this.setState({
      imagePreview: objectUrl,
      pageData: {
        ...this.state.pageData,
        image: e.target.files[0],
      },
      imageUploadcheck: true
    })
  }
  updateColorState = (event: React.MouseEvent) => {
    const target = event.target as HTMLLIElement;
    const backgroundStyle = target.style.background;
    this.setState({
      pageData: {
        ...this.state.pageData,
        bg_colour: backgroundStyle
      }
    })
  }
  handleCloseAlert = () => {
    this.setState({
      alertState: false,
      updatealertMsg: "",
      revertAlertMsg: "",
    })
  }
  handleClickOutside = (event: any) => {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target) || this.state.showColorList === true) {
      setTimeout(() => {
        this.setState({
          showColorList: false,
        });
      }, 200);
    }
  }
  handleDrawerToggles = () => {
    this.setState((prevState: any) => ({
      mobileOpen: !prevState.mobileOpen,
    }));
  };

  handleClickPopOver = (event: any) => {
    this.setState({
      anchorEl: event?.currentTarget
    })
  };

  handleClosePopOver = () => {
    this.setState({ anchorEl: null })
  }

  onScrollUnreadNotificationList = () => {
    this.setState({
      isLoading: true
    }, () => this.userNotificationsApiCallId = this.clientApiCallCmAll(configJSON.userNotifications + `&page=${this.state.scrollPage}`, configJSON.validationApiMethodType))
  }

  handleClearAll = () => {
    this.markAllReadApiCallId = this.clientApiCallCmAll(configJSON.markAllRead, configJSON.patchMethod)
  }
  getUserNotificationAll = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      const unreadDataS: any = responseJson.data;
      const items = [...this.state.unreadNotificationRecords, ...unreadDataS]
      this.setState({unreadNotificationRecords: items,scrollPage: responseJson.meta.next_page,isLoading: false})
    } else {
      this.setState({unreadNotificationRecords: [],isLoading: false,scrollPage: 1
      })
    }
  }

  clearAllNotificationListAll = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({unreadNotificationRecords: [],isLoading: false,scrollPage: 1})
    }
  }
  clientApiCallCmAll = (endPoint: string, method: string, httpBody?: any) => {
    let authToken = localStorage.getItem("authToken");
    const headers = {
      token: authToken,
      "Content-Type": "application/json",
    };
    const clientReqMessages = new Message(getName(MessageEnum.RestAPIRequestMessage));
    clientReqMessages.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    clientReqMessages.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    clientReqMessages.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    runEngine.sendMessage(clientReqMessages.id, clientReqMessages);
    return clientReqMessages.messageId
  }

  // Customizable Area End
}
