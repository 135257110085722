Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start

exports.getPermissionList = "bx_block_roles_permissions/permissions";
exports.getAccountType = "account_block/get_user_by_id";
exports.addPermission="bx_block_roles_permissions/user_permissions/update_permissions";
exports.updateUserRole ="account_block/update_user_role";
exports.removePermission="bx_block_roles_permissions/user_permissions/remove_permission";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ServiceSpecificSettingsAdmin";
exports.labelBodyText = "ServiceSpecificSettingsAdmin Body";
exports.updateMethod="PUT";
exports.postMethod="POST";
exports.userNotifications = "bx_block_notifications/user_notifications?is_read=false&per_page=10"
exports.markAllRead = "bx_block_notifications/mark_all_read"
exports.patchMethod = "PATCH";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End