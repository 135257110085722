import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React, { RefObject } from "react";
import { EditorState, ContentState, convertToRaw } from "draft-js";
//@ts-ignore
import Papa from "papaparse";
//@ts-ignore
import draftToHtml from "draftjs-to-html";
//@ts-ignore
import htmlToDraft from "html-to-draftjs";
// Customizable Area Start
import Cookies from "js-cookie";
interface CustomTypeData {
  method: string;
  endPoint: string;
}
import { AppConstant } from "../../../components/src/Constant";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  width: any;
  history?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userData: {
    email: string;
    name: string;
    imageUrl: string;
    userType: string;
  };
  getUserPermissionData: any;
  getUserPermissionDataSec: any;
  getUserPermissionDataThird: any;
  getUserPermissionDataForth: any;
  getUserPermissionDataFifth: any;
  getUserPermissionDataEmail: any;
  getUserPermissionDataDownload: any;
  getUserPermissionDataPrint: any;
  navPermissionManage: any;
  getUserPermissionDataGenerate: any;
  getUserPermissionDataCommission: any;
  getUserPermissionDataEditCommission: any;
  isLoading: boolean;
  page: any;
  usersAccount: any;
  createUserAccount: any;
  newUserAccount: any;
  userFirstName: any;
  userLastName: any;
  loginID: any;
  email: any;
  MT5: any;
  tinkerbell: any;
  deepest: any;
  affiliated: any;
  master: any;
  userImage: any;
  userDetailsFile: any;
  editUserForm: boolean;
  showCommission: boolean;
  selectAll: boolean;
  mobileOpen: boolean;
  showAccountCommission: boolean;
  showMonthlyReport: boolean;
  editorState: any;
  contentsError: any;
  currentDate: any;
  onlyDate: any;
  createNotiData: any;
  sentObj: any;
  notificationError: any;
  searchState: any;
  sortOrder: any;
  sortColumn: any;
  sentJsonData: any;
  checkboxes: any;
  ableReportButton: boolean;
  anchorEl: any;
  selectedMonth: any;
  isEditingUser: any;
  userUpdatedData: any;
  createNote: any;
  showAlert: boolean;
  alertType: any;
  userSuccessAlert: string;
  showAlUserNotes: any;
  getUserNotes: any;
  getUpdatedId: any;
  showUpdateNoteScreen: any;
  showNotesId: any;
  goToUpdateNote: boolean;
  selectedUser: any;
  selecedNoteId: any;
  showUpdatedNote: any;
  searchData: any;
  showMonthName: boolean;
  usersUpdateError: any;
  createReportResponse: any;
  successReport: boolean;
  selectedIds: any;
  months: any;
  selectedMonthName: any;
  selectedYear: any;
  selectedMonthlyTab: boolean;
  selectedYearlyTab: boolean;
  showSelectedYear: any;
  showSelectedUserIds: any;
  showTotalReport: any;
  monthlyPrint: boolean;
  userEditData: any;
  unreadNotificationRecords: Array<any>,
  anchorElNoti: any,
  scrollPage: number,
  isLoadingNoti: boolean,
  // Customizable Area End
}

interface SS {
  id?: any;
}
let currDate: any = new Date().toLocaleDateString("en-GB", {
  month: "2-digit",
  day: "2-digit",
  year: "numeric",
});
const today: any = new Date();
const options: any = {
  timeZone: "Asia/Kolkata",
  hour: "2-digit",
  minute: "2-digit",
};
const currTime = new Intl.DateTimeFormat("en-US", options).format(today);
let currDateTime = currDate + ", " + currTime;

export default class CommissionSplit extends BlockComponent<Props, S, SS> {
  public myFormRef: RefObject<HTMLFormElement>;
  public loadingRef: any;
  public observer: IntersectionObserver | any;
  public loadingCommissionRef: any;
  public observerCommission: IntersectionObserver | any;
  userNotificationsApiCallId: string = "";
  markAllReadApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.myFormRef = React.createRef();
    this.loadingRef = React.createRef();
    this.observer = null;
    this.loadingCommissionRef = React.createRef();
    this.observerCommission = null;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      userData: {
        email: "",
        name: "",
        imageUrl: "",
        userType: "",
      },
      getUserPermissionData: {},
      getUserPermissionDataSec: {},
      getUserPermissionDataThird: {},
      getUserPermissionDataForth: {},
      getUserPermissionDataFifth: {},
      getUserPermissionDataEmail: {},
      getUserPermissionDataDownload: {},
      getUserPermissionDataPrint: {},
      navPermissionManage: {},
      getUserPermissionDataGenerate: {},
      getUserPermissionDataCommission: {},
      getUserPermissionDataEditCommission: {},
      usersAccount: [],
      createUserAccount: false,
      newUserAccount: {},
      userFirstName: "",
      userLastName: "",
      loginID: "",
      email: "",
      MT5: null,
      tinkerbell: "",
      deepest: "",
      affiliated: "",
      master: "",
      userImage: null,
      userDetailsFile: false,
      editUserForm: false,
      showCommission: true,
      selectAll: false,
      mobileOpen: false,
      showAccountCommission: true,
      showMonthlyReport: false,
      isLoading: true,
      editorState: EditorState.createEmpty(),
      contentsError: "",
      currentDate: currDateTime,
      onlyDate: currDate,
      createNotiData: {
        title: "",
        description: "",
        id: "",
      },
      checkboxes: {},
      sentObj: {
        headings: "",
        contents: "",
        created_at: "",
        created_by: "",
        sent_date: "",
        sent_time: "",
        id: "",
        account_ids: [],
      },
      notificationError: "",
      searchState: "",
      sortOrder: "asc",
      sortColumn: "",
      sentJsonData: [],
      ableReportButton: false,
      anchorEl: null,
      selectedMonth: new Date().toLocaleString("default", { month: "long" }),
      isEditingUser: null,
      userUpdatedData: "0",
      createNote: {},
      showAlert: false,
      alertType: "",
      userSuccessAlert: "",
      showAlUserNotes: [],
      getUserNotes: {},
      getUpdatedId: 0,
      showUpdateNoteScreen: false,
      showNotesId: false,
      goToUpdateNote: false,
      page: 0,
      selectedUser: {},
      selecedNoteId: "",
      showUpdatedNote: {},
      searchData: {},
      showMonthName: false,
      usersUpdateError: null,
      createReportResponse: [],
      successReport: false,
      selectedIds: [],
      months: [
        { id: 1, name: "January" },
        { id: 2, name: "February" },
        { id: 3, name: "March" },
        { id: 4, name: "April" },
        { id: 5, name: "May" },
        { id: 6, name: "June" },
        { id: 7, name: "July" },
        { id: 8, name: "August" },
        { id: 9, name: "September" },
        { id: 10, name: "October" },
        { id: 11, name: "November" },
        { id: 12, name: "December" },
      ],
      selectedMonthName: null,
      selectedYear: new Date().getFullYear(),
      selectedMonthlyTab: false,
      selectedYearlyTab: false,
      showSelectedYear: null,
      showSelectedUserIds: [],
      showTotalReport: "",
      monthlyPrint: false,
      userEditData: [],
      unreadNotificationRecords: [],
      anchorElNoti: null,
      scrollPage:1,
      isLoadingNoti: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    const handlers = {
      [this.getApiCallId]: (responseJson: any) => {
        this.getUserFunct(responseJson);
        this.setApiCallId();
      },
      [this.getPermissionApiCallId]: (responseJson: any) =>
        this.getUserPermission(responseJson),
      [this.getApiEditCallId]: (responseJson: any) =>
        this.setApiEditCallId(responseJson),
      [this.createNoteCallId]: (responseJson: any) =>
        this.setCreateNote(responseJson),
      [this.showNoteCallId]: (responseJson: any) =>
        this.getUserComFunct(responseJson),
      [this.updatedNoteCallId]: (responseJson: any) =>
        this.setUpdatedNote(responseJson),
      [this.generateApiCallId]: (responseJson: any) =>
        this.setGenerateReport(responseJson),
      [this.sendEmailCallId]: () =>
        this.setState({
          userSuccessAlert: "Email sent Successfully",
          showAlert: true,
          alertType: "success",
        }),
      [this.userNotificationsApiCallId]: (responseJson: any) =>
        this.getUserNotificationNoti(responseJson),
      [this.markAllReadApiCallId]: (responseJson: any) =>
        this.clearAllNotificationListNoti(responseJson),
    };
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const handler = handlers[apiRequestCallId];
    if (handler) {
      handler(responseJson);
    }
  }
  // Customizable Area Start
  async componentDidMount() {
    this.observer = new IntersectionObserver(this.handleObserver, {
      root: null,
      rootMargin: "20px",
      threshold: [0, 0.25, 0.5, 0.75, 1],
    });
    if (this.loadingRef.current) {
      this.observer.observe(this.loadingRef.current);
    }
    // Data from API
    this.fetchUserAccounts(this.state.searchState);

    // Redirect User Who is not logged in
    this.redirectInvalidAccount();
    //Data from Cookie
    this.fetchUserAccountData();
    this.handlePermissionList();
    this.userNotificationsApiCallId = this.clientApiCallCommisionNoti(configJSON.userNotifications + `&page=${this.state.scrollPage}`, configJSON.validationApiMethodType)

    let createResponseApi: any = localStorage.getItem("createReportResponse");
    let showFinalUserReport = JSON.parse(createResponseApi);
    let selectedMonthName = localStorage.getItem("monthName");
    let selectedYearName = localStorage.getItem("selectedYear");
    let showSelectedIds = localStorage.getItem("showSelectedIds");
    let totalReport = localStorage.getItem("totalReport");
    if (createResponseApi != 0) {
      this.setState({ createReportResponse: showFinalUserReport });
    }
    this.setState({
      showSelectedYear: selectedYearName,
      selectedMonthName: selectedMonthName,
      showSelectedUserIds: showSelectedIds,
      showTotalReport: totalReport,
    });
    let accountId: any = localStorage.getItem("accountId");
    let noteUserId: any = localStorage.getItem("noteUserId");
    if (accountId) {
      this.setState({
        userUpdatedData: JSON.parse(accountId),
      });
    }
    const noteData = JSON.parse(noteUserId);
    if (noteData) {
      this.setState({ getUserNotes: noteData });
    }
    this.showUserNotes(noteData.id);
    const dataString: any = localStorage.getItem("data");
    const dataObject = JSON.parse(dataString);
    const description = dataObject.attributes.description;
    const title = dataObject.attributes.title;
    const userId = dataObject.id;
    this.setState({ selecedNoteId: userId });
    if (dataObject) {
      this.state.sentObj["headings"] = title;
      this.state.sentObj["contents"] = description;
      let selectedTitle = { ...this.state.sentObj };
      this.setState((prevState: any) => ({
        sentObj: { ...prevState.sentObj },
      }));
      this.setState({ goToUpdateNote: true, sentObj: selectedTitle });
      const blocksFromHTML = htmlToDraft(this.state.sentObj.contents);

      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const newEditorState = EditorState.createWithContent(contentState);
      this.setState({
        editorState: newEditorState,
      });
    }
  }
  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ) {
    if (this.loadingCommissionRef.current) {
      this.observerCommission = new IntersectionObserver(
        this.handleObserverCom,
        {
          root: null,
          rootMargin: "20px",
          threshold: [0, 0.25, 0.5, 0.75, 1],
        }
      );
      if (this.loadingCommissionRef.current) {
        this.observerCommission.observe(this.loadingCommissionRef.current);
      }
    }
  }
  async componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
    if (this.observerCommission) {
      this.observerCommission.disconnect();
    }
  }
  handleObserver = (entries: any) => {
    const [entry] = entries;
    if (
      entry.isIntersecting &&
      this.state.isLoading &&
      this.state.usersAccount.length != 0
    ) {
      this.fetchUserAccounts(this.state.searchState);
    }
  };
  handleObserverCom = (entries: any) => {
    const [entry] = entries;
    if (
      entry.isIntersecting &&
      this.state.isLoading &&
      this.state.showAlUserNotes.length != 0
    ) {
      this.showUserNotes(this.state.getUserNotes.id);
    }
  };
  getApiCallId: string = "";
  getPermissionApiCallId: string = "";
  fetchUserAccounts = async (searchvalue: any) => {
    this.getApiCallId = await this.apiCall({
      endPoint: `account_block/get_all_accounts?&page=${
        this.state.page + 1
      }&per_page=10&search=${searchvalue}&sort_by=desc`,
      method: "GET",
    });
  };
  handlePermissionList = async () => {
    this.getPermissionApiCallId = await this.apiCall({
      method: "GET",
      endPoint: `bx_block_roles_permissions/user_permissions`,
    });
  };
  apiCall = async (data: CustomTypeData) => {
    const { method, endPoint } = data;
    const header = {
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  redirectInvalidAccount = () => {
    if (!localStorage.getItem("authToken") || !Cookies.get("userProfile")) {
      this.props.navigation.navigate("EmailAccountLoginSignup");
    }
  };
  fetchUserAccountData = () => {
    const cookieData: any = Cookies.get("userProfile");
    const parsedCookieData = JSON.parse(cookieData).data.attributes;
    this.setState({
      userData: {
        email: parsedCookieData.email,
        name: parsedCookieData.first_name + " " + parsedCookieData.last_name,
        imageUrl: parsedCookieData.profile_image,
        userType: parsedCookieData.user_role,
      },
    });
  };
  resetUserDetails = (event: any) => {
    event.preventDefault();
    this.setState({
      tinkerbell: "",
      deepest: "",
      affiliated: "",
      master: "",
    });
  };

  editUserAccount = (accountId: any) => {
    const accountData = JSON.stringify(accountId);
    localStorage.setItem("accountId", accountData);
    localStorage.setItem("usersData", this.state.usersAccount);
    this.setState({ editUserForm: true });
    this.props.navigation.navigate("CommissionEdit");
    this.setState({ isEditingUser: accountId });
  };

  getApiEditCallId: string = "";
  updateUserAccount = () => {
    const { usersAccount, isEditingUser } = this.state;
    const editCommission= {
      "tinkerbell_commission": this.state.tinkerbell, 
      "deepset_fx_commission": this.state.deepest, 
      "affiliate_commission": this.state.affiliated, 
      "master_trader": this.state.master, 
      "account_id": this.state.userUpdatedData.id
    }
    if (this.state.isEditingUser) {
      // Editing an existing User account
      const updatedAccount = usersAccount.map((account: any) => {
        if (account.id === isEditingUser) {
          return { ...account, editCommission };
        }
        return account;
      });

      this.setState({
        isEditingUser: null,
        usersAccount: updatedAccount,
      });
    }
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getApiEditCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/update_commissions"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(editCommission)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return this.getApiEditCallId;
  };

  handleDrawerToggle = () => {
    this.setState((prevState: any) => ({
      mobileOpen: !prevState.mobileOpen,
    }));
  };
  accountCommissionPage = () => {
    this.setState({ showAccountCommission: true });
    this.setState({ showMonthlyReport: false, usersAccount: [] });

    localStorage.removeItem("createReportResponse");
    this.props.navigation.navigate("CommissionSplit");
  };
  monthlyReportPage = () => {
    this.setState({ showAccountCommission: false });
    this.setState({ showMonthlyReport: true, usersAccount: [] });
    this.props.navigation.navigate("MonthlyReport");
  };
  handleTinkerbell = (e: any) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(AppConstant.NUMBER_TYPE, "");
    const parsedValue = parseFloat(inputValue);
    if (
      (inputValue === "" || AppConstant.DECIMAL_TYPE.test(inputValue)) &&
      (inputValue === '' || (parsedValue >= 0 && parsedValue <= 100))
    ) {
      this.setState({
        tinkerbell: e.target.value,
      });
    }
  };
  handleDeepest = (e: any) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(AppConstant.NUMBER_TYPE, "");
    const parsedValue = parseFloat(inputValue);
    if (
      (inputValue === "" || AppConstant.DECIMAL_TYPE.test(inputValue)) &&
      (inputValue === '' || (parsedValue >= 0 && parsedValue <= 100))
    ) {
      this.setState({
        deepest: e.target.value,
      });
    }
  };
  handleAffiliated = (e: any) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(AppConstant.NUMBER_TYPE, "");
    const parsedValue = parseFloat(inputValue);
    if (
      (inputValue === "" || AppConstant.DECIMAL_TYPE.test(inputValue)) &&
      (inputValue === '' || (parsedValue >= 0 && parsedValue <= 100))
    ) {
      this.setState({
        affiliated: e.target.value,
      });
    }
  };
  handleMaster = (e: any) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(AppConstant.NUMBER_TYPE, "");
    const parsedValue = parseFloat(inputValue);
    if (
      (inputValue === "" || AppConstant.DECIMAL_TYPE.test(inputValue)) &&
      (inputValue === '' || (parsedValue >= 0 && parsedValue <= 100))
    ) {
      this.setState({
        master: e.target.value,
      });
    }
  };
  handleSelectAllToggle = () => {
    if (this.state.selectAll) {
      const updatedUsersAccount = this.state.usersAccount.map((data: any) => {
        return {
          ...data,
          checked: false,
        };
      });
      this.setState((prevState) => {
        return {
          selectAll: !prevState.selectAll,
          usersAccount: updatedUsersAccount,
        };
      });
    } else {
      this.setState((prevState) => {
        const updatedUsersAccount = prevState.usersAccount.map((data: any) => {
          return {
            ...data,
            checked: true,
          };
        });
        return {
          selectAll: !prevState.selectAll,
          usersAccount: updatedUsersAccount,
        };
      });
    }
  };
  handleCheck = (id: string) => {
    this.setState((prev) => {
      const updatedUser = prev.usersAccount.map((user: any) => {
        if (user.id === id) {
          return {
            ...user,
            checked: !user.checked,
          };
        }
        return user;
      });
      return {
        usersAccount: updatedUser,
      };
    });
  };
  handleSelectAll = () => {
    const { selectAll, usersAccount } = this.state;
    if (selectAll) {
      this.setState({ selectAll: false, selectedIds: [] });
    } else {
      const allIds = usersAccount.map((item: any) => item.id);
      this.setState({ selectAll: true, selectedIds: allIds });
    }
  };
  jsonToCsv = (json: any) => {
    const csv = Papa.unparse(json);
    return csv;
  };
  downloadCsv = (data: any, filename: any) => {
    const csv = this.jsonToCsv(data);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
  };
  handleDownload = () => {
    const selectDate = [
      this.state.selectedMonthName,
      this.state.showSelectedYear,
    ];
    const transformedData = this.transformMonthlyData(
      selectDate,
      this.state.createReportResponse
    );
    this.downloadCsv(transformedData, `commission-report-${this.state.selectedMonthName}-
    ${this.state.showSelectedYear}.csv`);
  };
  transformData = (data: any) => {
    const headers = [
      "Login ID",
      "MT5 Account Number",
      "Password",
      "Master Trade",
      "Tinkerbell Commission",
      "Deepset FX Commission",
      "Affiliate Commission",
    ];
    const transformedData = [headers];
    data.forEach((item: any) => {
      const transformedItem = [
        item.attributes.login_id,
        item.attributes.mt_account_number,
        item.attributes.user_password,
        item.attributes.master_trader,
        item.attributes.tinkerbell_commission,
        item.attributes.deepset_fx_commission,
        item.attributes.affiliate_commission,
      ];
      transformedData.push(transformedItem);
    });
    return transformedData;
  };
  transformMonthlyData = (date: any, data: any) => {
    const headers = [
      "Login ID",
      "MT5 Account No",
      "MT",
      "Tink",
      "Deepset",
      "Affiliate",
      "Total"
    ];
    const dateRow = [date];
    const transformedData = [dateRow];
    transformedData.push(headers);
    data.forEach((item: any) => {
      const transformedItem = [
        item.login_id,
        item.mt_account_number,
        `${item.mt} (${item.master_trader}%)`,
        `${item.tink} (${item.tinkerbell_commission}%)`,
        `${item.deepset} (${item.deepset_fx_commission}%)`,
        `${item.affiliate} (${item.affiliate_commission}%)`,
        `${item.total} (100%)`
      ];
      transformedData.push(transformedItem);
    });
    return transformedData;
  };

  downloadSelected = () => {
    const selectedData = this.state.usersAccount.filter(
      (user: any) => user.checked
    );
    const transformedData = this.transformData(selectedData);
    this.downloadCsv(transformedData, "selected_data.csv");
  };

  noteUserAccount = (noteUserId: any) => {
    localStorage.removeItem("accountId");
    const userNoteData = JSON.stringify(noteUserId);
    localStorage.setItem("noteUserId", userNoteData);
    this.props.navigation.navigate("ShowCommissionNotes");
  };
  onEditorStateChange = (editorState: EditorState) => {
    const contentStates = editorState.getCurrentContent();
    if (contentStates.hasText()) {
      const plainText: any = draftToHtml(convertToRaw(contentStates));
      this.setState({
        editorState,
        sentObj: {
          ...this.state.sentObj,
          contents: plainText,
        },
        contentsError: plainText ? "" : "content is required",
      });
    } else {
      this.setState({
        editorState,
        sentObj: {
          ...this.state.sentObj,
          contents: "",
        },
        contentsError: "content is required",
      });
    }
  };
  goToNote = () => {
    localStorage.removeItem("selectedUserTitle");
    localStorage.removeItem("data");
    this.props.navigation.navigate("CommissionNote");
    this.setState({ goToUpdateNote: false });
  };
  searchInput = (e: any) => {
    this.setState({
      searchState: e.target.value,
    });
  };
  searchCallId: string = "";
  searchButton = () => {
    this.setState({ usersAccount: [], page: 0 }, () => {
      this.fetchUserAccounts(this.state.searchState);
    });
  };
  handleSort = (column: any) => {
    const { sortColumn, sortOrder } = this.state;
    if (sortColumn === column) {
      this.setState({ sortOrder: sortOrder === "asc" ? "desc" : "asc" });
    } else {
      this.setState({ sortColumn: column, sortOrder: "asc" });
    }
  };
  customSort = (sortOrder: any, valueA: any, valueB: any) => {
    if (sortOrder === "asc") {
      return valueA && valueB ? valueA.localeCompare(valueB) : 0;
    } else {
      return valueA && valueB ? valueB.localeCompare(valueA) : 0;
    }
  };
  handleCheckboxChange = (checkedId: any) => {
    const { selectedIds } = this.state;

    if (selectedIds.includes(checkedId)) {
      const updatedIds = selectedIds.filter(
        (selectedId: any) => selectedId !== checkedId
      );
      this.setState({ selectedIds: updatedIds });
    } else {
      this.setState((prevState) => ({
        selectedIds: [...prevState.selectedIds, checkedId],
      }));
    }
  };

  isButtonDisabled = () => {
    const { selectedIds } = this.state;
    return !Object.values(selectedIds).some((checked) => checked);
  };
  selectionYear = () => {
    this.setState({ selectedYearlyTab: true });
  };
  selectionMonth = () => {
    this.setState({ selectedYearlyTab: false, selectedMonthlyTab: true });
  };
  handleMonthChange = (event: any) => {
    this.setState({ selectedMonth: event.target.value });
  };
  handleYearChange = (event: any) => {
    this.setState({ selectedYear: event.target.value });
  };

  showMonthlyReport = () => {
    this.GenerateApi();
  };
  generateApiCallId: string = "";
  GenerateApi = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken"),
    };

    const httpBody = {
      month_year: `${this.state.selectedYear}-${this.state.selectedMonth}`,
      account_ids: this.state.selectedIds,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.generateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_cfcommissionsplitcalculation/reports/generate_report"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.generateApiCallId;
  };
  handleOpenPopover = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClosePopover = () => {
    this.setState({ anchorEl: null });
  };

  setApiCallId = () => {
    const { userUpdatedData, getUserNotes } = this.state;
    if (userUpdatedData != 0) {
      this.setState({
        userFirstName: userUpdatedData.attributes?.first_name,
        userLastName: userUpdatedData.attributes?.last_name,
        isEditingUser: userUpdatedData.id,
        loginID: userUpdatedData.attributes?.login_id,
        MT5: userUpdatedData.attributes?.mt_account_number,
        tinkerbell: userUpdatedData.attributes?.tinkerbell_commission,
        deepest: userUpdatedData.attributes?.deepset_fx_commission,
        affiliated: userUpdatedData.attributes?.affiliate_commission,
        master: userUpdatedData.attributes?.master_trader,
      });
    } else if (getUserNotes != 0) {
      this.setState({
        isEditingUser: getUserNotes.id,
        loginID: getUserNotes.attributes?.login_id,
        MT5: getUserNotes.attributes?.mt_account_number,
      });
    }
  };
  setApiEditCallId = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({
        newUserAccount: responseJson.data,
        userSuccessAlert: "Account Updated Successfully",
        showAlert: true,
        alertType: "success",
      });
      setTimeout(() => {
        this.props.navigation.navigate("CommissionSplit");
      }, 500);
    }
    else{
      this.setState({
        usersUpdateError: responseJson.errors[0],
        showAlert: true,
        alertType: "error",
      });
    }
  };
  handleAlertClose = () => {
    this.setState({ showAlert: false });
  };

  // Create User Notes
  createUserNoteBtn = (noteUserId: any) => {
    if (
      this.state.sentObj.headings != "" &&
      this.state.sentObj.contents != ""
    ) {
      if (this.state.goToUpdateNote) {
        this.updatedNote(noteUserId);
      } else {
        this.createUserNote(noteUserId);
      }
    } else {
      if (this.state.sentObj.headings == "") {
        this.setState({ notificationError: "Title is Required" });
      } else if (this.state.sentObj.contents == "") {
        this.setState({ contentsError: "Content is Required" });
      }
    }
  };
  createNoteCallId: string = "";
  createUserNote = (noteUserId: any) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken"),
    };

    const httpBody = {
      notes: {
        title: `${this.state.sentObj.headings}`,
        description: `${this.state.sentObj.contents}`,
        account_id: `${noteUserId}`,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createNoteCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_projectreporting/account_commission_notes"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.createNoteCallId;
  };

  // Response of Create User Notes
  setCreateNote = (responseJson: any) => {
    this.setState({
      createNote: responseJson.data,
      userSuccessAlert: "Note Created Successfully",
      showAlert: true,
      alertType: "success",
    });
    this.showUserNotes(this.state.getUserNotes.id);
    setTimeout(() => {
      this.props.navigation.navigate("ShowCommissionNotes");
    }, 500);
  };
  //  show All Users Note Api
  showNoteCallId: string = "";
  showUserNotes = (showNoteId: any) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showNoteCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_projectreporting/account_commission_notes?account_id=${showNoteId}&sort=asc&page=${
        this.state.page + 1
      }&per_page=10`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return this.showNoteCallId;
  };

  //User Update Note Screen
  updateUserNote = (updatedId: any) => {
    if (updatedId) {
      this.setState({ showUpdateNoteScreen: true });
      this.setState({ getUpdatedId: updatedId });
    }
  };
  userUpdateNoteScreen = (selectedUserDesc: any) => {
    this.setState({ goToUpdateNote: true });
    const dataString = JSON.stringify(selectedUserDesc);
    localStorage.setItem("data", dataString);
    this.props.navigation.navigate("CommissionNote");
  };
  goBackUserNotes = () => {
    this.setState({ showUpdateNoteScreen: false });
    this.props.navigation.navigate("ShowCommissionNotes");
  };
  updatedNoteCallId: string = "";
  updatedNote = (userNoteId: any) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken"),
    };

    const httpBody = {
      notes: {
        title: `${this.state.sentObj.headings}`,
        description: `${this.state.sentObj.contents}`,
        account_id: `${this.state.isEditingUser}`,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updatedNoteCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_projectreporting/account_commission_notes/${userNoteId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.updatedNoteCallId;
  };

  setUpdatedNote = (responseJson: any) => {
    this.setState({
      showUpdatedNote: responseJson,
      userSuccessAlert: "Note Updated Successfully",
      showAlert: true,
      alertType: "success",
    });
    this.showUserNotes(this.state.getUserNotes.id);
    localStorage.removeItem("data");
    setTimeout(() => {
      this.props.navigation.navigate("ShowCommissionNotes");
    }, 500);
  };
  setGenerateReport = (responseJson: any) => {
    this.setState({
      userSuccessAlert: "Monthly Report Created Successfully",
      showAlert: true,
      alertType: "success",
      successReport: true,
    });
    let monthlyData = JSON.stringify(responseJson.data);
    localStorage.setItem("createReportResponse", monthlyData);
    localStorage.setItem("monthName", this.state.selectedMonth);
    localStorage.setItem("selectedYear", this.state.selectedYear);
    localStorage.setItem("showSelectedIds", this.state.selectedIds);
    localStorage.setItem("totalReport", responseJson.total);
    setTimeout(() => {
      this.props.navigation.navigate("FinalMonthlyReport");
    }, 500);
  };
  handleMonthlyPrint = () => {
    this.setState({ monthlyPrint: true }, () => {
      this.handlePrint();
    });
  };
  sendEmailCallId: string = "";
  sendEmailApi = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken"),
    };
    const inputString = this.state.showSelectedUserIds;

    const httpBody = {
      month_year: `${this.state.showSelectedYear}-${this.state.selectedMonthName}`,
      account_ids: inputString.split(",").map(Number),
      sent_to_all: false,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendEmailCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_cfcommissionsplitcalculation/reports/send_email"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return this.sendEmailCallId;
  };
  handleCommisionPrint = () => {
    this.setState({ monthlyPrint: false });
    this.handlePrint();
  };
  handlePrint = () => {
    let filterUser = [] as any;
    this.state.usersAccount.forEach((user: any) => {
      if (user.checked === true) {
        const downloadData = {
          login_id: user.attributes.login_id,
          mt_account_number: user.attributes.mt_account_number,
          master_trader: user.attributes.master_trader,
          tinkerbell_commission: user.attributes.tinkerbell_commission,
          deepset_fx_commission: user.attributes.deepset_fx_commission,
          affiliate_commission: user.attributes.affiliate_commission,
        };
        filterUser.push(downloadData);
      }
    });
    const printWindow = window.open();
    if (printWindow) {
      printWindow.document.write(
        `<html>
        <head>
          <title>Print Table</title>
        </head>
        <body>
          <table>
            <thead>
            ${
              this.state.monthlyPrint
                ? `<tr>
                <th>Login ID</th>
                <th>MT5 Account Number</th>
                <th>Tinkerbell_Commission%</th>
                <th>Deepest_Commission%</th>
                <th>Affiliate_Commission%</th>
                <th>Master_trader%</th>
                <th>total</th>
                <th>mt</th>
                <th>tink</th>
                <th>deepest</th>
                <th>affiliate</th>
              </tr> `
                : `<tr>
                <th>Login ID</th>
                <th>MT5 Account No</th>
                <th>MT%</th>
                <th>Tink%</th>
                <th>Deepest%</th>
                <th>Affiliate%</th>
              </tr>`
            }
            </thead>
            <tbody>
            ${
              this.state.monthlyPrint
                ? this.state.createReportResponse
                    .map(
                      (data: any, i: any) =>
                        `<tr>
                          <td>${data.login_id}</td>
                          <td>${data.mt_account_number}</td>
                          <td>${data.tinkerbell_commission}</td>
                          <td>${data.deepset_fx_commission}</td>
                          <td>${data.affiliate_commission}</td>
                          <td>${data.master_trader}</td>
                          <td>${data.total}</td>
                          <td>${data.mt}</td>
                          <td>${data.tink}</td>
                          <td>${data.deepset}</td>
                          <td>${data.affiliate}</td>
                        </tr>`
                    )
                    .join("")
                : filterUser
                    .map(
                      (data: any, i: any) =>
                        `<tr>
                      <td>${data.login_id}</td>
                      <td>${data.mt_account_number || 0}</td>
                      <td>${data.master_trader || 0}</td>
                      <td>${data.tinkerbell_commission || 0}</td>
                      <td>${data.deepset_fx_commission || 0}</td>
                      <td>${data.affiliate_commission || 0}</td>
                    </tr>`
                    )
                    .join("")
            }
            </tbody>
          </table>
        </body>
      </html>`
      );
      printWindow.document.close();
      printWindow.print();
    }
  };

  getUserFunct = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      const reData: any = responseJson.data.map((user: any) => ({
        ...user,
        checked: false,
      }));
      const items = [...this.state.usersAccount, ...reData];
      this.setState((prevState) => ({
        ...prevState,
        usersAccount: items,
        isLoading: !!responseJson.meta.next_page,
        page: prevState.page + 1,
      }));
    }
  };
  getUserPermission = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      let sidebar: any = responseJson.data;
      const foundItem: any = sidebar.find(
        (item: any) => item.attributes.permission.name == "View User Notes"
      );
      this.setState({
        getUserPermissionData: foundItem?.attributes.permission.name,
      });
      const foundItemSec: any = sidebar.find(
        (item: any) => item.attributes.permission.name == "Add New User Notes"
      );
      this.setState({
        getUserPermissionDataSec: foundItemSec?.attributes.permission.name,
      });
      const foundItemThird: any = sidebar.find(
        (item: any) => item.attributes.permission.name == "Edit User Notes"
      );
      this.setState({
        getUserPermissionDataThird: foundItemThird?.attributes.permission.name,
      });
      const foundItemForth: any = sidebar.find(
        (item: any) =>
          item.attributes.permission.name == "Print Commission Accounts"
      );
      this.setState({
        getUserPermissionDataForth: foundItemForth?.attributes.permission.name,
      });
      const foundItemFifth: any = sidebar.find(
        (item: any) =>
          item.attributes.permission.name == "Download Commission Accounts"
      );
      this.setState({
        getUserPermissionDataFifth: foundItemFifth?.attributes.permission.name,
      });
      const foundItemEmail: any = sidebar.find(
        (item: any) =>
          item.attributes.permission.name == "Email Commissions Reports"
      );
      this.setState({
        getUserPermissionDataEmail: foundItemEmail?.attributes.permission.name,
      });
      const foundItemDownload: any = sidebar.find(
        (item: any) =>
          item.attributes.permission.name == "Download Commissions Reports"
      );
      this.setState({
        getUserPermissionDataDownload:
          foundItemDownload?.attributes.permission.name,
      });
      const foundItemPrint: any = sidebar.find(
        (item: any) =>
          item.attributes.permission.name == "Print Commission Reports"
      );
      this.setState({
        getUserPermissionDataPrint: foundItemPrint?.attributes.permission.name,
      });
      const foundItemGenerate: any = sidebar.find(
        (item: any) =>
          item.attributes.permission.name == "Generate Commissions Reports"
      );
      this.setState({
        getUserPermissionDataGenerate:
          foundItemGenerate?.attributes.permission.name,
      });
      const foundItemCommissions: any = sidebar.find(
        (item: any) => item.attributes.permission.name == "View Commissions"
      );
      this.setState({
        getUserPermissionDataCommission:
          foundItemCommissions?.attributes.permission.name,
      });
      const foundItemEditCommition: any = sidebar.find(
        (item: any) => item.attributes.permission.name == "Edit Commissions"
      );
      this.setState({
        getUserPermissionDataEditCommission:
          foundItemEditCommition?.attributes.permission.name,
      });
      this.setState({
        navPermissionManage:
          !this.state.getUserPermissionData &&
          !this.state.getUserPermissionDataSec &&
          !this.state.getUserPermissionDataThird &&
          !this.state.getUserPermissionDataForth &&
          !this.state.getUserPermissionDataFifth &&
          !this.state.getUserPermissionDataEmail &&
          !this.state.getUserPermissionDataDownload &&
          !this.state.getUserPermissionDataPrint &&
          !this.state.getUserPermissionDataGenerate &&
          !this.state.getUserPermissionDataCommission &&
          !this.state.getUserPermissionDataEditCommission &&
           this.state.userData.userType !== "admin",
      });
      localStorage.setItem("sidebarDetails", JSON.stringify(sidebar));
      window.dispatchEvent(new Event("storage"));
    }
    if (this.state.navPermissionManage) {
      this.props.navigation.navigate("LandingPage");
    }
  };
  // Response of Get All Users Notes
  getUserComFunct = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      const reData: any = responseJson.data;
      const items = [...this.state.showAlUserNotes, ...reData];
      this.setState((prevState) => ({
        ...prevState,
        showAlUserNotes: items,
        isLoading: !!responseJson.meta.next_page,
      }));
    }
  };

  handleClickPopOverNoti = (event: any) => {
    this.setState({
      anchorElNoti: event.currentTarget
    })
  };

  handleClosePopOverNoti = () => {
    this.setState({ anchorElNoti: null })
  }

  onScrollUnreadNotificationList = () => {
    this.setState({
      isLoadingNoti: true
    }, () => this.userNotificationsApiCallId = this.clientApiCallCommisionNoti(configJSON.userNotifications + `&page=${this.state.scrollPage}`, configJSON.validationApiMethodType))
  }

  handleClearAll = () => {
    this.markAllReadApiCallId = this.clientApiCallCommisionNoti(configJSON.markAllRead, configJSON.patchMethod)
  }

  clientApiCallCommisionNoti = (endPoint: string, method: string, httpBody?: any) => {
    let authToken = localStorage.getItem("authToken");
    const header = {
      token: authToken,
      "Content-Type": "application/json",
    };
    const clientReqMessage = new Message( getName(MessageEnum.RestAPIRequestMessage));
    clientReqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    clientReqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    clientReqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    runEngine.sendMessage(clientReqMessage.id,
       clientReqMessage);
    return clientReqMessage.messageId
  }

  getUserNotificationNoti = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      const unreadData: any = responseJson.data;
      const items = [
        ...this.state.unreadNotificationRecords,
         ...unreadData
        ]
      this.setState({unreadNotificationRecords: items,scrollPage: responseJson.meta.next_page,isLoadingNoti: false})
    } else {
      this.setState({unreadNotificationRecords: [],isLoadingNoti: false,scrollPage: 1})
    }
  }

  clearAllNotificationListNoti = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({unreadNotificationRecords: [],isLoadingNoti: false,scrollPage: 1})
    }
  }
  // Customizable Area End
}
