import moment from 'moment'

export function formatMinutesToHoursAndMinutes(minutes: number) {

  const duration = moment.duration(minutes, 'minutes');
  const years = duration.years();
  const days = duration.days();
  const hours = duration.hours();
  const remainingMinutes = duration.minutes();

  const formattedDuration = [];

  if (years > 0) {
    formattedDuration.push(`${years}y`);
  }

  if (days > 0) {  
    formattedDuration.push(`${days}d`);
  }

  if (hours > 0) {
    formattedDuration.push(`${hours}h`);
  }

  if (remainingMinutes > 0) {
    formattedDuration.push(`${remainingMinutes} mins`);
  }

  if (formattedDuration.length === 0) {
    return '0 min';
  }

  return formattedDuration.join(' ');
}

export function convertToUTC(date:string, time:string) {
  const dateTimeString = `${date} ${time}`;
  const localDateTime = moment(dateTimeString, 'YYYY-MM-DD HH:mm:ss');
  const utcDateTime = localDateTime.utc();

  return {
    utcDate: utcDateTime.format('YYYY-MM-DD'),
    utcTime: utcDateTime.format('HH:mm')
  };
}

export function convertToLocal(utcDate:string, utcTime:string) {
  const dateTimeString = `${utcDate} ${utcTime}`;
  const utcDateTime = moment.utc(dateTimeString, 'YYYY-MM-DD HH:mm:ss');
  const localDateTime = utcDateTime.local();

  return {
    localDate: localDateTime.format('YYYY-MM-DD'),
    localTime: localDateTime.format('HH:mm')
  };
}
interface DataItem {
  attributes: { [key: string]: any };
}

export function sortData(data: DataItem[], sortColumn: string, sortOrder: 'asc' | 'desc') {
  return data.sort((aaa, bbb) => {
    const valueA1 = getNestedValue(aaa.attributes, sortColumn);
    const valueB1 = getNestedValue(bbb.attributes, sortColumn);

    if (sortOrder === 'asc') {
      return valueA1 && valueB1 ? valueA1.localeCompare(valueB1) : 0;
    } else {
      return valueA1 && valueB1 ? valueB1.localeCompare(valueA1) : 0;
    }
  });
}

function getNestedValue(obj: { [key: string]: any }, path: string) {
  return path.split('.').reduce((acc, key) => (acc ? acc[key] : undefined), obj);
}