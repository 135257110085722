import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import Cookies from "js-cookie"
interface CustomTypeData {
  contentType: string,
  method: string,
  endPoint: string,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  width?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userData: {
    email: string,
    name: string,
    imageUrl: any,
    userType: string
  }
  pageData: {
    header: string,
    body1: string,
    body2: string,
    bg_colour: string,
    image: string
  }
  mobileOpen: any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      userData: {
        email: "",
        name: "",
        imageUrl: "",
        userType: "",
      },
      pageData: {
        header: "",
        body1: "",
        body2: "",
        bg_colour: "",
        image: "",
      },
      mobileOpen: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && apiRequestCallId === this.getApiCallId) {
        const data = responseJson.data.attributes;
        this.setState({
          pageData: {
            header: data.header,
            body1: data.body1,
            body2: data.body2,
            image: data.image,
            bg_colour: data.bg_colour
          }
        })
      }
      
      if (apiRequestCallId === this.getPermissionApiCallId) {
        this.getUserPermission(responseJson)
     
      }
    
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getUserPermission = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      let sidebar: any = responseJson.data;
      localStorage.setItem('sidebarDetails', JSON.stringify(sidebar));
      window.dispatchEvent(new Event('storage'))
    }
  }
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  async componentDidMount() {

    // Data from API
    this.fetchPageData()
    this.handlePermissionList()
    // Redirect User Who is not logged in
    this.redirectInvalidUser()
    //Data from Cookie
    this.fetchUserData()
  }
  getApiCallId: string = "";
  getPermissionApiCallId: string = "";
  fetchPageData = async () => {
    this.getApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'GET',
      endPoint: 'bx_block_landingpage/landing_pages'
    })
  }
  handlePermissionList = async () => {
    this.getPermissionApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'GET',
      endPoint: 'bx_block_roles_permissions/user_permissions',
    })
  };
  apiCall = async (data: CustomTypeData) => {
    const { contentType, method, endPoint } = data;
    const header = {
      "Content-Type": contentType,
      "token": localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  redirectInvalidUser = () => {
    if (!localStorage.getItem("authToken") || !Cookies.get("userProfile")) {
      this.props.navigation.navigate("EmailAccountLoginSignup")
    }
  }
  fetchUserData = () => {
    const profileImage = localStorage.getItem("profileImage")
    const cookieData: any = Cookies.get("userProfile");
    const parsedCookieData = JSON.parse(cookieData).data.attributes
    this.setState({
      userData: {
        email: parsedCookieData.email,
        name: parsedCookieData.first_name + " " + parsedCookieData.last_name,
        imageUrl: profileImage,
        userType: parsedCookieData.user_role,
      }
    })
  }
  handleDrawerToggles = () => {
    this.setState((prevState: any) => ({
      mobileOpen: !prevState.mobileOpen,
    }));
  };
  // Customizable Area End
}
