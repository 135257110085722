import React from 'react';
import { formatMinutesToHoursAndMinutes } from './utils/utils';
import { defaultUser } from './assets'

const CustomTooltip = (props: any) => {

    const { data } = props
    if (!data) {
        return null;
    }
    const { x, y, payload } = data;
    return (
        <>
            <div className="customTooltip" style={{
                position: "absolute",
                top: 0,
                left: x + 6,
                transform: 'translateX(-50%)',
            }}>
                <span><img src={payload.profile_image || defaultUser} width={35} height={35} /></span>
                <div>
                    <span style={{ fontSize: "12px", fontWeight: 600, maxWidth: '200px' }}>{payload.first_name + ' ' + payload.last_name}</span>
                    <span style={{ fontSize: "14px", fontWeight: 400, maxWidth: '200px' }}>{formatMinutesToHoursAndMinutes(payload.duration.duration)}</span>
                    <div style={{
                        position: 'absolute',
                        width: '0',
                        height: '0',
                        borderLeft: '14px solid transparent',
                        borderRight: '14px solid transparent',
                        borderTop: '14px solid white',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        top: '100%',
                    }}></div>
                    <div style={{
                        position: 'absolute',
                        width: '2px',
                        height: y,
                        backgroundColor: '#0062FF',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        top: '125%',
                        zIndex: -1
                    }}>
                        <div style={{
                            width: '14px',
                            height: '14px',
                            backgroundColor: '#0062FF',
                            borderRadius: '50%',
                            position: 'absolute',
                            top: '0px',
                            left: '1px',
                            transform: 'translateX(-50%)',
                        }}></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CustomTooltip