import React from "react";

// Customizable Area Start
import {
  TextField,
  Box,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TableSortLabel,
  Select,
  MenuItem,
  withWidth,
  Drawer,
  AppBar,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import SideBar from "../../../components/src/SideBar";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Link } from 'react-router-dom';
// Customizable Area End

import NotificationsTdController, {
  Props,
} from "./NotificationsTdController";
import NotificationsHeader from "../../../components/src/NotificationsHeader";
import ThirdReminderHeader from "../../../components/src/ThirdReminderHeader";
import moment from 'moment';
import ArrowDropDownIcon from '@material-ui/icons/ExpandMore';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
const configJSON = require("./config");
// Customizable Area Start
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins'
  },
});

const MenuPropsSent = {
  anchorOrigin: {
    vertical: 'bottom' as number | "bottom" | "top" | "center",
    horizontal: 'left' as number | "center" | "left" | "right",
  },
  transformOrigin: {
    vertical: 'top' as number | "bottom" | "top" | "center",
    horizontal: 'left' as number | "center" | "left" | "right",
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      width: 220,
      borderRadius: "20px",
    },
  },
};
export class RemindersNotifications extends NotificationsTdController {
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start

    const { sortOrder, sortColumn } = this.state;
    const dropdownOptions = configJSON.dropdownOptions;
    const { width } = this.props;
    const isMobileSent = /s/.test(width);

    return (
      <ThemeProvider theme={theme}>
        <Box style={stylesSentReminder.fullscreenSent}>
          <div style={{zIndex:1200}}>
            {isMobileSent ?
              <div
                style={stylesSentReminder.sidebarIcon}
                className="sidebarIcon"
              >
                <IconButton
                  style={{ color: 'white' }}
                  onClick={this.handleDrawerToggles}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  ModalProps={{
                    keepMounted: true,
                  }}
                  open={this.state.mobileOpen}
                  onClose={this.handleDrawerToggles}
                >
                  {<SideBar
                    style={stylesSentReminder.sidebar}
                    userData={this.state.userData}
                    navigate={this.props.navigation.navigate}
                    activeMenuItem={"Notifications & Reminders"}
                  />}
                </Drawer>
              </div>

              :
              <div style={stylesSentReminder.sidebar} className="sidebarResponse">
                <Box style={stylesSentReminder.sidebarParentCreateRe}>
                  <SideBar
                    navigate={this.props.navigation.navigate}
                    activeMenuItem={"Notifications & Reminders"}
                    userData={this.state.userData}
                  />
                </Box>
              </div>}
          </div>
          <Box className="mainLftContainer" fontFamily={"Poppins"}>
            <div style={stylesSentReminder.topNavBarSent}>
              <AppBar position="fixed" style={stylesSentReminder.pageHeader} className="accessHeader">
                {/* <NotificationsActiveOutlinedIcon data-test-id="navigateBtn" onClick={() => this.props.navigation.navigate("Notifications")} style={stylesSentReminder.notificationIcon} /> */}
              </AppBar>
              <div style={stylesSentReminder.topNavBarCreateRe}>
                <div style={stylesSentReminder.pageTitleSent}>
                  <NotificationsHeader state={this.state}
                    goToSentNotifications={this.goToSentNotifications}
                    data-test-id="goToReminders"
                    goToReminders={this.goToReminders}
                    clickTab={this.clickTab}
                  />
                </div>

                <Box m={{ xs: "8px", md: "30px" }} sx={stylesSentReminder.scroll}>
                  <Box fontFamily={"Poppins"} sx={stylesSentReminder.reminderContainer}>
                    <ThirdReminderHeader state={this.state}
                      goToReminders={this.goToReminders}
                      goToRemindersCreate={this.goToRemindersCreate}
                      goToRemindersScheduled={this.goToRemindersScheduled}
                      clickTabNoti={this.clickTabRiminder}
                    />
                    <Box style={{ display: "flex", alignItems: 'center', margin: "0" }} className="flexWrapper">
                      <div style={{ marginTop: "17px" }}>
                        <Link to={`/Remainder/Create?newCompose=true`} style={{ textDecoration: 'none' }}><button style={{ ...stylesSentReminder.activeButtonSent, background: "#000", paddingLeft: " 1rem", paddingRight: "1rem", border: "1px solid #0c143e", marginRight: "10px" }} type="button">Compose New</button></Link>
                      </div>
                      <Box className="titleSearch" style={{marginRight: "4px"}}>
                        <TextField
                          className="searchInput"
                          label="Search by Title"
                          value={this.state.searchState}
                          onChange={this.reminderSearchInput}
                          data-test-id="textBtn"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment variant="filled" position="end">
                                <IconButton data-test-id="iconRem" onClick={() => {
                                  this.setState(prevState => ({
                                    ...prevState,
                                    searchTerm:prevState.searchState,
                                    sentRemData: {
                                      items: [],
                                      page: 0,
                                      hasMore: true
                                    },
                                    searchStatus:false
                                  }),
                                  ()=> this.getRemindersData("sent"))
                                }}>
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box style={{ maxHeight: "70vh", marginBottom: "0px !important" }} className="scrollFixed" fontFamily={"Poppins"}>
                    <Table className="RemTable" style={{ tableLayout: "auto" }}>
                      <TableHead>
                        <TableRow style={{ background: "#DCDCDC" }} className="tableRow">
                          <TableCell style={{ paddingRight: "2px" }} align="center">
                              Title
                          </TableCell>
                          <TableCell style={{ paddingRight: "2px" }} align="center">
                            <TableSortLabel
                              data-test-id="dateCreatedBtn"
                              onClick={() => this.handleSortByDate()}
                              active={sortColumn === 'created_at'}
                              direction={sortOrder}
                            >
                              Date Created
                            </TableSortLabel>
                          </TableCell>
                          <TableCell style={{ paddingRight: "2px" }} align="center">
                              Created by
                          </TableCell>
                          <TableCell style={{ paddingRight: "2px" }} align="center">
                              Sent Date
                          </TableCell>
                          <TableCell style={{ paddingRight: "2px" }} align="center" className="lastthree ml-6">
                              Frequency
                          </TableCell>
                          <TableCell align="center">
                            Notification sent
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.sentRemData.items.length > 0 &&
                          this.state.sentRemData.items
                            .map((row: any) => (
                              <TableRow key={row.id} className="tableRow" style={{alignItems:"center"}}>
                                <TableCell align="center" style={{ fontFamily: "Poppins" }}>{row.attributes.headings}</TableCell>
                                <TableCell align="center" style={{ fontFamily: "Poppins" }}>{moment(row.attributes.created_at).format('D-M-YYYY, h.mm A')}</TableCell>
                                <TableCell align="center" style={{ fontFamily: "Poppins" }}><div>{row.attributes.created_by.first_name + " " + row.attributes.created_by.last_name}</div> </TableCell>
                                <TableCell align="center" style={{ fontFamily: "Poppins" }}>
                                  {moment.utc(row.attributes.sent_date + ' ' + row.attributes.sent_time, 'YYYY-MM-DD hh:mm').local().format('D-M-YYYY, h.mm A')}
                                </TableCell>
                                <TableCell align="center" style={{ fontFamily: "Poppins" }} className="SelectOption">
                                  <Select
                                    value={row.attributes.frequency}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    displayEmpty
                                    MenuProps={MenuPropsSent}
                                    disableUnderline
                                    IconComponent={ArrowDropDownIcon}
                                  >
                                    {dropdownOptions.map((option: any) => (
                                      <MenuItem style={stylesSentReminder.dropdownList} className="optionTextCss" key={option} value={option}>
                                        {
                                          <RadioGroup
                                            value={row.attributes.frequency}
                                            row
                                          >
                                            <FormControlLabel data-test-id="forRadioRemChange" style={{ textTransform: "capitalize" }} labelPlacement="start" name="rrrrr" value={option} control={<Radio onChange={event => this.handleChangeFrequency(row.id, event)} />} label={option} />
                                          </RadioGroup>
                                        }
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </TableCell>
                                <TableCell align="center">{row.attributes.repitation}</TableCell>
                              </TableRow>
                            ))}
                        {(this.state.sentRemData.items.length === 0 && !this.state.sentRemData.hasMore) &&
                          <TableRow style={{ textAlign: "center" }}>
                            <TableCell align="center" style={{ textAlign: "center" }} colSpan={4}>No data found</TableCell>
                          </TableRow>
                        }
                        <TableRow ref={this.loadingRef} style={{ textAlign: "center" }}>
                        {(this.state.sentRemData.hasMore) && 
                          <TableCell align="center" style={{ textAlign: "center" }} colSpan={4}>Loading...</TableCell>
                       } </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Box>
              </div>
            </div>
          </Box>
        </Box>
        <Snackbar open={this.state.alertState} onClose={this.handleCreateCloseAlert} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <MuiAlert elevation={6} variant="filled" data-test-id="alertComponent" severity={this.state.alertType}>
            {this.state.createAlertMsg}
          </MuiAlert>
        </Snackbar>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
let stylesSentReminder: any = {
  fullscreenSent: {
    top: 0,
    bottom: 0,
    height: "100vh !important",
    fontFamily: "Poppins, sans-serif",
    display: 'flex',
    boxSizing: 'border-box'
  },
  topNavBarSent: {
    width: "100%",
    marginTop: '74px',
  },
  sidebarParentSent: {
    width: '24%',
    height: '100vh',
  },
  sidebar: {
    fontFamily: 'Poppins',
    position: "fixed",
    top: "0",
    width: "24vw",
    marginRight: "-100px",
    zIndex: "9",
    height: "100%",
  },
  scroll: {
    height: 'calc(100vh - 190px)',
    overflow: 'auto',
  },
  bellIconSent: {
    textAlign: "right",
    width: "100%"
  },
  pageTitleSent: {
    borderTop: "1px solid rgba(128, 128, 128, 0.43)",
    boxShadow: "rgb(34, 34, 34) 0px 4px 6px -6px",
  },

  activeButtonSent: {
    fontSize: "17px",
    color: "#fff",
    border: "none",
    borderRadius: "30px",
    padding: "0.4rem 1rem",
    marginRight: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },

  btnCover: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '7px',
    marginTop: '15px',
  },

  accesspageLft: {
    width: '100%',
    marginLeft: '24%',
  },

  sidebarIcon: { 
    position: "fixed", 
    top: 10, 
    left: 12, 
    background: "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)", },

  notificationIcon: {
    fontFamily: 'Poppins',
    position: "fixed",
    right: "30px",
    top: "20px",
    color: "black",
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
  },

  topNavBarCreateRe: {
    width: "100%",
    marginTop: '74px',
  }
  ,
  pageHeader: {
    fontFamily: 'Poppins',
    backgroundColor: "white",
    marginLeft: "22vw",
    width: "75vw",
    height: "75px",
    borderBottom: "1px solid rgba(128, 128, 128, 0.43)",
    boxShadow: "none",
    borderLeft: "none",
  },
  reminderContainer: {
    display: 'Flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: '15px',
    alignItems: "end"
  },
  dropdownList: {
    paddingTop: "15px",
    paddingBottom: "15px"
  }
}
// Customizable Area End
export default (withWidth()(RemindersNotifications));
