import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography, TextField, Tab, Tabs } from "@material-ui/core";
 import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import "../src/assets/Css/Style.css"


const SecondNotiHeader = (props:any) =>  {
        const { classes,state,
            goToSentNotifications,
            goToCreate,
            clickTabNoti,
            goToDraft,
            goToScheduled
        } = props
        return (

            <TabContext value={state.tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Box onChange={clickTabNoti} aria-label="lab API tabs example"
              
              className="tabsecond"
              style={{justifyContent:"flex-start"}}
              >
                <Tab className={state.sentNoti?"activeHeadSpan":""} label="Sent" value="1" style={{fontFamily: 'Poppins !important',fontSize:"19px",borderBottom: state.sentNoti ?"":""}} onClick={goToSentNotifications} />
                <Tab className={state.createNoti?"activeHeadSpan":""} label="Create" value="2" style={{fontFamily: 'Poppins !important',fontSize:"19px",borderBottom: state.createNoti ? "":""}} onClick={goToCreate} />
                <Tab className={state.scheduledNoti?"activeHeadSpan":""} label="Scheduled" value="3"  style={{fontFamily: 'Poppins !important',fontSize:"19px", borderBottom: state.scheduledNoti ? "":""}}  onClick={goToScheduled} />
                <Tab className={state.draftNoti?"activeHeadSpan":""} label="Draft" value="4" style={{fontFamily: 'Poppins !important',fontSize:"19px",borderBottom: state.draftNoti ? "":""}}  onClick={goToDraft} />
              </Box>
            </Box>
            {/* <TabPanel value="1">Item One</TabPanel>
            <TabPanel value="2">Item Two</TabPanel>
            <TabPanel value="3">Item Three</TabPanel>
            <TabPanel value="4">Item Three</TabPanel> */}
          </TabContext>
            )
}
export default SecondNotiHeader;

