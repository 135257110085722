import React from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import Cookies from "js-cookie"
import { convertFromRaw, ContentState, EditorState, convertToRaw } from 'draft-js';
// @ts-ignore
import htmlToDraft from 'html-to-draftjs'
import { isEmpty } from "../../../framework/src/Utilities";
import { convertToLocal, convertToUTC } from "../../../components/src/utils/utils";
// @ts-ignore
import draftToHtml from 'draftjs-to-html';

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  width?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  draftBoolean: boolean;
  txtInputValue: string;
  showModal: any;
  currentDate: any;
  onlyDate: any;
  scheduledData: any;
  scheduledReminderData: any;
  draftData: any;
  txtSavedValue: string;
  enableField: boolean;
  userData: any,
  sentNoti: boolean;
  createNoti: boolean;
  scheduledNoti: boolean;
  draftNoti: boolean;
  notiBorder: boolean;
  ReminderNotiBorder: boolean;
  sentReminder: boolean;
  createReminder: boolean;
  scheduledReminder: boolean;
  showColorList: boolean;
  tabValue: any;
  sortOrder: any;
  sortColumn: any;
  sortBy: string;
  createNotiData: any;
  alertType: any;
  editorState: any;
  editorScheduledState: any;
  editorReminderState: any;
  editorReminderScheduledState: any;
  personName: any;
  personReminderName: any;
  timeSet: any;
  sentRemData: {
    items: Array<any>,
    hasMore: boolean,
    page: number
  };
  sentJsonData: {
    items: Array<any>,
    hasMore: boolean,
    page: number
  };
  schRemData: {
    items: Array<any>,
    hasMore: boolean,
    page: number
  };
  draftJsonData: {
    items: Array<any>,
    hasMore: boolean,
    page: number
  };
  scheduledJsonData: {
    items: Array<any>,
    hasMore: boolean,
    page: number
  };
  sentObj: any;
  sentReminderObj: any;
  userJsonData: {
    items: Array<any>,
    isLoading: boolean,
    page: number
  };
  alertState: any;
  createAlertMsg: any;
  errorCreateAlertMsg: any;
  showDeleteModelId: any;
  contentsError: any;
  notificationError: any;
  dateError: any,
  searchState: any;
  searchTerm: string,
  draftUpdatedData: any;
  navTabRedirect: any;
  mobileOpen: boolean;
  formattedTime: string;
  personReminderNameErrors: string;
  personNameErrors: string,
  showComposeTitle: boolean,
  navDraft: boolean,
  searchStatus: boolean
  // Customizable Area End
}
type RawDraftContentBlock = {
  text: any;
  key: string;
  type: string;
  entityRanges: never[];
  depth: number;
  inlineStyleRanges: {
    offset: number;
    length: number;
    style: string;
  }[];
};
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
let currDate: any = new Date().toLocaleDateString('en-GB', {
  month: '2-digit', day: '2-digit', year: 'numeric'
})
let today: any = Date.now();
let currTime: any = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit' }).format(today);
let currDateTime = (currDate + ", " + currTime);
// Customizable Area End
export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area StartscheduledDataApiId
  getApiCallId: string = "";
  GetnotificationApiCallId: string = "";
  GetDraftNotificationApiCallId: string = "";
  GetSchNotificationApiCallId: string = "";
  scheduledDeleteIdAPICallId: string = "";
  scheduledReminderDeleteIdAPICallId: string = "";
  sentDataApiId: string = "";
  sentReminderDataApiId: string = "";
  draftDataApiId: string = "";
  scheduledDataApiId: string = "";
  scheduledReminderDataApiId: string = "";
  getUsersApiCallId: string = "";
  GetSentRemindersApiCallId: string = "";
  GetScheduledReminderApiCallId: string = ""
  scheduledReminderFrequencyDataApiId: string = "";
  observer: IntersectionObserver | any;
  loadingRef: any;
  scheduledObserver: IntersectionObserver | any;
  scheduledLoadingRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.loadingRef = React.createRef(); // Create a ref for the loading indicator
    this.observer = null;

    this.scheduledLoadingRef = React.createRef(); // Create a ref for the loading indicator
    this.scheduledObserver = null;

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      currentDate: currDateTime,
      // Customizable Area Start
      onlyDate: currDate,
      navTabRedirect: false,
      draftUpdatedData: 0,
      contentsError: "",
      notificationError: "",
      dateError: "",
      alertType: "",
      showDeleteModelId: "0",
      userJsonData: {
        items: [],
        isLoading: false,
        page: 1
      },
      createAlertMsg: "",
      alertState: false,
      errorCreateAlertMsg: "",
      timeSet: "",
      navDraft:true
,      scheduledData: {
        headings: "",
        contents: "",
        created_at: "",
        created_by: "",
        sent_date: "",
        sent_time: "",
        id: "",
        account_ids: [],
      },
      scheduledReminderData: {
        headings: "",
        contents: "",
        created_at: "",
        created_by: "",
        sent_date: "",
        sent_time: "",
        id: "",
        account_ids: [],
        allowEdit: false
      },
      draftData: {
        headings: "",
        contents: "",
        created_at: "",
        created_by: "",
        sent_date: "",
        sent_time: "",
        id: "",
        account_ids: [],
      },
      showModal: false,
      personName: [],
      personReminderName: [],
      tabValue: "1",
      sortOrder: 'asc',
      sortColumn: '',
      sortBy: 'desc',
      editorState: EditorState.createEmpty(),
      editorScheduledState: EditorState.createEmpty(),
      editorReminderState: EditorState.createEmpty(),
      editorReminderScheduledState: EditorState.createEmpty(),
      userData: {
        email: "",
        name: "",
        imageUrl: "",
        userType: "",
      },
      createNotiData: {
        title: "",
        description: "",
        id: ""
      },
      sentNoti: false,
      createNoti: false,
      scheduledNoti: false,
      draftNoti: false,
      showColorList: false,
      notiBorder: false,
      ReminderNotiBorder: false,
      sentReminder: false,
      createReminder: false,
      scheduledReminder: false,
      draftBoolean: false,
      sentRemData: {
        items: [],
        page: 0,
        hasMore: true
      },
      schRemData: {
        items: [],
        page: 0,
        hasMore: true
      },
      personReminderNameErrors: "",
      personNameErrors: "",
      sentObj: {
        headings: "",
        contents: "",
        created_at: "",
        created_by: "",
        sent_date: "",
        sent_time: "",
        id: "",
        account_ids: [],
      },
      sentReminderObj: {
        headings: "",
        contents: "",
        created_at: "",
        created_by: "",
        sent_date: "",
        sent_time: "",
        id: "",
        account_ids: [],
      },
      showComposeTitle: false,
      sentJsonData: {
        items: [],
        page: 0,
        hasMore: true
      },
      draftJsonData: {
        items: [],
        page: 0,
        hasMore: true
      },
      scheduledJsonData: {
        items: [],
        page: 0,
        hasMore: true
      },
      searchState: "",
      searchTerm: "",
      mobileOpen: false,
      formattedTime: "",
      searchStatus: true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId != null) {
        switch (true) {
          case apiRequestCallId === this.GetnotificationApiCallId:
            this.notificationsGetData(responseJson)
            break;
          case apiRequestCallId === this.getUsersApiCallId:
            this.getUserFunct(responseJson)
            break;
          case apiRequestCallId === this.sentDataApiId:
            this.createNotificationFunct(responseJson)
            break
          case apiRequestCallId === this.sentReminderDataApiId:
            this.createReminderNotificationFunct(responseJson)
            break
          case apiRequestCallId === this.draftDataApiId:
            this.draftNotificationFunct(responseJson)
            break
          case apiRequestCallId === this.GetDraftNotificationApiCallId:
            this.draftNotificationsGetData(responseJson)
            break
          case apiRequestCallId === this.GetSchNotificationApiCallId:
            this.scheduledNotificationsGetData(responseJson)
            break
          case apiRequestCallId === this.scheduledDeleteIdAPICallId:
            this.scheduledDeleteNotificationsData(responseJson)
            break;
          case apiRequestCallId === this.scheduledReminderDeleteIdAPICallId:
            this.scheduledReminderDeleteNotificationsData(responseJson)
            break;
          case apiRequestCallId === this.scheduledDataApiId:
            this.scheduledUpdateNotificationsData(responseJson)
            break
          case apiRequestCallId === this.scheduledReminderDataApiId:
            this.scheduledReminderUpdateNotificationsData(responseJson)
            break
          case apiRequestCallId === this.scheduledReminderFrequencyDataApiId:
            this.reminderFrequencyUpdate(responseJson)
            break;
          case apiRequestCallId === this.GetSentRemindersApiCallId:
            this.reminderSentGetData(responseJson)
            break;
          case apiRequestCallId === this.GetScheduledReminderApiCallId:
            this.reminderScheduledGetData(responseJson)
            break;
          default:
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area End
  // Customizable Area Start

  async componentDidMount() {
    this.observer = new IntersectionObserver(this.handleObserver, {
      root: null,
      rootMargin: '20px',
      threshold: [0, 0.25, 0.5, 0.75, 1]
    });


    if (this.loadingRef.current) {
      this.observer.observe(this.loadingRef.current);
    }

    this.scheduledObserver = new IntersectionObserver(this.handleScheduledObserver, {
      root: null,
      rootMargin: '20px',
      threshold: [0, 0.25, 0.5, 0.75, 1]
    });

    if (this.scheduledLoadingRef.current) {
      this.scheduledObserver.observe(this.scheduledLoadingRef.current);
    }

    const Url = window.location.pathname;
    if (Url.includes("/Notifications/SentNotifications")) {
      this.setState({
        sentNoti: true,
        createNoti: false,
        scheduledNoti: false,
        draftNoti: false,
        notiBorder: true,
        ReminderNotiBorder: false,
      });
    } else if (Url.includes("/Notifications/CreateNotifications")) {
      this.setState({
        sentNoti: false,
        createNoti: true,
        scheduledNoti: false,
        draftNoti: false,
        notiBorder: true,
        ReminderNotiBorder: false,
      });
      this.getUserDataData()
    } else if (Url.includes("/Notifications/ScheduledNotifications")) {
      this.setState({
        sentNoti: false,
        createNoti: false,
        scheduledNoti: true,
        draftNoti: false,
        notiBorder: true,
        ReminderNotiBorder: false,
      });
    }
    else if (Url.includes("/Notifications/DraftNotifications")) {
      this.setState({
        sentNoti: false,
        createNoti: false,
        scheduledNoti: false,
        draftNoti: true,
        notiBorder: true,
        ReminderNotiBorder: false,
      });
      this.getUserDataData()
    }
    this.handleQueryParams()
    this.ReminderUrlFunct(Url); 
    this.updateCurrentTime()
    //Data from Cookie
    this.redirectInvalidUser()
    this.fetchUserData()
  }

  async componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
    if (this.scheduledObserver) {
      this.scheduledObserver.disconnect();
    }
  }

  ReminderUrlFunct = (Url: any) => {
    if (Url.includes("/Remainder/Sent")) {
      this.setState({
        sentNoti: false,
        notiBorder: false,
        ReminderNotiBorder: true,
        sentReminder: true,
        createReminder: false,
        scheduledReminder: false,
      })
    }
    else if (Url.includes("/Remainder/Create")) {
      this.setState({
        notiBorder: false,
        ReminderNotiBorder: true,
        sentReminder: false,
        createReminder: true,
        scheduledReminder: false,
      });
      this.getUserDataData()
    } else if (Url.includes("/Remainder/Scheduled")) {
      this.setState({
        notiBorder: false,
        ReminderNotiBorder: true,
        sentReminder: false,
        createReminder: false,
        scheduledReminder: true,
      });
    }
  }

  updateCurrentTime = () => {
    const currentTime = new Date();
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    this.setState({
      formattedTime: `${hours}:${minutes}`
    })
  }

  fetchUserData = () => {
    const cookieData: any = Cookies.get("userProfile");
    const parsedCookieData = JSON.parse(cookieData).data.attributes
    this.setState({
      userData: {
        email: parsedCookieData.email,
        name: parsedCookieData.first_name + " " + parsedCookieData.last_name,
        imageUrl: parsedCookieData.profile_image,
        userType: parsedCookieData.user_role,
      }
    })
  }
  goToSentNotifications = () => {
    this.props.navigation.navigate("SentNotifications");
  }

  goToCreate = () => {
    this.props.navigation.navigate("CreateNotifications");
  }

  goToDraft = () => {
    this.props.navigation.navigate("DraftNotifications");
  }

  goToScheduled = () => {
    this.props.navigation.navigate("ScheduledNotifications");
  }

  goToRemindersCreate = () => {
    this.props.navigation.navigate("CreateRemindersNotifications");
  }
  //@ts-nocheck
  goToReminders = () => {
    this.props.navigation.navigate("SentRemindersNotifications");
  }

  goToRemindersScheduled = () => {
    this.props.navigation.navigate("ScheduledRemindersNotifications");
  }

  clickTab = (newValue: string) => {
    this.setState({ tabValue: newValue })
  }
  clickTabNoti = (newValue: string) => {
    this.setState({ tabValue: newValue })
  }
  clickTabRiminder = (newValue: string) => {
    this.setState({ tabValue: newValue })
  }
  saveDraftBtn = (values: any) => {
    this.formDraftSubmit(values)
  }
  handleNotificationSubmit = (values: any) => {
    this.formSubmit(values)
  }

  handleReminderSubmit = (values: any) => {
    this.formReminderSubmit(values)
  }
  formSubmit = (values: any) => {
    let token = localStorage.getItem("authToken");
    const { utcTime, utcDate } = convertToUTC(values.sent_date, values.sent_time)
    const headers = {
      "Content-Type": "application/json",
      "token": token,
    };
    let requestBody;
    requestBody = {
      notification: {
        notification_type: "notification",
        headings: values.headings,
        contents: values.contents,
        sent_date: utcDate,
        sent_time: utcTime,
        status: "sent",
        notify_to: this.state.sentObj.account_ids,
        send_to_all: values.send_to_all,
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sentDataApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sentNotiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  formReminderSubmit = (values: any) => {
    let token = localStorage.getItem("authToken");
    const headers = {
      "Content-Type": "application/json",
      "token": token,
    };

    const { utcTime, utcDate } = convertToUTC(values.sent_date, values.sent_time)
    let requestBody;
    requestBody = {
      notification: {
        notification_type: "reminder",
        headings: values.headings,
        contents: values.contents,
        sent_date: utcDate,
        sent_time: utcTime,
        status: "sent",
        notify_to: this.state.sentReminderObj.account_ids,
        send_to_all: values.send_to_all,
        frequency: values.frequency
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sentReminderDataApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createReminderEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleQueryParams = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const param1Value = queryParams.get('newCompose');
    if (param1Value) this.setState({
      showComposeTitle: true
    })
    else {
      this.setState({
        showComposeTitle: false
      })
    }
  };

  handleUpdateReminder = (values: any) => {
    let token: any = localStorage.getItem("authToken");
    const headers = {
      "Content-Type": "application/json",
      "token": token,
    };

    const { utcTime, utcDate } = convertToUTC(values.sent_date, values.sent_time)

    const requestBody = {
      notification: {
        notification_type: "reminder",
        headings: values.headings,
        contents: values.contents,
        sent_date: utcDate,
        sent_time: utcTime,
        status: "scheduled",
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.scheduledReminderDataApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.scheduledNotiEndPoint +
      "/" + values.id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  formDraftSubmit = (values: any) => {
    const { utcTime, utcDate } = convertToUTC(values.sent_date, values.sent_time)
    let token = localStorage.getItem("authToken");
    let requestBody;
    requestBody = {
      notification: {
        notification_type: "notification",
        headings: values.headings,
        contents: values.contents,
        sent_date: utcDate,
        sent_time: utcTime,
        status: "draft",
        notify_to: this.state.sentObj.account_ids,
        send_to_all: values.send_to_all,
      }
    }
    const headers = {
      "Content-Type": "application/json",
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.draftDataApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.darftNotiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleSubmitDraft = (values: any) => {
    const { utcTime,
      utcDate } = convertToUTC(values.sent_date, values.sent_time)
    let token: any = localStorage.getItem("authToken");
    const headers = {
      "Content-Type": "application/json",
      "token": token,
    };
    let requestBody;
    requestBody = {
      notification: {
        notification_type: "notification",
        sent_date: utcDate,
        sent_time: utcTime,
        headings: values.headings,
        contents: values.contents,
        status: "scheduled",
        notify_to: this.state.draftData.account_ids,
        send_to_all: values.send_to_all,
      }
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.scheduledDataApiId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.markAsReadMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.scheduledNotiEndPoint + "/" + this.state.draftData.id);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(requestBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleSubmitDraftAsEdit = (values: any) => {
    const { utcTime,
      utcDate } = convertToUTC(values.sent_date, values.sent_time)
    let token: any = localStorage.getItem("authToken");
    const headers = { "Content-Type": "application/json", "token": token };
    let requestBody;
    requestBody = {
      notification: {
        notification_type: "notification",
        contents: values.contents,
        headings: values.headings,
        sent_time: utcTime,
        sent_date: utcDate,
        status: "draft",
        send_to_all: values.send_to_all,
        notify_to: this.state.draftData.account_ids
      }
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.scheduledDataApiId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.scheduledNotiEndPoint + "/" + this.state.draftData.id);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.markAsReadMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(requestBody));
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleSubmitSch = (values: any) => {
    const { utcTime, utcDate } = convertToUTC(values.sent_date, values.sent_time)
    let token: any = localStorage.getItem("authToken");
    const headers = {
      "Content-Type": "application/json",
      "token": token,
    };
    let requestBody;
    requestBody = {
      notification: {
        notification_type: "notification",
        headings: values.headings,
        contents: values.contents,
        sent_date: utcDate,
        sent_time: utcTime,
        status: "scheduled",
        notify_to: this.state.scheduledData.account_ids
      }
    }



    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.scheduledDataApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.scheduledNotiEndPoint +
      "/" + this.state.scheduledData.id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleChangeAccount = (event: any, setValue: any) => {
    const selectedData = event.target.value;
    let duplicateRemoved: any = [];
    let idAccess: any = [];
    selectedData.forEach((item: any) => {
      item.first_name = item.attributes.first_name
      duplicateRemoved.push(item);
      idAccess.push(item.id);
    });
    let newAccess: any = idAccess.map((i: any) => Number(i));
    this.setState((sentObj: any) => ({
      sentObj: {
        ...sentObj.sentObj,
        account_ids: newAccess,
      },
      draftData: {
        ...sentObj.draftData,
        account_ids: newAccess,
      },
      personName: typeof duplicateRemoved === 'string' ? duplicateRemoved.split(',') : duplicateRemoved,
    }), () => {
      // Callback after setState is completed
      if (this.state.personName.length) {
        this.setState({ personNameErrors: "" })
        setValue("send_to_all", false)
      }
    })
  };
  handleChangeReminderAccount = (event: any, setValue: any) => {
    const selectedData = event.target.value;
    let duplicateRemoved: any = [];
    let idAccess: any = [];
    selectedData.forEach((item: any) => {
      item.first_name = item.attributes.first_name;
      duplicateRemoved.push(item);
      idAccess.push(item.id);
    });
    let newAccess: any = idAccess.map((i: any) => Number(i));
    // Use functional form of setState with a callback
    this.setState((prevState: any) => ({
      sentReminderObj: {
        ...prevState.sentReminderObj,
        account_ids: newAccess,
      },
      personReminderName:
        typeof duplicateRemoved === 'string' ? duplicateRemoved.split(',') : duplicateRemoved,
    }), () => {
      // Callback after setState is completed
      if (this.state.personReminderName.length) {
        this.setState({ personReminderNameErrors: "" })
        setValue("send_to_all", false)
      }
    })
  };



  handleSort = (column: any) => {
    const { sortColumn, sortOrder } = this.state;
    if (sortColumn === column) {
      this.setState({ sortOrder: sortOrder === 'asc' ? 'desc' : 'asc' });
    } else {
      this.setState({ sortColumn: column, sortOrder: 'asc' });
    }
  };

  handleSortByDate = () => {
    this.setState({
      sortBy: this.state.sortBy === 'asc' ? 'desc' : 'asc',
      sentRemData: {
        items: [],
        page: 0,
        hasMore: true
      },
      schRemData: {
        items: [],
        page: 0,
        hasMore: true
      },
      sentJsonData: {
        items: [],
        page: 0,
        hasMore: true
      },
      scheduledJsonData: {
        items: [],
        page: 0,
        hasMore: true
      } 
    })
  }

  draftEdit = (elementDrft: any) => {
    if (elementDrft.attributes.notify_to.length > 0) {
      const matchedItems: any = [];
      elementDrft.attributes.notify_to.map((item: any) => {
        const matchedItem = this.state.userJsonData.items.find((obj: any) => obj.id == item);
        if (matchedItem) {
          matchedItems.push(matchedItem);
        }
      });
      let dupliDraft: any = [];
      let idAccess: any = [];
      matchedItems.forEach((item: any) => {
        item.first_name = item.attributes.first_name
        dupliDraft.push(item);
        idAccess.push(item.id);
      });
      this.setState({ personName: dupliDraft })
      let newAccess: any = idAccess.map((i: any) => Number(i));
      this.setState({
        draftData: {
          ...this.state.draftData,
          account_ids: newAccess,
        }
      })
    }
    let contentDraftState: any = convertFromRaw({
      entityMap: {},
      blocks: [
        // @ts-ignore
        {
          text: elementDrft.attributes.contents,
          key: 'foo',
          type: 'unstyled',
          entityRanges: [],
        },
      ],
    });

    const newEditorDraftState = EditorState.createWithContent(contentDraftState);
    const { localTime, localDate } = convertToLocal(elementDrft.attributes.sent_date, elementDrft.attributes.sent_time)
    this.setState({
      editorScheduledState: newEditorDraftState,
      draftData: {
        id: elementDrft.id,
        headings: elementDrft.attributes.headings,
        contents: elementDrft.attributes.contents,
        sent_date: localDate,
        sent_time: localTime,
        send_to_all: elementDrft.attributes.send_to_all,
      }
    })
    const blocksFromHTML = htmlToDraft(elementDrft.attributes.contents);
    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    const newEditorState = EditorState.createWithContent(contentState);
    this.setState({
      editorScheduledState: newEditorState
    })
  }
  scheduledEdit = (row: any) => {
    const { localTime, localDate } = convertToLocal(row.attributes.sent_date, row.attributes.sent_time)
    this.setState({
      scheduledData: {
        id: row.id,
        headings: row.attributes.headings,
        contents: row.attributes.contents,
        created_at :row.attributes.created_at,
        first_name: row.attributes.created_by.first_name,
        sent_date: localDate,
        sent_time: localTime,
        allowEdit: false
      },
    })
    const blocksFromHTML = htmlToDraft(row.attributes.contents);
    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    const newEditorState = EditorState.createWithContent(contentState);
    this.setState({
      editorScheduledState: newEditorState
    })
  }


  scheduledReminderEdit = (row: any) => {
    const { localTime, localDate } = convertToLocal(row.attributes.sent_date, row.attributes.sent_time)
    this.setState({
      scheduledReminderData: {
        id: row.id,
        headings: row.attributes.headings,
        contents: row.attributes.contents,
        sent_date: localDate,
        sent_time: localTime,
        frequency: row.attributes.frequency,
        allowEdit: false
      },
    })
    const blocksFromHTML = htmlToDraft(row.attributes.contents);
    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    const newEditorState = EditorState.createWithContent(contentState);
    this.setState({
      editorReminderScheduledState: newEditorState
    })
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }
  searchInput = (e: any) => {
    this.setState({
      searchState: e.target.value
    })
  }
  searchInputSch = (e: any) => {
    this.setState({
      searchState: e.target.value
    })
  }
  searchInputDraft = (e: any) => {
    this.setState({searchState: e.target.value})
  }
  reminderSearchInputSch = (e: any) => {
    this.setState({
      searchState: e.target.value
    })
  }
  reminderSearchInput = (e: any) => {
    this.setState({
      searchState: e.target.value,
    })
  }

  handleChangeTimeSet = (e: any) => {
    this.setState({ timeSet: e.target.value })
  }
  handleChangeFrequency = (id: any, event: any) => {
    let updatedData = this.state.sentRemData.items.map((item: any) => {
      if (item.id === id) {
        this.handleChangeFrequencyReminder(id, event.target.value)
        return { ...item, attributes: { ...item.attributes, frequency: event.target.value } };

      }
      return item;
    });
    this.setState({ sentRemData: { ...this.state.sentRemData, items: updatedData } })
  }

  handleChangeScheduledFrequency = (id: any, event: any) => {
    let updatedData = this.state.schRemData.items.map((item: any) => {
      if (item.id === id) {
        this.handleChangeFrequencyReminder(id, event.target.value)
        return { ...item, attributes: { ...item.attributes, frequency: event.target.value } };
      }
      return item;
    });
    this.setState({ schRemData: { ...this.state.schRemData, items: updatedData } })
  }

  handleChangeFrequencyReminder = (id: any, frequency: string) => {
    let token: any = localStorage.getItem("authToken");
    const headers = {
      "Content-Type": "application/json",
      "token": token,
    };
    let requestBody;
    requestBody = {
      notification: {
        frequency
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.scheduledReminderFrequencyDataApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateReminder +
      "/" + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleDeleteReminder = () => {
    let token: any = localStorage.getItem("authToken");
    const headers = {
      "Content-Type": "application/json",
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.scheduledReminderDeleteIdAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.scheduledNotiEndPoint +
      "/" + this.state.showDeleteModelId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  draftNotificationFunct = (responseJson: any) => {
    if (responseJson) {
      this.setState({
        sentObj: {
          id: "",
          account_ids: [],
        },
        personName: [],
        editorState: EditorState.createEmpty(),
        createAlertMsg: "Add Draft Successfully!",
        alertType: "success",
        alertState: true
      })
    }
  }
  createNotificationFunct = (responseJson: any) => {

    if (responseJson && !responseJson.errors) {
      this.setState({
        sentObj: {
          id: "",
          account_ids: [],
        },
        personName: [],
        editorState: EditorState.createEmpty(),
        createAlertMsg: "Data Save Successfully!",
        alertType: "success",
        alertState: true
      })
    }
  }
  createReminderNotificationFunct = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({
        sentReminderObj: {
          id: "",
          account_ids: [],
        },
        personReminderName: [],
        editorReminderState: EditorState.createEmpty(),
        createAlertMsg: "Data Save Successfully!",
        alertType: "success",
        alertState: true
      })
    }
  }
  handleCreateCloseAlert = () => {
    this.setState({
      alertState: false,
      createAlertMsg: "",
      errorCreateAlertMsg: "",
    })
  }
  redirectInvalidUser = () => {
    if (!localStorage.getItem("authToken") || !Cookies.get("userProfile")) {
      this.props.navigation.navigate("EmailAccountLoginSignup")
    }
  }
  notificationsGetData = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      const reData: any = responseJson.data;
      const items = [...this.state.sentJsonData.items, ...reData]
      this.setState(prevState => ({ ...prevState, sentJsonData: { ...prevState.sentJsonData, items, hasMore: !!responseJson.meta.next_page, page: prevState.sentJsonData.page + 1 }, searchStatus:true }))
    } else {
      this.setState({
        sentJsonData: {
          items: [],
          hasMore: false,
          page: 0
        },
        searchStatus:true
      })
    }
  }

  reminderSentGetData = (responseJson: any) => {

    if (responseJson && !responseJson.errors) {
      const reData: any = responseJson.data;
      const items = [...this.state.sentRemData.items, ...reData]
      this.setState(prevState => ({ ...prevState, sentRemData: { ...prevState.sentRemData, items, hasMore: !!responseJson.meta.next_page, page: prevState.sentRemData.page + 1 }, searchStatus:true }))
    } else {
      this.setState({
        sentRemData: {
          items: [],
          hasMore: false,
          page: 0
        },
        searchStatus:true
      })
    }
  }

  reminderScheduledGetData = (responseJson: any) => {

    if (responseJson && !responseJson.errors) {
      const reData: any = responseJson.data;
      const items = [...this.state.schRemData.items, ...reData]
      this.setState(prevState => ({ ...prevState, schRemData: { ...prevState.schRemData, items, hasMore: !!responseJson.meta.next_page, page: prevState.schRemData.page + 1 }, searchStatus:true }))
    } else {
      this.setState({
        schRemData: {
          items: [],
          hasMore: false,
          page: 0
        },
        searchStatus:true
      })
    }
  }

  draftNotificationsGetData = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      const reData: any = responseJson.data;
      const items = [...this.state.draftJsonData.items, ...reData]
      this.setState(prevState => ({ ...prevState, draftJsonData: { ...prevState.draftJsonData, items, hasMore: !!responseJson.meta.next_page, page: prevState.draftJsonData.page + 1,searchStatus:true } }))
    } else {
      this.setState({
        draftJsonData: {
          items: [],
          hasMore: false,
          page: 0
        },
        searchStatus:true
      })
    }
  }
  scheduledNotificationsGetData = (responseJson: any) => {

    if (responseJson && !responseJson.errors) {
      const reData: any = responseJson.data;
      const items = [...this.state.scheduledJsonData.items, ...reData]
      this.setState(prevState => ({ ...prevState, scheduledJsonData: { ...prevState.scheduledJsonData, items, hasMore: !!responseJson.meta.next_page, page: prevState.scheduledJsonData.page + 1 }, searchStatus:true }))
    } else {
      this.setState({
        scheduledJsonData: {
          items: [],
          hasMore: false,
          page: 0
        },
        searchStatus:true
      })
    }

  }
  scheduledDeleteNotificationsData = (responseJson: any) => {
    this.setState({ showModal: false })
    this.getScheduledNotificationData();
  }

  scheduledReminderDeleteNotificationsData = (responseJson: any) => {
    this.setState({
      showModal: false, schRemData: {
        items: [],
        hasMore: true,
        page: 0
      }
    })
    this.getRemindersData("scheduled");
  }
  scheduledUpdateNotificationsData = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({
        scheduledData: {
          headings: "",
          contents: "",
          created_at: "",
          created_by: "",
          sent_date: "",
          sent_time: "",
          id: "",
          account_ids: [],
        },
        draftData: {
          headings: "",
          contents: "",
          created_at: "",
          created_by: "",
          sent_date: "",
          sent_time: "",
          id: "",
          account_ids: [],
        },
        personName: [],
        editorState: EditorState.createEmpty(),
        createAlertMsg: "Data Update Successfully!",
        alertType: "success",
        alertState: true
      })
      this.setState(prevState => ({
        ...prevState,
        scheduledJsonData: {
          items: [],
          page: 0,
          hasMore: true
        },
        draftJsonData: {
          items: [],
          page: 0,
          hasMore: true
        }
      }),
        () => {
          this.getScheduledNotificationData()
          this.getDraftNotificationData()
        }
      ) 
    }
  }

  scheduledReminderUpdateNotificationsData = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({
        scheduledReminderData: {
          headings: "",
          contents: "",
          created_at: "",
          created_by: "",
          sent_date: "",
          sent_time: "",
          id: "",
          account_ids: [],
        },
        personName: [],
        editorReminderScheduledState: EditorState.createEmpty(),
        createAlertMsg: "Data Update Successfully!",
        alertType: "success",
        alertState: true
      })
      this.getRemindersData("scheduled")
    }
  }

  reminderFrequencyUpdate = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({
        createAlertMsg: "Frequency Update Successfully!",
        alertType: "success",
        alertState: true
      })
    }
  }
  getUserFunct = (responseJson: any) => {
    const userData: any = responseJson.data;
    if (responseJson && !responseJson.errors) {
      const items = [...this.state.userJsonData.items, ...userData]
      this.setState(prevState => ({
        ...prevState, userJsonData: {
          ...prevState.userJsonData, items,
          page: responseJson.meta.next_page, isLoading: false
        }
      }))
    } else {
      this.setState({
        userJsonData: {
          items: [],
          isLoading: false,
          page: 1
        }
      })
    }
  }
  deleteSheduledBtn = () => {
    let token = localStorage.getItem("authToken");
    const headers = {
      "Content-Type": "application/json",
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.scheduledDeleteIdAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.schDeleteEndPoint +
      "/" + this.state.showDeleteModelId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getnotificationsData = () => {
    let token = localStorage.getItem("authToken");
    const headers = {
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetnotificationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointNotificationGet + `&page=${this.state.sentJsonData.page + 1}&search=${this.state.searchState}&sort_by=${this.state.sortBy}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleEditor = (editorReminderScheduledState: EditorState, setFieldValue: any) => {
    const contentStateSch = editorReminderScheduledState.getCurrentContent();
    if (contentStateSch.hasText()) {
      setFieldValue("contents", draftToHtml(convertToRaw(contentStateSch)))
    } else {
      setFieldValue("contents", "")
    }
    this.setState({ editorReminderScheduledState })
  }
  onEditorStateScheduledChange = (editorScheduledState: EditorState, setFieldValue: any) => {
    const contentStates = editorScheduledState.getCurrentContent();
    if (contentStates.hasText()) { 
    setFieldValue("contents", draftToHtml(convertToRaw(contentStates)))
  }else{
    setFieldValue("contents", "")
  }
    this.setState({ editorScheduledState })
    const currentContentSch: any = editorScheduledState.getCurrentContent();
    const plainTextSch: any = currentContentSch.getPlainText('\u0001');
    this.setState({
      editorScheduledState,
      sentObj: {
        ...this.state.sentObj,
        contents: plainTextSch,
      },
      contentsError: plainTextSch ? "" : "content is required"

    });
  };
  onEditorStateCreateChange = (editorState: EditorState, setFieldValue: any) => {
    const contentStatesNotification = editorState.getCurrentContent();
    if (contentStatesNotification.hasText()) { 
    setFieldValue("contents", draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }else{
    setFieldValue("contents", "")
  }
    this.setState({ editorState })
    const currentContentSch: any = editorState.getCurrentContent();
    const plainTextSch: any = currentContentSch.getPlainText('\u0001');
    this.setState({
      editorState,
      sentObj: {
        ...this.state.sentObj,
        contents: plainTextSch,
      },
      contentsError: plainTextSch ? "" : "content is required"

    });
  };
  onEditorStateCreateReminderChange= (editorReminderState: EditorState, setFieldValue: any) => {
    const contentStatesNotification = editorReminderState.getCurrentContent();
    if (contentStatesNotification.hasText()) { 
    setFieldValue("contents", draftToHtml(convertToRaw(editorReminderState.getCurrentContent())))
  }else{
    setFieldValue("contents", "")
  }
    this.setState({ editorReminderState })
    const currentContentRem: any = editorReminderState.getCurrentContent();
    const plainTextSch: any = currentContentRem.getPlainText('\u0001');
    this.setState({
      editorReminderState,
      sentObj: {
        ...this.state.sentObj,
        contents: plainTextSch,
      },
      contentsError: plainTextSch ? "" : "content is required"
    });
  };
  handleObserver = (entries: any) => {
    const [entry] = entries;
    if (this.state.searchStatus && this.state.sentReminder && entry.isIntersecting && this.state.sentRemData.hasMore) {
      this.setState(prevState => ({ ...prevState, sentRemData: { ...prevState.sentRemData, hasMore: false } }))
      this.getRemindersData("sent")
    }

    if (this.state.searchStatus && this.state.sentNoti && entry.isIntersecting && this.state.sentJsonData.hasMore) {
      this.setState(prevState => ({ ...prevState, sentJsonData: { ...prevState.sentJsonData, hasMore: false } }))
      this.getnotificationsData()
    }

    if (this.state.searchStatus && this.state.draftNoti && entry.isIntersecting && this.state.draftJsonData.hasMore) {
      this.setState(prevState => ({ ...prevState, draftJsonData: { ...prevState.draftJsonData, hasMore: false } }))
      this.getDraftNotificationData()
    }
    
    if (this.state.searchStatus && this.state.scheduledNoti && entry.isIntersecting && this.state.scheduledJsonData.hasMore) {
      this.setState(prevState => ({ ...prevState, scheduledJsonData: { ...prevState.scheduledJsonData, hasMore: false } }))     
      this.getScheduledNotificationData();
    }
  };
  handleScheduledObserver = (entries: any) => {
    const [entry] = entries;
    if (this.state.searchStatus && entry.isIntersecting && this.state.schRemData.hasMore) {
      this.setState(prevState => ({ ...prevState, schRemData: { ...prevState.schRemData, hasMore: false } }))
      this.getRemindersData("scheduled")
    }
  };
  handleScrollAccount = (event: any) => {
    const dropdown = event.target;
    const { scrollHeight, scrollTop, clientHeight } = dropdown
    if ((scrollHeight - scrollTop <= clientHeight + 20) && !this.state.userJsonData.isLoading && this.state.userJsonData.page) {
      this.setState({
        userJsonData: {
          ...this.state.userJsonData, isLoading: true
        }
      }, () => {
        this.getUserDataData()
      })
    }
  }

  getRemindersData = (status: "sent" | "scheduled" = "sent") => {
    let token = localStorage.getItem("authToken");
    const headers = {
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (status === "sent") {
      this.GetSentRemindersApiCallId = requestMessage.messageId;
    } else {
      this.GetScheduledReminderApiCallId = requestMessage.messageId;
    }
    let urlEndPoint = status === "sent" ? configJSON.sentReminderEndPoint(this.state.sentRemData.page + 1) : configJSON.schReminderEndPoint(this.state.schRemData.page + 1)

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlEndPoint + `=${this.state.searchTerm}&sort_by=${this.state.sortBy}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getDraftNotificationData = () => {
    let token = localStorage.getItem("authToken");
    const headers = {
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetDraftNotificationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.darftNotiEndPointGet  + `&page=${this.state.draftJsonData.page + 1}&search=${this.state.searchState}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getScheduledNotificationData = () => {
    let token = localStorage.getItem("authToken");
    const headers = {
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetSchNotificationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.schNotiEndPointGet + `&page=${this.state.scheduledJsonData.page + 1}&search=${this.state.searchState}&sort_by=${this.state.sortBy}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  scheduledDelete = (row: any) => {
    this.setState({
      showModal: true,
      showDeleteModelId: row.id
    });
  };
  getUserDataData = () => {
    let token = localStorage.getItem("authToken");
    const headers = {
      "token": token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUsersApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userAccountEndPoint(this.state.userJsonData.page)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  customSort = (sortOrder: any, valueA: any, valueB: any) => {
    if (sortOrder === 'asc') {
      return valueA && valueB ? valueA.localeCompare(valueB) : 0;
    } else {
      return valueA && valueB ? valueB.localeCompare(valueA) : 0;
    }
  }
  newCompose = () => {
    this.props.navigation.navigate("CreateNotifications");
  }

  reminderComposeNew = () => {

  }

  backBtn = () => {
    this.setState({
      scheduledData: {
        id: "",
      },
      draftData: {
        id: ""
      }
    })
  }

  handleDrawerToggles = () => {
    this.setState((prevState: any) => ({
      mobileOpen: !prevState.mobileOpen,
    }));
  };
  handleSuccessError = () => {
    if (this.state.alertType === "success") {
      return this.state.createAlertMsg;
    }
    else {
      return this.state.errorCreateAlertMsg
    }
  }
  formikValidation = (values: any, resetForm: any) => {
    const { send_to_all } = values;
    const { personName, draftBoolean } = this.state;
    if (!send_to_all && !personName.length) {
      this.setState({ personNameErrors: "Accounts should be selected" });
      return;
    }
    this.setState({ personNameErrors: "" });
    if (draftBoolean) {
      this.saveDraftBtn(values);
      this.setState({ draftBoolean: false });
    } else {
      this.handleNotificationSubmit(values);
    }

    resetForm();
  }
  handleScheduleNotification=(values:any,resetForm:any)=>{debugger
    if(this.state.navDraft){
      if (!values.send_to_all && !this.state.personName.length) {
        this.setState({ personNameErrors: "Accounts should be selected" })
      } else {
        this.setState({ personNameErrors: "" })
        this.handleSubmitDraft(values);
        resetForm();
      }
    }
  }
  handleDraNotification=(values:any)=>{debugger
    if(this.state.navDraft){
      if (!values.send_to_all && !this.state.personName.length) {
        this.setState({ personNameErrors: "Accounts should be selected" })
      } else {
        this.setState({ personNameErrors: "" })
        this.handleSubmitDraftAsEdit(values);
      }
    }
  }
  handleSubmitFormikValidation=(values:any,resetForm:any)=>{
    const {draftBoolean} = this.state;
    if (draftBoolean){
      this.handleDraNotification(values);
      this.setState({ draftBoolean: false });
    }else{
      this.handleScheduleNotification(values,resetForm);
    }
  }
  // Customizable Area End
}
