import React from "react";

// Customizable Area Start
import {
  withWidth,
  Box,
  Drawer,
  IconButton,
  Paper,
  InputBase
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import SideBar from "../../../components/src/SideBar";
import DashboardTdController, { Props } from "./DashboardTdController";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import UserTableList from "../../../components/src/UserTable.web";
import DashboardTabs from "../../../components/src/DashboardTabs.web";
import CircleChart from "../../../components/src/CircleChart.web";
import BarChartComponent from "../../../components/src/BarChart.web";
import SearchIcon from "@material-ui/icons/Search";
import CommissionStats from "../../../components/src/CommissionStats.web";
import UserStats from "../../../components/src/UserStats.web";
import CommissionChart from "../../../components/src/CommissionChart.web";
import { bellIcon, unreadBellIcon } from "./assets";
import UnreadNotificationList from "../../../components/src/UnreadNotificationList.web";
export const configJSON = require("./config");

// Customizable Area Start
const theme = createTheme({
  typography: {
    fontFamily: "Poppins"
  },
  palette: {
    background: {
      default: "#FAFAFB"
    }
  }
});
export class DashboardTd extends DashboardTdController {
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      selectedNavTab,
      selectedTab,
      commissionTitle,
      selectedUser,
      commissionsRecords,
      per_page,
      page,
      usagePagination,
      usageRecords,
      commissionArray,
      platformUsageData,
      mergedCommissionsData,
      unreadNotificationRecords,
      isLoading,
      scrollPage
    } = this.state;
    const { width } = this.props;
    const isMobile = /s/.test(width);
    return (
      <ThemeProvider theme={theme}>
        <Box style={dashboardStyles.fullscreenBody} className="table_body">
          <Box style={dashboardStyles.sidebarParentBodyBody}>
            {isMobile ? (
              <div style={dashboardStyles.toggleButton}>
                <IconButton
                  style={{ color: "white" }}
                  data-test-id="handleDrawerToggles"
                  onClick={this.handleDrawerToggles}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  onClose={this.handleDrawerToggles}
                  open={this.state.mobileOpen}
                  ModalProps={{
                    keepMounted: true
                  }}
                >
                  {
                    <SideBar
                      style={dashboardStyles.sidebar}
                      activeMenuItem={"Dashboard"}
                      navigate={this.props.navigation.navigate}
                      userData={this.state.userData}
                    />
                  }
                </Drawer>
              </div>
            ) : (
              <div className="sidebarResponse" style={dashboardStyles.sidebar}>
                <SideBar
                  activeMenuItem={"Dashboard"}
                  navigate={this.props.navigation.navigate}
                  userData={this.state.userData}
                />
              </div>
            )}
          </Box>
          <Box
            className="width_100_p"
            style={dashboardStyles.dashboardContainer}
          >
            <div style={dashboardStyles.bellIconBody}>
              {this.state.userData.userType !== "admin" && (
                <>
                  <img
                    src={
                      unreadNotificationRecords.length > 0
                        ? unreadBellIcon
                        : bellIcon
                    }
                    data-test-id="notificationTab"
                    style={dashboardStyles.pointerBody}
                    onClick={this.handleClickPopOver}
                  />
                  <UnreadNotificationList
                    anchorEl={this.state.anchorEl}
                    handleClosePopOver={this.handleClosePopOver}
                    navigate={this.props.navigation.navigate}
                    notificationData={unreadNotificationRecords}
                    isLoading={isLoading}
                    unreadNotificationList={this.onScrollUnreadNotificationList}
                    handleClearAll={this.handleClearAll}
                    scrollPage={scrollPage}
                  />
                </>
              )}
            </div>
            <Box style={dashboardStyles.overFlow}>
              <DashboardTabs
                tabList={configJSON.TOP_NAV_LIST}
                state={selectedNavTab}
                data-test-id="handleSwitchNav"
                handleSwitch={this.handleSwitchNav}
              />
              <div
                style={dashboardStyles.container}
                className="responsiveMargin"
              >
                {commissionsRecords && (
                  <>
                    <CommissionStats
                      commissionName={configJSON.commissionNameType[0]}
                      color={configJSON.COLORS[0]}
                      data-test-id="deepest"
                      commissionValue={
                        commissionsRecords.deepset_fx_commissions
                      }
                    />
                    <CommissionStats
                      commissionName={configJSON.commissionNameType[1]}
                      color={configJSON.COLORS[1]}
                      commissionValue={commissionsRecords.affiliate_commissions}
                    />
                    <CommissionStats
                      commissionName={configJSON.commissionNameType[2]}
                      color={configJSON.COLORS[2]}
                      commissionValue={
                        commissionsRecords.master_trader_commissions
                      }
                    />
                    <CommissionStats
                      commissionName={configJSON.commissionNameType[3]}
                      color={configJSON.COLORS[3]}
                      commissionValue={
                        commissionsRecords.tinkerbell_commissions
                      }
                    />
                  </>
                )}
              </div>
              <Box
                style={dashboardStyles.commissionContainer}
                className="responsiveMargin"
              >
                <CommissionChart
                  yTitle={commissionTitle}
                  colorArray={configJSON.COLORS}
                  data-test-id="mergedCom"
                  commissionsData={mergedCommissionsData}
                />
                <CircleChart
                  commissionsRecords={commissionsRecords}
                  commissionArray={commissionArray}
                  COLORS={configJSON.COLORS}
                  commissionNameType={configJSON.commissionNameType}
                />
              </Box>
              <Box className="responsiveMargin">
                <p style={dashboardStyles.progressTitle}>
                  Platform usage and user adoption
                </p>
                <Box style={dashboardStyles.progressBar}>
                  <UserStats
                    title="Average user usage"
                    data-test-id="averageUser"
                    colors={["#4B63D4", "#3dd5981a"]}
                    userMinutes={platformUsageData.avg_user_usage}
                    userCount={platformUsageData.total_user_account}
                  />
                  <UserStats
                    title="Today’s user usage"
                    colors={["#FFB74B", "#3dd5981a"]}
                    userMinutes={platformUsageData.today_usage}
                    userCount={platformUsageData.today_user_count}
                  />
                </Box>
              </Box>
              <Box className="chartContainer responsiveMargin">
                <BarChartComponent
                  yTitle={configJSON.TAB_LIST[selectedTab]}
                  xTitle={usagePagination}
                  userData={usageRecords}
                  selectedUser={selectedUser}
                  data-test-id="resetSelectedUser"
                  resetSelectedUser={this.resetSelectedUser}
                />
              </Box>
              <div className="searchBarTabs">
                <DashboardTabs
                  tabList={configJSON.TAB_LIST}
                  state={selectedTab}
                  data-test-id="handleSwitch"
                  handleSwitch={this.handleSwitch}
                />
                <Paper style={dashboardStyles.searchInput}>
                  <InputBase
                    style={{ marginLeft: 1, flex: 1 }}
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    value={this.state.searchState}
                    data-test-id="handleSearchInput"
                    onChange={this.handleSearchInput}
                  />
                  <IconButton
                    type="button"
                    aria-label="search"
                    data-test-id="handleSearchUser"
                    onClick={this.handleSearchUser}
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>
              <UserTableList
                userData={usageRecords}
                handleChangeUser={this.handleChangeUser}
                selectedUser={selectedUser}
                per_page={per_page}
                page={page}
                usagePagination={usagePagination}
                data-test-id="handlePaginationChange"
                handlePaginationChange={this.handlePaginationChange}
              />
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

let dashboardStyles: any = {
  bellIconBody: {
    width: "100%",
    textAlign: "right",
    height: "75px",
    boxShadow: "none",
    borderBottom: "1px solid rgba(128, 128, 128, 0.43)"
  },
  pointerBody: {
    marginTop: "20px",
    marginRight: "30px",
    cursor: "pointer",
    color: "black",
    width: "32px",
    height: "32px"
  },
  fullscreenBody: {
    display: "flex",
    position: "fixed",
    top: 0,
    bottom: 0,
    boxSizing: "border-box",
    height: "100vh !important",
    minWidth: "100vw"
  },
  sidebarParentBodyBody: {
    height: "100vh"
  },
  sidebar: {
    zIndex: "9",
    height: "100%",
    position: "fixed",
    bottom: 0,
    top: 0,
    width: "24vw",
    fontFamily: "Poppins",
    marginRight: "-100px"
  },
  dashboardContainer: {
    width: "76vw",
    marginLeft: "auto",
    background: "#FAFAFB"
  },
  overFlow: {
    overflow: "auto",
    height: "90%"
  },
  searchInput: {
    padding: "0px 12px",
    marginRight: "24px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    boxShadow: "none",
    borderRadius: "10px"
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    gap: "15px",
    alignItems: "center",
    justifyContent: "center",
    padding: "32px 0px"
  },
  progressTitle: {
    color: "#171725",
    fontSize: "16px",
    fontWeight: "600",
    letterSpacing: "0.1px",
    marginTop: "42px"
  },
  progressBar: {
    display: "flex",
    gap: "32px",
    flexWrap: "wrap"
  },
  commissionContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: "24px",
    borderRadius: "20px",
    background: "#FFF",
    padding: "24px"
  },
  toggleButton: {
    top: "10px",
    left: "12px",
    position: "fixed",
    background:
      "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)"
  }
};

// Customizable Area End
export default withWidth()(DashboardTd);
