import React from "react";

// Customizable Area Start
import {
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Button,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TableSortLabel,
  Drawer,
  AppBar,
  withWidth,
  Select,
  MenuItem
} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import SideBar from "../../../components/src/SideBar";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import { Formik } from "formik"
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from 'draft-js';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import * as Yup from "yup";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
const images = require("../../../components/src/assets");

import NotificationsTdController, {
  Props,
} from "./NotificationsTdController";
import NotificationsHeader from "../../../components/src/NotificationsHeader";
import ThirdReminderHeader from "../../../components/src/ThirdReminderHeader";
import { sortData } from "../../../components/src/utils/utils";
import moment from 'moment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ArrowDropDownIcon from '@material-ui/icons/ExpandMore';
const configJSON = require("./config");
import { Link } from 'react-router-dom';
import { timeValidation } from "./CreateReminder.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins !important'
  },
});

const validationSchema = Yup.object().shape({
  headings: Yup.string()
    .required('Title is Required'),
  contents: Yup.string()
    .required('Content is Required'),
  sent_date: Yup.date().required(),
  sent_time: timeValidation
});
export function FieldError({ error, touched }: any) {
  return error && touched ? (
    <div style={{ color: "#e65e52" }}>{error}</div>
  ) : null;
}

const MenuProps = {
  ...configJSON.commonMenuConfig,
  PaperProps: {
    style: {
      width: 220,
      borderRadius: "20px",
    },
  },
};

export class ScheduledReminder extends NotificationsTdController {
  // Customizable Area End
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { sortOrder, sortColumn, scheduledReminderData } = this.state;
    const dropdownOptions = configJSON.dropdownOptions;
    const { width } = this.props;
    const isMobileSch = /s/.test(width);

    const getElement = () => {

      if (scheduledReminderData.id && scheduledReminderData.allowEdit) {
        return (<Grid container spacing={0}>
          <Formik
            enableReinitialize={true}
            validateOnBlur={true}
            data-test-id="submitSch"
            initialValues={{
              headings: scheduledReminderData.headings,
              sent_date: scheduledReminderData.sent_date,
              sent_time: scheduledReminderData.sent_time,
              id: scheduledReminderData.id,
              contents: scheduledReminderData.contents
            }}
            onSubmit={(values, { resetForm }) => {
              this.setState(prevState => ({
                ...prevState,
                schRemData: {
                  items: [],
                  page: 0,
                  hasMore: true
                }
              }))
              this.handleUpdateReminder(values)
              resetForm();
            }}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, values, setFieldValue, errors, touched }) => {
              return (
                <>
                  <Grid item xs={12}>
                    <form onSubmit={handleSubmit} style={{padding: '2px'}}>
                      <Box className="createCardNoti" sx={{ marginRight: { xs: "8px" } }}>
                        <div className="postionRel borderBottomCss">
                          <Box className="customTextFieldBox">
                            <TextField value={values.headings} data-test-id="titleInput" onChange={(event: any) => {
                              setFieldValue("headings", event.target.value)
                            }} className="cc" variant="outlined" placeholder="Label" />
                            <FieldError
                              error={errors.headings}
                              touched={touched.headings}
                            />
                          </Box>
                          <div>
                            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "end", gap: "10px", padding: "10px" }} className="timePostionDiv">
                              <input data-test-id="dateInput" className="inputDateCust" onChange={(event: any) => {
                                setFieldValue("sent_date", event.target.value)
                              }} value={values.sent_date} type="date" min={new Date().toISOString().split('T')[0]}
                              />
                              <input data-test-id="timeInput" className="inputDateCust" onChange={(event: any) => {
                                setFieldValue("sent_time", event.target.value)
                              }} value={values.sent_time} type="time" />
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "end", gap: "10px", padding: "10px" }} className="timePostionDiv">
                              <FieldError
                                error={errors.sent_date}
                                touched={touched.sent_date}
                              />
                              <FieldError
                                error={errors.sent_time}
                                touched={touched.sent_time}
                              />
                            </div>
                          </div>
                        </div>
                        <Editor
                          editorState={this.state.editorReminderScheduledState}
                          data-test-id="editor"
                          onEditorStateChange={(event: EditorState) => this.handleEditor(event,setFieldValue )}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          toolbar={{
                            textAlign: { inDropdown: true },
                            link: { inDropdown: true },
                            history: { inDropdown: true },
                            list: { inDropdown: true },
                            inline: { inDropdown: true },
                          }}
                        />
                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                          <img style={{ width: "140px", height: "30px" }} src={`${images.iconLogo}`} />
                          <p>{this.state.onlyDate}</p>
                        </div>
                        <FieldError
                          error={errors.contents}
                          touched={touched.contents}
                        />
                      </Box>
                      <div style={{ marginTop: "10px", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <button style={{ ...stylesscheduledRem.activeButtonScheRem, background: "linear-gradient(180deg, #192C85 0%, #000000 100%)", paddingLeft: " 1.5rem", paddingRight: "1.5rem", border: "1px solid #0c143e", marginRight: 0 }} data-test-id="submitBtn" type="submit">Send Reminders</button>
                      </div>
                    </form>
                  </Grid>
                </>
              );
            }}
          </Formik>
        </Grid>)
      }
      if (scheduledReminderData.id) {
        return (
          <Box>
            <Table className="PTable">
              <TableHead>
                <TableRow style={{ background: "#DCDCDC",outline:"unset" }} className="tableRow">
                  <TableCell style={{ width: "170px", textAlign:"left"}}>
                    Label
                  </TableCell>
                  <TableCell style={{ width: "250px" }} align="center">
                    Date for Send Reminders
                  </TableCell>
                  <TableCell style={{ width: "120px"}} align="center">
                    Time
                  </TableCell>
                  <TableCell style={{ width: "120px" }} align="center" className="lastthree">
                    Frequency
                  </TableCell>
                  <TableCell style={{marginRight:"14px" }} align="center" className="lastTwo">
                    Edit
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className="tableRow"  style={{outline:"unset",borderBottom: "unset"}} >
                  <TableCell style={{ width: "170px",textAlign:"left" }}>{scheduledReminderData.headings}</TableCell>
                  <TableCell style={{ width: "250px" }} align="center">{moment(scheduledReminderData.sent_date).format('D-M-YYYY')}</TableCell>
                  <TableCell style={{ width: "120px" }} align="center">{moment(scheduledReminderData.sent_time, 'hh:mm A').format('h.mm A')}</TableCell>
                  <TableCell style={{ width: "120px" }} align="center">
                    {scheduledReminderData.frequency}
                  </TableCell>
                  <TableCell align="center" data-test-id="editButton" onClick={() => this.setState({ scheduledReminderData: { ...scheduledReminderData, allowEdit: true } })} className="lastTwo"><EditIcon /></TableCell>
                </TableRow>
                <TableRow style={{ marginTop: "-10px",outline:"unset" }} className="tableRow" >
                  <TableCell style={{ width: "100%", minHeight: "30vh", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                    <div style={{ textAlign: "start" }}>
                      <span style={{ fontSize: "17px" }}  dangerouslySetInnerHTML={{ __html:scheduledReminderData.contents }}>
                        </span>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <img style={{ width: "140px", height: "30px" }} src={`${images.iconLogo}`} />
                        <p>{this.state.onlyDate}</p>
                      </div>
                      <button typeof="submit" style={{ ...stylesscheduledRem.sendReminderButton, background: "linear-gradient(180deg, #192C85 0%, #000000 100%)", paddingLeft: " 1.5rem", paddingRight: "1.5rem", border: "1px solid #0c143e", }} type="submit">Post Reminders</button></div>

                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        )
      }
      return (
        <Box style={{maxHeight: "70vh" }} className="scrollFixed">
          <Table className="PTable alignTable">
            <TableHead>
              <TableRow style={{ background: "#DCDCDC",outline: "unset" }} className="tableRow">
                <TableCell align="center">
                  Label
                </TableCell>
                <TableCell align="center">
                  <TableSortLabel
                    data-test-id="sortOrder"
                    direction={sortOrder}
                    active={sortColumn === 'created_at'}
                    onClick={() => this.handleSortByDate()}
                  >
                    Date Created
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                    Created by
                </TableCell>
                <TableCell align="center">
                    Sent Date
                </TableCell>
                <TableCell align="center" className="lastthree">
                    Frequency
                </TableCell>
                <TableCell align="center" className="lastTwo">
                  Edit
                </TableCell>
                <TableCell align="center" className="lastOne">
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.schRemData.items.length > 0 && <>
                {this.state.schRemData.items
                  .map((row: any) => (
                    <TableRow className="tableRow" key={row.id} style={{alignItems:"center", outline: "unset"}}>
                      <TableCell align="center">{row.attributes.headings}</TableCell>
                      <TableCell align="center">{moment(row.attributes.created_at).format('D-M-YYYY, h.mm A')}</TableCell>
                      <TableCell align="center">{row.attributes.created_by.first_name + " " + row.attributes.created_by.last_name}</TableCell>
                      <TableCell align="center">
                        {moment.utc(row.attributes.sent_date + ' ' + row.attributes.sent_time, 'YYYY-MM-DD hh:mm').local().format('D-M-YYYY, h.mm A')}
                      </TableCell>
                      <TableCell align="center" style={{ fontFamily: "Poppins" }} className="SelectOption">
                        <Select
                          value={row.attributes.frequency}
                          inputProps={{ 'aria-label': 'Without label' }}
                          displayEmpty
                          MenuProps={MenuProps}
                          data-test-id="frequencyInput"
                          disableUnderline
                          IconComponent={ArrowDropDownIcon}
                        >
                          {dropdownOptions.map((option: any) => (
                            <MenuItem style={{ paddingTop: "15px", paddingBottom: "15px" }} className="optionTextCss" key={option} value={option}>
                              {
                                <RadioGroup
                                  value={row.attributes.frequency}
                                  row
                                >
                                  <FormControlLabel style={{ textTransform: "capitalize" }} labelPlacement="start" name="rrrrr" data-test-id="radioChange" value={option} control={<Radio onChange={event => this.handleChangeScheduledFrequency(row.id, event)} />} label={option} />
                                </RadioGroup>
                              }
                            </MenuItem>
                          ))}
                        </Select>

                      </TableCell>
                      <TableCell align="center" data-test-id="editBtn" onClick={() => this.scheduledReminderEdit(row)} className="lastTwo"><EditIcon /></TableCell>
                      <TableCell align="center" data-test-id="scheduledDelete" onClick={() => this.scheduledDelete(row)} className="lastOne"><DeleteIcon /></TableCell>
                    </TableRow>
                  ))}
              </>
               
              }
               {(this.state.schRemData.items.length === 0 && !this.state.schRemData.hasMore) &&
                          <TableRow style={{ textAlign: "center" }}>
                            <TableCell align="center" style={{ textAlign: "center" }} colSpan={4}>No data found</TableCell>
                          </TableRow>
                        }
                        <TableRow ref={this.scheduledLoadingRef} style={{ textAlign: "center" }}>
                        {(this.state.schRemData.hasMore) && 
                          <TableCell align="center" style={{ textAlign: "center" }} colSpan={4}>Loading...</TableCell>
                       } </TableRow>
            </TableBody>
          </Table>
        </Box>
      )
    }


    return (
      <ThemeProvider theme={theme}>
        <Box style={stylesscheduledRem.fullscreenScheRem}>
          <div style={stylesscheduledRem.pageContainer}>
            {isMobileSch ?
              <div
                style={stylesscheduledRem.sidebarIcon}
              >
                <IconButton
                  style={{ color: 'white' }}
                  onClick={this.handleDrawerToggles}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  ModalProps={{
                    keepMounted: true,
                  }}
                  open={this.state.mobileOpen}
                  onClose={this.handleDrawerToggles}
                >
                  {<SideBar
                    style={stylesscheduledRem.sidebar}
                    userData={this.state.userData}
                    navigate={this.props.navigation.navigate}
                    activeMenuItem={"Notifications & Reminders"}
                  />}
                </Drawer>
              </div>

              :
              <div style={stylesscheduledRem.sidebar} className="sidebarResponse">
                <Box style={stylesscheduledRem.sidebarParentCreateRe}>
                  <SideBar
                    navigate={this.props.navigation.navigate}
                    activeMenuItem={"Notifications & Reminders"}
                    userData={this.state.userData}
                  />
                </Box>
              </div>}
          </div>
          <Box className="mainLftContainer" >
            <div style={stylesscheduledRem.topNavBarScheRem} >
              <AppBar position="fixed" style={stylesscheduledRem.pageHeader} className="accessHeader">
                {/* <NotificationsActiveOutlinedIcon data-test-id="navigateBtn" onClick={() => this.props.navigation.navigate("Notifications")} style={stylesscheduledRem.notificationIcon} /> */}
              </AppBar>

              <div style={stylesscheduledRem.topNavBarCreateRe}>
                <div style={stylesscheduledRem.pageTitleScheRem}>
                  <NotificationsHeader state={this.state}
                    goToSentNotifications={this.goToSentNotifications}
                    goToReminders={this.goToReminders}
                    clickTab={this.clickTab}
                  />
                </div>

                <Box m={{ xs: "8px", md: "30px" }} sx={stylesscheduledRem.scroll}>
                  <Box sx={stylesscheduledRem.boxSch}>
                    <ThirdReminderHeader state={this.state}
                      goToReminders={this.goToReminders}
                      goToRemindersCreate={this.goToRemindersCreate}
                      data-test-id="goToRemindersScheduled"
                      goToRemindersScheduled={this.goToRemindersScheduled}
                      clickTabNoti={this.clickTabNoti}
                    />
                    <Box style={{ display: "flex", alignItems: 'center', margin: "0" }} className="flexWrapper">
                      <div style={{ marginTop: "17px" }}>
                        <Link to={`/Remainder/Create?newCompose=true`} style={{ textDecoration: 'none' }}><button data-test-id="composeNewButton"
                          onClick={this.reminderComposeNew}
                          style={{ ...stylesscheduledRem.activeButtonScheRem, fontSize: "17px", background: "#000", paddingLeft: " 1rem", paddingRight: "1rem", border: "1px solid #0c143e", marginRight: "10px" }} type="button">Compose New</button></Link>

                      </div>
                      <Box className="titleSearch" style={{marginRight: "4px"}}>
                        <TextField
                          className="searchInput"
                          label="Search by Title"
                          value={this.state.searchState}
                          onChange={this.reminderSearchInputSch}
                          data-test-id="searchInput"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment variant="filled" position="end">
                                <IconButton data-test-id="searchInputs" onClick={() => {
                                  this.setState(prevState => ({
                                    ...prevState,
                                    searchTerm:prevState.searchState,
                                    schRemData: {
                                      items: [],
                                      page: 0,
                                      hasMore: true
                                    },
                                    searchStatus:false
                                  }), ()=>this.getRemindersData("scheduled"))
                                }}>
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  {
                    getElement()
                  }
                </Box>
              </div>
            </div>
          </Box>
          <Dialog className="modalDelete" open={this.state.showModal} onClose={this.closeModal} PaperProps={isMobileSch ? { style: webStyless.logOutModalMobile } : { style: webStyless.logOutModala }}>
            <DialogContent style={webStyless.logoutContenta}>
              <p>Are you sure you want to Delete?</p>
            </DialogContent>
            <DialogActions>
              <Button type="button" className="noButton" onClick={this.closeModal}>
                NO
              </Button>
              <Button data-test-id="handleDeleteReminder" onClick={this.handleDeleteReminder} className="yesButton">
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
        <Snackbar open={this.state.alertState} onClose={this.handleCreateCloseAlert} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <MuiAlert elevation={6} variant="filled" data-test-id="alertComponent" severity={this.state.alertType}>
            {this.state.createAlertMsg}
          </MuiAlert>
        </Snackbar>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}


// Customizable Area Start
const webStyless = {
  logOutModala: {
    width: "835px",
    height: "346px",
    borderRadius: "20px",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
  },

  logOutModalMobile: {
    width: "auto",
    height: "auto",
    borderRadius: "20px",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
    textAlign: "center",
    margin: "0 15px"
  },
  noLogouta: {
    padding: "20px 60px",
    height: "42px",
    borderRadius: "20px",
    color: "black",
    fontSize: "15px",
    textTransform: "Capitalize",
    border: "1px solid black",
    display: 'flex',
    justifyContent: 'center',
    marginBottom: "20%",
  },
  yesLogouta: {
    padding: "20px 60px",
    height: "42px",
    background:
      "linear-gradient(0deg, rgba(16, 41, 114, 1.0), rgba(2, 5, 10, 1.0))",
    borderRadius: "20px",
    color: "white",
    fontSize: "15px",
    textTransform: "Capitalize",
    display: 'flex',
    justifyContent: 'center',
    marginBottom: "20%",
    marginRight: "23%"
  },
  logoutContenta: {
    fontSize: "20px",
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: "10%"
  }
};
let stylesscheduledRem: any = {
  fullscreenScheRem: {
    top: 0,
    bottom: 0,
    height: "100vh !important",
    fontFamily: "Poppins, sans-serif",
    display: 'flex',
    boxSizing: 'border-box'
  },
  pointerScheRem: {
    cursor: "pointer",
    margin: "1rem"
  },
  topNavBarScheRem: {
    width: "100%",
    marginTop: '74px',
  },
  sidebarParentScheRem: {
    width: '24%',
    height: '100vh',
  },
  bellIconScheRem: {
    textAlign: "right",
    width: "100%"
  },
  sidebar: {
    fontFamily: 'Poppins',
    position: "fixed",
    top: "0",
    width: "24vw",
    marginRight: "-100px",
    zIndex: "9",
    height: "100%",
  },

  btnCover: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '7px',
    marginTop: '15px',
  },

  accesspageLft: {
    width: '100%',
    marginLeft: '24%',
  },

  sidebarIcon: { position: "fixed", zIndex: 1200, top: 10, left: 12, background: "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)", },

  pageTitleScheRem: {
    borderTop: "1px solid rgba(128, 128, 128, 0.43)",
    boxShadow: "rgb(34, 34, 34) 0px 4px 6px -6px",
  },

  activeButtonScheRem: {
    fontSize: "17px",
    color: "#fff",
    border: "none",
    borderRadius: "30px",
    padding: "0.4rem 1rem",
    marginRight: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },

  customTextFieldRem: {
    border: "none",
    '& .MuiOutlinedInput-root': {
      border: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root:hover': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      border: 'none',
    },
  },

  pageHeader: {
    fontFamily: 'Poppins',
    backgroundColor: "white",
    marginLeft: "22vw",
    width: "75vw",
    height: "75px",
    borderBottom: "1px solid rgba(128, 128, 128, 0.43)",
    boxShadow: "none",
    borderLeft: "none",
  },

  notificationIcon: {
    fontFamily: 'Poppins',
    position: "fixed",
    right: "30px",
    top: "20px",
    color: "black",
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
  },

  topNavBarCreateRe: {
    width: "100%",
    marginTop: '74px',
  },
  boxSch: {
    display: 'Flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: '15px',
    alignItems: "end"
  },
  dateBtn: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "end",
    gap: "10px",
    padding: "10px"
  },
  sendReminderButton: {
    fontSize: "15px",
    color: "#fff",
    border: "none",
    borderRadius: "30px",
    padding: "0.4rem 1rem",
    marginRight: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    height: "38px"
  },
  scroll: {
    height: 'calc(100vh - 190px)',
    overflow: 'auto',
  }
}
// Customizable Area End
export default (withWidth()(ScheduledReminder));
