import React from "react";
// Customizable Area Start
import SideBar from "../../../components/src/SideBar";
import MenuIcon from "@material-ui/icons/Menu";
import "../../email-account-login/src/Style.css";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { bellIcon, unreadBellIcon } from "./assets";
import UnreadNotificationList from "../../../components/src/UnreadNotificationList.web";
const images = require("../../../components/src/assets");
const commisssionImages = require("./assets");

import {
  Table,
  Typography,
  TableBody,
  Tab,
  TableCell,
  AppBar,
  TableRow,
  Button,
  Box,
  IconButton,
  Drawer,
  withWidth,
} from "@material-ui/core";
import "../../notifications/assets/css/style.css";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins !important'
  },
});
// Customizable Area End
import CommissionSplitController, { Props } from "./CommissionSplitController";
export class FinalMonthlyReport extends CommissionSplitController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const { width } = this.props;
    const isMobileFinal = /s/.test(width);
    const {
      createReportResponse,
      showSelectedYear,
      userSuccessAlert,
      showAlert,
      alertType,
      unreadNotificationRecords,
      scrollPage,
      isLoadingNoti,
    } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <div style={webStyle.pageContainer}>
          {isMobileFinal ? (
            <div style={webStyle.sidebarIcon} className="sidebarIcon">
              <IconButton
                style={{ color: "white" }}
                onClick={this.handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                ModalProps={{
                  keepMounted: true,
                }}
                onClose={this.handleDrawerToggle}
                open={this.state.mobileOpen}
              >
                {
                  <SideBar
                    style={webStyle.sidebar}
                    className="main-content"
                    userData={this.state.userData}
                    activeMenuItem={"CommissionSplit"}
                    navigate={this.props.navigation.navigate}
                  />
                }
              </Drawer>
            </div>
          ) : (
            <div
              style={webStyle.sidebar}
              className="sidebarResponse  main-content"
            >
              <SideBar
                activeMenuItem={"CommissionSplit"}
                className="main-content"
                navigate={this.props.navigation.navigate}
                userData={this.state.userData}
              />
            </div>
          )}
          <div style={webStyle.pageReportDefault} className="accessPageDefault">
            <AppBar
              position="fixed"
              style={webStyle.pageHeaderFinal}
              className="accessAddHeader"
            >
              {this.state.userData.userType !== "admin" &&
                <>
                  <img
                    style={webStyle.notificationFinalIcon}
                    onClick={this.handleClickPopOverNoti}
                    src={unreadNotificationRecords.length > 0 ? unreadBellIcon : bellIcon}
                  />
                  <UnreadNotificationList
                    anchorEl={this.state.anchorElNoti}
                    handleClosePopOver={this.handleClosePopOverNoti}
                    navigate={this.props.navigation.navigate}
                    notificationData={unreadNotificationRecords}
                    isLoading={isLoadingNoti}
                    unreadNotificationList={this.onScrollUnreadNotificationList}
                    handleClearAll={this.handleClearAll}
                    scrollPage={scrollPage}
                  />
                </>
              }
            </AppBar>
            <div className="padding_0_res"
              style={webStyle.divider} >
              <div
                style={webStyle.pageHeading}>
                <Tab
                  style={webStyle.editProfileBtn}
                  label="Account Commission"
                  className="AccounComPage"
                  onClick={this.accountCommissionPage}
                ></Tab>
                <Tab
                  label="Monthly Report"
                  onClick={this.monthlyReportPage}
                  className="AccounComPage"
                  style={webStyle.selectedTab}
                ></Tab>
              </div>
            </div>

            <div className="commissionMonthlyContainer finalReportpages" style={{ height: "80%" }}>
              <Box style={webStyle.addReport} className="addReportHead">
                <img
                  style={{ width: "163px", height: "36px", padding: "10px 0px 10px 57px" }}
                  src={`${commisssionImages.deepestLogo}`} className="finalReports finalPage"
                />
                <Typography style={webStyle.monthlyReport} className="finalReports">
                  Monthly Report
                </Typography>
                <Typography style={webStyle.showMonthYear} className="finalReports finalDate">
                  {this.state.selectedMonthName} {showSelectedYear}
                </Typography>
              </Box>
              <div style={{ ...webStyle.showUserFinalAccounts, marginLeft: "8%" }} className="comTable">
                <Table style={{ tableLayout: "fixed" }}>
                  <TableBody>
                    <TableRow
                      style={webStyle.accountFinalDetails}
                      className="accountData"
                    >
                      <TableCell
                        style={{
                          ...webStyle.accountMonthlyCell,
                          fontWeight: 700,
                          width: "160px",
                        }}
                      >
                        Login ID
                      </TableCell>
                      <TableCell
                        style={{
                          ...webStyle.accountMonthlyCell,
                          fontWeight: 700,
                          width: "160px",
                        }}
                      >
                        MT5 Account No
                      </TableCell>
                      <TableCell
                        style={{
                          ...webStyle.accountMonthlyCell,
                          fontWeight: 700,
                          width: "100px",
                        }}
                      >
                        MT
                      </TableCell>
                      <TableCell
                        style={{
                          ...webStyle.accountMonthlyCell,
                          fontWeight: 700,
                          width: "100px",
                        }}
                      >
                        Tink
                      </TableCell>
                      <TableCell
                        style={{
                          ...webStyle.accountMonthlyCell,
                          fontWeight: 700,
                          width: "100px",
                        }}
                      >
                        Deepest
                      </TableCell>
                      <TableCell
                        style={{
                          ...webStyle.lastCell,
                          fontWeight: 700,
                          width: "100px",
                        }}
                      >
                        Affiliate
                      </TableCell>
                      <TableCell
                        style={{
                          ...webStyle.lastCell,
                          fontWeight: 700,
                          width: "100px",
                        }}
                      >
                        Total
                      </TableCell>
                    </TableRow>

                    {createReportResponse.map((data: any) => {
                      return (
                        <TableRow
                          style={webStyle.accountFinalDetails}
                          key={data.id}
                          className="accountData"
                        >
                          <TableCell
                            style={{
                              ...webStyle.accountMonthlyCell,
                              width: "160px",
                            }}
                          >
                            {data.login_id}
                          </TableCell>
                          <TableCell
                            style={{
                              ...webStyle.accountMonthlyCell,
                              width: "160px",
                            }}
                          >
                            {data.mt_account_number}
                          </TableCell>
                          <TableCell
                            style={{
                              ...webStyle.accountMonthlyCell,
                              width: "100px",
                            }}
                            data-testId="password"
                          >
                            {data.mt}
                            ({data.master_trader}%)
                          </TableCell>
                          <TableCell
                            style={{
                              ...webStyle.accountMonthlyCell,
                              width: "100px",
                            }}
                          >
                            {data.tink}
                            ({data.tinkerbell_commission}%)
                          </TableCell>
                          <TableCell
                            style={{
                              ...webStyle.accountMonthlyCell,
                              width: "100px",
                            }}
                          >
                            {data.deepset}
                            ({data.deepset_fx_commission}%)
                          </TableCell>
                          <TableCell
                            style={{ ...webStyle.lastCell, width: "100px" }}
                          >
                            {data.affiliate}
                            ({data.affiliate_commission}%)
                          </TableCell>
                          <TableCell
                            style={{ ...webStyle.lastCell, width: "100px" }}
                          >
                            {data.total}
                            (100%)
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <Box style={webStyle.totalReports} className="totalReportsBar">
                <Typography style={{ paddingLeft: "6%", fontWeight: 500 }}>Total</Typography>
                <Typography style={{ paddingRight: "4%", fontWeight: 500 }}>
                  {this.state.showTotalReport}(100%)
                </Typography>
              </Box>
              <div style={{ ...webStyle.downloads, position: "relative" }} className="buttonHolder downloadBtns">
                {this.state.getUserPermissionDataPrint == "Print Commission Reports" &&
                  <Button
                    style={webStyle.printBtn}
                    data-test-id="printMonthlyReport"
                    onClick={this.handleMonthlyPrint}
                  // className="button_width"
                  >
                    Print
                  </Button>
                }
                {this.state.getUserPermissionDataEmail == "Email Commissions Reports" &&
                  <Button
                    style={webStyle.printBtn}
                    onClick={this.sendEmailApi}
                    data-test-id="goToEmail"
                  // className="button_width"
                  >
                    Email
                  </Button>
                }
                {this.state.getUserPermissionDataDownload == "Download Commissions Reports" &&
                  <Button
                    style={webStyle.printBtn}
                    onClick={this.handleDownload}
                    data-test-id="handleCsv"
                  // className="button_width"
                  >
                    Download CSV
                  </Button>
                }
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={showAlert}
            autoHideDuration={3000}
            onClose={this.handleAlertClose}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              style={{ width: "100%" }}
              onClose={this.handleAlertClose}
              severity={alertType}
            >
              {alertType === "success" && `${userSuccessAlert}`}
            </MuiAlert>
          </Snackbar>
        </div>
      </ThemeProvider>
    );
  }
}
export default withWidth()(FinalMonthlyReport);

// Customizable Area End

// Customizable Area Start
const webStyle: any = {
  pageContainer: {
    margin: 0,
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "Poppins, sans-serif",
    position: "fixed",
    top: 0,
    bottom: 0,
    height: "100%",
  },
  pageHeaderFinal: {
    fontFamily: "Poppins",
    backgroundColor: "white",
    marginLeft: "22vw",
    width: "76vw",
    height: "75px",
    borderBottom: "1px solid rgba(128, 128, 128, 0.43)",
    boxShadow: "none",
    borderLeft: "none",
  },

  sidebar: {
    fontFamily: "Poppins",
    position: "fixed",
    top: "0",
    width: "24vw",
    marginRight: "-100px",
    zIndex: "9",
    height: "100%",
  },
  pageReportDefault: {
    fontFamily: "Poppins",
    height: "100%",
    width: "79vw",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    top: "100px",
    marginLeft: "21vw",
  },
  selectedTab: {
    fontSize: "21px",
    borderBottom: "3px solid #000",
    fontWeight: 500,
    whiteSpace: "nowrap",
  },
  accountFinalDetails: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "1000px",
    height: "100px",
    margin: " 10px auto",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
    borderRadius: "6px",
    textAlign: "center",
    border: "1px solid #c7c3c3",
  },
  accountMonthlyCell: {
    overflow: "hidden",
    overflowWrap: "anywhere",
    fontSize: "15px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    padding: "0px 10px",
    justifyContent: "center",
    wordWrap: "anywhere",
  },
  lastCell: {
    fontFamily: "Poppins",
    fontSize: "15px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  showUserFinalAccounts: {
    overflowY: "auto",
    height: "40%",
    marginBottom: "3%",
    display: "flex",
    justifyContent: "center",
    scrollbarWidth: "thin",
    scrollbarColor: "##121111",
    overflowX: "auto",
    marginLeft: "20px",
    marginRight: "4%",
  },
  notificationFinalIcon: {
    fontFamily: "Poppins",
    position: "fixed",
    right: "30px",
    top: "20px",
    color: "black",
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
  },
  pageHeading: {
    marginTop: "-2%",
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },
  editProfileBtn: {
    fontSize: "21px",
    fontWeight: 500,
    textTransform: "capitalize",
    color: "#6A6A6A",
  },
  downloads: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "4%",
  },
  printBtn: {
    padding: "20px 35px",
    height: "42px",
    borderRadius: "20px",
    color: "black",
    fontSize: "18px",
    textTransform: "Capitalize",
    border: "1px solid black",
    marginRight: "30px",
    whiteSpace: "nowrap",
  },
  totalReports: {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "Poppins",
    boxSizing: "border-box",
    height: "85px",
    alignItems: "center",
    marginBottom: "2%",
    backgroundColor: "E5E5E5",
    marginLeft:"9.5%",
    marginRight:"5%",
    borderRadius:"6px"
  },
  addReport: {
    backgroundColor: "10121E",
    color: "white",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    fontFamily: "Poppins",
    boxSizing: "border-box",
    height: "auto",
    alignItems: "center",
    marginTop: "4%",
    marginBottom: "1%",
    flexWrap: "wrap"
  },
  monthlyReport: {
    fontSize: "21px",
    fontWeight: 500,
  },
  showMonthYear: {
    fontSize: "16px",
    fontWeight: 500,
    paddingRight: "8%",
  },
  sidebarIcon: {
    position: "fixed",
    top: 10,
    left: 12,
    background:
      "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
  },
  divider: {
    padding: "0px 0px 0px 50px",
    boxShadow: "rgb(34, 34, 34) 0px 4px 6px -6px",
    width: "100%"
  },
};
// Customizable Area End
