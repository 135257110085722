import React from "react";
import { Box, Grid, Typography, TextField, Tab, Tabs } from "@material-ui/core";
import TabContext from "@material-ui/lab/TabContext";
import "../src/assets/Css/Style.css"


const SecondNotiHeader = (props:any) =>  {
        const { classes,state,
            goToReminders,
            goToRemindersCreate,
            clickTabNoti,
            goToRemindersScheduled
        } = props
        return (

            <TabContext value={state.tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Box onChange={clickTabNoti} aria-label="lab API tabs example"
              className="tabsecond"
              style={{justifyContent:"flex-start"}}
              >
                <Tab label="Sent" value="1" style={{fontFamily:"Poppins",fontSize:"19px",borderBottom: state.sentReminder ? "3px solid #000":"", fontWeight:state.sentReminder?"bold":"normal"}} onClick={goToReminders} />
                <Tab label="Create" value="2" style={{fontFamily:"Poppins",fontSize:"19px",borderBottom: state.createReminder ? "3px solid #000":"", fontWeight:state.createReminder?"bold":"normal"}} onClick={goToRemindersCreate} />
                <Tab label="Scheduled" value="3"  style={{fontFamily:"Poppins",fontSize:"19px",borderBottom: state.scheduledReminder ? "3px solid #000":"", fontWeight:state.scheduledReminder?"bold":"normal"}}  onClick={goToRemindersScheduled} />
              </Box>
            </Box>
          </TabContext>
            )
}
export default SecondNotiHeader;

