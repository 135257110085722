import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SideBarTd from "../../../components/src/SideBar";
// Customizable Area End

import NotificationsTdController, {
  Props,
} from "./NotificationsTdController";
    // Customizable Area Start
export default class NotificationsTd extends NotificationsTdController {
    // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start

      <ThemeProvider theme={theme}>
        <Container style={{display: "flex"}}>
          <Box sx={webStyleWeb.sidebarIdWeb}>
              <SideBarTd navigate={this.props.navigation.navigate} activeMenuItem = {"Notifications & Reminders"} 
              userData={this.state.userData}
              />
          </Box>
          <Box sx={webStyleWeb.pageDefaultWeb}>

          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyleWeb = {
  sidebarIdWeb:{
    position: "sticky",
    top: 0,
    width: "24vw",
    zIndex:9,
    heigth: "100%"
  },
  pageDefaultWeb:{
    height: "100%",
    width: "76vw",
    backgroundColor : "red",
  }
  
  };
// Customizable Area End
