import React from "react";

// Customizable Area Start
import {
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Button,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TableSortLabel,
  withWidth,
  Drawer
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import SideBar from "../../../components/src/SideBar";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import moment from 'moment';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import { Formik } from "formik"
import { Editor } from "react-draft-wysiwyg";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import * as Yup from "yup";
const images = require("../../../components/src/assets");
// Customizable Area End
import "../assets/css/style.css";
import NotificationsTdController, {
  Props,
} from "./NotificationsTdController";
import NotificationsHeader from "../../../components/src/NotificationsHeader";
import SecondNotiHeader from "../../../components/src/SecondNotiHeader";
import { isEmpty } from "../../../framework/src/Utilities";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { timeValidation } from "./CreateNotification.web";
import { EditorState } from 'draft-js';
// Customizable Area Start
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins !important'
  },
});
const notificationSchSchema = Yup.object().shape({
  headings: Yup.string()
  .required('Title is Required'),
contents: Yup.string()
  .required('Content is Required'),
sent_date: Yup.date().required(),
sent_time: timeValidation
});

export class ScheduledNotifications extends NotificationsTdController {
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { sortOrder, sortColumn, scheduledData } = this.state;
    const { width } = this.props;
    const isMobile = /s/.test(width);
    const getElement = () => {
      if (scheduledData.id && scheduledData.allowEdit) {
        return (
          <Grid container spacing={0}>
            <Formik
              enableReinitialize={true}
              validateOnBlur={true}
              data-test-id="schNotiSubmit"
              initialValues={{
                headings: scheduledData.headings,
                sent_date: scheduledData.sent_date,
                sent_time: scheduledData.sent_time,
                id: scheduledData.id,
                contents: scheduledData.contents
              }}
              onSubmit={(values, { resetForm }) => {
                if (isEmpty(this.state.scheduledData.contents)) {
                  this.setState({
                    contentsError: "content is required"
                  })
                } else {
                  this.handleSubmitSch(values);
                  resetForm();
                  this.setState({
                    contentsError: ""
                  })
                }
              }}
              validationSchema={notificationSchSchema}
            >
              {({ values,
                errors,
                setFieldTouched,
                setFieldValue,
                touched, handleSubmit }) => {
                return (
                  <>
                    <Grid item xs={12}>
                      <form onSubmit={handleSubmit} style={{padding: '2px'}}>
                        <Box className="createCardNoti" sx={{ marginRight: { xs: "8px" } }}>
                          <div className="postionRel borderBottomCss">
                            <Box className="customTextFieldBox">
                              <TextField
                                value={values.headings} 
                                data-test-id="titleInput" 
                                variant="outlined" 
                                placeholder="Label"
                                onChange={(event: any) => {
                                  setFieldValue("headings", event.target.value)
                                }} className="cc" 
                                />
                              <FieldError
                                touched={touched.headings}
                                error={errors.headings}
                              />
                            </Box>
                            <div className="timePostionDiv" style={{ display: "flex", flexWrap: "wrap", justifyContent: "end", gap: "10px", padding: "10px" }}>
                              <input
                                className="inputDateCu inputDateCust"
                                data-test-id="dateInput"
                                onChange={(event: any) => {
                                  setFieldValue("sent_date", event.target.value)
                                }} value={values.sent_date} type="date" min={new Date().toISOString().split('T')[0]}
                              />
                              <FormControl variant="standard" className="timeSelectclass" style={{ width: "190px", marginLeft: "20px" }} >
                                <input
                                data-test-id="timeInput"
                                  className="inputDateCust"
                                  onChange={(event: any) => {
                                    setFieldValue("sent_time", event.target.value)
                                  }}
                                  value={values.sent_time}
                                  aria-describedby="outlined-weight-helper-text"
                                  id="outlined-adornment-weight"
                                  type="time"
                                />
                              </FormControl>
                            </div>
                          
                          </div>
                          <div style={{ marginLeft: "auto", display: "flex", flexWrap: "wrap", justifyContent: "end", gap: "10px", padding: "10px" }} className="timePostionDiv">
                              <FieldError
                                error={errors.sent_date}
                                touched={touched.sent_date}
                              />
                              <FieldError
                                error={errors.sent_time}
                                touched={touched.sent_time}
                              />
                            </div>
                          <Editor
                            editorState={this.state.editorScheduledState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            data-test-id="schEditorChanges"
                            onEditorStateChange={(event: EditorState) => this.onEditorStateScheduledChange(event,setFieldValue )}
                            toolbar={{
                              inline: { inDropdown: true },
                              list: { inDropdown: true },
                              textAlign: { inDropdown: true },
                              link: { inDropdown: true },
                              history: { inDropdown: true },
                            }}
                          />
                          <div style={{ display: "flex" }}>
                            <img src={`${images.iconLogo}`} style={{ width: "170px", height: "50px" }} />
                            <p>{this.state.onlyDate}</p>
                          </div>
                          <FieldError
                            error={this.state.contentsError}
                            touched={this.state.contentsError}
                          />
                        </Box>
                        <div className="button_block" style={{ alignItems: "center", marginTop: "10px", display: "flex", justifyContent: "flex-end" }}>
                          <button type="button" data-test-id="backBtn" onClick={this.backBtn} style={{ ...stylesscheduledNoti.buttonCreateSch, marginRight: "1rem" }} >
                            Back</button>
                          <button className="margin_top_16px" style={{ ...stylesscheduledNoti.activeButtonSche, background: "linear-gradient(180deg, #192C85 0%, #000000 100%)", padding: "0.8rem 1.5rem", border: "1px solid #0c143e", marginRight: 0 }} type="submit">Post Notification</button>
                        </div>
                      </form>
                    </Grid>
                  </>
                );
              }}
            </Formik>
          </Grid>
        )
      }
      if (scheduledData.id) {
        return (
          <Box style={{maxHeight: "70vh" }} className="scrollFixed">
            <Table className="SechTable PTable">
              <TableHead>
                <TableRow className="SechTableTRow tableRow" style={{ background: "rgb(220, 220, 220) none repeat scroll 0% 0%" }}>
                  <TableCell style={{ paddingLeft: "25px" }}>
                      Label
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <TableSortLabel
                      onClick={() => this.handleSortByDate()}
                      data-test-id="created"
                      direction={sortOrder}
                      active={sortColumn === 'created_at'}
                    >
                      Date created
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                      Created by
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                      Sent date
                  </TableCell>
                  <TableCell className="lastTwo" style={{ padding: "8px 15px", textAlign: "center" }}>
                    Edit
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={scheduledData.id} className="tableRow">
                  <TableCell style={{ overflow: "hidden", padding: "15px 8px", paddingLeft: "25px" }}>{scheduledData.headings}</TableCell>
                  <TableCell style={{ overflow: "hidden", padding: "8px 8px", width: "195px", textAlign: "center" }}>{moment(scheduledData.created_at).format('D-M-YYYY, h.mm A')}</TableCell>
                  <TableCell style={{ overflow: "hidden", padding: "8px 8px", width: "180px", textAlign: "center" }}>{scheduledData.first_name}</TableCell>
                  <TableCell style={{ overflow: "hidden", padding: "8px 8px", width: "195px", textAlign: "center" }}>{moment(scheduledData.sent_date + ' ' + scheduledData.sent_time, 'YYYY-MM-DD hh:mm').format('D-M-YYYY, h.mm A')}</TableCell>
                  <TableCell align="center" data-test-id="editButton" onClick={() => this.setState({ scheduledData: { ...scheduledData, allowEdit: true } })} className="lastTwo"><EditIcon /></TableCell>
                </TableRow>
                <TableRow style={{ marginTop: "-10px" }} className="tableRow" >
                  <TableCell style={{ width: "100%", minHeight: "30vh", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                    <div style={{ textAlign: "start" }}>
                      <span style={{ fontSize: "17px" }} dangerouslySetInnerHTML={{ __html: scheduledData.contents }}>
                      </span>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <img style={{ width: "140px", height: "30px" }} src={`${images.iconLogo}`} />
                        <p>{this.state.onlyDate}</p>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        )
      }
      return (
        <Box className="scrollFixed"  style={{maxHeight: "70vh" }} >
          <Table className="SechTable PTable">
            <TableHead>
              <TableRow className="SechTableTRow tableRow" style={{ background: "rgb(220, 220, 220) none repeat scroll 0% 0%" }}>
                <TableCell style={{ paddingLeft: "25px" }}>
                    Label
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <TableSortLabel
                  data-test-id="handleSortNoti"
                    onClick={() => this.handleSortByDate()}
                    direction={sortOrder}
                    active={sortColumn === 'created_at'}
                  >
                    Date created
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                    Created by
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                    Sent date
                </TableCell>
                <TableCell className="lastTwo" style={{ padding: "8px 15px", textAlign: "center" }}>
                  Edit
                </TableCell>
                <TableCell className="lastOne" style={{ padding: "8px 15px", textAlign: "center" }}>
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.scheduledJsonData.items.length > 0 &&
                this.state.scheduledJsonData.items
                  .map((row: any) => (
                    <TableRow key={row.id} className="tableRow">
                      <TableCell style={{ overflow: "hidden", padding: "15px 8px", paddingLeft: "25px" }}>{row.attributes.headings}</TableCell>
                      <TableCell style={{ overflow: "hidden", padding: "8px 8px", width: "195px", textAlign: "center" }}>{moment(row.attributes.created_at).format('D-M-YYYY, h.mm A')}</TableCell>
                      <TableCell style={{ overflow: "hidden", padding: "8px 8px", width: "180px", textAlign: "center" }}>{row.attributes.created_by.first_name}</TableCell>
                      <TableCell style={{ overflow: "hidden", padding: "8px 8px", width: "195px", textAlign: "center" }}>{moment.utc(row.attributes.sent_date + ' ' + row.attributes.sent_time, 'YYYY-MM-DD hh:mm').local().format('D-M-YYYY, h.mm A')}</TableCell>
                      <TableCell style={{ textAlign: "center" }} className="lastTwo" data-test-id="schEditBtn" onClick={() => this.scheduledEdit(row)}><EditIcon /></TableCell>
                      <TableCell style={{ textAlign: "center" }} className="lastOne" data-test-id="schDeleteBtn" onClick={() => this.scheduledDelete(row)}><DeleteIcon /></TableCell>
                    </TableRow>
                  ))}
              {(this.state.scheduledJsonData.items.length === 0 && !this.state.scheduledJsonData.hasMore) &&
                <TableRow style={{ textAlign: "center" }}>
                  <TableCell align="center" style={{ textAlign: "center" }} colSpan={4}>No Notification found</TableCell>
                </TableRow>
              }
              <TableRow ref={this.loadingRef} style={{ textAlign: "center" }}>
                {(this.state.scheduledJsonData.hasMore) &&
                  <TableCell align="center" style={{ textAlign: "center" }} colSpan={4}>Loading...</TableCell>
                } </TableRow>
            </TableBody>
          </Table>
        </Box>
      )
    }
    return (
      <ThemeProvider theme={theme}>
        <Box style={stylesscheduledNoti.fullscreenSche} className="table_body">
          <Box style={stylesscheduledNoti.sidebarParentSche} className="width_0">
            {isMobile ?
              <div
                style={{
                  position: "fixed", top:"10px",left:"12px", background: "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
                }}
              >
                <IconButton
                  onClick={this.handleDrawerToggles}
                  style={{ color: 'white' }}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  open={this.state.mobileOpen}
                  onClose={this.handleDrawerToggles}
                  ModalProps={{
                    keepMounted: true,
                  }}
                >
                  {<SideBar
                    style={stylesscheduledNoti.sidebar}
                    navigate={this.props.navigation.navigate}
                    activeMenuItem={"Notifications & Reminders"}
                    userData={this.state.userData}
                  />}

                </Drawer>
              </div> :
              <div style={stylesscheduledNoti.sidebar} className="sidebarResponse">
                <SideBar
                  navigate={this.props.navigation.navigate}
                  activeMenuItem={"Notifications & Reminders"}
                  userData={this.state.userData}
                />
              </div>}
          </Box>
          <Box style={stylesscheduledNoti.divContainerBodySche} className="width_100_p">
            <div style={stylesscheduledNoti.topNavBarSche}>
              <div style={stylesscheduledNoti.bellIconSche}>
              </div>
              <div style={stylesscheduledNoti.pageTitleSche} className="padding_0_res">
                <NotificationsHeader state={this.state}
                  goToSentNotifications={this.goToSentNotifications}
                  goToReminders={this.goToReminders}
                  clickTab={this.clickTab}
                />
              </div>
              <Box sx={stylesscheduledNoti.scroll} m={{ xs: "8px", md: "30px" }}>
                  <Box className="BoxFlex">
                    <SecondNotiHeader state={this.state}
                      goToSentNotifications={this.goToSentNotifications}
                      goToCreate={this.goToCreate}
                      goToDraft={this.goToDraft}
                      data-test-id="goToScheduled"
                      goToScheduled={this.goToScheduled}
                      clickTab={this.clickTabRiminder}
                    />
                    <Box style={{ display: "flex", margin: "0", marginTop: " -10px" }} className="display_block">
                      <div style={{ marginTop: "17px" }}>
                        <button data-test-id="newCompose" onClick={this.newCompose} style={{ ...stylesscheduledNoti.activeButtonSche, fontSize: "17px", background: "#000", paddingLeft: " 1rem", paddingRight: "1rem", border: "1px solid #0c143e", marginRight: "10px" }} type="button">Compose New</button>
                      </div>
                      <Box style={{paddingRight:"10px"}}>
                        <TextField
                        data-test-id="searchInputSch"
                          className="searchInput"
                          label="Search by Title"
                          value={this.state.searchState}
                          onChange={this.searchInputSch}
                          InputProps={{

                            endAdornment: (
                              <InputAdornment variant="filled" position="end">
                                <IconButton
                                  onClick={() => {
                                    this.setState(prevState => ({
                                      ...prevState,
                                      searchTerm: prevState.searchState,
                                      scheduledJsonData: {
                                        items: [],
                                        page: 0,
                                        hasMore: true
                                      },
                                      searchStatus:false
                                    }), () => this.getScheduledNotificationData())
                                  }}
                                >
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  {getElement()}
              </Box>
            </div>
          </Box>
          <Dialog className="modalDelete" open={this.state.showModal} data-test-id="closeModalSch" onClose={this.closeModal} PaperProps={isMobile ? { style: webStyle.logOutModalMobile } : { style: webStyle.logOutModala }}>
            <DialogContent style={{...webStyle.logoutContent,overflowY: "hidden"}}>
              <p>Are you sure you want to delete this notification?</p>
            </DialogContent>
            <DialogActions>
              <Button type="button" className="noButton" onClick={() => this.deleteSheduledBtn()}>
                Yes
              </Button>
              <Button className="yesButton" data-test-id="deleteBtn" type="button" onClick={this.closeModal} >
                No
              </Button>
            </DialogActions>
          </Dialog>

          <Snackbar open={this.state.alertState} data-test-id="closeBtn" onClose={this.handleCreateCloseAlert} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <MuiAlert elevation={6} variant="filled" severity={this.state.alertType} style={{ width: '100%' }}>
              {this.state.createAlertMsg}
            </MuiAlert>
          </Snackbar>
        </Box>
      </ThemeProvider>

    );
    // Customizable Area End
  }
}
export default (withWidth()(ScheduledNotifications));

// Customizable Area Start
export function FieldError({ error, touched } : any) {
  return error && touched ? (
    <div style={{ color: "#e65e52" }}>{error}</div>
  ) : null;
}
const webStyle = {
  logOutModala: {
    width: "835px",
    height: "346px",
    borderRadius: "20px",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
  },

  logOutModalMobile: {
    width: "auto",
    height: "auto",
    borderRadius: "20px",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
    textAlign: "center",
    margin: "0 15px"
  },
  logOutModal: {
    width: "635px",
    height: "346px",
    borderRadius: "20px",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
  },
  noLogout: {
    padding: "20px 60px",
    height: "42px",
    borderRadius: "20px",
    color: "black",
    fontSize: "15px",
    textTransform: "Capitalize",
    border: "1px solid black",
    display: 'flex',
    justifyContent: 'center',
    marginBottom: "20%",
  },
  yesLogout: {
    padding: "20px 60px",
    height: "42px",
    background:
      "linear-gradient(0deg, rgba(16, 41, 114, 1.0), rgba(2, 5, 10, 1.0))",
    borderRadius: "20px",
    color: "white",
    fontSize: "15px",
    textTransform: "Capitalize",
    display: 'flex',
    justifyContent: 'center',
    marginBottom: "20%",
    marginRight: "23%"
  },
  logoutContent: {
    fontSize: "20px",
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: "10%",
  }
};
let stylesscheduledNoti: any = {
  scroll: {
    overflow: 'auto',
    height: 'calc(100vh - 180px)'
  },
  fullscreenSche: {
    position: "fixed",
    top: 0,
    bottom: 0,
    height: "100vh !important",
    minWidth: "100vw",
    fontFamily: "poppins !important",
    display: 'flex',
    boxSizing: 'border-box'
  },
  divContainerBodySche: {
    gap: "10px",
    height: "100%",
    width: "calc(100% - 24%)",
    margin: "0 auto"
  },
  sidebar: {
    width: "24vw",
    fontFamily: 'Poppins',
    marginRight: "-100px",
    zIndex: "9",
    height: "100%",
    position: "fixed",
    bottom: 0,
    top: 0,
  },
  pointerSche: {
    fontFamily: 'Poppins',
    position: "fixed",
    right: "30px",
    top: "20px",
    color: "black",
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
  },
  topNavBarSche: {
    width: "100%"
  },
  sidebarParentSche: {
    width: '24%',
    height: '100vh',
  },
  bellIconSche: {
    textAlign: "right",
    width: "100%",
    height: "75px"
  },
  pageTitleSche: {
    borderTop: "1px solid rgba(128, 128, 128, 0.43)",
    padding: "0px 0px 0px 50px",
    boxShadow: "rgb(34, 34, 34) 0px 4px 6px -6px",
  },
  buttonCreateSch: {
    fontSize: "17px",
    border: "1px solid black",
    borderRadius: "30px",
    padding: "0.8rem 1.5rem",
    marginRight: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "black",
    cursor: "pointer"
  },
  activeButtonSche: {
    fontSize: "17px",
    color: "#fff",
    border: "none",
    borderRadius: "30px",
    padding: "0.4rem 1rem",
    marginRight: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  customTextField: {
    border: "none",
    '& .MuiOutlinedInput-root': {
      border: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root:hover': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      border: 'none',
    },
  }
}
// Customizable Area End

