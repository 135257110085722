import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

const CommissionChart = (props: any) => {
  const { yTitle, colorArray, commissionsData } = props
  const [activeLine, setActiveLine] = useState<string | null>(null);

  // Define an array of line properties
  const lines = [
    { dataKey: 'deepset_fx_commissions', color: colorArray[0] },
    { dataKey: 'affiliate_commissions', color: colorArray[1] },
    { dataKey: 'master_trader_commissions', color: colorArray[2] },
    { dataKey: 'tinkerbell_commissions', color: colorArray[3] },
  ];

  // Custom tooltip content function
  const renderTooltipContent = (data: any) => {
    if (!data.active || !activeLine) {
      return null;
    }

    let index = lines.findIndex((line) => line.dataKey === activeLine);
    if (index !== -1 && data.active && data.payload && data.payload[index]) {
      return (
        <div className="customTooltip" style={{ minWidth: '115px', maxWidth: '180px' }}>
          <div>
            <span style={{ fontSize: "16px", fontWeight: 600, wordBreak: 'break-word', padding: '5px 12px' }}>{data.payload[index].value}</span>
            <span style={{ fontSize: "14px", fontWeight: 400, padding: '5px 12px' }}>{data.label}</span>
            <div style={{
              position: 'absolute',
              width: '0',
              height: '0',
              borderLeft: '14px solid transparent',
              borderRight: '14px solid transparent',
              borderTop: '14px solid white',
              left: '50%',
              transform: 'translateX(-50%)',
              top: '100%',
            }}></div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div style={{ overflowX: 'auto', overflowY: 'hidden', maxWidth: "675px" }} className='responsivePadding'>
      <div style={chartStyle.YAxisLable}>{yTitle}</div>
      <LineChart
        width={commissionsData.length * 80}
        data={commissionsData}
        height={380}
        margin={{
          top: 0,
          right: 50,
          left: 10,
          bottom: 5,
        }}
      >
        <CartesianGrid vertical={true} horizontal={false} stroke={'#F1F1F5'} strokeWidth={1} />
        <XAxis dataKey="name" axisLine={{ stroke: '#F1F1F5' }} tickMargin={10} style={chartStyle.text} />
        <YAxis axisLine={{ stroke: '#F1F1F5' }} tickMargin={10} />
        <Tooltip content={renderTooltipContent} />
        {lines.map((ele: any, index: number) => (
          <Line key={index} type="monotone" dataKey={ele.dataKey} stroke={ele.color} strokeWidth={3} dot={false} 
          style={{cursor: 'pointer'}}
          onMouseEnter={() => setActiveLine(ele.dataKey)}
          onMouseLeave={() => setActiveLine(null)}
          />
        ))}
      </LineChart>
    </div>
  );
}

let chartStyle: any = {
  text: {
    fontSize: '14px',
    color: '#92929D',
  },
  YAxisLable: {
    marginBottom: '24px',
    color: '#171725',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: ' 0.1px'
  }
}
export default CommissionChart