import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react"
import Cookies from "js-cookie"
interface CustomTypeData {
  method: string,
  endPoint: string,
}
// Customizable Area End

export const configJSON = require("./config");
const settingImages = require("./assets");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  width?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userData: {
    email: string,
    name: string,
    imageUrl: string,
    userType: string,
    loginID : string,
    phoneNo : string,
    address : string,
    userActivationUrl:string
  }
  showAlert: boolean,
  alertType: any,
  usersUpdateError: any,
  userSuccessAlert: string,
  showPassword:boolean,
  showChangePassword: boolean,
  showEditProfile: boolean
  showConfirmPassword : boolean,
  confirmPassword:any,
  changePassword:any,
  storeChangePassword:any,
  changePasswordField : boolean,
      confirmPasswordField : boolean,
      editModePhone:boolean,
      editModeAddress:boolean,
      editModeEmail:boolean,
      userAddress : any,
      userSecondaryEmail:any,
      userNumber:any,
      userEditProfile : any,
      profileImage:any,
      userRemoveProfile:any,
      userProfileData: any,
      profileImageApply: any,
      imageUploadcheck:boolean,
      mobileOpen:boolean,
      isButtonAble:boolean, 
      validImage:boolean,
      unreadNotificationRecords: Array<any>,
      anchorEl: any,
      scrollPage: number,
      isLoading: boolean,

  // Customizable Area End
}

interface SS {
  id?: any;
}

export default class SettingsController extends BlockComponent<
  Props,
  S,
  SS
> {

  public editPhone: any;
  public editAddress : any;
  public editEmail : any;
  userNotificationsApiCallId: string = "";
  markAllReadApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.editPhone = React.createRef();
    this.editAddress = React.createRef();
    this.editEmail = React.createRef();


    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      userData: {
        email: "",
        name: "",
        imageUrl: "",
        userType: "",
        loginID: "",
        phoneNo : "",
        address:"",
        userActivationUrl:""
      },
      showAlert: false,
      alertType: '',
      usersUpdateError: [],
      userSuccessAlert: '',
      showPassword:false,
      showChangePassword:true,
      showEditProfile:false,
      showConfirmPassword:false,
      confirmPassword:"",
      changePassword:"",
      storeChangePassword: {},
      changePasswordField : false,
      confirmPasswordField : false,
      editModePhone:false,
      editModeAddress:false,
      editModeEmail:false,
      userAddress : "",
      userSecondaryEmail:"",
      userNumber:"",
      userEditProfile :{},
      profileImage:"",
      userRemoveProfile:{},
      userProfileData:{},
      profileImageApply:"",
      imageUploadcheck:false,
      mobileOpen:false,
      isButtonAble:false,
      validImage: true,
      unreadNotificationRecords: [],
      anchorEl: null,
      scrollPage:1,
      isLoading: false,

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId != null) {
        switch (true) {
              case apiRequestCallId === this.changePasswordApiCallID:
              this.setChangePasswordCallId(responseJson)
                break;
                case apiRequestCallId === this.editProfileCallId:
              this.setEditProfileCallId(responseJson)
                break;
                case apiRequestCallId === this.removeProfileImageCallId:
              this.setRemoveProfileCallId(responseJson)
                break;
                case apiRequestCallId === this.userProfileCallID:
                  this.setUserProfile(responseJson)
                  break;
                  case apiRequestCallId === this.getUserPermissionsApiCallId:
                    this.getUserPermissions(responseJson)
                    break;

                case apiRequestCallId === this.userNotificationsApiCallId:
                  this.getSettingEdit(responseJson)
                  break;
                case apiRequestCallId === this.markAllReadApiCallId:
                  this.clearAllSettings(responseJson)
                  break;
                 
          default:
        }
      }
    }
    
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    // Data from API
    // Redirect User Who is not logged in
    this.redirectInvalidAccounts()
    //Data from Cookie
    this.fetchUserAccountData();
    this.userEditProfileData();
     this.handleUserPermissionList();
    this.userNotificationsApiCallId = this.clientApiCallSettingsEdit(configJSON.userNotifications + `&page=${this.state.scrollPage}`, configJSON.validationApiMethodType)
  }

  userProfileCallID : string ="";
  getUserPermissionsApiCallId: string="";
  userEditProfileData =() =>{
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("authToken"),
    };   
     const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.userProfileCallID = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    'account_block/get_profile'
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'GET'
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return this.userProfileCallID;
  };
  handleUserPermissionList =() =>{
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("authToken"),
    };   
     const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getUserPermissionsApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    'bx_block_roles_permissions/user_permissions'
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'GET'
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return this.getUserPermissionsApiCallId;
  };
  redirectInvalidAccounts = () => {
    if (!localStorage.getItem("authToken") || !Cookies.get("userProfile")) {
      this.props.navigation.navigate("EmailAccountLoginSignup")
    }
  }
  fetchUserAccountData = () => {
    const cookieData: any = Cookies.get("userProfile");
    const parsedCookieData = JSON.parse(cookieData).data.attributes
    this.setState({
      userData: {
        imageUrl: parsedCookieData.profile_image,
        userType: parsedCookieData.user_role,
        email: parsedCookieData.email,
        name: parsedCookieData.first_name + " " + parsedCookieData.last_name,
        loginID : parsedCookieData.login_id,
        phoneNo : parsedCookieData.phone_number,
        address :parsedCookieData.address,
        userActivationUrl:parsedCookieData.mt_account_activation_url
      }
    })
  }
  handelInput = (e: any) => {
    const { name, value } = e.target;
    this.setState({isButtonAble : true})
    if (name === 'confirmPassword') {
      this.setState({
        confirmPassword: value,
      });
    }
    if (name === 'changePassword') {
      this.setState({
        changePassword: value,
      });
    }
    if (name === 'secondaryEmail') {
      this.setState({
        userSecondaryEmail: value,
      });
    }
    if (name === 'address') {
      this.setState({
        userAddress: value,
      });
    }
    if (name === 'phoneNumber') {
      this.setState({
        userNumber: value,
      });
    }
  };
  handleAlertClose = () => {
    this.setState({ showAlert: false });
  };
   togglePasswordVisibility = () => {
    this.setState({showPassword : !this.state.showPassword});
  };
  toggleConfirmPasswordVisibility =() =>{
    this.setState({showConfirmPassword : !this.state.showConfirmPassword});

  }
  changePasswordPage =() =>{
    this.setState({showChangePassword : true})
    this.setState({showEditProfile : false})
    this.props.navigation.navigate("Settings")
  }
  editProfilePage =() =>{
    this.setState({showChangePassword : false})
    this.setState({showEditProfile : true})
    this.props.navigation.navigate("EditProfile")
  }
  changePasswordApiCallID: string =""
  changePasswordBtn =() =>{
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("authToken")
    };
const httpBody = {
  "data" :{
    "new_password": `${this.state.changePassword}`,
    "confirm_password": `${this.state.confirmPassword}`
  }
};

const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.changePasswordApiCallID = requestMessage.messageId;
requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  'bx_block_settings/new_password'
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestBodyMessage),
  JSON.stringify(httpBody)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  'PUT'
);

runEngine.sendMessage(requestMessage.id, requestMessage);

return this.changePasswordApiCallID;
  }
   setChangePasswordCallId =(responseJson:any) =>{
  if(responseJson && !responseJson.errors && this.state.validImage === true){
      this.setState({
        storeChangePassword: responseJson.data,
        userSuccessAlert: "Password Updated Successfully",
        showAlert: true,
        alertType: 'success',
        changePasswordField:false,
        confirmPasswordField:false
      });
    }
    else{
  this.setState({
        usersUpdateError: responseJson.errors,
        showAlert: true,
        alertType: 'error',
      });
      if(responseJson.errors == "New password is required" || responseJson.errors == "Password is invalid"){
        this.setState({
         changePasswordField: true,
         confirmPasswordField:false
        });
      }
      else if(responseJson.errors ==  "Confirm password is required"){
        this.setState({
         changePasswordField: false,
         confirmPasswordField: true
        });
      }
      else if(responseJson.errors ==  "New password and confirm password does not match"){
        this.setState({
         changePasswordField: true,
         confirmPasswordField:true
        });
      }
    }
   }
  handleEditPhoneClick =() =>{
    this.setState({ editModePhone: true }, () => {
      if (this.editPhone.current) {
  this.editPhone.current.focus();
      }
    });
   }
  handleEditAddressClick =() =>{
    this.setState({ editModeAddress: true }, () => {
      if (this.editAddress.current) {
  this.editAddress.current.focus();
      }
    });
   }
  handleEditEmailClick =() =>{
    this.setState({ editModeEmail: true }, () => {
      if (this.editEmail.current) {
  this.editEmail.current.focus();
      }
    });
   }
  editProfileCallId : string =""
   handleUserProfile =() => {
    this.setState({editModeAddress:false, editModePhone:false, editModeEmail:false})
    if(this.state.imageUploadcheck===true){
      this.setState({profileImage:'',imageUploadcheck:false})
    }
    
      const header = {
        "Content-Type": "application/json",
      "token": localStorage.getItem("authToken")
      };

  const httpBody ={
    "data": {
        "address": this.state.userAddress,
        "full_phone_number": this.state.userNumber,
        "secondry_email":this.state.userSecondaryEmail,
        "profile_image": this.state.profileImageApply

}
}
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.editProfileCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    'bx_block_settings/update_account'
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "PUT"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return this.editProfileCallId;
   }
    
  setEditProfileCallId =(responseJson : any) =>{
  if(responseJson && !responseJson.errors){
      this.setState({
        userEditProfile : responseJson.data,
        userSuccessAlert: "Profile Updated Successfully",
        showAlert: true,
        alertType: 'success',
      });
    this.userEditProfileData();

    }
    else{
  this.setState({
        usersUpdateError: responseJson.errors[0],
        showAlert: true,
        alertType: 'error',
      });
    }
   }
  convertBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }
   updateProfileImage = async(e : any) =>{
    const imageUrl:any = (e.target.files[0]);
    const fileType=imageUrl.type;
    if(!fileType.startsWith('image/')){
        this.setState({
          usersUpdateError: "Only Supported Images",
          showAlert: true,
          alertType: 'error',validImage: false,
          isButtonAble:false
        });
        return;
    }
  const objectUrl:any = URL.createObjectURL(e.target.files[0]);
  this.setState({profileImage : objectUrl, imageUploadcheck:true})
   const base64: any = await this.convertBase64(imageUrl)
   const baseImage=base64.split(",").pop()
   this.setState({profileImageApply : baseImage})
      this.setState({isButtonAble:true})
   }
   removeProfileImageCallId : string ="";
   removeProfileImage = () => {
        const header = {
          "Content-Type": "application/json",
        "token": localStorage.getItem("authToken")
        };
   const httpBody ={
      "login_id":`${this.state.userData.loginID}`
      }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.removeProfileImageCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_settings/remove_profile'
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return this.removeProfileImageCallId;
     
   }
  setRemoveProfileCallId =(responseJson : any) =>{
  if(responseJson && !responseJson.errors){
      this.setState({
        userRemoveProfile : responseJson,
        userSuccessAlert: "Profile Image Deleted Successfully",
        showAlert: true,
        alertType: 'success',
        profileImageApply:'',
        
      });
    this.userEditProfileData();
    localStorage.setItem("profileImage",`${settingImages.defaultImage}`)
    const fileInput: any = document.getElementById('image-upload');
    if (fileInput) {
      fileInput.value = '';
    }
    }
    else{
      this.setState({
            usersUpdateError: responseJson.errors,
            showAlert: true,
            alertType: 'error',
          });
        }
   }
   getUserPermissions = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      let sidebar: any = responseJson.data;  
      localStorage.setItem('sidebarDetails', JSON.stringify(sidebar));
      window.dispatchEvent(new Event('storage'))
    }
  }
      setUserProfile = (responseJson : any) =>{
    this.setState({
      userProfileData : responseJson,
      })
      this.setState({
        userNumber : this.state.userProfileData.data.attributes.full_phone_number,
        userSecondaryEmail : this.state.userProfileData.data.attributes.secondry_email,
        userAddress : this.state.userProfileData.data.attributes.address,
        profileImage : this.state.userProfileData.data.attributes.profile_image
      })
   }
      setImage =() =>{
        if(this.state.profileImage){
    localStorage.setItem("profileImage", this.state.profileImage)
    return this.state.profileImage
    }
    else if(this.state.profileImage == null){
      localStorage.setItem("profileImage",`${settingImages.defaultImage}`)
      return `${settingImages.defaultImage}` 
    }
    else{
      localStorage.setItem("profileImage", this.state.profileImageApply)
      return this.state.profileImageApply
    }
   }
      handleDrawersToggle = () => {
    this.setState((prevState: any) => ({
      mobileOpen: !prevState.mobileOpen,
    }));
  };

  handleClickPopUp = (event: any) => {
    this.setState({
      anchorEl: event?.currentTarget
    })
  };

  handleClosePopUp = () => {
    this.setState({ anchorEl: null })
  }

  onScrollUnreadNotificationLists = () => {
    this.setState({
      isLoading: true
    }, () => this.userNotificationsApiCallId = this.clientApiCallSettingsEdit(configJSON.userNotifications + `&page=${this.state.scrollPage}`, configJSON.validationApiMethodType))
  }

  handleClearAllNoti = () => {
    this.markAllReadApiCallId = this.clientApiCallSettingsEdit(configJSON.markAllRead, configJSON.patchMethod)
  }

  clientApiCallSettingsEdit = (endPoint: string, method: string, httpBody?: any) => {
    let authToken = localStorage.getItem("authToken");
    const header = {
      token: authToken,
      "Content-Type": "application/json",
    };
    const clientReqMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    clientReqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
    );
    clientReqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint
    );
    clientReqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), method
    );
    runEngine.sendMessage(clientReqMessage.id, clientReqMessage);
    return clientReqMessage.messageId
  }

  getSettingEdit = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      const unreadData: any = responseJson.data;
      const items = [...this.state.unreadNotificationRecords, ...unreadData]
      this.setState({
        unreadNotificationRecords: items,
        scrollPage: responseJson.meta.next_page,
        isLoading: false
      })
    } else {
      this.setState({
        scrollPage: 1,
        unreadNotificationRecords: [],
        isLoading: false,
      })
    }
  }

  clearAllSettings = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({
        unreadNotificationRecords: [],
        isLoading: false,
        scrollPage: 1
      })
    }
  }
  // Customizable Area End
}
