import React from "react";

// Customizable Area Start
import {
  Grid,
  TextField,
  Box,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  withWidth,
  Drawer,
  Popover,
  MenuItem,
  MenuList,
  Button,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import MoreVert from "@material-ui/icons/MoreVert";
import Divider from "@material-ui/core/Divider";
import SideBar from "../../../components/src/SideBar";
import MenuIcon from '@material-ui/icons/Menu';
import "../assets/css/style.css";
const images = require("../../../components/src/assets");
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ModalPopUp from "../../../components/src/ModalPopUp.web";
import moment from 'moment';
import { bellIcon, unreadBellIcon } from "./assets";
import UnreadNotificationList from "../../../components/src/UnreadNotificationList.web";
import Snackbar from '@material-ui/core/Snackbar';
// Customizable Area End

import UserNotificationTdController, {
  Props,
} from "./UserNotificationTdController";
import NotificationsHeaderSent from "../../../components/src/NotificationsHeader";
import UserSecondNotiHeader from "../../../components/src/UserSecondNotiHeader.web";
import AlertSechNoti from "../../../components/src/AlertNotification.web";
// Customizable Area Start
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins'
  },
});
export class UserReminderAll extends UserNotificationTdController {
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleTargetRow = () => {
    let response: any = {};
    if (this.state.allReminderList.items) {
      response = this.state.allReminderList.items.find((item: any) => item.id === this.state.getUpdatedId);
    }
    return response;
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { showModal, allReminderList, searchState, updateStatus, showUpdateRowData, unreadNotificationRecords, isLoading, scrollPage } = this.state;
    const targetData = this.handleTargetRow()
    const { width } = this.props;
    const isMobile = /s/.test(width);

    return (
      <ThemeProvider theme={theme}>
        <Box style={stylesAllReminder.fullscreenBody} className="table_body">
          <Box style={stylesAllReminder.sidebarParentBodyBody}>
            {isMobile ?
              <div
                style={{
                  top: "10px",
                  left: "12px",
                  position: "fixed", background: "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
                }}
              >
                <IconButton
                  style={{ color: 'white' }}
                  onClick={this.handleDrawerToggles}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  onClose={this.handleDrawerToggles}
                  open={this.state.mobileOpen}
                  ModalProps={{
                    keepMounted: true,
                  }}
                >
                  {<SideBar
                    style={stylesAllReminder.sidebar}
                    activeMenuItem={"Notifications & Reminders"}
                    navigate={this.props.navigation.navigate}
                    userData={this.state.userData}
                  />}

                </Drawer>
              </div> :
              <div className="sidebarResponse" style={stylesAllReminder.sidebar}>
                <SideBar
                  activeMenuItem={"Notifications & Reminders"}
                  navigate={this.props.navigation.navigate}
                  userData={this.state.userData}
                />
              </div>}
          </Box>
          <Box className="width_100_p" style={stylesAllReminder.divContainerBody} >
            <div style={stylesAllReminder.topNavBarBody}>
              <>
                <div style={stylesAllReminder.bellIconBody}>
                  {this.state.userData.userType !== "admin" &&
                    <>
                      <img
                        src={unreadNotificationRecords.length > 0 ? unreadBellIcon : bellIcon}
                        data-test-id="notificationTab"
                        style={stylesAllReminder.pointerBody}
                        onClick={this.handleClickPopOver}
                      />
                      <UnreadNotificationList
                        anchorEl={this.state.anchorElNoti}
                        handleClosePopOver={this.handleClosePopOverNoti}
                        navigate={this.props.navigation.navigate}
                        notificationData={unreadNotificationRecords}
                        isLoading={isLoading}
                        unreadNotificationList={this.onScrollUnreadNotificationList}
                        handleClearAll={this.handleClearAll}
                        scrollPage={scrollPage}
                      />
                    </>
                  }
                </div>
              </>
              <div className="padding_0_res" style={stylesAllReminder.pageTitleBody} >
                <NotificationsHeaderSent
                  clickTab={this.clickTab}
                  state={this.state}
                  goToSentNotifications={this.goToAll}
                  goToReminders={this.goToAllReminder}
                />
              </div>
            </div>

            <Box className="verticalScroll">
              <Box className="scrollMaxHeight" style={{ margin: "24px", overflow: 'auto', minHeight: '80%' }}>
                <Box className="BoxFlex" style={{ marginTop: "0px" }}>
                  <UserSecondNotiHeader
                    state={this.state}
                    clickTab={this.clickTabNoti}
                    goToAll={this.goToAllReminder}
                    goToUnread={this.goToUnreadReminder}
                    goToRead={this.goToReadReminder}
                  />
                  <Box style={{ marginRight: "4px" }}>
                    <TextField
                      label="Search by Title"
                      data-test-id="searchBtnAll"
                      className="searchInput"
                      value={searchState}
                      onChange={this.reminderSearchInput}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment variant="filled" position="end">
                            <IconButton
                              onClick={() => this.handleSearch('allReminder')}
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Box>
                </Box>
                <Box className="dynamicHeight">
                  <Table className="PTable">
                    {!showUpdateRowData && <TableHead>
                      <TableRow className="tableRow rowCell" style={stylesAllReminder.titleBar}>
                        <TableCell>
                          Label
                        </TableCell>
                        <TableCell>
                          Sent By
                        </TableCell>
                        <TableCell>
                          Date
                        </TableCell>
                        <TableCell>
                          More
                        </TableCell>
                      </TableRow>
                    </TableHead>}
                    <TableBody>
                      {showUpdateRowData && targetData ?
                        <>
                          <Grid item xs={12}>
                            <div style={{ borderRadius: "10px", border: "1px solid #C7C3C3" }}>
                              <div className="paddingBottomDflex" style={{ justifyContent: "space-between" }}>
                                <Box className="customTextFieldBox" style={{ padding: "12px", width: "fit-content", maxWidth: "500px", wordBreak: "break-all", minWidth: "200px" }}>
                                  <span> {targetData.attributes.notification.attributes.headings}</span>
                                </Box>
                                <div className="flexContainer">
                                  <span>{targetData.attributes.notification.attributes.created_by.first_name + " " + targetData.attributes.notification.attributes.created_by.last_name}</span>
                                  <span>{moment.utc(targetData.attributes.created_at, 'YYYY-MM-DD hh:mm').local().format('D-M-YYYY, h.mm A')}</span>
                                  <div>
                                    <IconButton>
                                      <MoreVert style={{ color: "#000000" }} />
                                    </IconButton>
                                  </div>
                                </div>
                              </div>
                              <Grid item xs={12}>
                                <form>
                                  <div style={stylesAllReminder.card}>
                                    <div className="borderBottomCss">
                                      <Box style={stylesAllReminder.titleText}>
                                        <span> {targetData.attributes.notification.attributes.headings}</span>
                                      </Box>
                                      <span style={{ marginLeft: "10px" }}>Date {moment.utc(targetData.attributes.created_at, 'YYYY-MM-DD hh:mm').local().format('D-M-YYYY, h.mm A')}</span>
                                    </div>
                                    <p style={{ paddingRight: "32px", wordBreak: "break-all" }}>
                                      <span style={{ fontSize: "17px" }} dangerouslySetInnerHTML={{ __html: targetData.attributes.notification.attributes.contents }}>
                                      </span>
                                    </p>
                                    <div style={stylesAllReminder.footer}>
                                      <div style={{ display: "flex" }}>
                                        <img src={`${images.iconLogo}`} style={{ height: "50px", width: "170px", marginRight: "10px" }} />
                                        <p> {moment.utc(targetData.attributes.notification.attributes.sent_date, 'YYYY-MM-DD').local().format('D-M-YYYY')}</p>
                                      </div>
                                      <Button
                                        data-test-id="cancelBtn"
                                        style={stylesAllReminder.cancelBtn}
                                        onClick={() => {
                                          this.setState({
                                            showUpdateRowData: false
                                          })
                                        }}>Back</Button>
                                    </div>
                                  </div>
                                </form>
                              </Grid>
                            </div>
                          </Grid>
                        </>
                        :
                        allReminderList.items.length > 0 &&
                        allReminderList.items.map((allReminder: any) => (
                          <TableRow
                            key={allReminder.id}
                            className="tableRow rowCell"
                            style={{ backgroundColor: this.handleRowColor(allReminder) }}
                            onClick={() => this.onRowClickData(allReminder.id)}
                            data-test-id="showUpdateRowBtn"
                          >
                            <TableCell>
                              {allReminder.attributes.notification.attributes.headings}
                            </TableCell>
                            <TableCell>
                              {allReminder.attributes.notification.attributes.created_by.first_name + " " + allReminder.attributes.notification.attributes.created_by.last_name}
                            </TableCell>
                            <TableCell>
                              {moment.utc(allReminder.attributes.created_at, 'YYYY-MM-DD hh:mm').local().format('D-M-YYYY, h.mm A')}
                            </TableCell>
                            <TableCell>
                              <div
                                onClick={(e: any) => {
                                  if (e) {
                                    e.stopPropagation()
                                  }
                                }}
                                data-test-id="stopPropagationBtn"
                              >
                                <IconButton
                                  data-test-id="moreBtn"
                                  onClick={(e: any) => {
                                    this.handleIconRowClick(e, allReminder, 'allReminder');
                                  }}
                                >
                                  <MoreVert style={{ color: "#000000" }} />
                                </IconButton>
                                <Popover
                                  data-test-id="showPopOverBtn"
                                  anchorEl={this.state.anchorEl}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  onClose={this.handleClosePopOver}
                                  open={Boolean(this.state.anchorEl)}
                                  PaperProps={{
                                    style: {
                                      boxShadow: "#CECECE 0px 2px 2px",
                                    },
                                  }}
                                  transformOrigin={{
                                    horizontal: "center",
                                    vertical: "top",
                                  }}
                                >
                                  <MenuList>
                                    <MenuItem
                                      data-test-id="editBtnPopover"
                                      onClick={() => {
                                        this.setState(
                                          {
                                            updateStatus: !updateStatus,
                                          },
                                          () => {
                                            this.setUpdateStatus();
                                          }
                                        );
                                      }}>
                                      {this.handleReminderStatus(updateStatus)}
                                    </MenuItem>
                                    <Divider
                                      style={{ padding: "0 10%", width: "80%" }}
                                    />
                                    <MenuItem
                                     data-test-id="deleteBtn"
                                      onClick={() =>
                                        this.setState({
                                          anchorEl: null,
                                          showModal: true
                                        })
                                      }
                                    >
                                      Delete
                                    </MenuItem>
                                  </MenuList>
                                </Popover>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      {(allReminderList.items.length === 0 && !allReminderList.hasMore) &&
                        <TableRow style={{ textAlign: "center" }}>
                          <TableCell align="center" style={{ textAlign: "center" }} colSpan={4}>No data found</TableCell>
                        </TableRow>
                      }
                      <TableRow style={{ textAlign: "center" }} ref={this.loadingRef}>
                        {(allReminderList.hasMore && !showUpdateRowData) &&
                          <TableCell colSpan={4} style={{ textAlign: "center" }} align="center">Loading...</TableCell>
                        } </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {showModal && <ModalPopUp closeModal={this.closeModal} handleDelete={this.handleDeleteNotification} showModal={showModal} isMobile={isMobile} />}
        <Snackbar onClose={this.handleCreateCloseAlert} open={this.state.alertState} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <AlertSechNoti sx={{ width: '100%' }} severity={this.state.alertType}>
            {this.state.createAlertMsg}
          </AlertSechNoti>
        </Snackbar>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

let stylesAllReminder: any = {
  sidebar: {
    zIndex: "9",
    height: "100%",
    position: "fixed",
    bottom: 0,
    top: 0,
    width: "24vw",
    fontFamily: 'Poppins',
    marginRight: "-100px",
  },
  fullscreenBody: {
    fontFamily: "poppins !important",
    display: 'flex',
    position: "fixed",
    top: 0,
    bottom: 0,
    boxSizing: 'border-box',
    height: "100vh !important",
    minWidth: "100vw",
  },
  pointerBody: {
    marginTop: "20px",
    marginRight: "20px",
    cursor: "pointer",
    color: "black",
    width: "32px",
    height: "32px"
  },
  divContainerBody: {
    // width: "calc(100% - 24%)",
    width: "76%",
    marginLeft: "auto",
    gap: "10px",
    height: "100%",
  },
  sidebarParentBodyBody: {
    height: '100vh'
  },
  topNavBarBody: {
    width: "100%"
  },
  pageTitleBody: {
    borderTop: "1px solid rgba(128, 128, 128, 0.43)",
    boxShadow: "rgb(34, 34, 34) 0px 4px 6px -6px",
    padding: "0px 0px 0px 50px"
  },
  bellIconBody: {
    width: "100%",
    textAlign: "right",
    height: "75px",
    boxShadow: "none"
  },
  titleBar: {
    outline: "unset",
    background: "#DCDCDC",
  },
  card: {
    borderRadius: "18px",
    background: " #F9F9F9",
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.50)",
    padding: "24px",
    margin: "0 24px 24px"
  },
  titleText: {
    fontSize: "18px",
    fontWeight: "500",
    color: "#0F1119",
    marginBottom: "18px",
    maxWidth: "500px",
    wordBreak: "break-all",
  },
  cancelBtn: {
    width: "120px",
    borderRadius: "30px",
    fontSize: "17px",
    backgroundColor: "rgb(199, 195, 195)",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  }
}
// Customizable Area End
export default withWidth()(UserReminderAll)