import { IBlock } from "../../../framework/src/IBlock";
import React from 'react';
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import Cookies from "js-cookie"
// Customizable Area Start
import { bellIcon, unreadBellIcon } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  width: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: any,
  txtSavedValue: any,
  userData: any,
  enableField: any,
  currentDate: any,
  anchorEl: any,
  showModal: any,
  tabValue: any;
  allNoti: boolean;
  readNoti: boolean;
  unreadNoti: boolean;
  notiBorder: boolean;
  ReminderNotiBorder: boolean;
  mobileOpen: boolean;
  allReminderList: {
    items: Array<any>,
    hasMore: boolean,
    page: number
  };
  allNotificationList: {
    items: Array<any>,
    hasMore: boolean,
    page: number
  };
  readReminderList: {
    items: Array<any>,
    hasMore: boolean,
    page: number
  };
  readNotificationList: { 
    items: Array<any>,
    hasMore: boolean,
    page: number
  }
  unreadReminderList: {
    items: Array<any>,
    hasMore: boolean,
    page: number
  };
  unreadNotificationList: {
    items: Array<any>,
    hasMore: boolean,
    page: number
  };
  showReminderDetails: boolean;
  observer: IntersectionObserver | null;
  loadingRef: any;
  readObserver: IntersectionObserver | null;
  readLoadingRef: any;
  unreadObserver: IntersectionObserver | null;
  unreadLoadingRef: any;
  observerNoti: IntersectionObserver | null;
  loadingRefNoti: any;
  readObserverNoti: IntersectionObserver | null;
  readLoadingRefNoti: any;
  unreadObserverNoti: IntersectionObserver | null;
  unreadLoadingRefNoti: any;
  searchState: any,
  searchTerm: any,
  selectedRowId: string,
  updateStatus: boolean,
  alertState: boolean;
  createAlertMsg: string;
  errorCreateAlertMsg: string;
  alertType: string;
  getUpdatedId: string;
  showUpdateRowData: boolean;
  userAction:string
  userAllJsonData: any;
  sortColumn: any;
  sortOrder: any;
  showRead: boolean;
  unreadNotificationRecords: Array<any>;
  anchorElNoti: any;
  scrollPage:number;
  isLoading: boolean,
  // Customizable Area End
}
type RawDraftContentBlock = {
  type: string;
  text: any;
  entityRanges: never[];
  key: string;
  depth: number;
  inlineStyleRanges: {
    length: number;
    style: string;
    offset: number;
  }[];
};
interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}
// Customizable Area Start
let currDates: any = new Date().toLocaleDateString('en-GB', {
  month: '2-digit', day: '2-digit', year: 'numeric'
})
let todays: any = Date.now();
let currTimes: any = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit' }).format(todays);
let currDateTimes = (currDates + ", " + currTimes);
// Customizable Area End
export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  GetnotificationApiCallId: string = " "; 
  getApiCallId: string = " ";
  getUserAllNotificationApiCallId: string = "";
  getUserAllReminderApiCallId: string = "";
  getReadNotificationApiCallId:string = " ";
  deleteNotificationApiCallId: string = "";
  setReminderApiCallId: string = "";
  setNotificationApiCallId: string = "";
  observer: IntersectionObserver | null;
  loadingRef: any;
  observerNoti: IntersectionObserver | null;
  loadingRefNoti: any;
  getUserReadReminderApiCallId: string = "";
  getUserUnreadReminderApiCallId: string = "";
  getUnreadNotificationApiCallId:string ="";
  readObserver: IntersectionObserver | any;
  readLoadingRef: any;
  unreadObserver: IntersectionObserver | null;
  unreadLoadingRef: any;
  readObserverNoti: IntersectionObserver | null;
  readLoadingRefNoti: any;
  unreadObserverNoti: IntersectionObserver | null;
  unreadLoadingRefNoti: any;
  userGetnotificationApiCallId: string = " ";
  userUpdateReadAll: string = "";
  userNotificationsApiCallId: string = "";
  markAllReadApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.loadingRef = React.createRef(); // Create a ref for the loading indicator
    this.observer = null;

    this.readLoadingRef = React.createRef(); // Create a ref for the loading indicator
    this.readObserver = null;

    this.unreadLoadingRef = React.createRef(); // Create a ref for the loading indicator
    this.unreadObserver = null;

    this.loadingRefNoti = React.createRef(); // Create a ref for the loading indicator
    this.observerNoti = null;

    this.readLoadingRefNoti = React.createRef(); // Create a ref for the loading indicator
    this.readObserverNoti = null;

    this.unreadLoadingRefNoti = React.createRef(); // Create a ref for the loading indicator
    this.unreadObserverNoti = null;

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      currentDate: currDateTimes,
      // Customizable Area Start
      tabValue: "1",
      notiBorder: false,
      ReminderNotiBorder: false,
      allNoti: false,
      readNoti: false,
      unreadNoti: false,
      mobileOpen: false,
      userData: {
        email: "",
        name: "",
        imageUrl: "",
        userType: "",
      },
      anchorEl: null,
      showModal: false,
      allReminderList: {
        items: [],
        page: 0,
        hasMore: true
      },
      allNotificationList: {   
        items: [],
        page: 0,
        hasMore: true},
      readReminderList: {
        items: [],
        page: 0,
        hasMore: true
      },
      readNotificationList:{   
        items: [],
        page: 0,
        hasMore: true},
      unreadReminderList: {
        items: [],
        page: 0,
        hasMore: true
      },
      unreadNotificationList: {
        items: [],
        page: 0,
        hasMore: true
      },
      showReminderDetails: false,
      observer: null,
      loadingRef: "",
      observerNoti: null,
      loadingRefNoti: "",
      searchState: "",
      searchTerm: "",
      selectedRowId: "0",
      updateStatus: false,
      createAlertMsg: "",
      alertState: false,
      errorCreateAlertMsg: "",
      alertType: '',
      readObserver: null,
      readLoadingRef: "",
      readObserverNoti: null,
      readLoadingRefNoti: "",
      unreadObserver: null,
      unreadLoadingRef: "",
      unreadObserverNoti: null,
      unreadLoadingRefNoti: "",
      getUpdatedId:'',
      showUpdateRowData: false,
      userAction:"",
      showRead: false,
      userAllJsonData: [],
      sortColumn: "",
      sortOrder: "asc",
      unreadNotificationRecords: [],
      anchorElNoti: null,
      scrollPage:1,
      isLoading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  userNotificationsGetData = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      const reData: any = responseJson?.data;
      this.setState({ userAllJsonData: reData })
    } else {
      this.setState({ userAllJsonData: [] })
    }
  }

  goToAllReminder = () => {
    this.props.navigation.navigate("UserReminderAll");
  }
  goToReadReminder = () => {
    this.props.navigation.navigate("UserReminderRead");
  }
  goToUnreadReminder = () => {
    this.props.navigation.navigate("UserReminderUnread");
  }

  clickTab = (newValue: string) => {
    this.setState({ tabValue: newValue })
  }

  goToAll = () => {
    this.props.navigation.navigate("UserNotificationAll");
  }
  goToRead = () => {
    this.props.navigation.navigate("UserNotificationRead");
  }
  goToUnread = () => {
    this.props.navigation.navigate("UserNotificationUnread");
  }
 
  searchInput = (e: any) => {
    this.setState({
      searchState: e.target.value
    })
  }

  clickTabNoti = (newValue: string) => {
    this.setState({ tabValue: newValue })
  }

  handleIconClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleIconRowClick = (event: any, data: any, userAction: string) => {
    this.setState({
      anchorEl: event.currentTarget,
      selectedRowId: data.id,
      updateStatus: data.attributes.is_read,
      userAction: userAction
    })
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.receiveApi(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area End
  // Customizable Area Start

  async componentDidMount() {
    let Url = ""
    if (typeof window !== "undefined") {
      Url = window.location.pathname;
    }
    if (Url.includes("/Notifications/All")) {
      this.setState({
        allNoti: true,
        readNoti: false,
        unreadNoti: false,
        notiBorder: true,
        ReminderNotiBorder: false,
      });
    } else if (Url.includes("/Notifications/Read")) {
      this.setState({
        allNoti: false,
        readNoti: true,
        unreadNoti: false,
        notiBorder: true,
        ReminderNotiBorder: false,
      });
    } else if (Url.includes("/Notifications/Unread")) {
      this.setState({
        allNoti: false,
        readNoti: false,
        unreadNoti: true,
        notiBorder: true,
        ReminderNotiBorder: false,
      });
    }
    this.ReminderUrlEvent(Url);
    this.fetchUserData()   
    this.handleLoaderRef()  
    this.userNotificationsApiCallId = this.clientApiCall(configJSON.userNotifications + `&page=${this.state.scrollPage}`, configJSON.getDataMethod) 
    //Data from Cookie
  

    //Data from Cookie

  }

  fetchUserData = () => {
    const cookieData: any = Cookies.get("userProfile");
    const parsedCookieData = JSON.parse(cookieData).data.attributes
    this.setState({
      userData: {
        email: parsedCookieData.email,
        name: parsedCookieData.first_name + " " + parsedCookieData.last_name,
        imageUrl: parsedCookieData.profile_image,
        userType: parsedCookieData.user_role,
      }
    })
  }

  handleLoaderRef = () => {

    this.observer = new IntersectionObserver(this.handleObserver, {
      root: null,
      rootMargin: '20px',
      threshold: [0, 0.25, 0.5, 0.75, 1]
    });

    if (this.loadingRef.current) {
      this.observer.observe(this.loadingRef.current);
    }

    //Data from Cookie
       
    this.readObserver = new IntersectionObserver(this.handleReadObserver, {
      root: null,
      rootMargin: '20px',
      threshold: [0, 0.25, 0.5, 0.75, 1]
    });
  
    if (this.readLoadingRef.current) {
      this.readObserver.observe(this.readLoadingRef.current);
    }

    this.unreadObserver = new IntersectionObserver(this.handleUnreadObserver, {
      root: null,
      rootMargin: '20px',
      threshold: [0, 0.25, 0.5, 0.75, 1]
    });
   
    if (this.unreadLoadingRef.current) {
      this.unreadObserver.observe(this.unreadLoadingRef.current);
    }     
   
    this.readObserverNoti = new IntersectionObserver(
      this.handleObserverNotificationRead
      , {
        root: null,
        rootMargin: '20px',
        threshold: [0, 0.25, 0.5, 0.75, 1]
      });
    if (this.readLoadingRefNoti.current) {
      this.readObserverNoti.observe(this.readLoadingRefNoti.current);
    }
    this.unreadObserverNoti = new IntersectionObserver(this.handleObserverNotificationUnread, {
      root: null,
      rootMargin: '20px',
      threshold: [0, 0.25, 0.5, 0.75, 1]
    });
    if (this.unreadLoadingRefNoti.current) {
      this.unreadObserverNoti.observe(this.unreadLoadingRefNoti.current);
    }
    this.observerNoti = new IntersectionObserver(this.handleObserverNotification, {
      root: null,
      rootMargin: '20px',
      threshold: [0, 0.25, 0.5, 0.75, 1]
    });
    if (this.loadingRefNoti.current) {
      this.observerNoti.observe(this.loadingRefNoti.current);
    }
    //Data from Cookie

  }

  async componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
    if (this.readObserver) {
      this.readObserver.disconnect();
    }
    if (this.unreadObserver) {
      this.unreadObserver.disconnect();
    }
  }

  ReminderUrlEvent = (Url: any) => {
    if (Url.includes("/Reminders/All")) {
      this.setState({
        allNoti: true,
        readNoti: false,
        unreadNoti: false,
        notiBorder: false,
        ReminderNotiBorder: true
      })
    }
    else if (Url.includes("/Reminders/Read")) {
      this.setState({
        allNoti: false,
        readNoti: true,
        unreadNoti: false,
        notiBorder: false,
        ReminderNotiBorder: true
      });
    } else if (Url.includes("/Reminders/Unread")) {
      this.setState({
        allNoti: false,
        readNoti: false,
        unreadNoti: true,
        notiBorder: false,
        ReminderNotiBorder: true
      });
    }
  }

  //Data from Cookie

  //@ts-nocheck

  receiveApi = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getUserAllReminderApiCallId) {
      this.getAllReminderData(responseJson)
    }
    
    if(apiRequestCallId === this.getUserReadReminderApiCallId){
      this.getReadReminderData(responseJson)
    }
    if (apiRequestCallId === this.getUserUnreadReminderApiCallId) {
      this.getUnreadReminderData(responseJson)
    }
    if (apiRequestCallId === this.deleteNotificationApiCallId) {
      this.deleteReminderData(responseJson)
    }
    if (apiRequestCallId === this.setReminderApiCallId) {
      this.updateReminderData(responseJson)
    }
    if (apiRequestCallId === this.getUserAllNotificationApiCallId) {
      this.getAllNotificationData(responseJson)
    }
    if (apiRequestCallId === this.getReadNotificationApiCallId) {
      this.getReadNotificationData(responseJson)
    }
    if (apiRequestCallId === this.getUnreadNotificationApiCallId) {
      this.getUnreadNotificationData(responseJson)
    } 
    if (apiRequestCallId === this.userNotificationsApiCallId) {
      this.getUserUnreadNotification(responseJson)
    }
    if (apiRequestCallId === this.markAllReadApiCallId) {
      this.clearAllUserNoti(responseJson)
    }   
  }

  clientApiCall = (endPoint: string, method: string, httpBody?: any) => {
    let token = "";
    if (typeof window !== "undefined") {
      token = localStorage.getItem("authToken") || ""
    }

    const header = {
      token: token,
      "Content-Type": "application/json",
    };

    const clientRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    clientRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint
    );

    clientRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
    );

    clientRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), method
    );

    if (httpBody) {
      clientRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    }

    runEngine.sendMessage(clientRequestMessage.id, clientRequestMessage);
    return clientRequestMessage.messageId
  }
 
  getAllNotificationData = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      const allNotification: any = responseJson.data;
      const items = [...this.state.allNotificationList.items, ...allNotification]
      this.setState(prevState => ({ ...prevState, allNotificationList: { ...prevState.allNotificationList, items, hasMore: !!responseJson.meta.next_page, page: prevState.allNotificationList.page + 1 } }))
    } else {
      this.setState({
        allNotificationList: {  items: [],
          hasMore: false,
          page: 0 
        }
        })
      }
    }

  getAllReminderData = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      const allReminder: any = responseJson.data;
      const items = [...this.state.allReminderList.items, ...allReminder]
      this.setState(prevState => ({ ...prevState, allReminderList: { ...prevState.allReminderList, items, hasMore: !!responseJson.meta.next_page, page: prevState.allReminderList.page + 1 } }))
    } else {
      this.setState({
        allReminderList: {
          items: [],
          hasMore: false,
          page: 0
        }
      })
    }
  }
  getReadReminderData = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      const readReminder: any = responseJson.data;
      const items = [...this.state.readReminderList.items, ...readReminder]
      this.setState(prevState => ({ ...prevState, readReminderList: { ...prevState.readReminderList, items, hasMore: !!responseJson.meta.next_page, page: prevState.readReminderList.page + 1 } }))
    } else {
      this.setState({
        readReminderList: {
          items: [],
          hasMore: false,
          page: 0
        }
      })
    }
  }

getReadNotificationData=(responseJson:any)=>{
  if (responseJson && !responseJson.errors) {
    const readNotification: any = responseJson.data;
    const items = [...this.state.readNotificationList.items, ...readNotification]
    this.setState(prevState => ({ ...prevState, readNotificationList: { ...prevState.readNotificationList, items, hasMore: !!responseJson.meta.next_page, page: prevState.readNotificationList.page + 1 } }))
  } else {
    this.setState({
      readNotificationList: {
        items: [],
        hasMore: false,
        page: 0
      }
    })
  }
}

  getUnreadReminderData = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      const unreadReminder: any = responseJson.data;
      const items = [...this.state.unreadReminderList.items, ...unreadReminder]
      this.setState(prevState => ({ ...prevState, unreadReminderList: { ...prevState.unreadReminderList, items, hasMore: !!responseJson.meta.next_page, page: prevState.unreadReminderList.page + 1 } }))
    } else {
      this.setState({
        unreadReminderList: {
          items: [],
          hasMore: false,
          page: 0
        }
      })
    }
  }
  getUnreadNotificationData=(responseJson:any)=>{
    if (responseJson && !responseJson.errors) {
      const unreadNotification: any = responseJson?.data;
      const items = [...this.state.unreadNotificationList.items, ...unreadNotification]
      this.setState(prevState => ({ ...prevState, unreadNotificationList: { ...prevState.unreadNotificationList, items, hasMore: !!responseJson.meta.next_page, page: prevState.unreadNotificationList.page + 1 } }))
    } else {
      this.setState({
        unreadNotificationList: {
          items: [],
          hasMore: false,
          page: 0
        }
      })
    }
  }
  deleteReminderData = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({
        showModal: false,
        createAlertMsg: "Delete Successfully!",
        alertType: "success",
        alertState: true       
      })
      this.resetState()
      this.fetchCommonApiData()
    }
  }
 
  updateReminderData = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({
        createAlertMsg: "Update Successfully!",
        alertType: "success",
        alertState: true,
        anchorEl: null
      })
      this.resetState()
      this.fetchCommonApiData()
    }
  }

  handleSearch(userAction: string) {
    this.setState(
      prevState => ({
        searchTerm: prevState.searchState,
        userAction: userAction,
        allReminderList: {
          items: [],
          page: 0,
          hasMore: true
        },
        readReminderList: {
          items: [],
          page: 0,
          hasMore: true
        },
        unreadReminderList: {
          items: [],
          page: 0,
          hasMore: true
        },
      }), () => this.fetchCommonApiData())
  }
  handleSearchNo(userAction: string) {
    this.setState(
      prevState => ({
        searchTerm: prevState.searchState,
        userAction: userAction,
        allNotificationList: {
          items: [],
          page: 0,
          hasMore: true
        },
        readNotificationList: {
          items: [],
          page: 0,
          hasMore: true
        },
        unreadNotificationList: {
          items: [],
          page: 0,
          hasMore: true
        },
      }), () => this.fetchCommonApiData())
  }
  fetchCommonApiData() {
    if (this.state.userAction == 'allReminder') {
      this.getUserAllReminderApiCallId = this.clientApiCall(configJSON.getUserReminder + `&page=${this.state.allReminderList.page + 1}` + `&search=${this.state.searchTerm}`, configJSON.getDataMethod)
      this.setState(prevState => ({ ...prevState, allReminderList: { ...prevState.allReminderList, hasMore: false } }))
    }

    if (this.state.userAction == 'readReminder') {
      this.getUserReadReminderApiCallId = this.clientApiCall(configJSON.getUserReminder + `&page=${this.state.readReminderList.page + 1}` + `&is_read=true&search=${this.state.searchTerm}`, configJSON.getDataMethod)
      this.setState(prevState => ({ ...prevState, readReminderList: { ...prevState.readReminderList, hasMore: false } }))
    }

    if (this.state.userAction == 'unreadReminder') {
      this.getUserUnreadReminderApiCallId = this.clientApiCall(configJSON.getUserReminder + `&page=${this.state.unreadReminderList.page + 1}` + `&is_read=false&search=${this.state.searchTerm}`, configJSON.getDataMethod)
      this.setState(prevState => ({ ...prevState, unreadReminderList: { ...prevState.unreadReminderList, hasMore: false } }))
    }
    if (this.state.userAction == 'allNotification') {
      this.getUserAllNotificationApiCallId = this.clientApiCall(configJSON.getUserNotification + `&page=${this.state.allNotificationList.page + 1}` + `&search=${this.state.searchTerm}`, configJSON.getDataMethod)
      this.setState(prevState => ({ ...prevState, allNotificationList: { ...prevState.allNotificationList, hasMore: false } }))
    }

    if (this.state.userAction == 'readNotification') {
      this.getReadNotificationApiCallId = this.clientApiCall(configJSON.getUserNotification + `&page=${this.state.readNotificationList.page + 1}` + `&is_read=true&search=${this.state.searchTerm}`, configJSON.getDataMethod)
      this.setState(prevState => ({ ...prevState, readNotificationList: { ...prevState.readNotificationList, hasMore: false } }))
    }

    if (this.state.userAction == 'unreadNotification') {
      this.getUnreadNotificationApiCallId = this.clientApiCall(configJSON.getUserNotification + `&page=${this.state.unreadNotificationList.page + 1}` + `&is_read=false&search=${this.state.searchTerm}`, configJSON.getDataMethod)
      this.setState(prevState => ({ ...prevState, unreadNotificationList: { ...prevState.unreadNotificationList, hasMore: false } }))
   }
  }
    
  resetState(){
    this.setState({
      allReminderList: {
        items: [],
        page: 0,
        hasMore: true
      },
      readReminderList: {
        items: [],
        page: 0,
        hasMore: true
      },
      unreadReminderList: {
        items: [],
        hasMore: true,
        page: 0
      },
      allNotificationList: {
        items: [],
        hasMore: true,
        page: 0
      },
      readNotificationList: {
        items: [],
        hasMore: true,
        page: 0
      },
      unreadNotificationList: {
        items: [],
        hasMore: true,
        page: 0
      }
    })
  }
  handleDeleteNotification = () => {
    this.deleteNotificationApiCallId = this.clientApiCall(configJSON.userDeleteEndPoint + this.state.selectedRowId, configJSON.deleteMethod)
  }

  setUpdateStatus = () => {
    const httpBody = { id: this.state.selectedRowId, is_read: this.state.updateStatus }
    this.setReminderApiCallId = this.clientApiCall(configJSON.setReminderStatusEndPoint, configJSON.markAsReadMethod, httpBody)
  }
  
  handleObserver = (entries: any) => {
    const [entry] = entries;
    if (entry.isIntersecting && this.state.allReminderList.hasMore) {
      this.getUserAllReminderApiCallId = this.clientApiCall(configJSON.getUserReminder + `&page=${this.state.allReminderList.page + 1}` + `&search=${this.state.searchTerm}`, configJSON.getDataMethod)
      this.setState(prevState => ({ ...prevState, allReminderList: { ...prevState.allReminderList, hasMore: false } }))
    }
  };
  handleObserverNotification = (entries: any) => {
    const [entry] = entries;
    if (entry.isIntersecting && this.state.allNotificationList.hasMore) {
      this.getUserAllNotificationApiCallId = this.clientApiCall(configJSON.getUserNotification + `&page=${this.state.allNotificationList.page + 1}` + `&search=${this.state.searchTerm}`, configJSON.getDataMethod)
      this.setState(prevState => ({ ...prevState, allNotificationList: { ...prevState.allNotificationList, hasMore: false } }))
    }
  };
  
  handleReadObserver = (entries:any) => {  
    const [entry] = entries;
    if (entry.isIntersecting && this.state.readReminderList.hasMore) {
      this.getUserReadReminderApiCallId = this.clientApiCall(configJSON.getUserReminder + `&page=${this.state.readReminderList.page + 1}` + `&is_read=true&search=${this.state.searchTerm}`, configJSON.getDataMethod)
      this.setState(prevState => ({ ...prevState, readReminderList: { ...prevState.readReminderList, hasMore: false } }))
    }
  };
  handleObserverNotificationRead = (entries: any) => {
    const [entry] = entries;
    if (entry.isIntersecting && this.state.readNotificationList.hasMore) {
      this.getReadNotificationApiCallId = this.clientApiCall(configJSON.getUserNotification + `&page=${this.state.readNotificationList.page + 1}` + `&is_read=true&search=${this.state.searchTerm}`, configJSON.getDataMethod)
      this.setState(prevState => ({ ...prevState, readNotificationList: { ...prevState.readNotificationList, hasMore: false } }))
    }
  };

  handleUnreadObserver = (entries: any) => {
    const [entry] = entries;
    if (entry.isIntersecting && this.state.unreadReminderList.hasMore) {
      this.getUserUnreadReminderApiCallId = this.clientApiCall(configJSON.getUserReminder + `&page=${this.state.unreadReminderList.page + 1}` + `&is_read=false&search=${this.state.searchTerm}`, configJSON.getDataMethod)
      this.setState(prevState => ({ ...prevState, unreadReminderList: { ...prevState.unreadReminderList, hasMore: false } }))
    }
  };
  handleObserverNotificationUnread = (entries: any) => {
    const [entry] = entries;
    if (entry.isIntersecting && this.state.unreadNotificationList.hasMore) {
      this.getUnreadNotificationApiCallId = this.clientApiCall(configJSON.getUserNotification + `&page=${this.state.unreadNotificationList.page + 1}` + `&is_read=false&search=${this.state.searchTerm}`, configJSON.getDataMethod)
      this.setState(prevState => ({ ...prevState, unreadNotificationList: { ...prevState.unreadNotificationList, hasMore: false } }))
    }
  };

  handleClosePopOver = () => {
    this.setState({ anchorEl: null })
  }
  

  reminderSearchInput = (e: any) => {
    this.setState({
      searchState: e.target.value,
    })
  }

  handleCreateCloseAlert = () => {
    this.setState({
      alertState: false,
      createAlertMsg: "",
      errorCreateAlertMsg: "",
    })
  }

  onRowClickData = (updatedId: any) => {
    if (updatedId) {
      this.setState({
        showUpdateRowData: true,
        getUpdatedId: updatedId,
      });
    }
  }

  closeModal = () =>{
    this.setState({ showModal: false })
  }


  handleRowColor = (allReminder: any) => {
    return allReminder.attributes.is_read ? "initial" : "#F1F1F1";
  }

  handleReminderStatus = (updateStatus: boolean) => {
    return updateStatus ? 'Mark as Unread' : 'Mark as Read'
  }

  handleDrawerToggles = () => {
    this.setState((prevState: any) => ({
      mobileOpen: !prevState.mobileOpen,
    }));
  };

  handleClickPopOver = (event: any) => {
    if (this.state.userData.userType !== 'admin') {
      this.setState({
        anchorElNoti: event?.currentTarget
      })
    }
  };
  handleClosePopOverNoti = () => {
    this.setState({ anchorElNoti: null })
  }
  onScrollUnreadNotificationList = () => {
    this.setState({
      isLoading: true
    }, () => this.userNotificationsApiCallId = this.clientApiCall(configJSON.userNotifications + `&page=${this.state.scrollPage}`, configJSON.getDataMethod))
  }

  handleClearAll = () => {
    this.markAllReadApiCallId = this.clientApiCall(configJSON.markAllRead, configJSON.patchMethod)
  }

  getUserUnreadNotification = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      const unreadData: any = responseJson.data;
      const items = [...this.state.unreadNotificationRecords, ...unreadData]
      this.setState({
        unreadNotificationRecords: items,
        scrollPage: responseJson.meta.next_page,
        isLoading: false
      })
    } else {
      this.setState({
        unreadNotificationRecords: [],
        isLoading: false,
        scrollPage: 1
      })
    }
  }

  clearAllUserNoti = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({
        unreadNotificationRecords: [],
        isLoading: false,
        scrollPage: 1
      })
    }
  }
  handleIcon=()=>{
   const icon= this.state.unreadNotificationRecords.length > 0 ? unreadBellIcon : bellIcon;
   return icon;
  }
  // Customizable Area End
}



