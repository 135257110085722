import React from "react";

// Customizable Area Start
import {
  TextField,
  Box,
  Grid,
  withWidth,
  Drawer
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import SideBar from "../../../components/src/SideBar";
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
const images = require("../../../components/src/assets");
import * as Yup from "yup";
import NotificationsTdController, {
  Props,
} from "./NotificationsTdController";
import NotificationsHeaderCreate from "../../../components/src/NotificationsHeader";
import SecondNotiHeaderCreate from "../../../components/src/SecondNotiHeader";
import { Formik } from "formik"
import { Editor } from "react-draft-wysiwyg";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import "../assets/css/style.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {EditorState } from 'draft-js';
// Customizable Area End
// Customizable Area Start
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins !important'
  },
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const configJSON = require("./config");

const MenuProps = {
  ...configJSON.commonMenuConfig,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      borderRadius: "20px",
      padding: "10px",
      marginTop: '30px',
      backgroundColor: "#F5F5F5",
    },
  },
};
export const timeValidation = Yup.string().when("sent_date", {
  is: (send_date) => { return send_date ? new Date() < new Date(send_date) : true; },
  then: Yup.string().required("Required Field"),
  otherwise: Yup.string().test("is-future-time", "Past Time Cannot be selected",
    function (value) {
      const newDate = new Date(new Date().toISOString().split("T")[0] + " " + value);
      const currentDate = new Date()
      currentDate.setSeconds(0)
      return newDate.toString() >= currentDate.toString();
    }
  )
})

const notificationSchema = Yup.object().shape({
  contents: Yup.string()
    .required('Content is Required'),
  headings: Yup.string()
    .required('Title is Required'),
  sent_time: timeValidation,
  sent_date: Yup.date().required(),
});
export function FieldError({ error, touched } : {error: any, touched: any}) {
  return error && touched ? (
    <div
      style={{ color: "#e65e52" }}
    >{error}</div>
  ) : null;
}
export class CreateNotifications extends NotificationsTdController {
  formik: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { personName, userJsonData } = this.state;
    const { width } = this.props;
    const isMobile = /s/.test(width);
    const getSnakbar = () => {
      return (
        <Snackbar open={this.state.alertState} onClose={this.handleCreateCloseAlert} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <MuiAlert elevation={6} variant="filled" severity={this.state.alertType} style={{ width: '100%' }}>
            {this.handleSuccessError()}
          </MuiAlert>
        </Snackbar>
      )
    }
    const getElement = () => {
      return (

        <Grid container spacing={0}>
          <Formik
            initialValues={{
              headings: "",
              sent_date: new Date().toISOString().split('T')[0],
              sent_time: this.state.formattedTime,
              contents: "",
              id: "",
              send_to_all: true
            }}
            data-test-id='formicData'
            innerRef={(p: any) => this.formik = p}
            enableReinitialize={true}
            onSubmit={(values, { resetForm }) => {
              this.formikValidation(values, resetForm)
            }}
            validationSchema={notificationSchema}
          >

            {({ values,
              errors,
              resetForm,
              setFieldTouched,
              setFieldValue,
              touched, handleSubmit }) => {
              return (
                <>
                  <Box className="BoxFlex bxFlexSelct WrapperBox" style={{ width: "100%",paddingTop:"20px",paddingBottom:"20px",alignItems:'center' }}>
                    <FormControl style={{ width: 170 }}
                      className="selectCheckbox"
                    >
                      <InputLabel id="demo-multiple-checkbox-label">
                        <AccountCircleOutlinedIcon />
                        <span className="textLabel">Account</span>
                      </InputLabel>
                      <Select id="demo-multiple-checkbox"
                             data-test-id="handleChangeAccount"
                             labelId="demo-multiple-checkbox-label"
                             onChange={(event) => this.handleChangeAccount(event, setFieldValue)}
                             multiple
                             value={personName}
                             renderValue={(selected: any) => <span title={selected.map((x: any) => x.first_name).join(', ')} className="selectAccountText" >
                             {selected.map((x: any) => x.first_name).join(', ')}
                           </span>}
                             onScroll={this.handleScrollAccount}
                             IconComponent={ArrowDropDownIcon}
                             MenuProps={MenuProps}
                      >
                        {userJsonData.items.length > 0 && userJsonData.items.map((element: any) => (
                            <MenuItem value={element} key={element} >
                              <ListItemText
                                primary={element.attributes.first_name}
                              />
                              <Checkbox checked={personName.indexOf(element) > -1} />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl style={{paddingTop: "5px"}} className="leftP36">
                      <RadioGroup
                        row
                        aria-labelledby="demo-form-control-label-placement"
                        defaultValue="top"
                        name="position"
                      >
                        <FormControlLabel data-test-id="formLabel" style={{ fontSize: "17px", flexDirection: "row-reverse" }}
                          value={true} checked={values.send_to_all === true} label="Send to All" control={<Radio onClick={(event: any) => {
                            if (event.target.value) {
                              this.setState({ personNameErrors: "", sentObj: {}, personName: [] })
                            }
                            setFieldValue("send_to_all", !values.send_to_all)
                          }} />}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <FieldError
                    error={this.state.personNameErrors}
                    touched={true}
                  />
                  <Grid item xs={12}>
                    <form onSubmit={handleSubmit}>
                      <div className="createCardNoti">
                        <div className="postionRel borderBottomCss">
                          <Box className="customTextFieldBox">
                            <TextField
                              type="text"
                              name={"headings"}
                              data-test-id="createText"
                              onChange={(event: any) => {
                                setFieldValue("headings", event.target.value)
                                this.setState({
                                  sentObj: {
                                    ...this.state.sentObj,
                                    headings: event.target.value,
                                  },
                                  notificationError: event.target.value ? "" : "notification is required"
                                })
                              }}
                              onBlur={() => setFieldTouched("headings")}
                              value={values.headings} variant="outlined"
                              placeholder="Notification Title" />
                            <FieldError
                              error={errors.headings || this.state.notificationError}
                              touched={touched.headings || this.state.notificationError}
                            />
                          </Box>
                          <div className="timePostionDiv">
                            {this.state.createNotiData.id ? 
                              <input className="inputDateCust"
                                required min={new Date().toISOString().split('T')[0]} type="date" />
                              : <>
                                <div className="timePostionDiv" style={{ display: "flex", flexWrap: "wrap", justifyContent: "end", gap: "10px", padding: "10px" }}>
                                  <input
                                    className="inputDateCust"
                                    data-test-id="createInput"
                                    onChange={(event: any) => {
                                      setFieldValue("sent_date", event.target.value)
                                    }} value={values.sent_date} type="date" min={new Date().toISOString().split('T')[0]}
                                  />
                                  <FormControl variant="standard" className="timeSelectclass" style={{ width: "190px", marginLeft: "20px" }} >
                                    
                                    <input data-test-id="createInput1" className="inputDateCust" onChange={(event: any) => {
                                      setFieldValue("sent_time", event.target.value)
                                    }} value={values.sent_time} type="time"
                                    />
                                  </FormControl>
                                </div>
                                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "end", gap: "10px", padding: "10px" }} className="timePostionDiv">
                                  <FieldError
                                    error={errors.sent_date}
                                    touched={touched.sent_date}
                                  />
                                  <FieldError
                                    error={errors.sent_time}
                                    touched={touched.sent_time}
                                  />
                                </div>
                              </>
                            }
                          </div>
                        </div>
                        <Editor
                          editorClassName="editorClassName"
                          editorState={this.state.editorState}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          data-test-id="onEditorChange"
                          onEditorStateChange={(event: EditorState) => this.onEditorStateCreateChange(event,setFieldValue )}
                          toolbar={{
                            inline: { inDropdown: true },
                            list: { inDropdown: true },
                            textAlign: { inDropdown: true },
                            link: { inDropdown: true },
                            history: { inDropdown: true },
                          }}
                        />
                        <div style={{ display: "flex" }}>
                          <img style={{ width: "170px", height: "50px" }} src={`${images.iconLogo}`} />
                          <p>{this.state.onlyDate}</p>
                        </div>
                        <FieldError
                          error={errors.contents}
                          touched={touched.contents}
                        />
                      </div>
                      <div style={{ marginTop: "10px", display: "flex", alignItems: "center", justifyContent: "flex-end", flexWrap: "wrap", gap: "10px" }}>
                        <button type="submit" data-test-id="submit" onClick={() => this.setState({ draftBoolean: true })} style={{ ...stylesCreateNoti.buttonCreateNoti }} >
                          Save Draft
                        </button>
                        <button type="button" data-test-id="editorState"
                          onClick={() => {
                            this.setState({
                              editorState: EditorState.createEmpty(),
                              personName: [],
                              sentObj: {}
                            })
                            resetForm()
                          }}

                          style={{ ...stylesCreateNoti.buttonCreateNoti }} >
                          Reset All
                        </button>
                        <button style={{ ...stylesCreateNoti.activeButtonHead, background: "linear-gradient(180deg, #192C85 0%, #000000 100%)", padding: "0.8rem 1.5rem", border: "1px solid #0c143e", marginRight: 0 }} type="submit">Post Notification</button>
                      </div>

                    </form>
                  </Grid>
                </>
              );
            }}
          </Formik>
        </Grid>
      )
    }
    return (
      <ThemeProvider theme={theme}>
        <Box style={stylesCreateNoti.fullscreenCreateNoti} className="table_body">
          <Box style={stylesCreateNoti.sidebarParentBodyCreateNoti} className="width_0" >
            {isMobile ?
              <div
                style={{
                  position: "fixed", top: "10px",left:"12px", background: "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
                }}
              >
                <IconButton
                data-test-id="toggleBtn"
                  onClick={this.handleDrawerToggles}
                  style={{ color: 'white' }}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  open={this.state.mobileOpen}
                  onClose={this.handleDrawerToggles}
                  ModalProps={{
                    keepMounted: true,
                  }}
                >
                  {<SideBar
                    style={stylesCreateNoti.sidebar}
                    navigate={this.props.navigation.navigate}
                    activeMenuItem={"Notifications & Reminders"}
                    userData={this.state.userData}
                  />}

                </Drawer>
              </div> :
              <div style={stylesCreateNoti.sidebar} className="sidebarResponse ">
                <SideBar
                  navigate={this.props.navigation.navigate}
                  activeMenuItem={"Notifications & Reminders"}
                  userData={this.state.userData}
                />
              </div>}
          </Box>
          <Box style={stylesCreateNoti.divContainerCreateNoti} className="mainLftContainer">
            <div style={stylesCreateNoti.topNavBarCreateNoti}>
              <div style={stylesCreateNoti.bellIconCreateNoti} className="accessHeader">
                {/* <NotificationsActiveOutlinedIcon data-test-id='form' onClick={() => this.props.navigation.navigate("Notifications")} style={stylesCreateNoti.pointerCreateNoti} /> */}
              </div>
              <div style={stylesCreateNoti.pageTitleCreateNoti} className="padding_0_res">
                <NotificationsHeaderCreate state={this.state}
                  goToSentNotifications={this.goToSentNotifications}
                  goToReminders={this.goToReminders}
                  data-test-id="clickTab"
                  clickTab={this.clickTab}
                />
              </div>
              <Box className="bodyScrollBarHidden" style={{height: 'calc(100vh - 180px)'}}>
                <Box m={{ xs: "8px", md: "30px" }}>
                  <Box className="BoxFlex">
                    <SecondNotiHeaderCreate state={this.state}
                    data-test-id="goToCreate"
                      goToSentNotifications={this.goToSentNotifications}
                      goToCreate={this.goToCreate}
                      goToDraft={this.goToDraft}
                      goToScheduled={this.goToScheduled}
                      clickTab={this.clickTabNoti}
                    />
                  </Box>
                  {
                    getElement()
                  }
                </Box>
              </Box>
            </div>
          </Box>
          {
            getSnakbar()
          }
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
export default (withWidth()(CreateNotifications));
// Customizable Area Start
let stylesCreateNoti: any = {
  fullscreenCreateNoti: {
    position: "fixed",
    top: 0,
    bottom: 0,
    height: "100vh !important",
    minWidth: "100vw",
    fontFamily: "poppins !important",
    display: 'flex',
    boxSizing: 'border-box'
  },
  sidebar: {
    width: "24vw",
    fontFamily: 'Poppins',
    marginRight: "-100px",
    zIndex: "9",
    height: "100%",
    position: "fixed",
    bottom: 0,
    top: 0,
  },
  buttonCreateNoti: {
    fontSize: "17px",
    border: "1px solid black",
    borderRadius: "30px",
    padding: "0.8rem 1.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "black",
    cursor: "pointer"
  },
  divContainerCreateNoti: {
    gap: "10px",
    height: "100%",
    width: "calc(100% - 24%)",
    margin: "0 auto"
  },
  pointerCreateNoti: {
    fontFamily: 'Poppins',
    position: "fixed",
    right: "30px",
    top: "20px",
    color: "black",
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
  },
  activeButtonHead: {
    fontSize: "17px",
    color: "#fff",
    border: "none",
    borderRadius: "30px",
    padding: "0.4rem 1rem",
    marginRight: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  topNavBarCreateNoti: {
    width: "100%"
  },
  sidebarParentBodyCreateNoti: {
    width: '24%',
    height: '100vh',
  },
  bellIconCreateNoti: {
    textAlign: "right",
    width: "100%",
    height: "75px"
  },
  pageTitleCreateNoti: {
    borderTop: "1px solid rgba(128, 128, 128, 0.43)",
    padding: "0px 0px 0px 50px",
    boxShadow: "rgb(34, 34, 34) 0px 4px 6px -6px",
  },
}
// Customizable Area End

