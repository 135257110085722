import React from "react";
// Customizable Area Start
import SideBar from "../../../components/src/SideBar";
import {
  AppBar,
  TextField,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  Drawer,
  withWidth,
} from "@material-ui/core";
import { bellIcon, unreadBellIcon } from "./assets";
import Divider from "@material-ui/core/Divider";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import UnreadNotificationList from "../../../components/src/UnreadNotificationList.web";
import "../../email-account-login/src/Style.css";
const accountImages = require("./assets");
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
  typography: {
    fontFamily: "Poppins !important",
  },
});
// Customizable Area End
import AccountAccessController, { Props } from "./AccountAccessController";
export class AddAccount extends AccountAccessController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const { width } = this.props;
    const isMobile = /s/.test(width);
    const {
      usersUpdateError,
      showPassword,
      passwordError,
      selectedUserAccount,
      lastNameError,
      firstNameError,
      showAlert,
      userSuccessAlert,
      alertType,
      unreadNotificationRecords,
      isLoadingNoti,
      scrollPage,
      masterError,
      affiliatedError,
      deepestError,
      tinkerError,
    } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <div style={webStyle.addAccountScreen}>
          {isMobile ? (
            <div style={webStyle.sidebarIcon} className="sidebarIcon">
              <IconButton
                onClick={this.handleDrawerToggle}
                style={{ color: "white" }}
                data-test-id="handleDrawerToggle"
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                ModalProps={{
                  keepMounted: true,
                }}
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
              >
                {
                  <SideBar
                    activeMenuItem={"Accounts and Access"}
                    userData={this.state.userData}
                    style={webStyle.sidebar}
                    navigate={this.props.navigation.navigate}
                  />
                }
              </Drawer>
            </div>
          ) : (
            <div  style={webStyle.sidebar} className="sidebarResponse">
              <SideBar
              userData={this.state.userData}
                activeMenuItem={"Accounts and Access"}
                navigate={this.props.navigation.navigate}
              />
            </div>
          )}
          <div
            className="accessAddPageDefault"
            style={webStyle.pageDefaultScreen}
          >
            <AppBar position="fixed" style={webStyle.pageHeading}>
              {this.state.userData.userType !== "admin" && (
                <>
                  <img
                    src={
                      unreadNotificationRecords.length > 0
                        ? unreadBellIcon
                        : bellIcon
                    }
                    onClick={this.handleClickPopOver}
                    style={webStyle.notifications}
                    className="notiIconAdd"
                  />
                  <UnreadNotificationList
                    anchorEl={this.state.anchorElNoti}
                    handleClosePopOver={this.handleClosePopOver}
                    navigate={this.props.navigation.navigate}
                    notificationData={unreadNotificationRecords}
                    isLoading={isLoadingNoti}
                    unreadNotificationList={this.onScrollUnreadNotificationList}
                    handleClearAll={this.handleClearAllNoti}
                    scrollPage={scrollPage}
                  />
                </>
              )}
            </AppBar>
            <div style={webStyle.accountNavbars}>
              <Typography style={webStyle.accountHeading} className="addText">
                {selectedUserAccount ? "Edit Account" : "Add Account"}
              </Typography>
            </div>
            <div>
              <div
                style={webStyle.userAccountForm}
                className="userDetailsAccount"
              >
                <Typography
                  className="min_to_max"
                  style={{ ...webStyle.inputTitle, marginBottom: "20px" }}
                >
                  *Required items
                </Typography>
                <form
                  onSubmit={this.createUsersAccount}
                  ref={this.myFormRef}
                  data-test-id="formSubmit"
                  className="userDetailsForm"
                >
                  <div style={webStyle.userInputDetails}>
                    <div style={webStyle.formDetails} className="formDetails">
                      <Typography
                        className="min_to_max"
                        style={webStyle.inputTitle}
                      >
                        Account Name*:
                      </Typography>
                      <div className="inputName">
                        <TextField
                          placeholder="First Name"
                          inputProps={{
                            maxLength: 30,
                          }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          name="firstName"
                          className="nameDetails"
                          onChange={this.handelInput}
                          data-test-id="handelInput"
                          value={this.state.userFirstName}
                          style={
                            firstNameError
                              ? {
                                  ...webStyle.nameFieldError,
                                  marginRight: "30px",
                                }
                              : {
                                  ...webStyle.inputNameDetails,
                                  marginRight: "30px",
                                }
                          }
                        ></TextField>
                        <TextField
                          placeholder="Last Name"
                          inputProps={{
                            maxLength: 30,
                          }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          name="lastName"
                          className="nameDetails"
                          onChange={this.handelInput}
                          value={this.state.userLastName}
                          style={
                            lastNameError
                              ? webStyle.nameFieldError
                              : webStyle.inputNameDetails
                          }
                        ></TextField>
                      </div>
                    </div>
                    <div style={webStyle.formDetails} className="formDetails">
                      <Typography
                        className="min_to_max"
                        style={webStyle.inputTitle}
                      >
                        Login ID*:
                      </Typography>
                      <TextField
                        placeholder="Login ID"
                        inputProps={{
                          maxLength: 15,
                        }}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        name="loginID"
                        className="inputDetails"
                        onChange={this.handelInput}
                        value={this.state.loginID}
                        style={this.errorStyle(webStyle)}
                      ></TextField>
                    </div>
                    <div style={webStyle.formDetails} className="formDetails">
                      <Typography
                        className="min_to_max"
                        style={webStyle.inputTitle}
                      >
                        Email*:
                      </Typography>
                      <TextField
                        placeholder="Email"
                        name="email"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        className="inputDetails"
                        onChange={this.handelInput}
                        data-test-id="emailError"
                        value={this.state.email}
                        style={this.emailErrorStyle(webStyle)}
                      ></TextField>
                    </div>
                    <div style={webStyle.formDetails} className="formDetails">
                      <Typography
                        className="min_to_max"
                        style={webStyle.inputTitle}
                      >
                        MT5 Account Number:
                      </Typography>
                      <TextField
                        placeholder="MT Account Number"
                        name="MT5"
                        onChange={this.handelInput}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        style={webStyle.inputDetails}
                        className="inputDetails"
                        value={this.state.MT5}
                      ></TextField>
                    </div>
                    <div style={webStyle.formDetails} className="formDetails">
                      <Typography
                        className="min_to_max"
                        style={webStyle.inputTitle}
                      >
                        MT5 Server Name:
                      </Typography>
                      <TextField
                        placeholder="MT Server Name"
                        name="server"
                        onChange={this.handelInput}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        style={webStyle.inputDetails}
                        className="inputDetails"
                        value={this.state.serverName}
                      ></TextField>
                    </div>
                    <div style={webStyle.formDetails} className="formDetails">
                      <Typography
                        className="min_to_max"
                        style={webStyle.inputTitle}
                      >
                        Password*:
                      </Typography>
                      <TextField
                        type={showPassword ? "text" : "password"}
                        name="password"
                        data-test-id="togglePasswordVisibility"
                        placeholder="8-16 charcters,1 Uppercase, Special, Numbers"
                        inputProps={{
                          maxLength: 16,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={this.togglePasswordVisibility}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          disableUnderline: true,
                        }}
                        onChange={this.handelInput}
                        value={this.state.password}
                        className="inputDetails"
                        style={
                          passwordError
                            ? webStyle.loginFieldError
                            : webStyle.inputDetails
                        }
                      ></TextField>
                    </div>
                    <div style={webStyle.formDetails} className="formDetails">
                      <Typography
                        className="min_to_max"
                        style={webStyle.inputTitle}
                      >
                        Account Description:
                      </Typography>
                      <TextField
                        placeholder="Description"
                        name="description"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        onChange={this.handelInput}
                        className="inputDetails"
                        value={this.state.description}
                        style={webStyle.inputDetails}
                      ></TextField>
                    </div>
                    <div style={webStyle.formDetails} className="formDetails">
                      <Typography
                        className="min_to_max"
                        style={webStyle.inputTitle}
                      >
                        Tinkerbell Commission%:
                      </Typography>
                      <TextField
                        placeholder="Tinkerbell Commission"
                        name="tinkerbell"
                        data-test-id="tinkerbell"
                        // type="number"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        onChange={this.handelInputTinkerbell}
                        value={this.state.tinkerbell}
                        className="inputDetails"
                        style={this.handleFieldStyle(webStyle, tinkerError)}
                      ></TextField>
                    </div>
                    <div style={webStyle.formDetails} className="formDetails">
                      <Typography
                        className="min_to_max"
                        style={webStyle.inputTitle}
                      >
                        Deepest FX Commission%:{" "}
                      </Typography>
                      <TextField
                        placeholder="Deepest FX Commission"
                        name="deepest"
                        data-test-id="deepest"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        onChange={this.handelInputDeepest}
                        value={this.state.deepest}
                        className="inputDetails"
                        style={this.handleFieldStyle(webStyle, deepestError)}
                      ></TextField>
                    </div>
                    <div style={webStyle.formDetails} className="formDetails">
                      <Typography
                        className="min_to_max"
                        style={webStyle.inputTitle}
                      >
                        Affiliated Commission%:{" "}
                      </Typography>
                      <TextField
                        placeholder="Affiliated Commission"
                        name="affiliated"
                        data-test-id="affiliated"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        onChange={this.handelInputAffiliated}
                        value={this.state.affiliated}
                        style={this.handleFieldStyle(webStyle, affiliatedError)}
                        className="inputDetails"
                      ></TextField>
                    </div>
                    <div style={webStyle.formDetails} className="formDetails">
                      <Typography
                        className="min_to_max"
                        style={webStyle.inputTitle}
                      >
                        Master Trader%:{" "}
                      </Typography>
                      <TextField
                        placeholder="Master Trader"
                        name="master"
                        data-test-id="master"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        onChange={this.handelInputMaster}
                        value={this.state.master}
                        style={this.handleFieldStyle(webStyle, masterError)}
                        className="inputDetails"
                      ></TextField>
                    </div>
                    {selectedUserAccount ? (
                      ""
                    ) : (
                      <div
                        style={webStyle.dividerContainer}
                        className="dividerContainer"
                      >
                        <Divider style={{ flexGrow: 1 }} />
                        <Typography
                          variant="body2"
                          style={{ margin: "0 16px", fontSize: "20px" }}
                        >
                          or
                        </Typography>
                        <Divider style={{ flexGrow: 1 }} />
                      </div>
                    )}
                    {selectedUserAccount ? (
                      ""
                    ) : (
                      <div style={webStyle.formDetails} className="formDetails">
                        <Typography
                          style={webStyle.inputFileTitle}
                          className="inputFileTitle"
                        >
                          Upload New Account information as a CSV or Excel File
                        </Typography>
                        <TextField
                          data-test-id="fileInput"
                          type="text"
                          placeholder="File Browser"
                          style={webStyle.inputImageDetails}
                          className="inputDetails"
                          InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                <label htmlFor="file-upload">
                                  <img
                                    src={`${accountImages.fileUpload}`}
                                    style={webStyle.uploadImage}
                                  />
                                </label>
                                <input
                                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                  style={{ display: "none" }}
                                  type="file"
                                  id="file-upload"
                                  onChange={this.handelChangeInput}
                                />
                              </InputAdornment>
                            ),
                          }}
                          value={
                            this.state.userImage
                              ? this.state.userImage.name
                              : ""
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div style={webStyle.formAction} className="formAction">
                    <Button
                      style={webStyle.resetFormBtn}
                      onClick={(e: any) => {
                        this.resetUserDetails(e);
                      }}
                      data-test-id="resetUserDetails"
                      className="button_width"
                    >
                      Reset Form
                    </Button>
                    {selectedUserAccount ? (
                      <Button
                        style={webStyle.btnAddAcount}
                        type="submit"
                        data-testId="editUser"
                        className="button_width"
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        style={webStyle.btnAddAcount}
                        type="submit"
                        test-id="addAccount"
                        className="button_width"
                        disabled={!this.state.validFile}
                      >
                        Add Account
                      </Button>
                    )}
                  </div>
                </form>
              </div>
            </div>
            <Snackbar
              open={showAlert}
              autoHideDuration={3000}
              onClose={this.handleAlertClose}
              data-test-id="handleAlertClose"
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <MuiAlert
                onClose={this.handleAlertClose}
                severity={alertType}
                elevation={6}
                variant="filled"
                style={{ width: "100%" }}
              >
                {alertType === "success"
                  ? `${userSuccessAlert}`
                  : `${usersUpdateError}`}
              </MuiAlert>
            </Snackbar>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}
export default withWidth()(AddAccount);

// Customizable Area End

// Customizable Area Start
const webStyle: any = {
  pageHeading: {
    backgroundColor: "white",
    fontFamily: "Poppins",
    width: "100%",
    height: "75px",
    borderBottom: "1px solid rgba(128, 128, 128, 0.43)",
    boxShadow: "none",
    borderLeft: "none",
    zIndex: "2",
  },
  addAccountScreen: {
    fontFamily: "Poppins",
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    margin: 0,
    overflowX: "hidden",
  },
  sidebar: {
    fontFamily: "Poppins",
    position: "fixed",
    top: "0",
    zIndex: "9",
    height: "100%",
    width: "24vw",
    marginRight: "-100px",
  },
  pageDefaultScreen: {
    fontFamily: "Poppins",
    height: "100%",
    width: "79vw",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    top: "100px",
    marginLeft: "21vw",
  },
  accountNavbars: {
    fontFamily: "Poppins",
    display: "flex",
    justifyContent: "space-between",
    // margin: "5px 7%",
    // width: "95%",
    margin: "5px 7%",
    width: "72%",
  },

  accountHeading: {
    fontFamily: "Poppins",
    fontSize: "25px",
    fontWeight: 500,
    marginLeft: "9%",
  },
  btnAddAcount: {
    width: "152px",
    fontFamily: "Poppins",
    padding: "20px 20px",
    height: "42px",
    background:
      "linear-gradient(0deg, rgba(16, 41, 114, 1.0), rgba(2, 5, 10, 1.0))",
    borderRadius: "20px",
    color: "white",
    fontSize: "15px",
    textTransform: "Capitalize",
    marginRight: "3%",
    lineHeight: "1",
  },
  notifications: {
    fontFamily: "Poppins",
    position: "fixed",
    right: "30px",
    top: "20px",
    color: "black",
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
  },
  userAccountForm: {
    fontFamily: "Poppins",
    margin: "5px 13% 5px 13%",
    width: "72%",
  },
  formDetails: {
    fontFamily: "Poppins",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  inputDetails: {
    fontFamily: "Poppins",
    width: "542px",
    marginBottom: "20px",
    border: "1px solid gray",
    borderRadius: "5px",
    padding: "10px 10px 10px 10px",
  },
  loginFieldError: {
    fontFamily: "Poppins",
    width: "542px",
    marginBottom: "20px",
    border: "1px solid red",
    borderRadius: "5px",
    padding: "10px",
  },
  inputImageDetails: {
    fontFamily: "Poppins",
    width: "492px",
    border: "1px solid gray",
    borderRadius: "5px",
    padding: "10px",
    height: "28px",
  },
  userInputDetails: {
    fontFamily: "Poppins",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  resetFormBtn: {
    width: "152px",
    fontFamily: "Poppins",
    padding: "20px 20px",
    height: "42px",
    borderRadius: "20px",
    color: "black",
    fontSize: "15px",
    textTransform: "Capitalize",
    border: "1px solid black",
    lineHeight: 1,
    marginRight: "10px",
    // marginRight: "30px",
  },
  inputNameDetails: {
    fontFamily: "Poppins",
    width: "244px",
    marginBottom: "20px",
    border: "1px solid gray",
    borderRadius: "5px",
    padding: "10px",
  },
  nameFieldError: {
    fontFamily: "Poppins",
    width: "244px",
    marginBottom: "20px",
    border: "1px solid red",
    borderRadius: "5px",
    padding: "10px",
  },
  inputTitle: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 400,
    marginTop: "12px",
  },
  dividerContainer: {
    fontFamily: "Poppins",
    display: "flex",
    alignItems: "center",
    width: "565px",
    marginLeft: "auto",
    // margin: "2% 0 4% 38%",
    // marginLeft:"auto",
    marginTop: "10px",
  },
  uploadImage: {
    fontFamily: "Poppins",
    width: 15,
    height: 20,
    backgroundColor: "none",
  },
  formAction: {
    fontFamily: "Poppins",
    display: "flex",
    // justifyContent: "space-between",
    marginTop: "20px",
    // marginRight:"-40px"
    float: "right",
    marginBottom: "20px",
  },
  inputFileTitle: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    width: "34%",
  },
  sidebarIcon: {
    position: "fixed",
    top: 10,
    left: 12,
    background:
      "linear-gradient(207.8deg, rgba(0, 0, 0, 0.95) 37.06%, rgba(25, 44, 133, 0.95) 106.22%)",
  },
};
// Customizable Area End
